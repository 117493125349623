<template>
    <v-sheet class="d-flex rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <v-sheet class="d-inline-block" :width="midAreaWidth" height="835" color="midAreaColor">
            <div class="text-h4 px-2 mt-1">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span>4-4. 프로그램&nbsp;수급자&nbsp;그룹관리</span>
                </span>
            </div>
            <div class="d-flex mx-3 mt-5">
                <CmmExpnBtn
                    v-model="isEntStt"
                    :btnNm="'입소현황'" vTyp="1" :bImg="'ic-house'"
                    :iList="vCd65" @onList="dmyTxt = String(Number(dmyTxt)+1)">
                </CmmExpnBtn>
                <CmmLvnExpnBtn
                    v-model="isLvnStt"
                    class="ml-1"
                    @onList="lvnSttApply">
                </CmmLvnExpnBtn>

                <exBtn class="ml-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
            
                <v-spacer></v-spacer>
                
                <span class="d-inline-block" style="width: 106px; height: 34px;">
                    <v-text-field
                        v-model="srchTxt" @input="dmyTxt = String(Number(dmyTxt)+1)"
                        class="txt-Theme" height="34" label="이름조회"
                        background-color="white" prepend-inner-icon="mdi-magnify"
                        single-line outlined dense filled hide-details>
                    </v-text-field>
                </span>
            </div>
            <div class="mt-2 mx-3 rounded-lg">
                <v-data-table class="bnfcrMidTable-Theme rounded" height="690" locale="ko"
                    :headers="headers" :items="bnfcrList"  item-key="rn"
                    :loading="isLoad" :loading-text="loadMsg"                    
                    :search="dmyTxt" :custom-filter="srchFltr"
                    fixed-header disable-pagination hide-default-footer dense>
                    <template v-slot:item='{ item, index }'>
                        <tr :class="[selectedItem == item.bnMmbrPk ? 'onClr' : '']" @click="tableClickEvent(item.bnMmbrPk)">
                            <td
                                class="fontOneRem txtAlgnMid allHdrFix hvrClr fixPstn0 zPstn2 py-2 pl-2 pr-6"
                                style="background-color: white;">
                                {{index+1}}
                            </td>
                            <td
                                class="fontOneRem txtAlgnMid allHdrFix hvrClr fixPstn8 zPstn2 pl-2 pr-6"
                                style="background-color: white;">
                                <span class="overflow-text-hidden d-inline-block" style="width: 51px;">{{item.name}}</span>
                            </td>
                            <td class="px-3">
                                <span class="d-inline-block" style="width: 100%;">
                                    <span class="d-inline-block pt-1">
                                        <v-img class="ic-sex" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                        <v-img class="ic-sex" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                    </span>
                                </span>
                            </td>
                            <td class="fontOneRem txtAlgnMid pl-2 pr-6">
                                {{item.age}}
                            </td>
                            <td class="px-3">
                                <span :class="'ic-level level0'+item.acknwRtng">
                                    {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                </span>
                            </td>
                            <td class="fontOneRem txtAlgnMid pl-2 pr-6">
                                <span class="overflow-text-hidden d-inline-block" style="width: 51px;">{{item.lvnRmNm}}</span>
                            </td>
                            <td class="fontOneRem txtAlgnMid pl-2 pr-6">
                                {{item.entStcdNm}}
                            </td>
                            <td class="fontOneRem txtAlgnMid pl-2 pr-5">
                                {{item.acknwPrdEnd}}
                            </td>
                        </tr>
                    </template>
                    <template v-slot:foot='{ items, pagination }'>
                        <div class="bnfcrMidTable-Foot d-inline-block">
                            <v-row class="pa-0 lign-center" no-gutters>
                                <span class="fontOneRem mr-2">전체: {{ pagination.itemsLength }}명,</span>
                                <span class="fontOneRem mr-2">남: {{ items.filter( v => v.gndr == 'M').length }}명,</span>
                                <span class="fontOneRem">여: {{ items.filter( v => v.gndr == 'F').length }}명</span>
                            </v-row>
                        </div>
                    </template>
                </v-data-table>
            </div>
        </v-sheet>
        <router-view ref="prgmChild"></router-view>
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';
import { http } from '@/api/baseAxios';

import CmmExpnBtn from '../../components/commons/CmmExpnBtn';
import CmmLvnExpnBtn from '../../components/commons/CmmLvnExpnBtn';
import exBtn from '../../components/commons/expandButtonCustom.vue';

export default {
    name: 'BnfcrAdv1',
    
    components: {
        CmmExpnBtn,
        CmmLvnExpnBtn,
        exBtn,
    },

    props : {

    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                this.selectedItem = this.$store.getters.getBnfcrState.bnmmbr
            }
            this.getCode(['62', '65'])
            this.getBnfcrList()
        })
    },

    computed: {
    
    },  

    watch: {
        'selectedItem': function () {
            if(this.selectedItem == -1){
                this.$store.commit('setBnfcrState', {
                    bnmmbr: 0, bnfcr: 0, name: '', entStcd: '',  entDt: '', lvDt: ''
                });
            }
            else{
                let chc = this.bnfcrList.findIndex( v => v.bnMmbrPk == this.selectedItem )
                if(chc > -1){
                    this.$store.commit('setBnfcrState', {
                        bnmmbr: this.selectedItem,
                        bnfcr: this.bnfcrList[chc].bnfcrPk,
                        name: this.bnfcrList[chc].name,
                        entStcd: this.bnfcrList[chc].entStcd,
                        entDt: this.bnfcrList[chc].entDt,
                        lvDt: ''
                    });
                }
            }
        },
    },

    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        getBnfcrList: function (){
            this.isLoad = true

            http.post('bnfcr/selAllBnfcrList', {})
                .then((response) => ( this.getBnfcrListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selAllBnfcrList : ' + error))
        },
        stCdMapping: function (res) {

            res.vCd62.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach

            if(res.vCd65.length > 0)
                res.vCd65[0].isOn = true

            this.vCd62 = res.vCd62
            this.vCd65 = res.vCd65

        },
        getBnfcrListAfter: function (res){
            this.isLoad = false

            let me = this
            res.forEach(items => {
                items.acknwPrdEnd = items.acknwPrdEnd.beforeDateFormatDot()
              
                me.vCd65.forEach(code => {
                    if(items.entStcd == code.valcd) items.entStcdNm = code.valcdnm
                });
            });

            this.bnfcrList = res
            this.dmyTxt = String(Number(this.dmyTxt)+1)

        },
        tableClickEvent: function (index){
            if(this.selectedItem == -1)
                this.selectedItem = index
            else if(this.selectedItem != index)
                this.selectedItem = index
            else
                this.selectedItem = -1
        },        
        filterSearch: function (sch) {
            switch(this.schChkField.target) {               
                case 'acknwRtng': this.schChkField.acknwRtng.splice(0)
                    sch.forEach((item) => {
                        if(item == '등급외')
                            this.schChkField.acknwRtng.push('99')
                        else
                            this.schChkField.acknwRtng.push(item.replaceAll('등급', ''))
                    });
                    break;
            }
          
            this.schDummy = this.schDummy + '.'
        },        
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'acknwRtng'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        // 테이블 검색 필터 (value: list, search: search key, filter: array column)
        srchFltr: function (value, search, filter){

            let field1 = false
            let field2 = true
            let field3 = true
            let field4 = true

            // 이름 검색
            field1 = filter.name.includes(this.srchTxt)

            // 입소현황 검색
            let entRunChc = this.vCd65.filter( v => v.isOn == true )

            if(entRunChc.length == 0)
                field2 = false
            else{
                entRunChc.forEach((itm, i) => {
                    if(i == 0) field2 = filter.entStcd == itm.valcd
                    else field2 = field2 || filter.entStcd == itm.valcd
                });
            }

            // 생활실 검색
            let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )
            if(lvnRunChc.length == 0)
                field3 = false
            else{
                lvnRunChc.forEach((itm, i) => {
                    if(i == 0) field3 = filter.lvnRmInfPk == itm.lvnRmInfPk
                    else field3 = field3 || filter.lvnRmInfPk == itm.lvnRmInfPk
                });
            }

            if(this.schChkField.acknwRtng.length != 0){
                this.schChkField.acknwRtng.forEach((stcd, i) => {
                    if(i == 0) field4 = filter.acknwRtng.includes(stcd)
                    else field4 = field4 || filter.acknwRtng.includes(stcd)
                });
            }

            return field1 && field2 && field3 && field4
        },
        
        // 생활실 선택 컴포넌트 변경 이벤트
        lvnSttApply: function (arr){
            this.aslLvnStt = arr

            this.dmyTxt = String(Number(this.dmyTxt)+1)
        },
    },
    data: () => ({
        isLoad: true,
        loadMsg: '잠시만 기다려주세요.',
        midAreaWidth: '550',
        selectedItem: -1,
        bnfcrList: [],
        vCd62: [],
        vCd65: [],
        schField: '',
        schDummy: '',
        schChkField: { acknwRtng: [], target: '' },
        srchTxt: '',                            // 테이블 이름 검색 필드
        dmyTxt: '',                             // 테이블 반응 더미(검색용)
        isEntStt: false,                        // 현황 확장 활성화 여부
        isLvnStt: false,                        // 생활실 확장 활성화 여부
        headers: [
            { text: '연번', value: 'rn', sortable: true, align:'center', width: '70', class: 'black--text bottomBorder allHdrFix fixPstn0 zPstn3 py-3 px-2'},
            { text: '수급자', value: 'name', sortable: true, align:'center', width: '80', class: 'black--text bottomBorder allHdrFix fixPstn8 zPstn3 px-2'},
            { text: '성별', value: 'gndr', sortable: true, align:'center', width: '60', class: 'black--text bottomBorder px-2'},
            { text: '나이', value: 'age', sortable: true, align:'center', width: '60', class: 'black--text bottomBorder px-2'},
            { text: '등급', value: 'acknwRtng', sortable: true, align:'center', width: '60', class: 'black--text bottomBorder px-2'},
            { text: '생활실', value: 'lvnRmNm', sortable: true, align:'center', width: '80', class: 'black--text bottomBorder px-2'},
            { text: '현황', value: 'entStcd', sortable: true, align:'center', width: '80', class: 'black--text bottomBorder px-2'},
            { text: '인정종료일', value: 'acknwPrdEnd', sortable: true, align:'center', width: '99', class: 'black--text bottomBorder px-2'},
        ],
        exBtn: [
            { index:  0, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 198, expandList: [], },
        ],
    }),
};
</script>