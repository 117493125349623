<template>
     <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">계약기간 수정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-form
            class="overflow-y-auto mt-4 pb-4"
            ref="cntrcInf"
            lazy-validation>
            <v-row class="pa-0 mb-2 align-center posn-rltv" no-gutters>
                <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                    <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                </span>
                <span class="fontOneDotTwoRem font-weight-medium ml-6">계약정보</span>
            </v-row>
            <v-divider class="mr-1" color="black"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                    <span class="font-weight-medium">계약일</span>
                </v-col>
                <v-col class="tableBR px-2" style="padding-top: 6px;" cols="3">
                    <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                        {{ cntrcInpInf.cntrcDt }}
                    </span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                    <span class="font-weight-medium">계약기간</span>
                </v-col>
                <v-col class="px-2 py-1" cols="5">
                    <span>{{ cntrcInpInf.cntrcPrdStrt }}</span>
                    <span class="mx-2">
                        <v-icon size="16" style="color: #dbdbdb;">mdi-tilde</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 136px; height: 100%;">
                        <CmmDateComp
                            v-model="cntrcInpInf.cntrcPrdEnd"
                            :minDt="cntrcInpInf.cntrcPrdStrt" :required="true"
                            @input="aplctSavBtn">
                        </CmmDateComp>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
        </v-form>
        <v-divider class="white my-3"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white my-1"></v-divider>
     </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import CmmDateComp from '../../commons/CmmDateComp.vue';         // 날짜 입력

export default {
    name: 'CntrcDtSavMdl',
    
    components: {
        CmmDateComp
    },

    props : { 

    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getRootInObj
            this.aplctInf(obj)
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '1'){
                let insObj = {}
                insObj.bnfcrCntrcInfPk = this.cntrcInpInf.bnfcrCntrcInfPk
                insObj.cntrcPrdEnd = this.$moment(this.cntrcInpInf.cntrcPrdEnd, 'YYYY-MM-DD').format('YYYYMMDD')

                this.updCntrcPrd(insObj)

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.savBtn = false
                this.runAble = '0'
            }
        },
    },
        
    methods: {
        // 계약기간 변경
        updCntrcPrd: function (obj){
            http.post('/bnfcrAuth/updBnfcrCntrcPrdEnd', obj)
                .then((response) => ( this.updCntrcPrdRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/updBnfcrCntrcPrdEnd : ' + error))
        },

        updCntrcPrdRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 3500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200')
                this.$parent.$parent.$parent.$parent.mdlOff()
            else{
                if(Array.isArray(res.messageList))
                    obj.cntnt = res.messageList

                obj.tmr = 4500
                obj.type = 'warning'
            }

            this.$store.commit('setWrnnInf', obj)

            this.savBtn = false
        },

        // 계약 저장 전 확인
        valiCntrcInf: function (obj){
            http.post('/bnfcrAuth/validCntrcInfo', obj)
                .then((response) => ( this.valiCntrcRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/validCntrcInfo : ' + error))
        },

        valiCntrcRst: function (res){
            console.log(res)
            // 200 통과, 205 확인, 500 불가

            let insObj = {}
            insObj.bnfcrCntrcInfPk = this.cntrcInpInf.bnfcrCntrcInfPk
            insObj.cntrcPrdEnd = this.$moment(this.cntrcInpInf.cntrcPrdEnd, 'YYYY-MM-DD').format('YYYYMMDD')

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = res.message
            obj.cntnt = []
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 수정하겠습니다.'

            let wrnObj = {}
            wrnObj.actv = true                             // 경고 노출여부
            wrnObj.tmr = 5000                              // 경고 노출시간 (-1: 무제한)
            wrnObj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
            wrnObj.title = res.message                     // 경고 타이틀
            wrnObj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            res.messageList.forEach(itm => {
                obj.cntnt.push(itm)
            });

            let exObj = {}

            switch (res.statusCode) {
                case '200':
                    this.updCntrcPrd(insObj)
                    break;
                case '205':
                    this.$store.commit('setSubInf', {
                        actv: true, mxWdth: 600, prsst: true, typ: '1001', inObj: obj, outObj: exObj, runEvt: false,
                    });

                    this.runAble = '1'
                    break;
                case '500':
                    if(Array.isArray(res.messageList))
                        wrnObj.cntnt = res.messageList

                    this.$store.commit('setWrnnInf', wrnObj)

                    this.savBtn = false
                    break;
                default:
                    break;
            }

        },

        // 기본정보 적용
        aplctInf: function (obj) {
            let cntrcPrd = obj.cntrcprd.split('~')

            this.cntrcInpInf.bnMmbrPk = obj.bnMmbrPk
            this.cntrcInpInf.bnfcrPk = obj.bnfcrPk
            this.cntrcInpInf.bnfcrCntrcInfPk = obj.bnfcrCntrcInfPk
            this.cntrcInpInf.cntrcDt = this.$moment(obj.cntrcDt, 'YYYYMMDD').format('YYYY-MM-DD')
            this.cntrcInpInf.cntrcPrdStrt = this.$moment(cntrcPrd[0], 'YYYY.MM.DD').format('YYYY-MM-DD')
            this.cntrcInpInf.cntrcPrdEnd = this.$moment(cntrcPrd[1], 'YYYY.MM.DD').format('YYYY-MM-DD')
            this.cntrcInpInf.entDt = this.$moment(obj.entDt, 'YYYYMMDD').format('YYYY-MM-DD')

            this.aplctSavBtn()
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.cntrcInpInf.cntrcDt) == false)
                return 0
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.cntrcInpInf.cntrcPrdStrt) == false)
                return 0
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.cntrcInpInf.cntrcPrdEnd) == false)
                return 0
            if(this.$moment(this.cntrcInpInf.entDt).isAfter(this.$moment(this.cntrcInpInf.cntrcDt)))
                return 0
            if(this.$moment(this.cntrcInpInf.cntrcPrdStrt).isAfter(this.$moment(this.cntrcInpInf.cntrcPrdEnd)))
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                                      // 경고 노출여부
            obj.tmr = 3500                                       // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                                 // 경고 유형 (success, info, warning, error)
            obj.title = '필수요소가 부족하여 저장 할 수 없습니다.'    // 경고 타이틀
            obj.cntnt = []                                       // 경고 내용 (arr만 출력됨!)

            let insObj = {}
            insObj.bnMmbrPk = this.cntrcInpInf.bnMmbrPk
            insObj.bnfcrPk = this.cntrcInpInf.bnfcrPk
            insObj.bnfcrCntrcInfPk = this.cntrcInpInf.bnfcrCntrcInfPk
            insObj.cntrcPrdStrt = this.$moment(this.cntrcInpInf.cntrcPrdStrt, 'YYYY-MM-DD').format('YYYYMMDD')
            insObj.cntrcPrdEnd = this.$moment(this.cntrcInpInf.cntrcPrdEnd, 'YYYY-MM-DD').format('YYYYMMDD')

            //console.log(insObj)
            this.valiCntrcInf(insObj)
        },
    },

    data: () => ({
        savBtn: true,
        runAble: '0',
        cntrcInpInf: {
            bnMmbrPk: -1, bnfcrPk: -1, bnfcrCntrcInfPk: -1, cntrcDt: '', cntrcPrdStrt: '', cntrcPrdEnd: '', entDt: ''
        },
    }),
};
</script>

<!--
inqYmdStart
inqYmdEnd
-->