<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">{{ ttl }}</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-3 mb-2 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span v-if="bscsInf.srvcClcd == '2'" class="fontOneDotTwoRem font-weight-medium ml-6">서비스 이용량</span>
            <span v-else class="fontOneDotTwoRem font-weight-medium ml-6">서비스 이용기간</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row v-if="bscsInf.srvcClcd == '1'" class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">수급자수</span>
            </v-col>
            <v-col class="px-2 py-1" cols="3">
                <span class="fontOneRem">{{ bscsInf.bnfcrCnt }}명</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">이용요금 &#40; 부가세 포함 &#41;</span>
            </v-col>
            <v-col class="px-2 py-1" cols="4">
                <span class="fontOneRem">{{ bscsInf.useAmt?.toLocaleString('ko-KR') }}원</span>
            </v-col>
        </v-row>
        <v-row v-else-if="bscsInf.srvcClcd == '2'" class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium">문자건수</span>
            </v-col>
            <v-col class="tableBR px-2" style="padding-top: 6px;" cols="3">
                <span class="d-inline-block" style="width: 90px;">
                    <v-select
                        v-model="sltPrd"
                        :items="payMsg" @change="calcYysSvc"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>
                </span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium">잔여 문자건수</span>
            </v-col>
            <v-col class="txtAlgnEnd py-2 px-4" cols="3">
                <span class="fontOneRem">{{ bscsInf.mmsSvcRmnCnt }}건</span>
            </v-col>
        </v-row>
        <v-row v-else-if="bscsInf.srvcClcd == '3'" class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                <span class="font-weight-medium">홈페이지명</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1 px-1" cols="2">
                <span class="fontOneRem">{{ fcltyInf.cmpnNm }}</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                <span class="font-weight-medium">도메인</span>
            </v-col>
            <v-col class="txtAlgnMid py-1 px-1" cols="2">
                <span class="fontOneRem">yoyangone.co.kr</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 6px;" cols="2">
                <span class="font-weight-medium">결제기간</span>
            </v-col>
            <v-col class="px-4 py-1" cols="2">
                <span class="d-inline-block" style="width: 90px;">
                    <v-select
                        v-model="sltPrd"
                        :items="usePrd" @change="calcYysSvc"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row v-if="bscsInf.srvcClcd == '1'" class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="2">
                <span class="font-weight-medium">결제기간</span>
            </v-col>
            <v-col class="px-2" style="padding-top: 6px;" cols="3">
                <span class="d-inline-block" style="width: 86px;">
                    <v-select
                        v-model="sltPrd"
                        :items="payPrd" @change="calcYysSvc"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>
                </span>
            </v-col>
            <v-col
                :class="['nmlBfClrImp-greyE01 tableBR py-2 px-8', Number(sltPrd) < 2 ? 'txtAlgnMid' : '']"
                cols="3">
                <span class="font-weight-medium">이용기간</span>
                <span v-for="(itm, i) in payPrd.filter( v => v.valcd == sltPrd )" :key="i" class="ml-1">
                    <span v-if="(itm.valdesc - itm.valprd) > 0" class="posn-rltv">
                        <span
                            class="rounded d-flex yellow001"
                            style="position: absolute; top: -2px; left: 6px; padding: 4px 6px 2px 6px !important;">
                            <span class="d-inline-block fontOneRem" style="width: 66px; height: 100%;">
                                {{ itm.valdesc - itm.valprd }}개월 추가
                            </span>
                        </span>
                    </span>
                </span>
            </v-col>
            <v-col class="pa-2" cols="4">
                <span class="fontOneRem">{{ bscsInf.yysSvcEndDt }}</span>
                <span class="mx-1">
                    <v-icon class="" size="12">mdi-tilde</v-icon>
                </span>
                <span class="fontOneRem">{{ bscsInf.prdEndDt }}</span>
                <!-- payPrd: [       // 결제기간
                    { valcd: '1', valcdnm: '1개월', valdesc: 1, valprd: 1 }, { valcd: '2', valcdnm: '6개월', valdesc: 7, valprd: 6 },
                    { valcd: '3', valcdnm: '12개월', valdesc: 15, valprd: 12 }
                ] -->
            </v-col>
        </v-row>
        <v-row v-else-if="bscsInf.srvcClcd == '2'" class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">이용요금 &#40; 부가세 포함 &#41;</span>
            </v-col>
            <v-col class="txtAlgnEnd tableBR px-4 py-1" cols="3">
                <span class="fontOneRem">{{ bscsInf.useAmt?.toLocaleString('ko-KR') }}원</span>
            </v-col>
        </v-row>
        <v-row v-else-if="bscsInf.srvcClcd == '3'" class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">이용요금 &#40; 부가세 포함 &#41;</span>
            </v-col>
            <v-col class="txtAlgnEnd tableBR px-4 py-1" cols="3">
                <span class="fontOneRem">{{ bscsInf.useAmt?.toLocaleString('ko-KR') }}원</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">이용기간</span>
            </v-col>
            <v-col class="py-1 px-2" cols="3">
                <span class="fontOneRem">{{ bscsInf.yysSvcEndDt }}</span>
                <span class="mx-1">
                    <v-icon class="" size="12">mdi-tilde</v-icon>
                </span>
                <span class="fontOneRem">{{ bscsInf.prdEndDt }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <div
            v-if="bscsInf.srvcClcd == '1'"
            class="brdSurrd mt-2 mr-1 mb-3 px-6 py-2"
            style="background-color: #f4f4f4 !important;">
            <p class="fontOneRem mb-0" style="margin-top: 2px !important;">
                ※ 결제 기준 수급자 수는 입소중인 수급자 수입니다.
                <br/>
                ※ 결제 이후 수급자 수가 감소해도 환불 처리되지 않습니다.
                <br/>
                ※ 결제 이후 수급자 수가 증가해도 추가 결제가 필요하지 않습니다.
            </p>
        </div>
        <v-row class="pa-0 mt-4 mb-2 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">결제수단 선택</span>
        </v-row>
        <v-tabs
            v-model="tab"
            class="tab-small-Theme mr-1" style="height: 30px !important;" active-class="yellow002"
            center-active hide-slider>
            <v-tab
                v-for="item in tabItems" :key="item.tab"
                :class="item.class" :disabled="item.enter">
                {{ item.tab }}
            </v-tab>
        </v-tabs>
        <v-divider class="yellow002 mr-1 mb-1" style="padding: 1px 0px 0px 0px;"></v-divider>
        <v-tabs-items v-model="tab">
            <v-tab-item transition="false" eager>
                <v-divider class="mr-1 mt-1"></v-divider>
                <v-row class="pa-0 mr-1" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                        <span class="font-weight-medium">예금주</span>
                    </v-col>
                    <v-col class="tableBR px-4" style="padding-top: 3px;" cols="4">
                        <span class="fontOneRem">주식회사 해리지앤디</span>
                    </v-col>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                        <span class="font-weight-medium">입금은행 (계좌)</span>
                    </v-col>
                    <v-col class="px-4" style="padding-top: 3px;" cols="4">
                        <span class="fontOneRem">수협은행 &#40; 123-4567-89645 &#41;</span>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
                <v-row class="pa-0 mr-1" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                        <span class="font-weight-medium posn-rltv">
                            입금자명
                            <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                <v-icon color="red">mdi-star-four-points-small</v-icon>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="tableBR px-2 py-1" cols="4">
                        <v-text-field
                            v-model="bnkTrnsInf.dpstrNm" @input="aplctSavBtn"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </v-col>
                    <v-col class="nmlBfClrImp-greyE01 pl-3 tableBR" style="padding-top: 7px;" cols="2">
                        <span class="font-weight-medium posn-rltv">
                            입금자 연락처
                            <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                <v-icon color="red">mdi-star-four-points-small</v-icon>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="px-2 py-1" cols="4">
                        <span class="d-inline-block" style="width: 50px; height: 100%">
                            <v-text-field
                                v-model="bnkTrnsInf.dpstrTel1" @input="aplctSavBtn"
                                height="28" hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <span class="mx-1">
                            <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                        </span>
                        <span class="d-inline-block" style="width: 60px; height: 100%">
                            <v-text-field
                                v-model="bnkTrnsInf.dpstrTel2" @input="aplctSavBtn"
                                height="28" hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <span class="mx-1">
                            <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                        </span>
                        <span class="d-inline-block" style="width: 60px; height: 100%">
                            <v-text-field
                                v-model="bnkTrnsInf.dpstrTel3" @input="aplctSavBtn"
                                height="28" hide-details outlined dense>
                            </v-text-field>
                        </span>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
                <v-row class="pa-0 mr-1" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                        <span class="font-weight-medium">환불받을은행</span>
                    </v-col>
                    <v-col class="tableBR px-2 py-1" cols="4">
                        <span class="d-inline-block" style="width: 120px;">
                            <v-select
                                v-model="bnkTrnsInf.dpstrBnkCd"
                                :items="bnkList" :disabled="bnkList.length == 0"
                                item-text="bnkNm" item-value="bnkCd"
                                dense hide-details outlined>
                            </v-select>
                        </span>
                    </v-col>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                        <span class="font-weight-medium">환불받을계좌</span>
                    </v-col>
                    <v-col class="px-2 py-1" cols="4">
                        <v-text-field
                            v-model="bnkTrnsInf.dpstrAcct" @input="aplctSavBtn"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-divider class="mr-1 mb-2"></v-divider>
            </v-tab-item>
            <v-tab-item transition="false" eager>
                <div
                    class="brdSurrd mt-1 mr-1 px-6 py-2"
                    style="background-color: #f4f4f4 !important;">
                    <p class="fontOneRem mb-0" style="margin-top: 2px !important;">
                        ※ 해외에서 발급된 신용카드로는 결제가 불가능합니다.
                    </p>
                    <p class="fontOneRem ma-0 pa-0">
                        ※ 세금계산서는 따로 발행이 되지 않으며, 신용카드 매출전표를 매입세금계산서로 사용하실 수 있습니다.
                    </p>
                </div>
            </v-tab-item>
            <v-tab-item transition="false" eager>
                <div
                    class="brdSurrd mt-1 mr-1 mb-4 px-6 py-2"
                    style="background-color: #f4f4f4 !important;">
                    <p class="fontOneRem mb-0" style="margin-top: 2px !important;">
                        ※ '결제' 버튼을 누르면 계좌이체 결제창에서 금액확인 및 거래 은행 선택이 진행됩니다.
                    </p>
                    <p class="fontOneRem ma-0 pa-0">
                        ※ 인터넷뱅킹을 위한 공인인증서 및 보안카드가 필요합니다.
                    </p>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <div v-if="tab == 0 || tab == 2">
            <v-row class="pa-0" no-gutters>
                <v-radio-group
                    v-model="bscsInf.taxInvcCd" @change="aplctSavBtn"
                    class="radio-dft-Theme mt-0" ref="info" hide-details row>
                    <v-radio
                        class="pr-2" style="padding-bottom: 2px !important;"
                        :ripple="false" label="세금계산서 발급" value="1">
                    </v-radio>
                    <v-radio
                        class="" style="padding-bottom: 2px !important;"
                        :ripple="false" label="현금영수증 발급" value="2">
                    </v-radio>
                </v-radio-group>
            </v-row>
            <div v-if="bscsInf.taxInvcCd == '1'">
                <v-divider class="mr-1 mt-1"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                        <span class="font-weight-medium posn-rltv">
                            상호
                            <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                <v-icon color="red">mdi-star-four-points-small</v-icon>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="tableBR px-2 py-1" cols="3">
                        <v-text-field
                            v-model="fcltyInf.cmpnNm" @input="aplctSavBtn"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </v-col>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                        <span class="font-weight-medium">업태</span>
                    </v-col>
                    <v-col class="tableBR px-2 py-1" cols="2">
                        <v-text-field
                            v-model="fcltyInf.bsnsCndtn"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </v-col>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="1">
                        <span class="font-weight-medium">업종</span>
                    </v-col>
                    <v-col class="px-2 py-1" cols="2">
                        <v-text-field
                            v-model="fcltyInf.bsnsItm"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                        <span class="font-weight-medium posn-rltv">
                            사업자 번호
                            <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                <v-icon color="red">mdi-star-four-points-small</v-icon>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="tableBR px-2 py-1" cols="3">
                        <v-text-field
                            v-model="fcltyInf.bsnsRgst" @input="aplctSavBtn"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </v-col>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                        <span class="font-weight-medium posn-rltv">
                            대표자명
                            <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                <v-icon color="red">mdi-star-four-points-small</v-icon>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="tableBR px-2 py-1" cols="2">
                        <v-text-field
                            v-model="fcltyInf.ceo" @input="aplctSavBtn"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </v-col>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="1">
                        <span class="font-weight-medium">담당자명</span>
                    </v-col>
                    <v-col class="px-2 py-1" cols="2">
                        <v-text-field
                            v-model="fcltyInf.mngr"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                        <span class="font-weight-medium">전화번호</span>
                    </v-col>
                    <v-col class="px-2 py-1" cols="4">
                        <span class="d-inline-block" style="width: 50px; height: 100%">
                            <v-text-field
                                v-model="fcltyInf.tel1"
                                height="28" hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <span class="mx-1">
                            <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                        </span>
                        <span class="d-inline-block" style="width: 60px; height: 100%">
                            <v-text-field
                                v-model="fcltyInf.tel2"
                                height="28" hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <span class="mx-1">
                            <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                        </span>
                        <span class="d-inline-block" style="width: 60px; height: 100%">
                            <v-text-field
                                v-model="fcltyInf.tel3"
                                height="28" hide-details outlined dense>
                            </v-text-field>
                        </span>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                        <span class="font-weight-medium posn-rltv">
                            이메일
                            <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                <v-icon color="red">mdi-star-four-points-small</v-icon>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="px-2 py-1" cols="6">
                        <span class="d-inline-block" style="width: 152px; height: 100%;">
                            <v-text-field
                                v-model="fcltyInf.emailId" @input="aplctSavBtn"
                                class="" height="28" color="blue"
                                hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <v-icon class="mx-2" style="padding-top: 1px;" size="20" color="greyE00">mdi-at</v-icon>
                        <span class="d-inline-block" style="width: 168px; height: 100%;">
                            <v-text-field
                                v-model="fcltyInf.emailDmn" @input="aplctSavBtn"
                                class="" height="28" color="blue"
                                :disabled="fcltyInf.emailDmnClcd != '0'"
                                hide-details outlined dense>
                            </v-text-field>
                        </span>
                    </v-col>
                    <v-col class="py-1" cols="2">
                        <v-select
                            v-model="fcltyInf.emailDmnClcd"
                            :items="vCd64"
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                        <span class="font-weight-medium">사업장 주소</span>
                    </v-col>
                    <v-col class="px-2 py-1" cols="10">
                        <span class="d-inline-block" style="width: 70px; height: 100%">
                            <v-text-field
                                v-model="fcltyInf.zip"
                                height="28" readonly hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <v-btn
                            class="white--text ml-1" color="#3C9099" style="padding: 0px 1px 0px 2px !important"
                            min-width="30" height="26" @click="onLclMdl('01', 600)" small>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs" v-on="on">mdi-map-search-outline</v-icon>
                                </template>
                                <span>주소검색</span>
                            </v-tooltip>
                        </v-btn>
                        <span class="d-inline-block ml-2" style="width: 45%; height: 100%">
                            <v-text-field
                                v-model="fcltyInf.addr"
                                height="28" readonly hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <span class="d-inline-block ml-1" style="width: 35%; height: 100%">
                            <v-text-field
                                v-model="fcltyInf.addrDtl" height="28" hide-details outlined dense>
                            </v-text-field>
                        </span>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
            </div>
            <div v-else-if="bscsInf.taxInvcCd == '2'">
                <v-divider class="mt-1 mr-1"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="3">
                        <span class="font-weight-medium posn-rltv">
                            현금 영수증 사용 용도
                            <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                <v-icon color="red">mdi-star-four-points-small</v-icon>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="tableBR pl-2 py-1" cols="5">
                        <v-radio-group
                            v-model="cashRcptInf.cashRcptCd" @change="aplctSavBtn"
                            class="radio-dft-Theme mt-0" ref="info" hide-details row>
                            <v-radio
                                class="pr-2" style="padding-bottom: 2px !important;"
                                :ripple="false" label="개인소득 공제용" value="1">
                            </v-radio>
                            <v-radio
                                class="" style="padding-bottom: 2px !important;"
                                :ripple="false" label="사업자 지출 증빙용" value="2">
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                        <span class="font-weight-medium posn-rltv">
                            신청자명
                            <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                <v-icon color="red">mdi-star-four-points-small</v-icon>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="px-2 py-1" cols="2">
                        <v-text-field
                            v-model="cashRcptInf.ceo" @input="aplctSavBtn"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="3">
                        <span class="font-weight-medium posn-rltv">
                            <span v-if="cashRcptInf.cashRcptCd == '1'">휴대폰 번호</span>
                            <span v-else-if="cashRcptInf.cashRcptCd == '2'">사업자 번호</span>
                            <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                                <v-icon color="red">mdi-star-four-points-small</v-icon>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="px-2 py-1" cols="5">
                        <v-text-field
                            v-model="cashRcptInf.bsnsRgst" @input="aplctSavBtn"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </v-col>
                    <v-col class="px-2" style="padding-top: 7px;" cols="4">
                        <span class="fontOneRem grey006--text">※ '-' 없이 숫자만 입력해 주세요.</span>
                    </v-col>
                </v-row>
                <v-divider class="mr-1"></v-divider>
            </div>
            <!-- <div v-else-if="bscsInf.taxInvcCd == '2' && tab == 2">
                <v-divider class="mt-4 mr-1"></v-divider>
            </div> -->
        </div>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">결제</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <AdrsSrch v-if="lclMdlInf.typ == '01'" @onDataThr="rflAddrInf" @isClose="rstLclMdlInf"></AdrsSrch>
            <KsnetPayments v-else-if="lclMdlInf.typ == '02'" :payMthd="tab" @isOff="rstLclMdlInf"></KsnetPayments>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import AdrsSrch from '../commons/AdrsSrch.vue';               // 주소 검색
import KsnetPayments from '../commons/KsnetPayments.vue';     // 결제 창

export default {
    name: 'UseSvcPymSavMdl',

    components: {
        AdrsSrch,
        KsnetPayments,
    },

    props : {
        prtCd64: { type: Array, default: () => { return [] } },     // 이메일
    },

    created: function(){
       
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.vCd64 = [...this.prtCd64]
            this.vCd64.unshift({ valcd: '0', valcdnm: '직접입력' })

            let obj = this.$store.getters.getSubInObj
            this.aplctInf(obj)
        })
    },

    computed: {
      
    },

    watch: {
        'tab': function (){
            this.aplctSavBtn()
        },
        'fcltyInf.emailDmnClcd': function (){
            if(this.fcltyInf.emailDmnClcd == '0')
                this.fcltyInf.emailDmn = ''
            else{
                let emlChc = this.prtCd64.findIndex( v => v.valcd == this.fcltyInf.emailDmnClcd )
                if(emlChc > -1)
                    this.fcltyInf.emailDmn = this.prtCd64[emlChc].valcdnm
                else
                    this.fcltyInf.emailDmn = ''
            }
        },
        'prtCd64': function(v){
            this.vCd64.splice(0)
            this.vCd64 = [...v]
            this.vCd64.unshift({ valcd: '0', valcdnm: '직접입력' })
        },
    },

    methods:{
        // 은행코드 조회
        getBnkList: function (){
            let obj = {}
            obj.clcd  = '1'

            http.post('impExp/selBnkList', obj)
                .then((response) => ( this.bnkRst(response.data) ))
                .catch((error) => console.log('connect error /impExp/selBnkList : ' + error))
        },

        bnkRst: function (res){
            if(Array.isArray(res))
                this.bnkList = res

            if(this.bnkList.length > 0)
                this.bnkList.unshift({ bnkCd: '000', bnkNm: '선택' })
        },

        // 이용서비스 상품 이용기간/문자건수 다건 조회
        getPrdctUseList: function (clcd){
            this.prdctUseList.splice(0)

            let obj = {}
            obj.srvcClcd = clcd
            //obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('yyspym/selYysPrdctUseList', obj)
                .then((response) => ( this.prdctUseRst(response.data) ))
                .catch((error) => console.log('connect error /yyspym/selYysPrdctUseList : ' + error))
        },

        prdctUseRst: function (res){
            if(Array.isArray(res.result)){
                this.prdctUseList = res.result
            }
        },

        // 세금계산서 발급 요청
        setPymHisBnk: function (obj){
            http.post('yyspym/insYysPymHisBnkTrns', obj)
                .then((response) => ( this.pymHisBnkRst(response.data) ))
                .catch((error) => console.log('connect error /yyspym/insYysPymHisBnkTrns : ' + error))
        },

        pymHisBnkRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.messageList.length > 0)
                obj.cntnt = res.messageList

            if(res.statusCode == '200')
                this.savBtn = false
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.getBnkList()
            this.getPrdctUseList(obj.srvcClcd)

            this.bscsInf.srvcClcd = obj.srvcClcd
            switch (obj.srvcClcd) {
                case '1':
                    this.ttl = '요양시스 이용요금 결제'
                    break;
                case '2':
                    this.ttl = '알림톡/문자요금 충전'
                    break;
                case '3':
                    this.ttl = '기관 홈페이지 서비스 결제'
                    break;
                default:
                    this.ttl = '잘못된 접근입니다.'
                    break;
            }
            this.bscsInf.srvcMode = obj.srvcMode        // 1: 신청, 2: 연장
            this.bscsInf.bnfcrCnt = obj.bnfcrCnt
            this.bscsInf.mmsSvcRmnCnt = obj.mmsSvcRmnCnt
            this.bscsInf.yysUseLstPk = obj.yysUseLstPk
            if(obj.yysSvcEndDt != '')
                this.bscsInf.yysSvcEndDt = this.$moment(obj.yysSvcEndDt, 'YYYYMMDD').add(1, 'days').format('YYYY-MM-DD')
            if(obj.webSvcEndDt != '')
                this.bscsInf.webSvcEndDt = this.$moment(obj.webSvcEndDt, 'YYYYMMDD').add(1, 'days').format('YYYY-MM-DD')

            if(obj.srvcMode == '1'){
                this.bscsInf.yysSvcEndDt = this.$moment().format('YYYY-MM-DD')
                this.bscsInf.webSvcEndDt = this.$moment().format('YYYY-MM-DD')
            }

            this.fcltyInf.cmpnNm = obj.fcltyInf.fcltyClnm
            this.fcltyInf.bsnsRgst = obj.fcltyInf.bsnsRgst
            this.fcltyInf.ceo = obj.fcltyInf.ceo
            this.fcltyInf.mngr = obj.fcltyInf.mngr
            this.fcltyInf.tel1 = obj.fcltyInf.fcltyTel1
            this.fcltyInf.tel2 = obj.fcltyInf.fcltyTel2
            this.fcltyInf.tel3 = obj.fcltyInf.fcltyTel3
            this.fcltyInf.emailId = obj.fcltyInf.emailId
            this.fcltyInf.emailDmn = obj.fcltyInf.emailDmn
            this.fcltyInf.bsnsCndtn = obj.fcltyInf.bsnsCndtn
            this.fcltyInf.bsnsItm = obj.fcltyInf.bsnsItm
            this.fcltyInf.zip = obj.fcltyInf.zip
            this.fcltyInf.addr = obj.fcltyInf.addr
            this.fcltyInf.addrDtl = obj.fcltyInf.addrDtl
            let chc = this.vCd64.findIndex ( v => v.valcdnm == obj.fcltyInf.emailDmn )
            if(chc > -1)
                this.fcltyInf.emailDmnClcd = this.vCd64[chc].valcd
            else
                this.fcltyInf.emailDmnClcd = '0'

            this.calcYysSvc()
            this.aplctSavBtn()
        },

        // 요양시스 서비스 이용 계산
        calcYysSvc: function () {
            let pay = 11000
            let prd = 0
            let prdIdx = this.payPrd.findIndex( v => v.valcd == this.sltPrd )
            if(prdIdx > -1)
                prd = this.payPrd[prdIdx].valdesc
            let trgDt = this.$moment(this.bscsInf.yysSvcEndDt, 'YYYY-MM-DD')
            this.bscsInf.useAmt = pay + ( this.bscsInf.bnfcrCnt * 1100 )

            switch (this.bscsInf.srvcClcd) {
                case '1':
                    this.bscsInf.prdEndDt = trgDt.add(prd, 'months').subtract(1, 'days').format('YYYY-MM-DD')
                    this.bscsInf.useAmt = ( pay + ( this.bscsInf.bnfcrCnt * 1100 ) ) * this.payPrd[prdIdx].valprd
                    break;
                case '2':
                    this.bscsInf.useAmt = this.payMsg[( Number(this.sltPrd) - 1 )].valdesc * 22
                    break;
                case '3':
                    this.bscsInf.prdEndDt = trgDt.add( Number(this.sltPrd), 'years' ).format('YYYY-MM-DD')
                    this.bscsInf.useAmt = this.usePrd[( Number(this.sltPrd) - 1 )].valdesc * 132000
                    break;
                default:
                    break;
            }
            // bscsInf: {
            //     srvcClcd: '', srvcMode: '', bnfcrCnt: 0, yysSvcEndDt: '', mmsSvcRmnCnt: 0, webSvcEndDt: '', useAmt: 0,
            // },
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            let cellphoneRule = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/

            if(this.bscsInf.srvcClcd == '')
                return 0
            if(this.bscsInf.taxInvcCd == '')
                return 0

            if(this.tab != 1){
                switch (this.tab) {
                    case 0:
                        if(this.bnkList.length == 0)
                            return 0
                        if(this.bnkTrnsInf.dpstrNm == '')
                            return 0
                        if(this.bnkTrnsInf.dpstrTel1 == '')
                            return 0
                        if(this.bnkTrnsInf.dpstrTel2 == '')
                            return 0
                        if(this.bnkTrnsInf.dpstrTel3 == '')
                            return 0
                        if(!cellphoneRule.test(this.bnkTrnsInf.dpstrTel1+this.bnkTrnsInf.dpstrTel2+this.bnkTrnsInf.dpstrTel3))
                            return 0
                        break;
                    case 1:
                        break;
                    default:
                        break;
                }

                switch (this.bscsInf.taxInvcCd) {
                    case '1':
                        if(this.fcltyInf.cmpnNm == '')
                            return 0
                        if(this.fcltyInf.bsnsRgst == '')
                            return 0
                        if(this.fcltyInf.ceo == '')
                            return 0
                        if(this.fcltyInf.emailId == '')
                            return 0
                        if(this.fcltyInf.emailDmn == '')
                            return 0
                        break;
                    case '2':
                        if(this.cashRcptInf.cashRcptCd == '')
                            return 0
                        if(this.cashRcptInf.bsnsRgst == '')
                            return 0
                        if(this.cashRcptInf.ceo == '')
                            return 0
                        break;
                    default:
                        break;
                }
            }

            this.savBtn = false
        },

        // 결제버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.srvcClcd = this.bscsInf.srvcClcd
            obj.mjrPrdCd = this.prdctUseList[Number(this.sltPrd)-1].mjrPrdCd
            obj.pymtMthdCd = this.tab + 1
            obj.pymtAmt = this.bscsInf.useAmt
            obj.dpstrAcct = this.bnkTrnsInf.dpstrAcct
            obj.dpstrNm = this.bnkTrnsInf.dpstrNm
            obj.dpstrBnkCd = this.bnkTrnsInf.dpstrBnkCd
            obj.dpstrTel1 = this.bnkTrnsInf.dpstrTel1
            obj.dpstrTel2 = this.bnkTrnsInf.dpstrTel2
            obj.dpstrTel3 = this.bnkTrnsInf.dpstrTel3
            obj.cnfrmYn = '2'
            obj.cnclYn = '2'

            obj.taxInvcCd = this.bscsInf.taxInvcCd
            if(obj.taxInvcCd == '1'){
                obj.cmpnNm = this.fcltyInf.cmpnNm
                obj.bsnsRgst = this.fcltyInf.bsnsRgst
                obj.ceo = this.fcltyInf.ceo
                obj.bsnsCndtn = this.fcltyInf.bsnsCndtn
                obj.bsnsItm = this.fcltyInf.bsnsItm
                obj.tel1 = this.fcltyInf.tel1
                obj.tel2 = this.fcltyInf.tel2
                obj.tel3 = this.fcltyInf.tel3
                obj.zip = this.fcltyInf.zip
                obj.addr = this.fcltyInf.addr
                obj.addrDtl = this.fcltyInf.addrDtl
                obj.mngr = this.fcltyInf.mngr
                obj.emailId = this.fcltyInf.emailId
                obj.emailDmn = this.fcltyInf.emailDmn
            }
            else if(obj.taxInvcCd == '2'){
                obj.cashRcptCd = this.cashRcptInf.cashRcptCd
                obj.bsnsRgst = this.cashRcptInf.bsnsRgst
                obj.ceo = this.cashRcptInf.ceo
            }
      
            //console.log(obj)

            if(this.tab == 0)
                this.setPymHisBnk(obj)
            else
                this.onLclMdl('02', 820)
        },

        getLocalUrl: function (mypage) {
            var myloc = location.href
            return myloc.substring(0, myloc.lastIndexOf('/')) + '/' + mypage
        },

        // 주소 검색 내용 적용
        rflAddrInf: function (obj) {
            this.fcltyInf.zip = obj.zonecode
            this.fcltyInf.addr = obj.address

            this.rstLclMdlInf()
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        tab: 0,
        ttl: '',
        sltPrd: '1',
        savBtn: true,
        bscsInf: {
            srvcClcd: '', srvcMode: '', bnfcrCnt: 0, useAmt: 0,
            yysUseLstPk: -1, yysSvcEndDt: '', mmsSvcRmnCnt: 0, webSvcEndDt: '', prdEndDt: '',
            taxInvcCd: '1',
        },
        bnkTrnsInf: { dpstrNm: '', dpstrAcct: '', dpstrBnkCd : '000', dpstrTel1: '', dpstrTel2: '', dpstrTel3: ''  },
        fcltyInf: {
            cmpnNm: '', bsnsRgst: '', ceo: '', mngr: '', tel1: '', tel2: '', tel3: '',
            emailId: '', emailDmn: '', emailDmnClcd: '0', bsnsCndtn: '', bsnsItm: '', zip: '', addr: '', addrDtl: '',
        },
        cashRcptInf: {
            cashRcptCd: '1', bsnsRgst: '', ceo: ''
        },
        payPrd: [       // 결제기간
            { valcd: '1', valcdnm: '1개월', valdesc: 1, valprd: 1 }, { valcd: '2', valcdnm: '6개월', valdesc: 7, valprd: 6 },
            { valcd: '3', valcdnm: '12개월', valdesc: 15, valprd: 12 }
        ],
        payMsg: [       // sms 충전 종류
            { valcd: '1', valcdnm: '500건', valdesc: 500 }, { valcd: '2', valcdnm: '1000건', valdesc: 1000 },
            { valcd: '3', valcdnm: '1500건', valdesc: 1500 },
        ],
        usePrd: [       // 이용기간
            { valcd: '1', valcdnm: '1년', valdesc: 1 }, { valcd: '2', valcdnm: '2년', valdesc: 2 },
            { valcd: '3', valcdnm: '3년', valdesc: 3 }, { valcd: '4', valcdnm: '4년', valdesc: 4 },
            { valcd: '5', valcdnm: '5년', valdesc: 5 },
        ],
        bnkList: [],    // 은행목록
        tabItems: [
            { index: 0, tab: '무통장 입금', class: 'fontOneRem px-10', enter: false },
            { index: 1, tab: '신용카드', class: 'fontOneRem px-10', enter: false },
            { index: 2, tab: '계좌이체', class: 'fontOneRem px-10', enter: false },
        ],
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어 (01: 주소검색)
        prdctUseList: [],
        vCd64: [],
    })
}
</script>