<template>
    <v-sheet class="rounded-lg rounded-l-0">
        <v-row class="pa-0 mt-2 align-center" no-gutters>
            <v-col class="d-flex" cols="3">
                <CmmLvnExpnBtn                
                    v-model="isLvnStt"
                    class="mr-1"
                    @onList="lvnSttApply">
                </CmmLvnExpnBtn>               
                <exBtn class="mr-1" :btnData="exBtn[2]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                <v-spacer></v-spacer>
                <span class="d-inline-block" style="width: 130px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" height="35px" background-color="white"
                        append-icon="mdi-magnify" single-line rounded outlined dense filled hide-details label="이름조회"
                        @input="setSearchText">
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="6">
                <span class="d-inline-block mr-2" style="width: 97px;">
                    <v-select v-model="srchCd" :items="selectItems" item-text="valcdnm" item-value="valcd"
                        @change="selectedDate()" dense hide-details outlined />
                </span>
                <expandNowDatePicker @nowDate="getDate" v-if="srchCd == 0"></expandNowDatePicker>
                <expandMonthPicker @nowYearWithMonth="getPickerDate" v-if="srchCd == 1"></expandMonthPicker>
                <expandYearOnlyPicker @nowYear="getYear" v-if="srchCd == 2"></expandYearOnlyPicker>
            </v-col>
            <v-col class="" cols="3">

            </v-col>
        </v-row>

        <v-divider class="white mb-2"></v-divider>

        <v-divider class=""></v-divider>
        <v-data-table
            class="msclnTable" width="100%" height="656"  locale="ko"
            :headers="headers" :items="items" :search="schDummy" :custom-filter="customFilter"
            hide-default-header fixed-header disable-pagination hide-default-footer dense>
            <template v-slot:header="{ props }">
                <thead>
                    <tr>
                        <th style="width: 60px !important;"
                            class="black--text clmsHeaderCommon tableBR py-3">
                            {{ props.headers[0].text }}</th>
                        <th style="width: 70px !important;"
                            class="black--text clmsHeaderCommon tableBR">
                            {{ props.headers[1].text }}</th>
                        <th style="width: 120px !important;"
                            class="black--text clmsHeaderCommon tableBR">
                            {{ props.headers[2].text }}</th>
                        <th style="width: 70px !important;"
                            class="black--text clmsHeaderCommon tableBR">
                            {{ props.headers[3].text }}</th>
                        <th style="width: 90px !important;"
                            class="black--text clmsHeaderCommon tableBR">
                            {{ props.headers[4].text }}</th>
                        <th style="width: 110px !important;"
                            class="black--text clmsHeaderCommon tableBR">
                            {{ props.headers[5].text }}</th>
                        <th style="width: 98px !important;"
                            class="black--text clmsHeaderCommon tableBR">
                            {{ props.headers[6].text }}</th>
                        <th style="width: 110px !important;"
                            class="black--text clmsHeaderCommon tableBR">
                            {{ props.headers[7].text }}</th>
                        <th style="width: 98px !important;"
                            class="black--text clmsHeaderCommon tableBR">
                            {{ props.headers[8].text }}</th>
                        <th style="width: 200px !important;"
                            class="black--text clmsHeaderCommon tableBR">
                            {{ props.headers[9].text }}</th>
                        <th style="width: 210px !important;"
                            class="black--text clmsHeaderCommon">
                            {{ props.headers[10].text }}</th>
                    </tr>
                </thead>
            </template>
            <template v-slot:item='{ item }'>
                <tr>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon py-2">
                        <span class="cmmFontSizeOne">{{ item.rn }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.aplctNm }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.name }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <span :class="'ic-level level0' + item.acknwRtng"
                            style="margin: auto;">{{ item.acknwRtng }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.lvnRmNm }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.outDtStrt }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.outTmStrt }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.outDtEnd }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.outTmEnd }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.dstnt }}</span>
                    </td>
                    <td class="txtAlgnMid tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.rsn }}</span>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';
import { bnfcrList, selOutHisList } from '../../api/bnfcr.js';
import { selBtcInqOutHisList } from '../../api/bnfcrStts.js';
import { snglHdrXlsxPrdc } from '../../api/global/cmmExcelJS.js';

import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import CmmLvnExpnBtn from '../../components/commons/CmmLvnExpnBtn';

export default {
    name: 'BnfcrSttsTab2',

    components: {
        exBtn,
        expandNowDatePicker,
        expandYearOnlyPicker,
        expandMonthPicker,
        CmmLvnExpnBtn
    },

    created: function () {
        this.getCode(['62', '65'])
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    computed: {

    },

    watch: {
        'selectedItem': function () {
            console.log('row clicked: ')
        },
        'date': function (value) {
            let obj = {
                inqYMD: value,
                fcltyNum: this.$store.getters.getFcltyNum
            }
            this.selBtcInqOutHisList(obj)
        },
        'mDate': function (value) {
            let obj = {
                inqYYMM: value,
                fcltyNum: this.$store.getters.getFcltyNum
            }
            this.selBtcInqOutHisList(obj)
        },
        'yDate': function (value) {
            let obj = {
                inqYYYY: value,
                fcltyNum: this.$store.getters.getFcltyNum
            }
            this.selBtcInqOutHisList(obj)
        },
    },

    methods: {
        getOutHisList: function (strt, lmt) {
            selOutHisList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr, strt, lmt)
                .then((response) => (this.getOutHisListAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selOutHisList : ' + error))
        },
        selBtcInqOutHisList: function (obj) {
            selBtcInqOutHisList(obj)
                .then((response) => (this.selBtcInqOutHisListAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selOutHisList : ' + error))
        },
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => (this.stCdMapping(response.data)))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        getBnfcrList: function () {
            bnfcrList(this.$store.getters.getFcltyNum)
                .then((response) => (this.getBnfcrListAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrList : ' + error))
        },
        getOutHisListAfter: function (res) {
            this.outHisList = res.list

            this.outHisList.forEach(element => {
                element.name = this.$store.getters.getBnfcrState.name
            })
            this.btnDisabled = false

        },
        selBtcInqOutHisListAfter: function (res) {
            // console.log(res)
            if(res.length > 0){
                res.forEach(idx => {
                    let chc = this.vCd62.findIndex( v => v.valcd == idx.acknwRtng )
                    if(chc > -1)
                        idx.acknwRtngNm = this.vCd62[chc].valcdnm
                });

                this.items = res

                if(this.items.length > 0){
                    this.$parent.$parent.$parent.$parent.outlinedBtn[0].disabled = false
                    this.$parent.$parent.$parent.$parent.outlinedBtn[1].disabled = false
                }else{
                    this.$parent.$parent.$parent.$parent.outlinedBtn[0].disabled = true
                    this.$parent.$parent.$parent.$parent.outlinedBtn[1].disabled = true
                }
            }
        },
        stCdMapping: function (res) {
            res.vCd62.forEach((code) => {
                this.exBtn[2].expandList.push(code.valcdnm)
            });//end foreach
            res.vCd65.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach

            this.vCd62 = res.vCd62
            this.vCd65 = res.vCd65

        },
        getBnfcrListAfter: function (res) {
            let me = this
            res.forEach(items => {
                items.acknwPrdEnd = items.acknwPrdEnd.beforeDateFormatDot()
                items.birth = items.birth.beforeDateFormatDot()

                me.vCd65.forEach(code => {
                    if (items.entStcd == code.valcd) items.entStcd = code.valcdnm
                });
            });

            this.bnfcrList = res

        },

        getDate: function (obj) {
            this.date = obj.date
        },
        getPickerDate: function (obj) {
            this.mDate = obj
        },
        getYear: function (obj) {
            this.yDate = obj
        },
        selectedDate: function () {
            if (this.srchCd == 0) {
                let obj = {
                    inqYMD: this.date,
                    fcltyNum: this.$store.getters.getFcltyNum
                }
                this.selBtcInqOutHisList(obj)
            } else if (this.srchCd == 1) {
                let obj = {
                    inqYYMM: this.mDate,
                    fcltyNum: this.$store.getters.getFcltyNum
                }
                this.selBtcInqOutHisList(obj)
            } else {
                let obj = {
                    inqYYYY: this.yDate,
                    fcltyNum: this.$store.getters.getFcltyNum
                }
                this.selBtcInqOutHisList(obj)
            }
        },
        resetPage() {
            this.BtcInqOutHisList = []
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
            switch (this.schChkField.target) {
                case 'entStcd': this.schChkField.entStcd.splice(0)
                    sch.forEach((item) => { this.schChkField.entStcd.push(item) });
                    break;
                case 'lvnRmNm': this.schChkField.lvnRmNm.splice(0)
                    sch.forEach((item) => { this.schChkField.lvnRmNm.push(item) });
                    break;
                case 'acknwRtng': this.schChkField.acknwRtng.splice(0)
                    sch.forEach((item) => {
                        if (item == '등급외')
                            this.schChkField.acknwRtng.push('99')
                        else
                            this.schChkField.acknwRtng.push(item.replaceAll('등급', ''))
                    });
                    break;
            }

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)

            let col1 = true
            if (this.schChkField.entStcd.length != 0) {
                this.schChkField.entStcd.forEach((stcd, i) => {
                    if (i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                });
            }

            let col2 = true
            let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )
            if(lvnRunChc.length == 0)
                col2 = false
            else{
                lvnRunChc.forEach((itm, i) => {
                    if(i == 0) col2 = filter.lvnRmInfPk == itm.lvnRmInfPk
                    else col2 = col2 || filter.lvnRmInfPk == itm.lvnRmInfPk
                });
            }

            let col3 = true
            if (this.schChkField.acknwRtng.length != 0) {
                this.schChkField.acknwRtng.forEach((stcd, i) => {
                    if (i == 0) col3 = filter.acknwRtng.includes(stcd)
                    else col3 = col3 || filter.acknwRtng.includes(stcd)
                });
            }

            return col0 && col1 && col2 && col3;
        },
        expandClose: function (index) {
            switch (index) {
                case 0: this.schChkField.target = 'entStcd'
                    break;
                case 1: this.schChkField.target = 'lvnRmNm'
                    break;
                case 2: this.schChkField.target = 'acknwRtng'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },

        xlsxProd: function (){
            let shetNm = '외출외박현황'
            let title = shetNm + '_' + this.$store.getters.getFcltyNum + '_'

            if(this.srchCd == 1)
                title += this.mDate
            else if(this.srchCd == 2)
                title += this.yDate
            else
                title += this.date

            // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 시트명, 파일명 )
            snglHdrXlsxPrdc(this.exlHeader, this.items, shetNm, title)
            this.exlBtn = true
        },

        prtOutStay: function(){
            let strtDt = ''
            let endDt = ''

            if(this.srchCd == 1){
                strtDt = this.mDate + '01'
                endDt = this.$moment(strtDt, 'YYYYMMDD').endOf('month').format('YYYYMMDD')
            }
            else if(this.srchCd == 2){
                strtDt = this.yDate + '0101'
                endDt = this.$moment(strtDt, 'YYYYMMDD').endOf('year').format('YYYYMMDD')
            }
            else{
                strtDt = this.date
                endDt = this.date
            }
  
            this.$parent.$parent.$parent.$parent.prtThrowObj.list[0].bzPk.splice(0)
            this.$parent.$parent.$parent.$parent.prtThrowObj.list[0].bzClcd.splice(0)
            this.$parent.$parent.$parent.$parent.prtThrowObj.inqYYYY = ''
            this.$parent.$parent.$parent.$parent.prtThrowObj.inqYmdStart = strtDt
            this.$parent.$parent.$parent.$parent.prtThrowObj.inqYmdEnd = endDt
            this.$parent.$parent.$parent.$parent.prtThrowObj.prtCd = '68'
            this.$parent.$parent.$parent.$parent.rprtExtLink()
        },

        // 생활실 선택 컴포넌트 변경 이벤트
        lvnSttApply: function (arr){
            this.aslLvnStt = arr
            this.schDummy = String(Number(this.schDummy)+1)
        },
    },

    data: () => ({
        srchCd: 0,
        selectItems: [{ valcdnm: '일별', valcd: 0 }, { valcdnm: '월별', valcd: 1 }, { valcdnm: '연별', valcd: 2 },],
        outHisList: [],
        items: [],
        BtcInqOutHisList: [],
        midAreaWidth: '530',
        selectedItem: -1,
        bnfcrList: [],
        vCd62: [],
        vCd65: [],
        schField: '',
        schDummy: '',
        schChkField: { entStcd: [], lvnRmNm: [], acknwRtng: [], target: '' },
        headers: [
            { text: '연번', value: 'rn', sortable: true, align: 'center', width: '5%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '구분', value: 'aplctNm', sortable: true, align: 'center', width: '10%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '수급자명', value: 'name', sortable: true, align: 'center', width: '10%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '등급', value: 'acknwRtng', sortable: true, align: 'center', width: '10%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '생활실', value: 'lvnRmNm', sortable: true, align: 'center', width: '10%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '외출일', value: 'outDtStrt', sortable: true, align: 'center', width: '10%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '외출시간', value: 'outTmStrt', sortable: true, align: 'center', width: '5%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '복귀일', value: 'outDtEnd', sortable: true, align: 'center', width: '10%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '복귀시간', value: 'outTmEnd', sortable: true, align: 'center', width: '5%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '행선지', value: 'dstnt', sortable: true, align: 'center', width: '10%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '외출목적', value: 'rsn', sortable: true, align: 'center', width: '25%', class: 'black--text clmsHeaderCommon tableBT' },
        ],
        exBtn: [
            { index: 0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },
            { index: 1, btnTitle: '생활실', btnIcon: 'bed', exWidth: 124, exHeigth: 350, expandList: [], },
            { index: 2, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 198, expandList: [], },
        ],
        exlHeader: [
            { header: '연번', key: 'rn', width: 8, }, { header: '구분', key: 'aplctNm', width: 10, },
            { header: '수급자', key: 'name', width: 14, }, { header: '등급', key: 'acknwRtngNm', width: 12, },
            { header: '생활실', key: 'lvnRmNm', width: 14, },
            { header: '외출일', key: 'outDtStrt', width: 16, }, { header: '외출시간', key: 'outTmStrt', width: 12, },
            { header: '복귀일', key: 'outDtEnd', width: 16, }, { header: '복귀시간', key: 'outTmEnd', width: 12, },
            { header: '행선지', key: 'dstnt', width: 36, }, { header: '외출목적', key: 'rsn', width: 36, },
        ],
        date: 19900101,
        mDate: 199001,
        yDate: 1990,
        isLvnStt            : false,                            // 생활실 확장 활성화 여부
        aslLvnStt           : [],
    }),
};
</script>