<template>
    <v-sheet class="ma-0">
        <v-row class="pa-0" no-gutters>
            <CmmExpnBtn
                v-model="isEntStt"
                :btnNm="'입소현황'" vTyp="1" :bImg="'ic-house'"
                :iList="vCd65" @onList="dmyTxt = String(Number(dmyTxt)+1)">
            </CmmExpnBtn>
            <CmmLvnExpnBtn
                v-model="isLvnStt"
                class="ml-1"
                @onList="lvnSttApply">
            </CmmLvnExpnBtn>
            <v-spacer></v-spacer>
            <span class="d-inline-block" style="width: 106px; height: 34px;">
                <v-text-field
                    v-model="srchTxt" @input="dmyTxt = String(Number(dmyTxt)+1)"
                    class="txt-Theme" height="34" label="이름조회"
                    background-color="white" prepend-inner-icon="mdi-magnify"
                    single-line outlined dense filled hide-details>
                </v-text-field>
            </span>
        </v-row>
        <div class="overflow-x-auto">
            <v-data-table 
                v-model="tableSelected"
                class="tableFixedElm mt-3" height="660" locale="ko"
                :headers="bnfcrHeader" :items="bnfcrList" item-key="rn"
                :loading="isLoad" :loading-text="loadMsg"
                :search="dmyTxt" :custom-filter="srchFltr"
                hide-default-header fixed-header hide-default-footer disable-pagination dense>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>
                            <th class="clmsFixTbl clmsHeaderCommon fixPstn0 zPstn3 tableBR tableBT" style="width: 57px; padding-left: 17px !important;">
                                <span class="d-inline-block" style="width: 28px; height: 28px;">
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                        v-model="allChecker" @change="checkBoxMessage"
                                        hide-details>
                                    </v-checkbox>
                                </span>
                            </th>
                            <th class="clmsFixTbl black--text clmsHeaderCommon tableBR tableBT" style="left: 57px; width: 80px !important; z-index: 3;">{{props.headers[0].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[1].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBT">{{props.headers[2].text}}</th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:item='{ item}'>
                    <tr class="fixedCol">
                        <td 
                            class="clmsFixTbl clmsBodyCommon zPstn2 fixedCol0 fixPstn0 px-0 tableBR tableBB"    
                                style="padding-left: 6px !important;"                                
                            >
                            <span class="d-inline-block" style="width: 28px; height: 28px;">
                                <v-checkbox
                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                    v-model="item.isSelected" @change="checkBoxIndex()"
                                    hide-details>
                                </v-checkbox>
                            </span>
                        </td>
                        <!-- hghlgCol -->
                        <td class="clmsFixTbl clmsBodyCommon fixedCol0 zPstn2 tableBR tableBB"
                            style="left: 57px;">                            
                            <span class="d-inline-block text-overflow" style="width:60px;">{{ item.name }}</span>
                        </td>
                        <td class="clmsBodyCommon fixedCol0 tableBR tableBB"
                                >{{ item.entStNm }}</td>
                        <td class="clmsBodyCommon fixedCol0 tableBB">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{ item.lvnRmNm }}</span>
                                </template>
                                <span>{{ item.bldNm }} - {{ item.bldFlr }}층</span>
                            </v-tooltip>
                        </td>                        
                    </tr>
                </template>
            </v-data-table>
        </div>
    </v-sheet>
</template>

<script>
import CmmExpnBtn from '../../components/commons/CmmExpnBtn';
import CmmLvnExpnBtn from '../../components/commons/CmmLvnExpnBtn';

import { http } from '@/api/baseAxios';

export default {
    name: 'BathBlnftSrvRcrdBnfcr',
        

    props : {                
        cd62:{type: Array, default: () => { return [] }},
        cd63:{type: Array, default: () => { return [] }},
        cd64:{type: Array, default: () => { return [] }},
        cd65:{type: Array, default: () => { return [] }},
    },

    components: {
        CmmExpnBtn,
        CmmLvnExpnBtn
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getAllBnfcrList()
            this.setCode()
        })
    },

    computed: {

    },

    watch: {        
        'isEntStt': function (v) {            
            if(v) this.isLvnStt = false
        },
        'isLvnStt': function (v) {
            if(v) this.isEntStt = false
        }
    },
        
    methods: {
        // 전체 수급자 목록 조회 (퇴소자 포함)
        getAllBnfcrList: function (){
            this.isLoad = true
            this.bnfcrList.splice(0)

            let obj = {}

            http.post('bnfcr/selAllBnfcrList', obj)
                .then((response) => ( this.allBnfcrRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selAllBnfcrList : ' + error))
        },
        
        //결과 :: 전체 수급자 목록 조회 (퇴소자 포함)
        allBnfcrRst: function (res){
            
            if(res != null){
                if(res.length > 0){
                    res.forEach((itm, i) => {
                        itm.arrNum = i + 1
                    });
                    this.bnfcrList = res
                    this.dmyTxt = String(Number(this.dmyTxt)+1)
                }
            }

            this.isLoad = false
        },

        // 테이블 검색 필터 (value: list, search: search key, filter: array column)
        srchFltr: function (value, search, filter){

            let field1 = false
            let field2 = true
            let field3 = true

            // 이름 검색
            field1 = filter.name.includes(this.srchTxt)

            // 입소현황 검색
            let entRunChc = this.vCd65.filter( v => v.isOn == true )
            if(entRunChc.length == 0)
                field2 = false
            else{
                entRunChc.forEach((itm, i) => {
                    if(i == 0) field2 = filter.entStcd == itm.valcd
                    else field2 = field2 || filter.entStcd == itm.valcd
                });
            }

            // 생활실 검색
            let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )
            if(lvnRunChc.length == 0)
                field3 = false
            else{
                lvnRunChc.forEach((itm, i) => {
                    if(i == 0) field3 = filter.lvnRmInfPk == itm.lvnRmInfPk
                    else field3 = field3 || filter.lvnRmInfPk == itm.lvnRmInfPk
                });
            }

            return field1 && field2 && field3
        },

        // 생활실 선택 컴포넌트 변경 이벤트
        lvnSttApply: function (arr){
            this.aslLvnStt = arr

            this.dmyTxt = String(Number(this.dmyTxt)+1)
        },

        //코드 정리
        setCode:function(){

            this.vCd65.splice(0)
            this.cd65.forEach(cd => {
                this.vCd65.push(cd)
            });

            if(this.vCd65.length > 0)
                this.vCd65[0].isOn = true
        },

        checkBoxMessage: function () {
            if(this.allChecker == true){
                this.bnfcrList.forEach(item => {
                    item.isSelected = true
                })
            }
            else if(this.allChecker == false){
                this.bnfcrList.forEach(item => {
                    item.isSelected = false
                })
            }
            let pkList = []
            this.bnfcrList.forEach(items => {
                if(items.isSelected == true) pkList.push(items.bnMmbrPk)
            });
            this.selectedList = pkList

            this.$emit('onBnfcr', this.selectedList)
        },

        checkBoxIndex: function () {
            let chk = []
            let pkList = []
            this.bnfcrList.forEach(items => {
                if(typeof items.isSelected == 'undefined') chk.push(false)
                else chk.push(items.isSelected)

                if(items.isSelected == true) pkList.push(items.bnMmbrPk)
            });
            this.selectedList = pkList
            let result = chk.every((item) => item==true)

            if(result) this.allChecker = true
            else this.allChecker = false

            this.$emit('onBnfcr', this.selectedList)
        },
    },

    data: () => ({
        allChecker:false,
        isLoad: true,
        loadMsg: '잠시만 기다려주세요.',
        sltRow: { bnMmbrPk: -1, bnfcrPk: -1 },
        tableSelected: [],
        srchTxt: '',                            // 테이블 이름 검색 필드
        dmyTxt: '',                             // 테이블 반응 더미(검색용)
        routeLctnNm: '',                        // 라우터 경로
        isEntStt: false,                        // 현황 확장 활성화 여부
        isLvnStt: false,                        // 생활실 확장 활성화 여부        
        bnfcrList: [],                          // 수급자 목록
        aslLvnStt: [],
        vCd65:[],
        bnfcrHeader: [
            { text: '수급자', value: 'name', sortable: true, filterable: true, width: '90' },
            { text: '현황', value: 'entStNm', sortable: true, filterable: true, width: '80' },
            { text: '생활실', value: 'lvnRmNm', sortable: true, filterable: false, width: '90' },
            { text: '등급', value: 'acknwRtng', sortable: true, filterable: false, width: '70 '},
        ],
    }),
};
</script>