<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">인정번호&nbsp;수정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">기본 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">수급자</span>
            </v-col>
            <v-col class="px-4 py-1" cols="9">
                <span class="mr-2">{{ bscsInf.name }}</span>
                &#40;
                <span v-if="bscsInf.gndr == 'M'">남</span>
                <span v-else-if="bscsInf.gndr == 'F'">여</span>
                <span class="ml-1">/ 만 {{ bscsInf.age }}세</span>
                &#41;
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">인정번호</span>
            </v-col>
            <v-col class="px-4 py-1" cols="9">
                <span class="mr-5">{{ bscsInf.acknwNum1 == '' ? '해당없음' : bscsInf.acknwNum1 }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">인정등급/기간</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span v-if="bscsInf.acknwRtngNm == null">해당없음</span>
                <span v-else>
                    <span>{{ bscsInf.acknwRtngNm }}</span>
                    <span class="mx-2">&#47;</span>
                    <span>{{ $moment( bscsInf.acknwPrdStrt, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
                    <span class="mx-2">&#126;</span>
                    <span>{{ $moment( bscsInf.acknwPrdEnd, 'YYYYMMDD' ).format('YYYY.MM.DD') }}</span>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">변경 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 tableBR d-flex align-center justify-center" cols="3">
                <span class="font-weight-medium posn-rltv">
                    인정번호
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-2 py-2" cols="9">
                <span class="white d-inline-block" style="width: 120px;">
                    <v-text-field
                        v-model="acknwNum1" @input="aplctSavBtn"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'AcknwNumSavMdl',

    components: {

    },

    props : {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getSubInObj
            this.aplctInf(obj)
        })
    },

    computed: {
      
    },

    watch: {
        
    },

    methods:{
        // 인정번호 수정
        setAcknwUpd: function (obj){
            this.savBtn = true

            http.post('bnfcrAuth/updBnfcrRtngAcknwNum', obj)
                .then((response) => ( this.updAcknwRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcrAuth/updBnfcrRtngAcknwNum : ' + error))
        },

        updAcknwRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200')
                this.savBtn = false
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.bscsInf.bnMmbrPk = obj.bnMmbrPk
            this.bscsInf.bnfcrPk = obj.bnfcrPk
            this.bscsInf.name = obj.name
            this.bscsInf.gndr = obj.gndr
            this.bscsInf.age = obj.age
            this.bscsInf.acknwNum1 = obj.acknwNum1

            this.bscsInf.acknwRtng = obj.isNowObj?.acknwRtng
            this.bscsInf.acknwRtngNm = obj.isNowObj?.acknwRtngNm
            this.bscsInf.acknwPrdStrt = obj.isNowObj?.acknwPrdStrt
            this.bscsInf.acknwPrdEnd = obj.isNowObj?.acknwPrdEnd

            this.aplctSavBtn()
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(this.acknwNum1 == '')
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = ''                              // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            let tmp = {}
            tmp.bnMmbrPk = this.bscsInf.bnMmbrPk
            tmp.bnfcrPk = this.bscsInf.bnfcrPk
            tmp.acknwNum1 = this.acknwNum1

            if(this.acknwNum1 == this.bscsInf.acknwNum1){
                obj.title = '이전 인정번호와 동일한 번호는 입력하실수 없습니다.'
                this.$store.commit('setWrnnInf', obj)
                
                return 0
            }

            this.setAcknwUpd(tmp)
        }
    },

    data: () => ({
        savBtn: true,
        acknwNum1: '',
        bscsInf: {
            bnMmbrPk: -1, bnfcrPk: -1, name: '', gndr: '', age: '',
            acknwRtng: '', acknwRtngNm: '', acknwNum1: '', acknwPrdStrt: '', acknwPrdEnd: ''
        },
    })
}
</script>