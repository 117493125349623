<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth+100" :max-width="areaWidth+300">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div>
            <v-tabs
                v-model="tab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{ item.tab }}</v-tab>
            </v-tabs>

            <v-divider class="bodyColor" style="padding:1px 0px;"></v-divider>

            <v-tabs-items v-model="tab">
                <v-tab-item transition="fade-transition" eager>
                    <EmpWrkngSchdl ref="empWrkngSchdl"></EmpWrkngSchdl>
                </v-tab-item>
                <!-- <v-tab-item transition="fade-transition" eager>
                    <SchdlRgstr ref="schdlRgstr"></SchdlRgstr>
                </v-tab-item> -->
                <v-tab-item transition="fade-transition" eager>
                    <SchdlMgmt ref="schdlMgmt"></SchdlMgmt>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-sheet>
</template>

<script>
import EmpWrkngSchdl from '../../components/emp/EmpWrkngSchdl.vue';
//import SchdlRgstr from '../../components/emp/SchdlRgstr.vue';
import SchdlMgmt from './SchdlMgmt.vue';

// import IndstCmpln from '../../components/emp/IndstCmpln.vue';

export default {
    name: 'WrkngSchdl',
    
    components: {
        EmpWrkngSchdl,
        //SchdlRgstr,
        SchdlMgmt,
        //IndstCmpln,
    },

    props : {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
    },  

    watch: {
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        'tab': function () {
            let dt = ''

            if(this.tab == 0)
                dt = this.$moment(this.$refs.empWrkngSchdl.start, 'YYYY-MM-DD').format('YYYYMM')     
            // else if(this.tab == 1)
            //     dt = this.$moment(this.$refs.schdlRgstr.start, 'YYYY-MM-DD').format('YYYYMM')
            else if(this.tab == 1)
                dt = this.$moment(this.$refs.schdlMgmt.start, 'YYYY-MM-DD').format('YYYYMM')
            
            let tmp = this.$parent.$parent.schChkField.wrkStcd
            if(tmp.length == 0){
                tmp.push('재직')
                tmp.push('휴직')
                tmp.push('퇴직')
            }
 
            if(tmp.length > 0){
                this.$parent.$parent.vCd70.forEach(code => {
                    if(tmp[0] == code.valcdnm)
                        this.$parent.$parent.getEmpWrkSchdHrlList(dt, code.valcd)
                })
            }

            //this.$parent.$parent.sltDt = ''
            this.$parent.$parent.getStdWrkHrInfo(dt)
        },
    },

    methods: {
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({
        tab: 0,
        areaWidth: 708,
        tabItems: [
            { index: 0, tab: '근무일정', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 1, tab: '일정등록', class: 'fontOneDotTwoRem px-15', enter: false },
            // { index: 2, tab: '테스트', class: 'fontOneDotTwoRem px-15', enter: false },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>