<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">인지기능 평가</span>
            <v-btn
                class="ml-3" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 14)" icon>
                <v-icon size="20" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
            </v-btn>
            <span class="ml-1 grey006--text fontOneRem">인지선별검사(CIST)</span>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                    :class="list.class" height="30" :color="list.color" rounded
                    :disabled="btnDisabled"
                    style="font-size: 14px;">
                    <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </span>
            <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j"
                :class="list.class" 
                :disabled="list.disabled"
                @click = "onClickBtn(list.text)"
                samll height="30" :color="list.color" rounded outlined>
                <v-icon small>{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <basicInfo ref="basicInfo" :bnMmbrPk="bnMmbrPk" @setParentData="setBnfcrData"></basicInfo>
        <v-form ref="form" lazy-validation>
            <v-row no-gutters style="border-bottom: 1px solid #dbdbdb !important">
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">조사사유</span>
                </v-col>
                <v-col class="py-1 pl-2 d-flex justify-center align-center" cols="2">
                    <span class="d-inline-block" style="width: 250px;">
                        <v-radio-group
                            v-model="items.examRsncd" :rules="[rules.required]"
                            class="radio-dft-Theme mt-0" hide-details row>
                            <v-radio class="pa-0 mr-1" :ripple="false" label="신규" value="1"></v-radio>
                            <v-radio class="pa-0 mr-1" :ripple="false" label="재사정" value="2"></v-radio>
                            <v-radio class="pa-0 ma-0" :ripple="false" label="상태변화" value="3"></v-radio>
                        </v-radio-group>
                    </span>
                </v-col>
                 <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">치매진단</span>
                    <v-btn
                        class="ml-1" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 17)" icon>
                        <v-icon size="20" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="py-1 pl-2 d-flex justify-start align-center" cols="2">     
                    <v-checkbox
                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                        v-model="items.dmntYn" :ripple="false"
                        value="1" false-value="2" hide-details>
                    </v-checkbox>               
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">검사일</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="2">
                    <span class="white d-inline-block py-1" style="width: 154px;">    
                        <CmmDateComp v-model="items.examDt" :required="true" :minDt="inqYYYY+'-01-01'" :maxDt="inqYYYY+'-12-31'"></CmmDateComp>                                               
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">검사자</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="2">
                    <span class="d-inline-block py-1" style="width: 120px;">
                        <v-text-field v-model="items.wrtr" color="blue" ref="info"
                            :rules="[rules.required]" readonly hide-details outlined dense :key="forceRender"></v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal"
                        >
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters style="border-bottom: 1px solid #dbdbdb !important">                
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0"  cols="1">
                    <span class="black--text font-weight-medium">교육정도</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-start align-center"  cols="2">
                    <v-select 
                        v-model="eduDeg"                               
                        :items="codeItem"
                        item-text="valcdnm"
                        item-value="valcd"
                        dense hide-details outlined
                        @change="getScoreCalclr"
                        :rules="[rules.required, rules.selCheck]"
                    />
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">검사장소</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="4">
                        <v-text-field v-model="items.examPlc" color="blue" ref="info"
                            :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                </v-col>  
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <div class="d-flex">
                        <span class="black--text font-weight-medium">첨부파일</span>
                        <v-file-input 
                            v-model="files" 
                            class="athFileInput"
                            dense hide-details truncate-length="5" 
                            multiple counter
                            prepend-icon="mdi-upload"
                            hide-input                                                                 
                            outlined 
                            @change="upFile()"
                            ></v-file-input>
                    </div>
                </v-col>
                <v-col class="ma-0 overflow-y-auto" cols="3" style="height:80px">
                    <span class="d-inline-block pb-1 px-1 tableBB" style="width: 100%;"
                        v-for="(item, j) in fileRes" :key="j">
                        <v-chip            
                            class="mt-1" label close outlined
                            @click="fileDownload(j)" @click:close="delfiles(j)">
                            <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                mdi-file-pdf-box
                            </v-icon>
                            <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                mdi-microsoft-excel
                            </v-icon>
                            <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                mdi-file-powerpoint
                            </v-icon>
                            <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                mdi-file-word
                            </v-icon>
                            <v-icon v-else color='cyan' left>
                                mdi-file-document
                            </v-icon>
                            <span class="longText">{{ item.orgFileNm }}</span>
                        </v-chip> 
                    </span>
                </v-col>                
            </v-row>            
            <v-divider class="bodyColor mt-2" style="padding:1px 0px;"></v-divider>
            <v-row class="mt-1" style="height:550px;overflow-y:auto">
                <v-col v-for="(item, i) in cgntvList" :key="i"  cols="4">
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">{{item.clsfc2Cdnm}}</span>
                    </div>
                    <v-row class="pa-0 ma-0 mt-2" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" cols="4">                                
                            <span class="black--text font-weight-medium">구분</span>
                        </v-col>
                        <v-col class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" cols="8"> 
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" cols="6">                                
                                    <span class="black--text font-weight-medium">항목</span>
                                </v-col>
                                <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="6">                                
                                    <span class="black--text font-weight-medium">평가</span>
                                </v-col>
                            </v-row>         
                        </v-col>                            
                    </v-row>
                    <v-row v-for="(clsfc3, j) in item.clsfc3Cds" :key="j" class="pa-0 ma-0" style="font-size:0.9rem !important;" no-gutters>
                        <v-col class=" d-flex justify-center align-center py-1 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" cols="4">                                
                            {{clsfc3.clsfc3Cdnm}}
                        </v-col>
                        <v-col class="pa-0 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="8"> 
                            <v-row v-for="(list,n) in clsfc3.list"  :key="n" class="pa-0 ma-0" no-gutters>
                                <v-col class="d-flex justify-center align-center py-1 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" cols="6">   
                                    {{list.itmCdnm}}
                                </v-col>
                                <v-col class="d-flex justify-center align-center py-1 ma-0 cgntvEvltn-radio-box" style="border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" cols="6">
                                    <span v-if="list.clsfc2Cd == '1' || list.clsfc2Cd == '3' || (list.clsfc2Cd == '5' &&  list.itmCd != '3')|| list.clsfc2Cd == '7'" class="d-inline-block" style="width: 94px;">
                                        <v-radio-group v-model="list.itmVal" class="radio-dft-Theme mt-0 pl-2" hide-details row>
                                            <v-radio class="pa-0 mr-1" :ripple="false" label="0" value="0" @click="getScoreCalclr"></v-radio>
                                            <v-radio class="pa-0 ma-0" :ripple="false" label="1" value="1" @click="getScoreCalclr"></v-radio>
                                        </v-radio-group>
                                    </span>
                                    <span v-if="list.clsfc2Cd == '4' || (list.clsfc2Cd == '5' &&  list.itmCd == '3') || list.clsfc2Cd == '6' || (list.clsfc2Cd == '8' &&  list.itmCd == '2')" class="d-inline-block" style="width: 127px;">
                                        <v-radio-group v-model="list.itmVal" class="radio-dft-Theme mt-0 pl-2" hide-details row>
                                            <v-radio class="pa-0 mr-1" :ripple="false" label="0" value="0" @click="getScoreCalclr"></v-radio>
                                            <v-radio class="pa-0 mr-1" :ripple="false" label="1" value="1" @click="getScoreCalclr"></v-radio>
                                            <v-radio class="pa-0 ma-0" :ripple="false" label="2" value="2" @click="getScoreCalclr"></v-radio>
                                        </v-radio-group>
                                    </span>
                                    <span v-if="list.clsfc2Cd == '2'" class="d-inline-block" style="width:24px;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            v-model="list.itmVal" :ripple="false"
                                            value="1" false-value="0" hide-details>
                                        </v-checkbox>
                                    </span>
                                    <span v-if="list.clsfc2Cd == '8' && list.itmCd == '1'" class="d-inline-block d-flex align-center">
                                        <span style="width:55px">
                                            <v-text-field v-model="list.itmVal" :rules="[rules.numberCheck]"
                                                type="number" min="0" :key="forceRender" hide-details outlined dense>
                                            </v-text-field>
                                        </span>
                                        <span class="pl-1">개</span>
                                    </span>
                                </v-col>
                            </v-row>         
                        </v-col>                            
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <div class="d-flex align-center mt-2">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">결과요약표</span>
                    </div>
                    <v-row class="pa-0 ma-0 mt-2" no-gutters>
                        <v-col cols="4">
                            <v-row class="pa-0 ma-0 mt-2" no-gutters>
                                <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" cols="6">                                
                                    <span class="black--text font-weight-medium">인지영역</span>
                                </v-col>
                                <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" cols="6"> 
                                    <span class="black--text font-weight-medium">점수</span>      
                                </v-col>                            
                            </v-row>
                            <v-row v-for="(item, k) in cgntvTotData" :key="k" class="pa-0 ma-0" style="font-size:0.9rem !important;" no-gutters>
                                <v-col v-if="item.clsfc2Cdnm =='총점'"  class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" cols="6">                         
                                    <span class="black--text font-weight-medium">{{item.clsfc2Cdnm}}</span>
                                </v-col>
                                <v-col v-else class="d-flex  justify-center align-center pa-1 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" cols="6">                         
                                    {{item.clsfc2Cdnm}}
                                </v-col>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" cols="6"> 
                                    {{item.score}}/{{item.maxScore}}
                                </v-col>                            
                            </v-row>
                        </v-col>
                        <v-col cols="8" class="pl-2">
                            <v-row class="pa-0 ma-0 mt-2" no-gutters>
                                <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" cols="3">                                
                                    <span class="black--text font-weight-medium">판정</span>
                                </v-col>
                                <v-col class="d-flex justify-center align-center pa-2 ma-0" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" cols="9">                                
                                    <span class="d-inline-block" style="width: 173px;">
                                        <v-radio-group
                                            v-model="cgntvResData.itmVal" :rules="[rules.required]"
                                            class="radio-dft-Theme mt-0 pl-2" hide-details row>
                                            <v-radio v-for="(list, m) in cgntvResData.list" :key="m" class="pa-0 ma-0" :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>
                                        </v-radio-group>
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" cols="3">                                
                                    <span class="black--text font-weight-medium">비고</span>
                                </v-col>
                                <v-col class="d-flex justify-center align-center pa-2 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" cols="9">                                
                                    <v-textarea v-model="cgntvEvltnData.evltn" class="rmrksArea" no-resize   
                                        rows="7" outlined dense hide-details>
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>    
        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="'인지기능평가'" @madalState="onDelSwich"></btnModalDelete>  
        </v-dialog>  
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
        </v-dialog>  
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import { getMultiStcd} from '../../api/index.js';
import {getBnfcrExamHisInfo, getBnfcrExamList, insBnfcrExamHis, delBnfcrExamHis} from '../../api/bnftSrv.js';
import { selAtchdFileList, tmpFileUpload, fileDownload, delAtchdFile } from '../../api/index.js';

export default {
    name: 'CgntvEvltn',

    props : {
        inqYYYY: Number,  
        bnMmbrPk : { type: Number, default: -1 },
        cgntvEvltnPk : { type: Number, default: -1 },
        btnDisabled : { type: Boolean, default: false },     
    },
        
    components: {
        CmmDateComp,
        basicInfo,
        btnModalDelete,
        EmpSelector,
    },
    
    computed: {
        
    },  

    watch: {     
        'cgntvEvltnPk':function(value){  
            this.filledBtn[0].visible = false 
            this.outlinedBtn[0].disabled = false
            if(value > -1){
                this.bnfcrExamHisPk = value
                this.onShow()
                if(value == 0){
                    this.filledBtn[0].visible = true
                    this.outlinedBtn[0].disabled = true
                } 
            }
        }
    },

    mounted: function(){             
        this.$nextTick(function () {  
            this.bnfcrExamHisPk = this.cgntvEvltnPk
            this.onShow()
            this.filledBtn[0].visible = false
            this.outlinedBtn[0].disabled = false
            if(this.bnfcrExamHisPk == 0) {
                this.filledBtn[0].visible = true
                this.outlinedBtn[0].disabled = true
            }
        })
    },

    methods: {
        onShow : function(){
            this.onCodeload()
            this.getInit()
            this.getExamHisInfo()
            this.getBnfcrExamList()       
            this.bnfcrInfoReLoad() 
            this.getAtchdFileList()
        },

        onClickBtn: function(text) {
            if(text == "이전자료"){
                this.getPreData = true
                this.getBnfcrExamList()
            }else if(text=="저장"){
                this.insBnfcrExam()
            } else if(text=="삭제"){
                if(this.bnfcrExamHisPk > 0){
                    this.delType = "all"
                    this.dialogDelete = true
                }
            } else if(text=="출력"){
                this.cgntvEvltnPrt()
            }
        },

        onClose: function () {
            this.bnfcrExamHisPk=0
            this.$refs.form.reset();
            this.$emit('modalEvent', 3)
        },
        onCodeload:function(){
            getMultiStcd(['76'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },
        codeMapping:function(res){
            
            this.codeItem = []

            let selR = {
                valcdnm:'선택',
                valcd:'0',
            }
            this.codeItem.push(selR)
            res["vCd76"].forEach(x=>{
                this.codeItem.push(x)
            })

        },
        getInit(){           
            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)
            let dateStr = timezoneDate.toISOString().split('T',2)[0]
            this.items.examDt = dateStr
            this.items.wrtr = this.$store.getters.getUserNm;
            this.maxDate = this.inqYYYY+'-'+'12'+'-'+'31'
            this.minDate = this.inqYYYY+'-'+'01'+'-'+'01'
            this.forceRender += 1
        },       

        insBnfcrExam(){
            if(this.$refs.form.validate()){
                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnMmbrPk:this.bnMmbrPk,
                    bnfcrExamHisPk:this.bnfcrExamHisPk,
                    examClcd:'104',                    
                    examRsncd : this.items.examRsncd,
                    examPlc : this.items.examPlc,
                    examDt : this.items.examDt.afterDateFormatHyp(),
                    dmntYn: this.items.dmntYn,
                    wrtr : this.items.wrtr,
                    examYyyy : this.inqYYYY,
                    files:[],
                }

                let examList = []

                this.cgntvList.forEach(el=>{
                    el.clsfc3Cds.forEach(x => {
                        x.list.forEach( itm => {
                            examList.push(itm)
                        })
                    })
                })

                this.cgntvResData.list.forEach(x => {

                    if(this.cgntvResData.itmVal == x.itmCd) x.itmVal = '1'
                    else x.itmVal = '0'

                    examList.push(x)
                })

                examList.push(this.cgntvEvltnData)

                //첨부파일
                this.fileRes.forEach(x=>{
                    if(x.atchdFilePk == undefined){
                        obj.files.push(x)
                    }
                })

                let bnfcr = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    bnfcrPk:this.$store.getters.getBnfcrState.bnfcr,
                    eduDeg:this.eduDeg,
                }

                insBnfcrExamHis(obj,examList,bnfcr)
                    .then((response) => ( this.insBnfcrExamAfter(response.data)))
                    .catch((error) => console.log(error))

            }
        },

        insBnfcrExamAfter: function (res) {
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])                
                this.bnfcrExamHisPk = res.result
                this.getExamHisInfo()
                this.getBnfcrExamList()       
                this.bnfcrInfoReLoad() 
                this.getAtchdFileList()

                //수급자 평가내역 Reload
                this.$emit('modalEvent', 99)

                this.filledBtn[0].visible = false
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }        
        },

        onDelSwich:function(res){
            if(this.delType == 'all') this.onDelete(res)
            else if(this.delType == 'file') this.delAtchdFile(res)
        },

        onDelete:function(res){
            if(res == 9990){              
                delBnfcrExamHis(this.$store.getters.getFcltyNum, this.bnfcrExamHisPk, this.$store.getters.getUserNm)
                .then((response) => ( this.onDeleteAfter(response.data)))
                .catch((error) => console.log(error))
            }else if(res == 7){
                this.dialogDelete = false
            }

        },

        onDeleteAfter:function(res){
            if(res.statusCode == 200){                                                
                this.$emit('modalEvent', 99)
                this.onClose()
            }
            else{               
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        
        getExamHisInfo(){
            if(this.bnfcrExamHisPk > 0){
                let code = this.$store.getters.getFcltyNum;
                getBnfcrExamHisInfo(code, this.bnfcrExamHisPk)
                .then((response) => ( this.getExamHisInfoAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        getExamHisInfoAfter(data){            
            data.examDt = data.examDt.beforeDateFormatHyp()
            this.items = data
        },        

        //교육정도 조회
        bnfcrInfoReLoad:function(){
            if(this.$refs.basicInfo != undefined){
                this.$refs.basicInfo.bnfcrBasicInfoReLoad()
                let data = this.$refs.basicInfo.getBnfcrData()
                this.eduDeg = data.eduDeg
            }
        },

        getBnfcrExamList(){            

            if(this.getPreData){
                this.obj = {
                    code: this.$store.getters.getFcltyNum, 
                    inqTFclsfc1Cd: true, 
                    inqTFclsfc2Cd: true, 
                    inqTFclsfc3Cd: true, 
                    inqTFitmCd: true, 
                    clsfc1Cd: '104',
                    bnfcrExamHisPk:null,
                    bnMmbrPk:this.bnMmbrPk
                }
            }else{  
                this.obj = {
                    code: this.$store.getters.getFcltyNum, 
                    inqTFclsfc1Cd: true, 
                    inqTFclsfc2Cd: true, 
                    inqTFclsfc3Cd: true, 
                    inqTFitmCd: true, 
                    clsfc1Cd: '104',
                    bnfcrExamHisPk:this.bnfcrExamHisPk
                }
            }

            getBnfcrExamList(this.obj)
                .then((response) => ( this.getBnfcrExamListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selBnfcrExamList : ' + error))
        },

        getBnfcrExamListAfter(res){    
            this.cgntvList.splice(0)

            let cgntvData = []
            this.cgntvResData = Object.assign({itmVal:'',list:[]})
            this.cgntvEvltnData = Object.assign({})

            res.forEach(item => {
                if(this.getPreData) item.bnfcrExamPk = 0

                if(item.clsfc2Cd == '999'){
                    this.cgntvEvltnData = item
                }else if(item.clsfc2Cd == '9'){
                    this.cgntvResData.list.push(item)
                }else{
                    cgntvData.push(item)
                }
            });

            //판정 결과
            this.cgntvResData.itmVal = this.getRadioData(this.cgntvResData.list)
            
            let chc =[]
            let chcch =[]

            cgntvData.forEach(el => {
                if(chc.findIndex(v=>v == el.clsfc2Cd) == -1) chc.push(el.clsfc2Cd)
                if(chcch.findIndex(v=>v == el.clsfc3Cd) == -1) chcch.push(el.clsfc3Cd)
            });
            
            chc.forEach(el => {
                let grp = {
                    clsfc2Cdnm : '',
                    clsfc3Cds : [],
                }

                let item = cgntvData.filter(v=>v.clsfc2Cd == el)

                chcch.forEach(v=> {
                    let child = item.filter(x=>x.clsfc3Cd == v)

                    if(child.length > 0){
                        let cgrp={
                            clsfc3Cdnm : '',
                            type : '',
                            list : [],
                        }

                        child.forEach(itm => {                            
                            grp.clsfc2Cdnm = itm.clsfc2Cdnm
                            cgrp.clsfc3Cdnm = itm.clsfc3Cdnm                            
                            cgrp.list.push(itm)
                        });

                        grp.clsfc3Cds.push(cgrp)
                    }
                })

                this.cgntvList.push(grp)
            });

            this.getScoreCalclr()
            this.$refs.form.resetValidation();

            if(this.getPreData) this.getPreData = false
        },

        //결과요약표 계산
        getScoreCalclr:function(){
            this.cgntvTotData.splice(0)
            let totScore = 0
            let maxTotScore = 0

            let maxscrs = [5,3,2,4,10,4,2]

            this.cgntvList.filter(x=>x.clsfc2Cdnm != '기억력').forEach((el,key) => {                
                let obj = {
                    clsfc2Cdnm : el.clsfc2Cdnm,
                    score:0,
                    maxScore:maxscrs[key],
                }

                maxTotScore += maxscrs[key]

                el.clsfc3Cds.forEach(x => {                    
                    x.list.forEach( itm => {
                        switch (itm.clsfc2Cd) {      
                            case '1':
                            case '2':
                            case '3':
                            case '4':
                            case '5':
                            case '6':
                            case '7':
                                obj.score += (itm.itmVal != null)?Number(itm.itmVal):0
                                break;
                            case '8':
                                obj.score += (itm.itmCd=='2' && itm.itmVal != null)?Number(itm.itmVal):0                                  
                                break;
                            default:
                                break;
                        }
                    });
                });
                totScore += obj.score
                this.cgntvTotData.push(obj)
            });


            if(this.eduDeg > 0){

                let target = Object.assign({})
    
                if(this.age > 49 && this.age < 60 ) target = this.scroeBasicArr[0]
                if(this.age > 59 && this.age < 70 ) target = this.scroeBasicArr[1]
                if(this.age > 69 && this.age < 80 ) target = this.scroeBasicArr[2]
                if(this.age > 79  )                 target = this.scroeBasicArr[3]

                let evalScore = Number(target['score'+this.eduDeg])

                if(totScore >= evalScore) this.cgntvResData.itmVal = '1'
                else this.cgntvResData.itmVal = '2'
            }


            this.cgntvTotData.push({clsfc2Cdnm:'총점',score:totScore,maxScore:maxTotScore})
        },

        //급여제공 관련 자료
        getAtchdFileList:function(){          
            let clcd = '17'
            if(this.bnfcrExamHisPk > 0){
                selAtchdFileList(this.$store.getters.getFcltyNum, clcd,this.bnfcrExamHisPk)
                    .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/selAtchdFileList : ' + error))
            }
        },

        getAtchdFileListAfter:function(res){            
            this.files = null
            this.fileRes.splice(0)
            
            res.forEach(x=>{                
                let fileNm = x.fileNm.split(".")
                x.ext = fileNm[1]  
                this.fileRes.push(x)
            })
        },

        upFile: function () {            
            let formData = new FormData();
            
            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files);
                    tmpFileUpload(formData)
                        .then((response) => ( this.upFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }            
        },
        
        upFileAfter:function(res){

            res.files.forEach(x=>{
                let fileNm = x.fileNm.split(".")
                x.ext = fileNm[1]
                this.fileRes.push(x)
            })

            this.files = null
        },

        fileDownload:function(idx){
            if(this.fileRes[idx] != undefined){
                fileDownload(this.fileRes[idx])
                    .then((response) => ( this.fileDownLoaderAfter(response) ))
                    .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
            }else{
                this.snackControll([true, 3000, '선택된 파일이 없습니다.', 'warning'])
            }
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },

        delfiles:function(idx){
            if(this.fileRes[idx].atchdFilePk != undefined){
                this.delType = "file"
                this.fileSeletr = idx
                this.delTitle = '첨부파일'
                this.dialogDelete = true
            }else{
                this.fileRes.splice(idx,1)
            }
        }, 

        delAtchdFile:function(res){
            if(res == 9990){                
                if(this.fileRes[this.fileSeletr].atchdFilePk > 0){
                    delAtchdFile(this.$store.getters.getFcltyNum, this.fileRes[this.fileSeletr].atchdFilePk)
                        .then((response) => ( this.delAtchdFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/delAtchdFile : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        delAtchdFileAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getAtchdFileList()
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.fileSeletr = 0
            this.dialogDelete = false
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        snackControll: function (options) {
            this.snackOptions = options
        },
       
        onModal: function () {
            this.prtWrtDt = this.items.examDt
            this.menu.splice(1, 1, true)
        },
        isModal: function () {
            this.menu.splice(1, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },

        getRadioData(data){     
            let itmVal = ''       
            data.forEach(item => {
                if(item.itmVal == '1') itmVal = item.itmCd
            });

            return itmVal
        }, 
        
        setBnfcrData(data){    
            this.age = data.age  
            this.eduDeg = data.eduDeg
        },

        //인지기능 양식 출력
        cgntvEvltnPrt: function (){
            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk
            obj.bnfcrPk = this.$parent.$parent.$parent.$parent.bnfcrPk
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = '59'
            obj.inqYYYY = ''
            obj.inqYmdStart = ''
            obj.inqYmdEnd = ''
            obj.list = [ { bzClcd: [], bzPk: [] } ]
            obj.list[0].bzPk.push(this.bnfcrExamHisPk)

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        },
    },
    
    data: () => ({
        getPreData:false,       
        minDate:'',
        maxDate:'',
        prtWrtDt:'',
        bnfcrExamHisPk : 0,
        items:{},
        cgntvList:[],
        cgntvResData:{itmVal:'',list:[]},
        cgntvEvltnData:{},
        cgntvTotData:[],
        codeItem:[],
        menu: [false,false],
        age:0,
        eduDeg:'0',
        delType:'',
        delTitle:'인지기능평가',
        fileSeletr:0,
        files:null,
        fileRes:[],
        fileRules: [
            value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
        },
        filledBtn: [
            { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'#3C9099', text: '이전자료', type:'action', visible: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type:'action', visible: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type:'action', visible: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력',disabled: false, },
        ],
        scroeBasicArr:[
            {'score1':0,'score2':0,'score3':22,'score4':24,'score5':26,'score6':27},
            {'score1':0,'score2':16,'score3':21,'score4':23,'score5':25,'score6':26},
            {'score1':13,'score2':14,'score3':19,'score4':22,'score5':22,'score6':25},
            {'score1':10,'score2':11,'score3':16,'score4':18,'score5':20,'score6':22},
        ],
        snackOptions: [false, 3000, '', 'info'],
        forceRender:0,
        dialogDelete:false,
    }),
};
</script>
<style>
.cgntvEvltn-radio-box .v-input__control .v-input__slot {min-height: 24px !important;}
</style>