<template>
    <div>
        <div class="d-flex mt-2">
            <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
            <CmmLvnExpnBtn                
                v-model="isLvnStt"
                class="mr-1"
                @onList="lvnSttApply">
            </CmmLvnExpnBtn>
            <exBtn class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />
            <span class="d-inline-block" style="width: 130px;">
                <v-text-field class="ma-0 pa-0 nameSearch" height="35px" background-color="white" append-icon="mdi-magnify"
                    single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                </v-text-field>
            </span>
            
        </div>
        <div class="mt-2 rounded-lg">
            <v-data-table
                class="mt-2 stts" item-key="rn" locale="ko" height="665"
                :search="schDummy" :custom-filter="customFilter" :headers="headers" :items="items"
                fixed-header disable-pagination hide-default-footer dense>

                <template v-slot:header="props" >
                    <thead>
                        <th v-for="(list, i) in props.headers" :key="i">{{list.text}}</th>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
            
                    <tr class="fixedCol">
                        <td class="fixedTable txtAlgnMid fontOneRem removeBB fixPstn0 zPstn2">
                            <span>{{item.rn}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="fixedTable txtAlgnMid fontOneRem removeBB fixPstn1 zPstn2">
                            <span class="d-inline-block overflow-text-hidden" style="width:60px">{{item.name}}</span>
                            <span class="d-inline-block" style="width:6px" v-if="item.name.length >= 4"></span>
                            <span class="d-inline-block" style="width:18px" v-else></span>
                        </td>
                        <td class="fixedTable removeBB fixPstn3 zPstn2">
                            <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                            <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                        </td> 
                        <td class="fixedTable txtAlgnMid fontOneRem removeBB">
                            <span>{{item.age}}</span>
                            <span class="d-inline-block" style="width:22px"></span>
                        </td>
                        <td class="fixedTable txtAlgnMid fontOneRem removeBB">
                            <span>{{item.entStcd}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="fixedTable txtAlgnMid fontOneRem removeBB">
                            <span :class="'d-inline-block ic-level level0'+item.acknwRtng">
                                {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                            </span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span>{{item.rsdntBirth}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span>{{item.lvnRmNm}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span class="d-inline-block overflow-text-hidden" style="width:118px">
                                {{item.acknwNum1}}&nbsp;&#45;&nbsp;{{item.acknwNum2}}
                            </span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span>{{item.acknwPrdStrt}}</span>
                            <span class="d-inline-block" style="width:16px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span>{{item.acknwPrdEnd}}</span>
                            <span class="d-inline-block" style="width:16px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span>{{item.entDt}}</span>
                            <span class="d-inline-block" style="width:10px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span>{{item.copayRateNm}}</span>
                            <span class="d-inline-block" style="width:10px"></span>
                        </td>
                        <td class="fontOneRem removeBB">
                            <span>{{item.majorDsss}}</span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span>{{item.grdName}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span>{{item.rltns}}</span>
                            <span class="d-inline-block" style="width:18px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span>{{item.grdCelphn_1}}&#45;{{item.grdCelphn_2}}&#45;{{item.grdCelphn_3}}</span>
                            <span class="d-inline-block" style="width:10px"></span>
                        </td>
                        <td class="txtAlgnMid fontOneRem removeBB">
                            <span>{{item.lvRsn}}</span>
                            <span class="d-inline-block" style="width:10px"></span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import { selAllBnfcrList, } from '../../api/bnfcr.js';
import { getMultiStcd } from '../../api/index.js';
import { snglHdrXlsxPrdc } from '../../api/global/cmmExcelJS.js';

import exBtn from '../../components/commons/expandButtonCustom.vue';
import CmmLvnExpnBtn from '../../components/commons/CmmLvnExpnBtn';

export default {
    name: 'BnfcrSttsTab1',
        
    components: {
        exBtn,
        CmmLvnExpnBtn,
    },

    created: function(){
        this.getCode(['62', '63', '65'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                this.selectedItem = this.$store.getters.getBnfcrState.bnmmbr
            }
            this.selAllBnfcrList()
        })
    },

    computed: {

    },

    watch:{ 
        // 'selectedItem': function () {
        //     console.log('row clicked: ')
        // },
    },
        
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        selAllBnfcrList: function (){
            selAllBnfcrList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.selAllBnfcrListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selAllBnfcrListAfter : ' + error))
        },
        stCdMapping: function (res) {

            res.vCd62.forEach((code) => {
                this.exBtn[1].expandList.push(code.valcdnm)
            });//end foreach
            res.vCd65.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach

            this.vCd62 = res.vCd62
            this.vCd63 = res.vCd63
            this.vCd65 = res.vCd65
        },
        selAllBnfcrListAfter: function (res) {
            let me = this
            res.forEach(e => {
                e.acknwPrdStrt = e.acknwPrdStrt?.beforeDateFormatDot()
                e.acknwPrdEnd = e.acknwPrdEnd?.beforeDateFormatDot()
                e.lvDt = e.lvDt?.beforeDateFormatDot()
                e.rsdntBirth = e.rsdntBirth?.beforeDateFormatDot()
                e.entDt = e.entDt?.beforeDateFormatDot()
                e.lvDt = e.lvDt?.beforeDateFormatDot()
                e.acknwNumNm = e.acknwNum1 + ' - ' + e.acknwNum2
                e.ageNm = e.age + '세'
                if(e.acknwRtng == '99')
                    e.acknwRtngNm = '등급외'
                else
                    e.acknwRtngNm = e.acknwRtng + '등급'

                me.vCd65.forEach(code => {
                    if (e.entStcd == code.valcd) e.entStcd = code.valcdnm
                });
            });
            // this.vCd65.forEach(e => {
            //     this.items.forEach(s => {
            //         if (e.valcd == s.entStcd) {
            //             s.entStcd.push(e.valcdnm)
            //             console.log(s.entStcd)
            //         }
            //     });
            // });
            this.items = res
        },
        cutText(text, count, more = "...") {
            let length = text.length;

            if (length <= count)
                return text;
            else
                return text.substring(0, count) + " " + more;
        },
        tableClickEvent: function (index){
            if(this.selectedItem == -1){
                this.selectedItem = index
                this.$store.commit('setMmbrPk', index)
            }
            else if(this.selectedItem != index){
                this.selectedItem = index
                this.$store.commit('setMmbrPk', index)
            }
            else{
                //Form Clear
                this.selectedItem = -1
                this.$store.commit('setMmbrPk', 0)
            }
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
            switch(this.schChkField.target) {
                case 'entStcd': this.schChkField.entStcd.splice(0)
                    sch.forEach((item) => { this.schChkField.entStcd.push(item) });
                    break;                
                case 'acknwRtng': this.schChkField.acknwRtng.splice(0)
                    sch.forEach((item) => {
                        if(item == '등급외')
                            this.schChkField.acknwRtng.push('99')
                        else
                            this.schChkField.acknwRtng.push(item.replaceAll('등급', ''))
                    });
                    break;
            }
          
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.entStcd.length != 0){
                this.schChkField.entStcd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                });
            }

            let col2 = true
            if(this.schChkField.acknwRtng.length != 0){
                this.schChkField.acknwRtng.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.acknwRtng.includes(stcd)
                    else col2 = col2 || filter.acknwRtng.includes(stcd)
                });
            }

            let col3 = true
            // 생활실 검색
            let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )
            if(lvnRunChc.length == 0)
                col3 = false
            else{
                lvnRunChc.forEach((itm, i) => {
                    if(i == 0) col3 = filter.lvnRmInfPk == itm.lvnRmInfPk
                    else col3 = col3 || filter.lvnRmInfPk == itm.lvnRmInfPk
                });
            }

            return col0 && col1 && col2 && col3;
        },
        xlsxProd: function (){
            let shetNm = '전체수급자현황'
            let title = shetNm + '_' + this.$store.getters.getFcltyNum + '_' + this.$moment().format('YYYYMMDD') 

            // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 시트명, 파일명 )
            snglHdrXlsxPrdc(this.exlHeader, this.items, shetNm, title)
            this.exlBtn = true
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'entStcd'
                    break;
                case 1: this.schChkField.target = 'acknwRtng'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },

        // 생활실 선택 컴포넌트 변경 이벤트
        lvnSttApply: function (arr){
            this.aslLvnStt = arr
            this.schDummy = String(Number(this.schDummy)+1)
        },
    },
    
    data: () => ({
        items: [],
        midAreaWidth: '530',
        selectedItem: -1,
        bnfcrList: [],
        vCd62: [],
        vCd63: [],
        vCd65: [],
        schField: '',
        schDummy: '',
        schChkField: { entStcd: [], lvnRmNm: [], acknwRtng: [], target: '' },
        headers: [
            { text: '연번', value: 'rn', sortable: true, width: '80', align: 'center', class: 'black--text py-4 greyE01 fontOneRem bnfcrMidIndex fixPstn0 zPstn3'},
            { text: '수급자', value: 'name', sortable: true, width: '110', align: 'center', class: 'black--text greyE01 fontOneRem bnfcrMidIndex fixPstn1 zPstn3'},
            { text: '성별', value: 'gndr', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 fontOneRem bnfcrMidIndex fixPstn3 zPstn3'},
            { text: '나이', value: 'age', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 fontOneRem bnfcrMidIndex'},
            { text: '현황', value: 'entStcd', sortable: true, width: '100', align: 'center', class: 'black--text greyE01 fontOneRem bnfcrMidIndex'},
            { text: '등급', value: 'acknwRtng', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 fontOneRem bnfcrMidIndex'},
            { text: '생년월일', value: 'rsdntBirth', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '생활실', value: 'lvnRmNm', sortable: true, width: '110', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '인정번호', value: '', sortable: true, width: '170', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '인정시작일', value: 'acknwPrdStrt', sortable: true, align: 'center', width: '130', class: 'black--text greyE01 fontOneRem'},
            { text: '인정종료일', value: 'acknwPrdEnd', sortable: true, align: 'center', width: '130', class: 'black--text greyE01 fontOneRem'},
            { text: '입소일', value: 'entDt', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '본인부담률', value: 'copayRate', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '주요질환', value: 'majorDsss', sortable: true, width: '300', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '보호자', value: 'grdName', sortable: true, width: '90', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '관계', value: 'rltns', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '보호자 연락처', value: 'grdCelphn', sortable: true, width: '150', align: 'center', class: 'black--text greyE01 fontOneRem'},
            { text: '퇴소사유', value: '', width: '200', sortable: true, align: 'center', class: 'black--text clmsHeaderCommon tableBT'},
        ],
        exBtn: [
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },
            { index:  1, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 198, expandList: [], },
        ],
        exlHeader: [
            { header: '연번', key: 'rn', width: 8, }, { header: '수급자', key: 'name', width: 12, },
            { header: '성별', key: 'gndrNm', width: 10, }, { header: '나이', key: 'ageNm', width: 10, },
            { header: '현황', key: 'entStcd', width: 12, }, { header: '생년월일', key: 'rsdntBirth', width: 16, },
            { header: '생활실', key: 'lvnRmNm', width: 12, }, { header: '등급', key: 'acknwRtngNm', width: 10, },
            { header: '인정번호', key: 'acknwNumNm', width: 20, }, { header: '인정시작일', key: 'acknwPrdStrt', width: 12, },
            { header: '인정종료일', key: 'acknwPrdEnd', width: 12, }, { header: '입소일(시간)', key: 'entDtNm', width: 16, },
            { header: '퇴소일(시간)', key: 'lvDtNm', width: 16, }, { header: '본인부담률', key: 'copayRateNm', width: 12, },
            { header: '주요질환', key: 'majorDsss', width: 50, }, { header: '보호자', key: 'grdName', width: 14, },
            { header: '관계', key: 'rltns', width: 16, }, { header: '보호자 연락처', key: 'grdCelphnNm', width: 16, },
        ],
        isLvnStt            : false,                            // 생활실 확장 활성화 여부
        aslLvnStt           : [],
    }),
};
</script>
<style>
.rightBorder {
    border-right: 1px solid #dbdbdb !important;
}
.fixedOprH0 {
    left: 34px;
}
.fixedOprH1 {
    left: 104px;
}
.fixedOprH2 {
    left: 174px;
}
.fixedOprH3 {
    left: 208px;
}
.fixedOprH4 {
    left: 278px;
}
</style>
