<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-divider :class="['mt-2', empChcWhList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', empChcWhList.length> 10 ? 'mr-2__5' : 'ma-0']" no-gutters>
            <v-col class="d-flex justify-center align-center tableBR" cols="1">
                <span class="d-inline-block ml-1" style="width: 26px;" v-if="chkTyp == '2'">
                    <v-checkbox
                        class="dftChcBox-Theme pt-0" v-model="allChckr" :key="forceRender" @click="allChnger"
                        true-value="1" false-value="2" 
                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                        hide-details>
                    </v-checkbox>
                </span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">이름</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">구분</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="3">
                <span class="black--text font-weight-medium">분야</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2" cols="4">
                <span class="black--text font-weight-medium">기간</span>
            </v-col>
            <!-- <v-col class="d-flex justify-center align-center py-2" cols="3">
                <span class="black--text font-weight-medium">근무일정</span>
            </v-col> -->
        </v-row>
        <v-divider :class="[empChcWhList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <div class="d-inline-block overflow-y-auto" style="width: 100%; height: 401px;">
            <v-row class="ma-0 pa-0" style="border-bottom: 1px solid #dbdbdb;"
                v-for="(wList, i) in empChcWhList" :key="i" no-gutters>
                <v-col class="d-flex justify-center align-center tableBR" cols="1">
                    <span class="d-inline-block ml-1" style="width: 26px;" v-if="chkTyp == '2'">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" v-model="wList.checkr" :key="forceRender" :disabled="wList.disChekr" @change="allChekrFunc"
                            true-value="1" false-value="2" style="margin-top: 3px !important;"
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>
                    <span class="d-inline-block ml-1" style="width: 26px;" v-else-if="chkTyp == '1'">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" v-model="wList.checkr" :key="forceRender" :disabled="wList.disChekr" @change="onlyOneCheckr(i)"
                            true-value="1" false-value="2" style="margin-top: 3px !important;"
                             on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span class="black--text">{{ wList.name }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span class="" style="text-align:center;" v-if="wList.cmteClcd=='1'">내부인사</span>
                    <span class="" style="text-align:center;" v-else-if="wList.cmteClcd=='2'">외부인사</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="3">
                    <span class="" style="text-align:center;" v-if="wList.cmteFdcd=='1'">시설의장</span>
                    <span class="" style="text-align:center;" v-if="wList.cmteFdcd=='2'">시설 거주자 대표</span>
                    <span class="" style="text-align:center;" v-if="wList.cmteFdcd=='3'">시설 종사자의 대표</span>
                    <span class="" style="text-align:center;" v-if="wList.cmteFdcd=='4'">시설 거주자의 보호자 대표</span>
                    <span class="" style="text-align:center;" v-if="wList.cmteFdcd=='5'">사회복지 공무원</span>
                    <span class="" style="text-align:center;" v-if="wList.cmteFdcd=='6'">후원자 대표</span>
                    <span class="" style="text-align:center;" v-if="wList.cmteFdcd=='7'">지역주민</span>
                    <span class="" style="text-align:center;" v-if="wList.cmteFdcd=='8'">공인단체 추천인</span>
                    <span class="" style="text-align:center;" v-if="wList.cmteFdcd=='9'">전문적인 지식과 경험이 풍부한 자</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2" cols="4">
                    <span class="" style="text-align:center;">{{wList.dtStrt}}&#126;{{wList.dtEnd}}</span>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
// import { selEmpChcWhList } from '../../api/emp.js';
import { selCmteList } from '../../api/opr.js';
export default {
    name: 'CmteMltpSel',

    props : {
        exChekrList: { type: Array, default: new Array() },
        chkTyp: { type: String, default: '2' },
    },
            
    components: {
        
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getEmpChcWhList()
        })
    },

    computed: {
       
    },

    watch: {
        'exChekrList': function () {
            this.empChcWhList.splice(0)
            this.allChckr = '2'
            this.getEmpChcWhList()

            this.forceRender += 1
        },
    },
        
    methods: {
        getEmpChcWhList: function (inqYMD) {
            selCmteList(this.$store.getters.getFcltyNum, inqYMD)
                .then((response) => ( this.getEmpChcWhListAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/selCmteList : ' + error))
        },
        getEmpChcWhListAfter: function (res) {
            this.empChcWhList.splice(0)
            this.forceRender += 1

            res.forEach(item => {
                item.disChekr = false
                item.checkr = '2'
                item.dtTyp = 'cmte'
                    item.dtStrt = item.dtStrt?.beforeDateFormatHyp()
                    item.dtEnd = item.dtEnd?.beforeDateFormatHyp()

            });

            this.empChcWhList = res

            if(this.empChcWhList.length > 0){
                this.chngInfrm()
            }
        },
        allChnger: function () {
            if(this.allChckr == '1') {
                this.empChcWhList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '1'
                });
            }
            else if(this.allChckr == '2') {
                this.empChcWhList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '2'
                });
            }
        },
        allChekrFunc: function () {
            let tCh = '1'
            this.empChcWhList.forEach(el => {
                if(el.checkr == '2') tCh = '2'
            });
            this.allChckr = tCh
        },
        onlyOneCheckr: function (index) {
            if(this.chkTyp == '1'){
                this.empChcWhList.forEach(el => {
                    if(el.disChekr == false) el.checkr = '2'
                });
                this.forceRender += 1
            }
            this.empChcWhList[index].checkr = '1'
        },
        chngInfrm: function () {
            this.exChekrList.forEach(item => {
                if(typeof item.empPk != 'undefined'){
                    this.empChcWhList.forEach(items => {
                        if(item.empPk == items.empPk){
                            items.checkr = '1'
                            items.disChekr = item.disChekr
                        }
                    });
                }
                else  {
                    this.empChcWhList.forEach(items => {
                        if(item.mmbrpk == items.mmbrPk){
                            items.checkr = '1'
                            items.disChekr = item.disChekr
                        }
                    });
                }
            });
        },
        fltrThrw: function () {
            let arr = this.empChcWhList.filter(v => v.disChekr == false)

            return arr.filter(v => v.checkr == '1')
        },
    },
    
    data: () => ({
        empChcWhList: [],
        allChckr: '2',
        forceRender: 0,
    }),
};
</script>