<template>
    <v-app class="minimumSize"
        style="background: linear-gradient(90deg, rgba(162,205,233,1) 0%, rgba(93,185,211,1) 100%);">
        <v-main class="d-flex align-center">
            <div style="position:relative;">
                <v-snackbar
                    v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                    :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                    <div style="text-align: center;">
                        <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                    </div>
                </v-snackbar>
            </div>
            <AuthLogin v-if="authStep == 0"></AuthLogin>
            <AcptOftr v-else-if="authStep == 1"></AcptOftr>
            <RgstrMmbr v-else-if="authStep == 2"></RgstrMmbr>
            <FrgtIdPswrd v-else-if="authStep == 3"></FrgtIdPswrd>
        </v-main>
        <v-dialog v-model="menu[0]" content-class="round" max-width="500" persistent eager>
            <AdrsSrch ref="adrsSrch" @onDataThr="onDataAbj" @isClose="menu.splice(0, 1, false)"></AdrsSrch>
        </v-dialog>
    </v-app>
</template>

<script>
import { getMultiStcd } from '../api/index.js';

import AuthLogin from '../components/auth/AuthLogin.vue';
import AcptOftr from '../components/auth/AcptOftr.vue';
import RgstrMmbr from '../components/auth/RgstrMmbr.vue';
import FrgtIdPswrd from '../components/auth/FrgtIdPswrd.vue';
import AdrsSrch from '../components/commons/AdrsSrch.vue';


export default {
    name: 'AppTpl001',

    components: {
        AuthLogin,
        AcptOftr,
        RgstrMmbr,
        FrgtIdPswrd,
        AdrsSrch,
    },

    created: function(){
        this.getCode(['64','132'])
    },

    computed:{
        theme: function() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light'
        },
    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.$route.params.step != 'undefined')
                this.chngComp(this.$route.params.step)
        })
    },

    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res){
            res.vCd64.forEach(cd => {
                this.emailCode.push(cd)
            });
            this.emailCode.unshift({ valcd: '0', valcdnm: '직접입력' })

            res.vCd132.forEach(cd => {
                this.inflCode.push(cd)
            });
        },
        onDataAbj: function (result) {
            this.mmbrInfo.zip = result.zonecode
            this.mmbrInfo.addr = result.address
            this.menu.splice(0, 1, false)
        },
        chngComp: function (txt) {
            switch (txt) {
                case '1':
                    this.authStep = Number(txt)
                    break;
                default:
                    break;
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        menu: [ false, ],
        emailCode: [],
        inflCode: [],
        authStep: 0,
        mmbrInfo: {
            fcltyClcd: '1', fcltyClnm: '', fcltyNum: '', fcltyTel1: '', fcltyTel2: '', fcltyTel3: '', zip: '', addr: '', addrDtl: '',
            bsnsRgst: '', ceo: '', id: '', passwd: '', passwdChk: '', name: '', rsdntBirth: '', gndr: 'M', birth: '', lnrYn: '1',
            celphn_1: '', celphn_2: '', celphn_3: '', emailId: '', emailDmn: '', emailDmnSlt: '0', trmsAgrYn: '2', ntcArgYn: '2', inflRout:'',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .authBtn span { color: #7e839e; }
    .authCheck .v-input__control { width: 142px; }
    .authTxtField .v-input__control .v-input__slot { min-height: 46px; }
    .authTxtField .v-input__control .v-input__slot .v-input__append-inner { margin-top: 4px !important; }
    .authTxtField .v-input__control .v-text-field__slot { overflow: initial; }
    .authTxtField .v-input__control .v-text-field__slot label { font-size: 1.1rem; top: 10px !important;}
    .authTxtField .v-input__control .v-input__slot .v-input__append-inner .v-input__icon--append button {
        margin-top: 10px;
    }
    .authTxtField .v-input__control .v-input__slot .v-input__append-inner .v-input__icon--append button {
        margin-top: 14px !important;
    }
    .authTxtFieldPwdIcon .v-input__control .v-input__slot .v-input__append-inner .v-input__icon--append button {
        color: #2a9bed;
    }
</style>