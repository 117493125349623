<template>
    <v-sheet
        class="d-inline-block rounded-lg rounded-l-0 pa-4"
        min-width="1260" max-width="1560" height="835">
        <v-row class="pa-0 mb-4" no-gutters>
            <v-col cols="3">
                <div class="text-h4 d-flex align-center">
                    <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                    <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">10-5. 이용요금 결제</span>
                    <v-spacer></v-spacer>
                    <!-- button area -->
                </div>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>

        <v-row class="pa-0">
            <v-col cols="6">
                <v-row class="pa-0 mt-1 mb-2 align-center posn-rltv" no-gutters>
                    <span
                        class="d-inlne-block"
                        style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                        <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                    </span>
                    <span class="fontOneDotTwoRem font-weight-medium ml-6">서비스 이용현황</span>
                    <v-spacer></v-spacer>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0 nmlBfClrImp-greyE01" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-2" cols="4">
                        <span class="font-weight-medium">서비스</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-2" cols="5">
                        <span class="font-weight-medium">이용현황</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-2" cols="3">
                        <span class="font-weight-medium">처리</span>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="4">
                        <span class="">요양시스</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-1" cols="5">
                        <span v-if="yysSvcInf.useDtEnd == ''" class="red--text">미신청</span>
                        <span v-else class="mr-1">서비스 만료일 :</span>
                        <span class="">
                            {{ yysSvcInf.useDtEnd != '' ? this.$moment(yysSvcInf.useDtEnd, 'YYYYMMDD').format('YYYY.MM.DD') : '' }}
                        </span>
                    </v-col>
                    <v-col class="txtAlgnMid py-1" cols="3">
                        <v-btn
                            v-if="yysSvcInf.useDtEnd == ''"
                            class="white--text" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor"
                            @click="onPymMdl('1', '1')"
                            min-width="20" height="20" small outlined>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">서비스 신청</span>
                        </v-btn>
                        <v-btn
                            v-else
                            class="white--text" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor"
                            @click="onPymMdl('1', '2')"
                            min-width="20" height="20" small outlined>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">서비스 연장</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="4">
                        <span class="">알림톡/문자</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-1" cols="5">
                        <span class="mr-1">잔여건수 :</span>
                        <span class="">{{ mmsSvcInf.txtCnt }}건</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-1" cols="3">
                        <v-btn
                            class="white--text" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor" @click="onPymMdl('2', '1')"
                            min-width="20" height="20" small outlined>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">문자 충전</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="4">
                        <span class="">기관 홈페이지</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-1" cols="5">
                        <span v-if="webSvcInf.useDtEnd == ''" class="red--text">미신청</span>
                        <span v-else class="mr-1">홈페이지 만료일 :</span>
                        <span class="">
                            {{ webSvcInf.useDtEnd != '' ? this.$moment(webSvcInf.useDtEnd, 'YYYYMMDD').format('YYYY.MM.DD') : '' }}
                        </span>
                    </v-col>
                    <v-col class="txtAlgnMid py-1" cols="3">
                        <v-btn
                            v-if="webSvcInf.useDtEnd == ''"
                            class="white--text" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor" @click="onPymMdl('3', '1')"
                            min-width="20" height="20" small outlined>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">서비스 신청</span>
                        </v-btn>
                        <v-btn
                            v-else
                            class="white--text" style="padding: 9px 7px 8px 5px !important;"
                            color="blueBtnColor" @click="onPymMdl('3', '2')"
                            min-width="20" height="20" small outlined>
                            <span class="fontNineThRem" style="padding-bottom: 2px;">서비스 연장</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
            </v-col>
            <v-col cols="6">
                <v-row class="pa-0 mt-1 mb-2 align-center posn-rltv" no-gutters>
                    <span
                        class="d-inlne-block"
                        style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                        <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                    </span>
                    <span class="fontOneDotTwoRem font-weight-medium ml-6">이용요금 안내</span>
                    <v-spacer></v-spacer>
                    <span class="grey006--text fontOneRem mr-1">※ 부가세 포함</span>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0 nmlBfClrImp-greyE01" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-2" cols="3">
                        <span class="font-weight-medium">서비스</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-2" cols="3">
                        <span class="font-weight-medium">구분</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-2" cols="6">
                        <span class="font-weight-medium">요금</span>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="d-flex align-center justify-center tableBR py-1" cols="3">
                        <span class="">요양시스</span>
                    </v-col>
                    <v-col class="" cols="9">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                <span class="">설치비/가입비</span>
                            </v-col>
                            <v-col class="txtAlgnMid py-1" cols="8">
                                <span class="">무료</span>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                <span class="">월 이용료</span>
                            </v-col>
                            <v-col class="txtAlgnMid py-1" cols="8">
                                <span class="">11,000원 + 수급자 당 1,100원</span>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="d-flex align-center justify-center tableBR py-1" cols="4">
                                <span class="">혜택</span>
                            </v-col>
                            <v-col class="" cols="8">
                                <div class="txtAlgnMid py-1">
                                    <span class="">신규 1년 무료이용</span>
                                </div>
                                <v-divider class=""></v-divider>
                                <div class="txtAlgnMid pt-1">
                                    <span class="">6개월 결제시 1개월 무료,</span>
                                </div>
                                <div class="txtAlgnMid pb-1">
                                    <span class="">12개월 결제시 3개월 무료</span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="txtAlgnMid tableBR py-1" cols="3">
                        <span class="">알림톡/문자</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-1" cols="3">
                        <span class="">건당 22원</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-1" cols="6">
                        <span class="">500건: 11,000원, 1000건: 22,000원</span>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="d-flex align-center justify-center tableBR py-1" cols="3">
                        <span class="">기관 홈페이지</span>
                    </v-col>
                    <v-col class="txtAlgnMid" cols="9">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                <span class="">연간 이용료</span>
                            </v-col>
                            <v-col class="txtAlgnMid py-1" cols="8">
                                <span class="">132,000원</span>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                                <span class="">혜택</span>
                            </v-col>
                            <v-col class="txtAlgnMid py-1" cols="8">
                                <span class="">신규 제작비 무료</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
            </v-col>
        </v-row>

        <v-row class="pa-0 mt-12 mb-2 align-center posn-rltv" no-gutters>
            <span
                class="d-inlne-block"
                style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">결제 내역</span>
            <v-spacer></v-spacer>
        </v-row>
        <v-divider :class="[pymHisList.length > 5 ? 'mr-2__5' : '']"></v-divider>
        <v-row
            :class="['pa-0 nmlBfClrImp-greyE01', pymHisList.length > 5 ? 'mr-2__5' : '']"
            no-gutters>
            <v-col class="txtAlgnMid tableBR py-2" cols="1">
                <span class="font-weight-medium">연번</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-2" cols="1">
                <span class="font-weight-medium">결제일</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-2" cols="1">
                <span class="font-weight-medium">결제구분</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                <span class="font-weight-medium">결제내역</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                <span class="font-weight-medium">세부내역</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-2" cols="1">
                <span class="font-weight-medium">결제방법</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                <span class="font-weight-medium">결제상태</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-2" cols="1">
                <span class="font-weight-medium">결제금액</span>
            </v-col>
            <v-col class="txtAlgnMid py-2" cols="1">
                <span class="font-weight-medium">처리</span>
            </v-col>
        </v-row>
        <div
            v-if="pymHisList.length > 0"
            class="overflow-y-auto" style="width: 100%; height: 166px; padding-bottom: 1px;">
            <v-divider></v-divider>
            <v-row
                v-for="(itm, i) in pymHisList" :key="i"
                class="pa-0 tableBB"
                no-gutters>
                <v-col class="txtAlgnMid tableBR py-1" cols="1">
                    <span class="">{{ itm.rn }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="1">
                    <span class="">{{ $moment(itm.pymtDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="1">
                    <span v-if="itm.srvcClcd == '1'" class="">요양시스</span>
                    <span v-else-if="itm.srvcClcd == '2'" class="">문자충전</span>
                    <span v-else-if="itm.srvcClcd == '3'" class="">기관 홈페이지</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="2">
                    <span class="">{{ itm.cntnt1 }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="2">
                    <span v-if="itm.srvcClcd == '2'">
                        <span class="">충전: </span>
                        <span class="">
                            {{ itm.txtCnt - itm.cnclTxtCnt }}건
                        </span>
                        <span v-if="itm.pymtCnclCd == '2'" class=""> / </span>
                        <span v-if="itm.pymtCnclCd == '2'" class=""> 취소: {{ itm.cnclTxtCnt }}건</span>
                    </span>
                    <span v-else>
                        <span v-if="itm.pymtCnclCd == '2'">
                            <span class="">이용: {{ itm.pymtMnth - itm.cnclMnth }}개월&nbsp;/&nbsp;</span>
                            <span class="">해지: {{ itm.cnclMnth }}개월</span>
                        </span>
                        <span v-else>
                            <span v-if="Number(itm.pymtSttCd) < 5" class="">이용:&nbsp;</span>
                            <span v-else>해지:&nbsp;</span>
                            <span class="">{{ itm.pymtMnth }}개월</span>
                        </span>
                    </span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="1">
                    <span v-if="itm.pymtMthdCd == '1'" class="">무통장 입금</span>
                    <span v-else-if="itm.pymtMthdCd == '2'" class="">신용카드</span>
                    <span v-else-if="itm.pymtMthdCd == '3'" class="">계좌이체</span>
                    <span v-else-if="itm.pymtMthdCd == '4'" class="">예수금</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR py-1" cols="2">
                    <span class="">{{ itm.pymtSttNm }}</span>
                    <span v-if="itm.pymtCnclCd == '2'" class=""> (부분취소)</span>
                </v-col>
                <v-col class="txtAlgnEnd tableBR py-1 px-2" cols="1">
                    <span class="">{{ (itm.pymtAmt - itm.cnclAmt - itm.cnclTax)?.toLocaleString('ko-KR') }}원</span>
                </v-col>
                <v-col class="txtAlgnMid py-1" cols="1">
                    <v-btn
                        class="white--text" style="padding: 9px 7px 8px 5px !important;"
                        color="blueBtnColor"  @click="onPymDtlMdl(itm)"
                        min-width="20" height="20" small outlined>
                        <span class="fontNineThRem" style="padding-bottom: 2px;">결제상세</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBT tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 조회된 목록이 없습니다.</span>
            </v-col>
        </v-row>
        <v-pagination
            v-model="page" :length="pageCount" @input="getPage"
            class="mt-4" total-visible="5">
        </v-pagination>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'PaymentMgmt',

    components: {

    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getFcltyInf()
            this.getYysUseInf()
            this.getPymHisList(0, this.itemsRows)
        })
    },

    computed: {
        getRootActv: function () {
            return this.$store.getters.getRootActv
        },
        getSubActv: function () {
            return this.$store.getters.getSubActv
        },
    },

    watch: {
        getRootActv: function (v) {
            if(this.runAble == '2' && !v){
                this.page = 1
                this.getYysUseInf()
                this.getPymHisList(0, this.itemsRows)
                this.runAble = '0'
            }

        },
        getSubActv: function (v) {
            if(this.runAble == '1' && !v){
                this.page = 1
                this.getYysUseInf()
                this.getPymHisList(0, this.itemsRows)
                this.runAble = '0'
            }
        },
    },

    methods: {
        // 기관정보 조회
        getFcltyInf: function (){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('opr/selFcltyInfInfo', obj)
                .then((response) => ( this.fcltyInf = response.data ))
                .catch((error) => console.log('connect error /opr/selFcltyInfInfo : ' + error))
        },

        // 요양시스 이용 최종정보 조회
        getYysUseInf: function (){
            let obj = {}

            http.post('yyspym/selYysUseLstInfo', obj)
                .then((response) => ( this.yysUseRst(response.data) ))
                .catch((error) => console.log('connect error /yyspym/selYysUseLstInfo : ' + error))
        },

        yysUseRst: function(res){
            this.bnfcrCnt = res.result.bnfcrCnt
            if(res.result.yys != null){
                this.yysSvcInf.yysUseLstPk = res.result.yys.yysUseLstPk
                this.yysSvcInf.srvcClcd = res.result.yys.srvcClcd
                if(res.result.yys.useDtStrt != null)
                    this.yysSvcInf.useDtStrt = res.result.yys.useDtStrt
                if(res.result.yys.useDtEnd != null)
                    this.yysSvcInf.useDtEnd = res.result.yys.useDtEnd
            }
            if(res.result.sms != null){
                this.mmsSvcInf.yysUseLstPk = res.result.sms.yysUseLstPk
                this.mmsSvcInf.srvcClcd = res.result.sms.srvcClcd
                this.mmsSvcInf.txtCnt = res.result.sms.txtCnt
            }
            if(res.result.hom != null){
                this.webSvcInf.yysUseLstPk = res.result.hom.yysUseLstPk
                this.webSvcInf.srvcClcd = res.result.hom.srvcClcd
                if(res.result.hom.useDtStrt != null)
                    this.webSvcInf.useDtStrt = res.result.hom.useDtStrt
                if(res.result.hom.useDtEnd != null)
                    this.webSvcInf.useDtEnd = res.result.hom.useDtEnd
            }
        },

        // 결제내역 조회
        getPymHisList: function (strt, rowCnt){
            let obj = {}
            obj.rnLmt = rowCnt
            obj.rnStrt = strt

            http.post('yyspym/selYysPymHisList', obj)
                .then((response) => ( this.pymHisRst(response.data) ))
                .catch((error) => console.log('connect error /yyspym/selYysPymHisList : ' + error))
        },

        pymHisRst: function(res){
            if(isNaN(res.result.rnTot) == false)
                this.pymHisTot = res.result.rnTot
            if(res.result.list.length > 0)
                this.pymHisList = res.result.list

            this.pageCount = Math.ceil( Number(this.pymHisTot) / this.itemsRows )
        },

        // 결제내역 리스트 페이징
        getPage: function(){
            let strt = ( ( this.page-1 ) * this.itemsRows )
            this.getPymHisList(strt, this.itemsRows)
        },

        // 서비스 결제 팝업
        onPymMdl: function (clcd, mode) {
            let obj = {}
            obj.srvcClcd = clcd
            obj.srvcMode = mode
            obj.bnfcrCnt = this.bnfcrCnt
            
            switch (clcd) {
                case '1':
                    obj.yysUseLstPk = this.yysSvcInf.yysUseLstPk
                    break;
                case '2':
                    obj.yysUseLstPk = this.mmsSvcInf.yysUseLstPk
                    break;
                case '3':
                    obj.yysUseLstPk = this.webSvcInf.yysUseLstPk
                    break;
                default:
                    break;
            }

            obj.yysSvcEndDt = this.yysSvcInf.useDtEnd
            obj.mmsSvcRmnCnt = this.mmsSvcInf.txtCnt
            obj.webSvcEndDt = this.webSvcInf.useDtEnd
            obj.fcltyInf = this.fcltyInf

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 800, prsst: true, typ: '6001', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },

        // 결제내역 상세 팝업
        onPymDtlMdl: function (oObj) {
            let obj = {}
            obj.yysPymHisPk = oObj.yysPymHisPk
            obj.taxInvcPk = oObj.taxInvcPk
            obj.taxInvcCd = oObj.taxInvcCd
            obj.trno = oObj.trno
            obj.pymtMthdCd = oObj.pymtMthdCd
            obj.cntnt1 = oObj.cntnt1
            obj.pymtSttNm = oObj.pymtSttNm
            obj.pymtDt = oObj.pymtDt
            obj.pymtDttm = oObj.pymtDttm
            obj.pymtAmt = oObj.pymtAmt
            obj.pymtCnclDt = oObj.pymtCnclDt
            obj.pymtCnclDttm = oObj.pymtCnclDttm
            obj.cnclAmt = oObj.cnclAmt
            obj.cnclTax = oObj.cnclTax

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '10-01', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '2'
        },
    },

    data: () => ({
        page: 1,                            // 결제내역 페이지 번호
        pageCount: 0,                       // 결제내역 페이지 갯수
        itemsRows: 5,                       // 결제내역 목록 갯수
        runAble: '0',                       // 팝업 오픈 여부
        bnfcrCnt: 0,                        // 수급자 수
        pymHisTot: 0,                       // 결제내역 목록 총 갯수
        pymHisList: [],                     // 결제 내역 목록
        fcltyInf: {},                       // 기관 정보
        yysSvcInf: { yysUseLstPk: -1, srvcClcd: '', useDtStrt: '', useDtEnd: '' },
        mmsSvcInf: { yysUseLstPk: -1, srvcClcd: '', txtCnt: 0 },
        webSvcInf: { yysUseLstPk: -1, srvcClcd: '', useDtStrt: '', useDtEnd: '' },
        tabItems: [
            { index: 0, tab: '서비스 이용현황', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 1, tab: '이용요금 결제', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 2, tab: '이용요금 안내', class: 'fontOneDotTwoRem px-10', enter: false },
        ],
    }),
};
</script>