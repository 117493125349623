<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">{{date?.beforeDateFormatMonthDot()}}&nbsp;임금명세서</span>
            <v-spacer></v-spacer>            
            <v-btn v-for="(list, i) in filledBtn" :key="i"  @click = "onClickBtn(i)"
                :class="list.class" height="30" :color="list.color" :disabled="list.disabled" rounded
                style="font-size: 14px;">
                <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-form ref="tabform" lazy-validation>
            <div class="d-flex mt-2">
                <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:400px;">
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">직원정보</span>
                    </div>
                    <v-divider class="mt-2"></v-divider>                
                    <div class="d-flex pa-0 ma-0">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:190px;">
                            <span class="black--text font-weight-medium">직원명</span>
                        </div>
                        <div class="d-flex justify-start align-center py-1 px-2 ma-0 tableBL" style="width:180px;">
                            <span>{{empWgeItems.name}}</span>
                            <span class="ml-1">&#40;</span>
                            <span>
                                <v-img class="ic-sex" v-if="empWgeItems.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                <v-img class="ic-sex" v-else-if="empWgeItems.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>                                
                            </span>
                            <span>&#41;</span>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex pa-0 ma-0">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:190px;">
                            <span class="black--text font-weight-medium">생년월일</span>
                        </div>
                        <div class="d-flex justify-start align-center py-1 px-2 ma-0 tableBL" style="width:180px;">                           
                            <span>{{empWgeItems.rsdntBirth?.beforeDateFormatDot()}}</span>
                            <span class="ml-1">&#40;{{empWgeItems.age}}&#41;</span>                            
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex pa-0 ma-0">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:190px;">
                            <span class="black--text font-weight-medium">직종</span>
                        </div>
                        <div class="d-flex justify-start align-center py-1 px-2 ma-0 tableBL" style="width:180px;">
                            {{empWgeItems.jobNm}}
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex pa-0 ma-0">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:190px;">
                            <span class="black--text font-weight-medium">입사일</span>
                        </div>
                        <div class="d-flex justify-start align-center py-1 px-2 ma-0 tableBL" style="width:180px;">
                            {{empWgeItems.entDt?.beforeDateFormatDot()}}
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex pa-0 ma-0">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:190px;">
                            <span class="black--text font-weight-medium">임금형태</span>
                        </div>
                        <div class="d-flex justify-start align-center py-1 px-2 ma-0 tableBL" style="width:180px;">
                            {{empWgeItems.wgeTypNm}}
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex pa-0 ma-0">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:190px;">
                            <span class="black--text font-weight-medium">부양가족수</span>
                        </div>
                        <div class="d-flex justify-start align-center py-1 px-2 ma-0 tableBL" style="width: 180px;">
                            총&#58;{{empWgeItems.dpnNum}}&#40;20이하&#58;{{empWgeItems.dpnNumU20}}&#41;
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex pa-0 ma-0">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:90px;">
                            <span class="black--text font-weight-medium">지급계좌</span>
                        </div>
                        <div class="justify-start align-center pa-0 ma-0 tableBL" style="width:309px;">
                            <div class="d-flex pa-0 ma-0" style="width:309px;">
                                <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:99px;">                                
                                    <span class="black--text font-weight-medium">은행</span>
                                </div>
                                <div class="d-flex justify-start align-center py-1 px-2 ma-0 tableBL" style="width:180px;"> 
                                    {{empWgeItems.bnkNm}}  
                                </div>
                            </div>
                            <v-divider></v-divider>
                            <div class="d-flex pa-0 ma-0" no-gutters style="width:100%;">
                                <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:99px;">                                
                                    <span class="black--text font-weight-medium">계좌번호</span>
                                </div>
                                <div class="d-flex justify-start align-center py-1 px-2 ma-0 tableBL" style="width:180px;"> 
                                    {{empWgeItems.bnkAcct}}  
                                </div>
                            </div>
                            <v-divider></v-divider>
                            <div class="d-flex pa-0 ma-0" no-gutters style="width:100%;">
                                <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:99px;">                                
                                    <span class="black--text font-weight-medium">예금주명</span>
                                </div>
                                <div class="d-flex justify-start align-center py-1 px-2 ma-0 tableBL" style="width:180px;"> 
                                    {{empWgeItems.bnkHld}}    
                                </div>
                            </div>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex pa-0 ma-0">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:190px;">
                            <span class="black--text font-weight-medium">지급일</span>
                        </div>
                        <div class="d-flex justify-start align-center tableBL" style="width:180px;">
                            <span class="d-inline-block py-2 px-1" style="width: 140px;">
                                <CmmDateComp v-model="empWgeItems.pymDt" :required="true"></CmmDateComp>
                            </span>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex align-center" style="margin-top: 62px;">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">명세서 산출식 또는 산출방법</span>
                    </div>
                    <div class="pa-0 ma-0 mt-2">
                        <v-row style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                                <span class="black--text font-weight-medium">구분</span>
                            </v-col>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="8" style="border-left: 1px solid #dbdbdb !important">
                                <span class="black--text font-weight-medium">산출식 또는 산출방법</span>
                            </v-col>
                        </v-row>
                        <div v-for="(item, i) in wgeStmtList" :key="i">
                            <v-row v-if="item.view" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4">
                                    {{item.wgeItmClnm}}
                                </v-col>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="8" style="border-left: 1px solid #dbdbdb !important">                                
                                    {{item.calcCntnt}}
                                </v-col>
                            </v-row>
                        </div>
                        <div v-if="wgeStmtListLen == 0">
                            <v-row style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="12">
                                   ※ 목록이 없습니다.
                                </v-col>                                
                            </v-row>
                        </div>
                    </div>
                </div>
                <div class="d-inline-block pa-0 ma-0 ml-3" style="width:735px;">
                    <div class="d-flex" style="width:100%">
                        <div class="d-inline-block pl-2 ma-0 align-self-start" style="width:50%">
                            <div class="d-flex align-center">
                                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                                <span class="text-h6 font-weight-bold">지급 항목</span>
                                <v-spacer></v-spacer>
                                <!-- <span v-if="empWgePymList.length > 0">
                                    <v-btn
                                        class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                                        min-width="30" height="25" @click="menu = true"
                                        small outlined>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                    <v-icon size="14">mdi-plus-circle-outline</v-icon>
                                                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">추가</span>
                                                </div>
                                            </template>
                                            <span>지급 항목 추가</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span> -->
                                <span>
                                    <v-btn
                                        class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                                        min-width="30" height="25" @click="empWgePym = true"
                                        small outlined>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                    <v-icon size="14">mdi-text-box-edit-outline</v-icon>
                                                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">수정</span>
                                                </div>
                                            </template>
                                            <span>지급 항목 수정</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span>                                
                            </div>
                            <v-divider :class="['mt-2', empWgePymList.length > 7 ? 'mr-2__5' : '']"></v-divider>
                            <div :class="['greyE01 d-flex pa-0', empWgePymList.length > 7 ? 'mr-2__5' : '']">
                                <div class="pa-1 ma-0" style="width:35%; text-align: center;">                                
                                    <span class="black--text font-weight-medium">급여내역</span>
                                </div>
                                <div class="pa-1 ma-0 tableBL" style="width:30%; text-align: center;">                                
                                    <span class="black--text font-weight-medium">과세구분</span>
                                </div>
                                <div class="pa-1 ma-0 tableBL" style="width:35%; text-align: center;">                                
                                    <span class="black--text font-weight-medium">소득액</span>
                                </div>
                            </div>
                            <v-divider :class="[empWgePymList.length > 7 ? 'mr-2__5' : '']"></v-divider>
                            <div class="overflow-y-auto" style="height:370px;">
                                <div v-if="empWgePymList.length > 0">
                                    <div v-for="(item,m) in empWgePymList" :key="m" class="d-flex pa-0 tableBB">
                                        <div class="d-flex justify-center align-center py-1 pl-2 ma-0" style="width:35%;">                                
                                            {{item.itmNm}}
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:30%;">                                
                                            {{item.txClNm}}
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:35%;">                                                                            
                                            {{item.amt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="d-flex pa-0 tableBB" style="width:100%;">
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                                        데이터가 없습니다.
                                    </div> 
                                </div>
                            </div>
                            <v-divider class="mt-4"></v-divider>
                            <div class="d-flex pa-0" style="width:100%;">
                                <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:65%;">                                
                                    <span class="black--text font-weight-medium">과세 합계</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:35%;">                                
                                    {{empWgePymTaxAmt?.toLocaleString('ko-KR')}}
                                </div>
                            </div>
                            <v-divider class=""></v-divider>
                            <div class="d-flex pa-0 ma-0" no-gutters style="width:100%;">
                                <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:65%;">                                
                                    <span class="black--text font-weight-medium">비과세 합계</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:35%;">                                
                                    {{empWgePymFreeAmt?.toLocaleString('ko-KR')}}
                                </div>
                            </div>
                            <v-divider class=""></v-divider>
                            <div class="d-flex pa-0 ma-0" no-gutters style="width:100%;">
                                <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:65%;">                                
                                    <span class="black--text font-weight-medium">소득액 합계</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:35%;">                                
                                    {{empWgePymTotAmt?.toLocaleString('ko-KR')}}
                                </div>
                            </div>
                            <v-divider class=""></v-divider>
                        </div>
                        <div class="d-inline-block pl-2 ma-0 align-self-start" style="width:50%">
                            <div class="d-flex align-center">
                                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                                <span class="text-h6 font-weight-bold">공제 항목</span>
                                <v-spacer></v-spacer>
                                <span>
                                    <v-btn
                                        class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                                        min-width="30" height="25" @click="empWgeDctn = true"
                                        small outlined>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                    <v-icon size="14">mdi-text-box-edit-outline</v-icon>
                                                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">수정</span>
                                                </div>
                                            </template>
                                            <span>공제 항목 수정</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span>  
                            </div>
                            <v-divider :class="['mt-2', empWgePymDctnList.length >  8 ? 'mr-2__5' : '']"></v-divider>
                            <div :class="['greyE01 d-flex pa-0', empWgePymDctnList.length >  8 ? 'mr-2__5' : '']">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;">                                
                                    <span class="black--text font-weight-medium">공제항목</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:50%;">                                
                                    <span class="black--text font-weight-medium">공제액</span>
                                </div>
                            </div>
                            <v-divider :class="[empWgePymDctnList.length >  8 ? 'mr-2__5' : '']"></v-divider>
                            <div class="overflow-y-auto" style="height:418px;">
                                <div v-if="empWgePymDctnList.length > 0">
                                    <div v-for="(item,m) in empWgePymDctnList" :key="m" class="d-flex pa-0 tableBB">
                                        <div class="d-flex justify-center align-center py-1 pl-2ma-0" style="width:50%;">                                
                                            {{item.itmNm}}
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:50%;">                                
                                            {{item.amt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="d-flex pa-0 tableBB" style="width:100%">
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                                        데이터가 없습니다.
                                    </div>
                                </div>
                            </div>
                            <v-divider></v-divider>
                            <div class="d-flex pa-0 ma-0">
                                <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:50%;">                                
                                    <span class="black--text font-weight-medium">공제액 합계</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:50%;">                                
                                    {{empWgePymDctnAmt?.toLocaleString('ko-KR')}}
                                </div>
                            </div>
                            <v-divider></v-divider>
                            <div class="d-flex pa-0 ma-0">
                                <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:50%;">                                
                                    <span class="black--text font-weight-medium">실수령액</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:50%;">                                
                                    {{empWgePymRealAmt?.toLocaleString('ko-KR')}}
                                </div>
                            </div>
                            <v-divider></v-divider>
                        </div>
                    </div>
                    <div style="width:100%">
                        <v-divider class="mt-3"></v-divider>
                        <v-row class="pa-0 ml-2" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">
                                <span class="black--text font-weight-medium">비고</span>
                            </v-col>
                            <v-col class="d-flex justify-start align-center pa-1 ma-0 tableBL" cols="9">                               
                               <v-textarea v-model="empItems.rmrks" class="rmrksArea" style="font-size:0.9rem !important;"
                                    rows="5" outlined dense hide-details no-resize>
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                    </div>
                </div>
            </div>
        </v-form>
        <div class="ma-0 pa-0">           
            <v-dialog v-model="menu" content-class="round" max-width="600" persistent>
                <AddPymItm ref="addPymItm" :rmList="empWgePymList" @modalClose="menu=false" @sltList="addWgePymList"></AddPymItm>
            </v-dialog>
            <v-dialog eager v-model="empWgePym" content-class="round" max-width="800" persistent>
                <EmpWageStmtPymWrt ref="empWageStmtPymWrt" @modalClose="empWgePym=false" @mdlTrigger="mdlTrigger"></EmpWageStmtPymWrt>
            </v-dialog>
            <v-dialog eager v-model="empWgeDctn" content-class="round" max-width="500" persistent>
                <EmpWageStmtDctnWrt ref="empWageStmtDctnWrt" @modalClose="empWgeDctn=false" @mdlTrigger="mdlTrigger"></EmpWageStmtDctnWrt>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import AddPymItm from './AddPymItm.vue';
import EmpWageStmtPymWrt from './EmpWageStmtPymWrt.vue';
import EmpWageStmtDctnWrt from './EmpWageStmtDctnWrt.vue';


import { selEmpWgeStmtInfo, updWgePymHisRmrks } from '../../api/impExp.js';

export default {
    name: 'EmpWageStmtWrt',
    
    components: {  
        CmmDateComp,
        AddPymItm,
        EmpWageStmtPymWrt,
        EmpWageStmtDctnWrt,
    },

    props : {
        empWageStmtWrtPk : { type: Number, default: 0 },
        mmbrPk: { type: Number, default: -1 },
        date: { type: String, default: '' },
        cnfrm: { type: Boolean, default: false },
    },

    computed: {
        
    },

    created: function(){   
    },

    mounted: function(){
        this.$nextTick(function () {
            this.empPk = this.empWageStmtWrtPk
            this.onLoad()
        })
    },

    watch: {
        'empWageStmtWrtPk':function(value){
            if(value > 0) {
                this.empPk = this.empWageStmtWrtPk
                this.onLoad()

            }

            if(this.cnfrm) this.filledBtn[0].disabled = true 
            else  this.filledBtn[0].disabled = false 
        }
    },

    methods: {  
        onLoad: function(){          
            this.getEmpWgeInfo()   
        },
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.onEmpWgeSave()
                    break;                
                default:
                    break;
            }
        },
        onEmpWgeSave:function(){
            if(this.$refs.tabform.validate()){
                let pObj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    //mmbr
                    empPks:[this.empPk],
                    empPk: this.empPk,
                    userNm:this.$store.getters.getUserNm,
                    inqYYMM:this.date,
                    stdYymm:this.date,
                    mmbrPk: this.mmbrPk,
                    rmrks:this.empItems.rmrks,
                    pymDt: this.$moment(this.empWgeItems.pymDt, 'YYYY-MM-DD', true).format('YYYYMMDD'),
                }
    
                updWgePymHisRmrks(pObj)
                    .then((response) => ( this.onEmpWgeSaveAfter(response.data) ))
                    .catch((error) => console.log('connect error /impExp/updWgePymHisRmrks : ' + error))
            }            
        },
        getEmpWgeInfo: function(){            
            selEmpWgeStmtInfo(this.$store.getters.getFcltyNum, this.date, this.empPk)
                .then((response) => ( this.getEmpWgeInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selEmpWgeStmtInfo : ' + error))
        },
        
        
        onEmpWgeSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])

                if(typeof this.$refs.addPymItm != 'undefined')
                    this.$refs.addPymItm.getWgeItmList()

                this.$emit('modalEvent', 99)
                this.onLoad(this.empPk)
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        },
        
        getEmpWgeInfoAfter: function(res){   
            this.empWgeItems = Object.assign({})
            this.empWgeItems = res.info
            this.cmnHrlWgs =   res.info.cmnHrlWgs

            if(typeof this.empWgeItems.pymDt != 'undefined')
                this.empWgeItems.pymDt = this.$moment(this.empWgeItems.pymDt, 'YYYYMMDD', true).format('YYYY-MM-DD')

            // if(typeof this.$refs.addPymItm != 'undefined')
            //     this.$refs.addPymItm.getWgeItmList()

            if(res.list != null && res.list.length > 0  && res.list != undefined){
                this.getMobEmpWgePymListAfter(res.list)
                this.getEmpWgePymListAfter(res.list)
            }
        },        
       
        getMobEmpWgePymListAfter: function(res){
            this.empWgePymList.splice(0)            
            this.empWgePymDctnList.splice(0)   
            this.empItems.rmrks = ''
            this.resetWgeStmt()

            res.forEach(item => {
                if(item.amt == '' || item.amt == null) item.amt = 0

                if(item.calcClcd == '1' && item.amt != 0){
    
                    if(item.txClcd == '1')
                        item.txClNm = '과세'
                    else if(item.txClcd == '2')
                        item.txClNm = '비과세'

                    this.empWgePymList.push(item)
                }
                else if(item.calcClcd == '2'  && item.amt != 0)
                    this.empWgePymDctnList.push(item)

                this.empItems.rmrks = item.rmrks

                switch (item.wgeItmClcd) {
                    case '18':
                        this.setWgeStmt(item)
                        break;
                    case '19':
                        this.setWgeStmt(item)
                        break;
                    case '20':
                        this.setWgeStmt(item)
                        break;                
                    default:
                        break;
                }

            });

            this.calcTotPym()   
        },   
        getEmpWgePymListAfter:function(res){
            this.$refs.empWageStmtPymWrt.setData(res)
            this.$refs.empWageStmtDctnWrt.setData(res)
        },
        //지급, 공제 항목 합계 계산
        calcTotPym: function () {
            let totAmt = 0
            let taxAmt = 0
            let freeAmt = 0
            let dctnAmt = 0

            this.empWgePymList.forEach(pym => {                
                totAmt += Number(pym.amt)
    
                if(pym.txClcd == '1')
                    taxAmt += Number(pym.amt)
                else if(pym.txClcd == '2')
                    freeAmt += Number(pym.amt)


                switch (pym.wgeItmClcd) {
                    case '18':
                        this.setWgeStmtUse(pym)
                        break;
                    case '19':
                        this.setWgeStmtUse(pym)
                        break;
                    case '20':
                        this.setWgeStmtUse(pym)
                        break;                
                    default:
                        break;
                }
            });

            this.empWgePymDctnList.forEach(dctn => {
                dctnAmt += Number(dctn.amt)
            });

            this.empWgePymTotAmt = totAmt
            this.empWgePymTaxAmt = taxAmt
            this.empWgePymFreeAmt = freeAmt
            this.empWgePymDctnAmt = dctnAmt

            this.empWgePymRealAmt = totAmt - dctnAmt
        },
        addWgePymList: function (sltList) {
            let tmpArr = this.empWgePymList.filter( v => v.wgePymPk > 0 )
            this.empWgePymList.splice(0)
            this.empWgePymList = tmpArr

            //trgt: 1(기관), 2(직원), pymDt 지급일자, wgeItmClcd 임금항목구분, 
            sltList.forEach(list => {
                list.trgt = '2'
                list.wgePymPk = 0
                if(this.empWgePymList.length > 0){
                    list.wgePymHisPk = this.empWgePymList[0].wgePymHisPk
                    list.pymDt = this.empWgePymList[0].pymDt
                }
                else{
                    list.wgePymHisPk = 0
                    list.pymDt = this.date
                }

                list.amt = 0
                list.txClNm = list.txClcdNm
                this.empWgePymList.push(list)

                switch (list.wgeItmClcd) {
                    case '18':
                        this.setWgeStmt(list)
                        break;
                    case '19':
                        this.setWgeStmt(list)
                        break;
                    case '20':
                        this.setWgeStmt(list)
                        break;                
                    default:
                        break;
                }
            });
        },
        resetWgeStmt:function(){
            this.wgeStmtListLen = 0
            this.wgeStmtList.forEach(el => {
                el.disabled     = true
                el.view         = false
                el.calcCntnt    = ''
            });
        },
        setWgeStmt:function(obj){                        
            let idx = this.wgeStmtList.findIndex(x=>x.wgeItmClcd == obj.wgeItmClcd)
            this.wgeStmtList[idx].calcCntnt     = obj.calcCntnt
            this.wgeStmtList[idx].placeholder   = "0시간 × "+this.cmnHrlWgs?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')+" × 0.5"
            this.wgeStmtList[idx].view          = true
            this.wgeStmtListLen++
        },
        setWgeStmtUse:function(obj){
            let idx = this.wgeStmtList.findIndex(x=>x.wgeItmClcd == obj.wgeItmClcd)
            if(obj.amt != '' && Number(obj.amt) > 0){
                this.wgeStmtList[idx].disabled      = false
            }else{
                this.wgeStmtList[idx].calcCntnt     = ''
                this.wgeStmtList[idx].disabled      = true
            }
        },
        onClose: function () {
            this.$emit('modalEvent', 0)
        },
        mdlTrigger:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getEmpWgeInfo()
                this.$emit('modalEvent', 99)
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({ 
        menu: false,
        empWgePym:false,
        empWgeDctn:false,
        forceRender:0,
        empPk:0,
        cmnHrlWgs:0,
        empItems:{ pymDt: '', rmrks:'' },
        empWgeItems:{},
        empWgeWrkList:[],
        empWgePymTotAmt:0,
        empWgePymTaxAmt:0,
        empWgePymFreeAmt:0,
        empWgePymDctnAmt:0,
        empWgePymRealAmt:0,
        empWgePymList:[],
        empWgePymDctnList:[],
        wgeStmtList:[
            {wgeItmClnm:'연장가산수당', wgeItmClcd:'18',calcCntnt:'', placeholder:'', disabled:true, view:false},
            {wgeItmClnm:'야간가산수당', wgeItmClcd:'19',calcCntnt:'', placeholder:'', disabled:true, view:false},
            {wgeItmClnm:'휴일가산수당', wgeItmClcd:'20',calcCntnt:'', placeholder:'', disabled:true, view:false},
        ],
        wgeStmtListLen:0,
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'800px', disabled: false, },            
        ],
        outlinedBtn: [            
        ],
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,
    }),
};
</script>