<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">건강수준 평가</span>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                    :class="list.class" height="30" :color="list.color" rounded
                    :disabled="btnDisabled"
                    style="font-size: 14px;">
                    <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </span>
            <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j"
                :class="list.class" @click = "onClickBtn(list.text)"
                :disabled="btnDisabled"
                samll height="30" :color="list.color" rounded outlined>
                <v-icon small>{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <basicInfo :bnMmbrPk="this.bnMmbrPk"></basicInfo>
        <v-form ref="form" lazy-validation>
            <v-row class="pa-0 mt-2" style="border-top: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">조사사유</span>
                </v-col>
                <v-col class="py-1 pl-2 d-flex justify-start align-center" cols="3">
                    <span class="d-inline-block" style="width: 250px;">
                        <v-radio-group
                            v-model="items.examRsncd" :rules="[rules.required]"
                            class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row>
                            <v-radio class="pa-0 mr-1" :ripple="false" label="신규" value="1"></v-radio>
                            <v-radio class="pa-0 mr-1" :ripple="false" label="재사정" value="2"></v-radio>
                            <v-radio class="pa-0 ma-0" :ripple="false" label="상태변화" value="3"></v-radio>
                        </v-radio-group>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">작성일</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="2">
                    <span class="white d-inline-block py-1 pl-2" style="width: 154px;">   
                        <CmmDateComp v-model="items.examDt" :required="true" :minDt="inqYYYY+'-01-01'" :maxDt="inqYYYY+'-12-31'"></CmmDateComp>                                                     
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="4">
                    <span class="d-inline-block py-1 pl-2" style="width: 120px;">
                        <v-text-field
                            v-model="items.wrtr" :rules="[rules.required]" :key="forceRender"
                            color="blue" height="28" ref="info"
                            readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal"
                        >
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>            
            </v-row>
            <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col v-for="(item, i) in hlthEvltnList" :key="i" cols="4" :class="['', i != 6 ? 'tableBB' : '']">
                    <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="3">
                            <span class="black--text font-weight-medium">{{item.itmCdnm}}</span>
                        </v-col>
                        <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="9">
                            <span v-if="item.valClcd == '2'" class="d-inline-block py-1 pl-2" style="width: 120px;">
                                <v-text-field
                                    v-model="item.itmVal" :rules="[rules.required]"
                                    color="blue" height="28" ref="info"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span v-if="item.valClcd == '5'" class="d-inline-block" style="width: 250px;">
                                <v-radio-group
                                    v-model="item.itmVal"  :rules="[rules.required]"
                                    class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row>
                                    <span v-for="(item, i) in codeItem" :key="i">
                                        <v-radio class="pa-0 mr-1" :ripple="false" :label="item.valcdnm" :value="item.valcd"></v-radio>
                                    </span>
                                </v-radio-group>
                            </span>                            
                        </v-col>
                    </v-row>                    
                </v-col>    
                <v-col cols="8" class="tableBL">
                    <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="12">
                            <div class="pt-1" v-for="(list, g) in hlthEvltnEtcList" :key="g">
                                <div v-if="list.itmCd < 13" style="width: 80px;">
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                        v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                        value="1" hide-details>
                                    </v-checkbox>
                                </div>  
                                <div v-else-if="list.itmCd == 13 || list.itmCd == 14" style="width: 100px;">
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                        v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                        value="1" hide-details>
                                    </v-checkbox>
                                </div>  
                                <div v-else class="d-flex">
                                    <span style="width:60px">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                            value="1" hide-details>
                                        </v-checkbox>
                                    </span>
                                    <span>&#40;</span>
                                    <span style="width:200px">
                                        <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                    </span>
                                    <span>&#41;</span>
                                </div>
                            </div>                          
                        </v-col>
                    </v-row>                    
                </v-col>              
            </v-row>    
             
            <v-row class="mt-3" style="height:450px;overflow-y:auto" no-gutters>
                <v-col class="pr-3" cols="6">
                    <v-row v-for="(item, j) in hlthEvltnLftList" :key="j" no-gutters style="border-top: 1px solid #dbdbdb !important">
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <span class="black--text font-weight-medium">{{item.clsfc2Cdnm}}</span>
                        </v-col>
                        <v-col class="pa-0 justify-start align-center" style="border-left: 1px solid #dbdbdb;" cols="10">
                            <div class="pl-1 py-2" v-if="item.clsfc2Cd == '2'">   
                                <div v-for="(clsfc3, n) in item.clsfc3Cds" :key="n">
                                    <v-radio-group v-model="clsfc3.itmVal" class="radio-dft-Theme mt-0 pl-1 pt-1" hide-details row>                                            
                                        <div v-for="(list, m) in clsfc3.list" :key="m">
                                            <span class="d-inline-block pa-0 ma-0" >
                                                <v-radio :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>
                                            </span>
                                            <span v-if="list.valClcd=='2'">&#40;</span>
                                            <span class="d-inline-block pa-0 ma-0" style="width:70px" v-if="list.valClcd=='2'">
                                                <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                            </span>           
                                            <span v-if="list.valClcd=='2'">&#41;</span>                               
                                        </div>
                                    </v-radio-group>
                                </div>
                            </div>
                            <div class="pl-1 py-2" v-else-if="item.clsfc2Cd == '3'">   
                                <div v-for="(clsfc3, n) in item.clsfc3Cds" :key="n">
                                    <v-radio-group v-model="clsfc3.itmVal" class="radio-dft-Theme mt-0 pl-1 pt-1" hide-details row>                                            
                                        <div v-for="(list, m) in clsfc3.list" :key="m">
                                            <div v-if="list.valClcd =='1'" style="width:240px">
                                                <v-checkbox
                                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                    v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                    value="1" hide-details>
                                                </v-checkbox>
                                            </div>    
                                            <div v-else class="d-flex" style="width:240px">
                                                <span style="width:80px">
                                                    <v-checkbox
                                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                        v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                        value="1" hide-details>
                                                    </v-checkbox>
                                                </span>
                                                <span>&#40;</span>
                                                <span style="width:80px">
                                                    <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                </span>
                                                <span>&#41;</span>
                                            </div>                  
                                        </div>
                                    </v-radio-group>
                                </div>
                            </div>
                            <div class="pl-1 py-2" v-else-if="item.clsfc2Cd == '4'">   
                                <div v-for="(clsfc3, n) in item.clsfc3Cds" :key="n">
                                    <div v-for="(list, m) in clsfc3.list" :key="m" class="d-inline-block">
                                        <div v-if="list.itmCd != '8' && list.itmCd != '9' && list.itmCd != '10'">
                                            <div v-if="list.itmCd != '7' && list.valClcd =='1'" style="width:180px">
                                                <v-checkbox
                                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                    v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                    value="1" hide-details>
                                                </v-checkbox>
                                            </div>
                                            <div v-else-if="list.itmCd == '7'" style="width:320px">
                                                <v-checkbox
                                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                    v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                    value="1" hide-details>
                                                </v-checkbox>
                                            </div>
                                            <div v-else class="d-flex" style="width:240px">
                                                <span style="width:150px">
                                                    <v-checkbox
                                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                        v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                        value="1" hide-details>
                                                    </v-checkbox>
                                                </span>
                                                <span>&#40;</span>
                                                <span style="width:80px">
                                                    <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                </span>
                                                <span>&#41;</span>
                                            </div>
                                        </div>
                                        <div v-else-if="list.itmCd == '8'">
                                            <div class="d-flex align-center" style="width:155px">
                                                <span style="width:60px">
                                                    <v-checkbox
                                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                        v-model="list.itmVal" :ripple="false" :label="'통증'"
                                                        value="1" hide-details>
                                                    </v-checkbox>
                                                </span>
                                                <span>&#40;</span>
                                                <span>부위:</span>
                                                <span style="width:50px">
                                                    <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-else-if="list.itmCd == '9'">
                                            <div class="d-flex align-center" style="width:150px">                                            
                                                <span>양상:</span>
                                                <span style="width:50px">
                                                    <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                </span>
                                                <span>&#41;</span>
                                            </div>
                                        </div>
                                        <div v-else-if="list.itmCd == '10'">
                                            <div class="d-flex align-center" style="width:180px">                                            
                                                <span style="width:60px">
                                                    <v-checkbox
                                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                        v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                        value="1" hide-details>
                                                    </v-checkbox>
                                                </span>
                                                <span>&#40;</span>
                                                <span style="width:80px">
                                                    <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                </span>
                                                <span>&#41;</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="item.clsfc2Cd == '5'">
                                <div v-for="(clsfc3, n) in item.clsfc3Cds" :key="n">
                                    <div v-if="clsfc3.clsfc3Cd =='1'">
                                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters> 
                                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                                                <span class="black--text">{{clsfc3.clsfc3Cdnm}}</span>
                                            </v-col>
                                            <v-col class="pa-1 ma-0 d-flex" style="border-left: 1px solid #dbdbdb;" cols="10"> 
                                                <div v-for="(list, m) in clsfc3.list" :key="m" style="width:100px;">
                                                    <v-checkbox
                                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                        v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                        value="1" hide-details>
                                                    </v-checkbox>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div v-else-if="clsfc3.clsfc3Cd =='2'">
                                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters> 
                                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                                                <span class="black--text">{{clsfc3.clsfc3Cdnm}}</span>
                                            </v-col>
                                            <v-col class="pa-1 ma-0" style="border-left: 1px solid #dbdbdb;" cols="10">                                                 
                                                <v-radio-group v-model="clsfc3.itmVal" class="radio-dft-Theme mt-0 pt-1" hide-details row>                                            
                                                    <div v-for="(list, m) in clsfc3.list" :key="m" class="pr-3">
                                                        <span class="d-inline-block pa-0 ma-0" >
                                                            <v-radio :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>
                                                        </span>
                                                        <span v-if="list.valClcd=='2'">&#40;</span>
                                                        <span class="d-inline-block pa-0 ma-0" style="width:70px" v-if="list.valClcd=='2'">
                                                            <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                        </span>           
                                                        <span v-if="list.valClcd=='2'">&#41;</span>                               
                                                    </div>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div v-else-if="clsfc3.clsfc3Cd =='3'" class="pl-1">
                                        <div v-for="(list, m) in clsfc3.list" :key="m" class="d-inline-block">
                                            <div v-if="list.itmCd != '7' && list.itmCd != '8' && list.itmCd != '10'">
                                                <div v-if="list.valClcd =='1'" style="width:120px">
                                                    <v-checkbox
                                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                        v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                        value="1" hide-details>
                                                    </v-checkbox>
                                                </div>
                                            </div>
                                            <div v-else-if="list.itmCd == '7'">
                                                <div class="d-flex align-center" style="width:150px">
                                                    <span style="width:60px">
                                                        <v-checkbox
                                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                            v-model="list.itmVal" :ripple="false" :label="'통증'"
                                                            value="1" hide-details>
                                                        </v-checkbox>
                                                    </span>
                                                    <span>&#40;</span>
                                                    <span>부위:</span>
                                                    <span style="width:50px">
                                                        <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                    </span>
                                                </div>
                                            </div>
                                            <div v-else-if="list.itmCd == '8'">
                                                <div class="d-flex align-center" style="width:150px">                                            
                                                    <span>양상:</span>
                                                    <span style="width:50px">
                                                        <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                    </span>
                                                    <span>&#41;</span>
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="item.clsfc2Cd == '6'" class="pl-1 py-2">
                                <div v-for="(clsfc3, n) in item.clsfc3Cds" :key="n">
                                    <div v-for="(list, m) in clsfc3.list" :key="m" class="d-inline-block">
                                        <div v-if="list.itmCd == '1'">
                                            <div class="d-flex align-center" style="width:155px">
                                                <span style="width:60px">
                                                    <v-checkbox
                                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                        v-model="list.itmVal" :ripple="false" :label="'흉통'"
                                                        value="1" hide-details>
                                                    </v-checkbox>
                                                </span>
                                                <span>&#40;</span>
                                                <span>부위:</span>
                                                <span style="width:50px">
                                                    <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-else-if="list.itmCd == '2'">
                                            <div class="d-flex align-center" style="width:145px">                                            
                                                <span>양상:</span>
                                                <span style="width:50px">
                                                    <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                </span>
                                                <span>&#41;</span>
                                            </div>
                                        </div>   
                                        <div v-else-if="list.itmCd == '3'">
                                            <div class="d-flex align-center" style="width:155px">
                                                <span style="width:60px">
                                                    <v-checkbox
                                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                        v-model="list.itmVal" :ripple="false" :label="'부종'"
                                                        value="1" hide-details>
                                                    </v-checkbox>
                                                </span>
                                                <span>&#40;</span>
                                                <span>부위:</span>
                                                <span style="width:50px">
                                                    <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                </span>
                                                <span>&#41;</span>
                                            </div>
                                        </div>   
                                        <div v-else>
                                            <div v-if="list.valClcd =='1'" style="width:150px">
                                                <v-checkbox
                                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                    v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                    value="1" hide-details>
                                                </v-checkbox>
                                            </div>    
                                            <div v-else class="d-flex" style="width:240px">
                                                <span style="width:80px">
                                                    <v-checkbox
                                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                        v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                        value="1" hide-details>
                                                    </v-checkbox>
                                                </span>
                                                <span>&#40;</span>
                                                <span style="width:80px">
                                                    <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                </span>
                                                <span>&#41;</span>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="item.clsfc2Cd == '8'" class="pl-2 py-2">
                                <div v-for="(clsfc3, n) in item.clsfc3Cds" :key="n">                                    
                                    <div v-for="(list, m) in clsfc3.list" :key="m" class="d-inline-block">
                                        <div v-if="list.itmCd =='1'" class="d-flex" style="width:250px">
                                            <span style="width:150px">
                                                <v-checkbox
                                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                    v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                    value="1" hide-details>
                                                </v-checkbox>
                                            </span>
                                            <span>&#40;</span>
                                            <span style="width:80px">
                                                <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                            <span>&#41;</span>
                                        </div> 
                                        <div v-else-if="list.itmCd =='2'" class="d-flex" style="width:240px">
                                            <span style="width:120px">
                                                <v-checkbox
                                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                    v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                    value="1" hide-details>
                                                </v-checkbox>
                                            </span>
                                            <span>&#40;</span>
                                            <span style="width:80px">
                                                <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                            </span>
                                            <span>&#41;</span>
                                        </div>   
                                        <div v-else style="width:100px">
                                            <v-checkbox
                                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                value="1" hide-details>
                                            </v-checkbox>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="6">
                    <v-row v-for="(item, j) in hlthEvltnRftList" :key="j" no-gutters style="border-top: 1px solid #dbdbdb !important">
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <span v-if="item.clsfc2Cd == '10'" class="black--text font-weight-medium">
                                일상생활<br />수행능력<br />정도
                            </span>
                            <span v-else class="black--text font-weight-medium">{{item.clsfc2Cdnm}}</span>
                        </v-col>
                        <v-col class="pa-0 justify-start align-center" style="border-left: 1px solid #dbdbdb;" cols="10">
                            <div v-if="item.clsfc2Cd == '7'">
                                <div v-for="(clsfc3, n) in item.clsfc3Cds" :key="n">                                    
                                    <div v-if="clsfc3.clsfc3Cd =='1'" class="pl-2 py-2">
                                        <div v-for="(list, m) in clsfc3.list" :key="m" class="d-inline-block">
                                            <div v-if="list.valClcd =='1'" style="width:150px">
                                                <v-checkbox
                                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                    v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                    value="1" hide-details>
                                                </v-checkbox>
                                            </div>    
                                            <div v-else class="d-flex" style="width:160px">
                                                <span style="width:80px">
                                                    <v-checkbox
                                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                        v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                        value="1" hide-details>
                                                    </v-checkbox>
                                                </span>
                                                <span>&#40;</span>
                                                <span style="width:80px">
                                                    <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                </span>
                                                <span>&#41;</span>
                                            </div>                                              
                                        </div>
                                    </div>
                                    <div v-else-if="clsfc3.clsfc3Cd =='2'">
                                        <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb;" no-gutters> 
                                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                                                <span class="black--text">{{clsfc3.clsfc3Cdnm}}</span>
                                            </v-col>
                                            <v-col class="pa-1 ma-0" style="border-left: 1px solid #dbdbdb;" cols="10"> 
                                                <div v-for="(list, m) in clsfc3.list" :key="m" class="d-flex align-center py-1">
                                                    <span class="d-inline-block" style="width:100px">{{list.itmCdnm}}</span>
                                                    <span class="pr-3">&#58;</span>
                                                    <span class="d-inline-block" style="width:100px">
                                                        <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                    </span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div v-else-if="clsfc3.clsfc3Cd =='3'">
                                        <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb;" no-gutters> 
                                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                                                <span class="black--text">{{clsfc3.clsfc3Cdnm}}</span>
                                            </v-col>
                                            <v-col class="pa-1 ma-0" style="border-left: 1px solid #dbdbdb;" cols="10">                                                 
                                                <div v-for="(list, m) in clsfc3.list" :key="m" class="d-flex align-center py-1">
                                                    <span class="d-inline-block" style="width:100px">{{list.itmCdnm}}</span>
                                                    <span class="pr-3">&#58;</span>
                                                    <span class="d-inline-block" style="width:100px">
                                                        <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                    </span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </div>
                            
                            <div v-else-if="item.clsfc2Cd == '9'">
                                <div v-for="(clsfc3, n) in item.clsfc3Cds" :key="n" class="pl-2 py-1">                                    
                                    <div v-for="(list, m) in clsfc3.list" :key="m" class="d-inline-block py-1">
                                        <span class="d-inline-block" style="width:120px">{{list.itmCdnm}}</span>
                                        <span class="pr-3">&#58;</span>
                                        <span class="d-inline-block pr-3" style="width:100px">
                                            <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="item.clsfc2Cd == '10'">
                                <div v-for="(clsfc3, n) in item.clsfc3Cds" :key="n">         
                                    <v-row :class="['', clsfc3.clsfc3Cd != '4' ? 'tableBB' : '']" no-gutters> 
                                        <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4"> 
                                            <span class="black--text">{{clsfc3.clsfc3Cdnm}}</span>
                                        </v-col>
                                        <v-col class="pa-1 ma-0" style="border-left: 1px solid #dbdbdb;" cols="8"> 
                                            <v-radio-group v-model="clsfc3.itmVal" class="radio-dft-Theme mt-0 pl-1 pt-1" hide-details row>                                            
                                                <div v-for="(list, m) in clsfc3.list" :key="m">
                                                    <span class="d-inline-block pa-0 ma-0" >
                                                        <v-radio :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>
                                                    </span>
                                                    <span v-if="list.valClcd=='2'">&#40;</span>
                                                    <span class="d-inline-block pa-0 ma-0" style="width:70px" v-if="list.valClcd=='2'">
                                                        <v-text-field v-model="list.evltn" height="28" hide-details outlined dense></v-text-field>
                                                    </span>           
                                                    <span v-if="list.valClcd=='2'">&#41;</span>                               
                                                </div>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                            <div v-else-if="item.clsfc2Cd == '11'" class="pl-2 py-2">
                                <div v-for="(clsfc3, n) in item.clsfc3Cds" :key="n"> 
                                    <div v-for="(list, m) in clsfc3.list" :key="m" class="d-inline-block">                                        
                                        <div style="width:70px">
                                            <v-checkbox
                                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                v-model="list.itmVal" :ripple="false" :label="list.itmCdnm"
                                                value="1" hide-details>
                                            </v-checkbox>
                                        </div>                                     
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="item.clsfc2Cd == '999'" class="pl-2 py-2">
                                <div v-for="(clsfc3, n) in item.clsfc3Cds" :key="n"> 
                                    <div v-for="(list, m) in clsfc3.list" :key="m" class="d-inline-block" style="width:100%">                                         
                                        <v-textarea
                                            v-model="list.evltn"
                                            class="rmrksArea" rows="2"
                                            no-resize outlined dense hide-details>
                                        </v-textarea>                              
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                </v-col>
            </v-row>
        </v-form>
        
        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="'건강수준 평가'" @madalState="onDelete"></btnModalDelete>  
        </v-dialog>   
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
        </v-dialog>    
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import { getMultiStcd } from '../../api/index.js';
import { insBnfcrExamHis,getBnfcrExamHisInfo,getBnfcrExamList, delBnfcrExamHis} from '../../api/bnftSrv.js';
import EmpSelector from '../commons/EmpSelector.vue';

export default {
    name: 'HlthEvltn',

     props : {
        inqYYYY: Number,  
        bnMmbrPk : { type: Number, default: -1 },
        hlthEvltnPk : { type: Number, default: -1 },
        btnDisabled : { type: Boolean, default: false },     
    },
        
    components: {
        CmmDateComp,
        basicInfo,
        btnModalDelete,
        EmpSelector,
    },
    

    watch: {     
        'hlthEvltnPk':function(value){  
            this.filledBtn[0].visible = false
            if(value > -1){
                this.bnfcrExamHisPk = value
                this.onShow()
                if(value == 0) this.filledBtn[0].visible = true
            }
        }
    },

    mounted: function(){             
        this.$nextTick(function () {  
            this.bnfcrExamHisPk = this.hlthEvltnPk
            this.onShow()
            this.filledBtn[0].visible = false
            if(this.bnfcrExamHisPk == 0) this.filledBtn[0].visible = true
        })
    },
    
    methods: {
        setStyle:function(value){
            let style = "";
            if(value > 13){
                style="width:400px;"
            }else if(value > 10 && value <= 13){
                style="width:170px;"
            }else if(value > 5 && value <= 10){
                style="width:160px;"
            }else if(value == 2){
                style="width:60px;"
            }else if(value == 3){
                style="width:80px;"
            }else{
                style="width:100px;"
            }
            return style;
        },
        onShow : function(){   
            this.getInit()
            this.onCodeload()
            this.getExamHisInfo() 
            this.getBnfcrExamList()
        },
        onClose: function () {
            this.bnfcrExamHisPk=0
            this.frmReset();
            this.$emit('modalEvent', 4)
        },
        
        onCodeload:function(){
            getMultiStcd(['5'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },

        codeMapping:function(res){
          this.codeItem = res["vCd5"]
        },
        getInit(){
            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)
            let dateStr = timezoneDate.toISOString().split('T',2)[0]
            this.items.examDt = dateStr
            this.items.wrtr = this.$store.getters.getUserNm;
            this.minDate = this.inqYYYY+'-'+'01'+'-'+'01'
            this.maxDate = this.inqYYYY+'-'+'12'+'-'+'31'
            this.forceRender += 1
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        onClickBtn: function(text) {
            if(text == "이전자료"){
                this.getPreData = true
                this.getBnfcrExamList()
            }else if(text=="저장"){
                 if(this.$refs.form.validate()){
                    this.insBnfcrExam();
                 }
            } else if(text=="삭제"){
                if(this.bnfcrExamHisPk > 0){
                    this.dialogDelete = true
                }
            }else if(text=="출력"){
                this.hlthEvltnPrt()
            }
        },

        onDelete:function(res){
            if(res == 9990){
                let code = this.$store.getters.getFcltyNum;
                let value = this.bnfcrExamHisPk;
                let user = this.$store.getters.getUserNm

                delBnfcrExamHis(code, value, user)
                .then((response) => ( this.delBnfcrExamHisAfter(response.data)))
                .catch((error) => console.log(error))
            }else if(res == 7){
                this.dialogDelete = false
            }

        },

        delBnfcrExamHisAfter:function(res){
            if(res.statusCode == 200){                                                
                this.$emit('modalEvent', 99)
                this.onClose()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        frmReset:function(){                
            this.$refs.form.reset();
        },

        getExamHisInfo(){
            if(this.bnfcrExamHisPk !='' && this.bnfcrExamHisPk > 0){
                let code = this.$store.getters.getFcltyNum;
                getBnfcrExamHisInfo(code, this.bnfcrExamHisPk)
                .then((response) => ( this.setData(response.data)))
                .catch((error) => console.log(error))
            }else{
                this.items.wrtr = this.$store.getters.getUserNm;
            }
        },
        setData(data){
            data.examDt = data.examDt.beforeDateFormatHyp()
            this.items = data
        },

        insBnfcrExam(){

            let obj = {
                fcltyNum:this.$store.getters.getFcltyNum,
                bnMmbrPk:this.bnMmbrPk,
                userNm : this.$store.getters.getUserNm,
                bnfcrExamHisPk:this.bnfcrExamHisPk,
                examClcd:'105',                    
                examRsncd : this.items.examRsncd,
                examDt : this.items.examDt.afterDateFormatHyp(),
                wrtr : this.items.wrtr,
                examYyyy : this.inqYYYY,

            }

            let examList = []

            this.hlthEvltnList.forEach(el => {
                el.itmValCd = el.valClcd
                examList.push(el)
            });

            this.hlthEvltnEtcList.forEach(el => {
                el.itmValCd = el.valClcd
                examList.push(el)
            });

            this.hlthEvltnLftList.forEach(el=>{
                if(el.clsfc2Cd == '2'){
                    el.clsfc3Cds.forEach(x => {
                        x.list.forEach( itm => {
                            
                            if(x.itmVal == itm.itmCd) itm.itmVal = '1'
                            else itm.itmVal = '2'

                            itm.itmValCd = itm.valClcd
                            examList.push(itm)
                        })
                    })
                } else if(el.clsfc2Cd == '5'){
                    el.clsfc3Cds.forEach(x => {
                        x.list.forEach( itm => {

                            if(x.clsfc3Cd == '2'){
                                if(x.itmVal == itm.itmCd) itm.itmVal = '1'
                                else itm.itmVal = '2'
                            }

                            itm.itmValCd = itm.valClcd
                            examList.push(itm)
                        })
                    })                
                }else{                    
                    el.clsfc3Cds.forEach(x => {
                        x.list.forEach( itm => {
                            itm.itmValCd = itm.valClcd
                            examList.push(itm)
                        })
                    })
                }
            })

            this.hlthEvltnRftList.forEach(el=>{
                if(el.clsfc2Cd == '10'){
                    el.clsfc3Cds.forEach(x => {
                        x.list.forEach( itm => {
                            
                            if(x.itmVal == itm.itmCd) itm.itmVal = '1'
                            else itm.itmVal = '2'

                            itm.itmValCd = itm.valClcd
                            examList.push(itm)
                        })
                    })
                } else{                    
                    el.clsfc3Cds.forEach(x => {
                        x.list.forEach( itm => {
                            itm.itmValCd = itm.valClcd
                            examList.push(itm)
                        })
                    })
                }
            })

            insBnfcrExamHis(obj,examList)
                .then((response) => ( this.insBnfcrExamAfter(response.data)))
                .catch((error) => console.log(error))
           
        },

        insBnfcrExamAfter: function (res) {            
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])                
                this.bnfcrExamHisPk = res.result                
                this.getExamHisInfo() 
                this.getBnfcrExamList()
                this.$emit('modalEvent', 99)
                this.filledBtn[0].visible = false
            }
            else{
                // this.snackControll([true, 5000, res.message, 'error'])
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        getBnfcrExamList(){            

             if(this.getPreData){
                this.obj = {
                    code: this.$store.getters.getFcltyNum, 
                    inqTFclsfc1Cd: true, 
                    inqTFclsfc2Cd: true, 
                    inqTFclsfc3Cd: true, 
                    inqTFitmCd: true, 
                    clsfc1Cd: '105',
                    bnfcrExamHisPk:null,
                    bnMmbrPk:this.bnMmbrPk
                }
            }else{    
                this.obj = {
                    code: this.$store.getters.getFcltyNum, 
                    inqTFclsfc1Cd: true, 
                    inqTFclsfc2Cd: true, 
                    inqTFclsfc3Cd: true, 
                    inqTFitmCd: true, 
                    clsfc1Cd: '105',
                    bnfcrExamHisPk:this.bnfcrExamHisPk
                }
            }
            getBnfcrExamList(this.obj)
            .then((response) => ( this.getBnfcrExamListAfter(response.data)))
            .catch((error) => console.log(error))
        },

        getBnfcrExamListAfter(res){   
            this.hlthEvltnList.splice(0)
            this.hlthEvltnEtcList.splice(0)
            this.hlthEvltnLftList.splice(0)
            this.hlthEvltnRftList.splice(0)

            let hlthEvltnData = []
            res.forEach(el => {   
                if(this.getPreData) el.bnfcrExamPk = 0

                if(el.clsfc2Cd == '1') {
                    if(Number(el.clsfc3Cd) > 0 && Number(el.itmCd) < 8) this.hlthEvltnList.push(el)
                    else this.hlthEvltnEtcList.push(el)
                }else{
                    hlthEvltnData.push(el)
                }
            });

            let chc =[]
            let chcch =[]

            hlthEvltnData.forEach(el => {
                if(chc.findIndex(v=>v == el.clsfc2Cd) == -1) chc.push(el.clsfc2Cd)
                if(chcch.findIndex(v=>v == el.clsfc3Cd) == -1) chcch.push(el.clsfc3Cd)
            });

            chc.forEach(el => {
                let grp = {
                    clsfc2Cdnm : '',
                    clsfc2Cd : '',
                    clsfc3Cds : [],
                }

                let item = hlthEvltnData.filter(v=>v.clsfc2Cd == el)

                chcch.forEach(v=> {
                    let child = item.filter(x=>x.clsfc3Cd == v)

                    if(child.length > 0){                        
                        let cgrp={
                            clsfc3Cd:'',
                            clsfc3Cdnm : '',
                            itmVal : '',
                            list : [],
                        }

                        child.forEach(itm => {                            
                            grp.clsfc2Cdnm = itm.clsfc2Cdnm
                            grp.clsfc2Cd = itm.clsfc2Cd                                                    
                            cgrp.clsfc3Cd = itm.clsfc3Cd
                            cgrp.clsfc3Cdnm = itm.clsfc3Cdnm
                            cgrp.list.push(itm)

                            if(itm.itmVal=='1') cgrp.itmVal = itm.itmCd
                        });

                        grp.clsfc3Cds.push(cgrp)
                    }
                })
                
                if(Number(grp.clsfc2Cd) < 7) this.hlthEvltnLftList.push(grp)
                else if(grp.clsfc2Cd == '8') this.hlthEvltnLftList.push(grp)
                else this.hlthEvltnRftList.push(grp)

            })

            this.$refs.form.resetValidation();
            if(this.getPreData) this.getPreData = false
        },
        //건강수준 양식 출력
        hlthEvltnPrt: function (){
            let obj = {}
            obj.rptTyp = '1'
            obj.mode = '2'
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk
            obj.bnfcrPk = this.$parent.$parent.$parent.$parent.bnfcrPk
            obj.prtClcd = '1'
            obj.inqClcd = 2
            obj.prtCd = '43'
            obj.inqYYYY = ''
            obj.inqYmdStart = ''
            obj.inqYmdEnd = ''
            obj.list = [ { bzClcd: [], bzPk: [] } ]
            obj.list[0].bzPk.push(this.bnfcrExamHisPk)

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: obj, outObj: null, runEvt: true,
            });
        },
        onModal: function () {
            this.prtWrtDt = this.items.examDt
            this.menu.splice(1, 1, true)
        },
        isModal: function () {
            this.menu.splice(1, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
        snackControll: function (options) {
            this.snackOptions = options
        },

    },
    
    
    data: () => ({   
        getPreData:false,  
        minDate:'',
        maxDate:'',
        prtWrtDt:'',       
        bnfcrExamHisPk : 0,        
        items:{},
        forceRender:0,        
        codeItem:[],
        hlthEvltnList:[],
        hlthEvltnEtcList:[],
        hlthEvltnLftList:[],
        hlthEvltnRftList:[],     
        menu: [false,false],
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        filledBtn: [
            { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'blueBtnColor', text: '이전자료', type:'action', visible: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type:'action', visible: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type:'action', visible: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력',disabled: false, },
        ],
    }),
};
</script>