<template>
    <v-sheet class="ml-0">
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <v-form ref="form" lazy-validation class="mt-1 mr-3">
        <v-divider class="mt-2"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">본인부담금&nbsp;청구&nbsp;월</span>
            </v-col>
            <v-col class="d-flex align-center tableBL" cols="10">
                <span class="d-inline-block" style="width: 110px; margin-left: 10px">
                    <CmmDateYMComp v-model="initItm.itmVal1" :required="true" :disabled="initDisd"></CmmDateYMComp>
                </span>
                <span class="d-inline-block pl-2">
                    <v-btn
                        color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"  
                        :disabled="initDisd"
                        @click="setFcltyInit"       
                        min-width="30" height="25" small outlined>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                    <v-icon size="20">mdi-content-save-edit-outline</v-icon>
                                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">저장</span>
                                </div>  
                            </template>
                            <span>본인부담금&nbsp;청구&nbsp;월&nbsp;저장</span>
                        </v-tooltip>
                    </v-btn>
                </span>
                <span v-if="initDisd" class="d-inline-block pl-2">
                    <span class="red--text">※ 수정불가&#40;초기 미납금 &#183;선납금 존재합니다.&#41;</span>
                </span>
            </v-col>
        </v-row>                  
        <v-divider></v-divider>
        </v-form>
        <v-row v-if="initItm.fcltyInitPk > 0" class="mt-4 pa-0" no-gutters>
            <v-col class="ma-0 pa-0" cols="6">
                <v-row class="pa-0" no-gutters>
                    <v-col class="d-flex align-center" cols="12">
                        <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold ml-3">본인부담금 초기 미납금 관리</span>
                    </v-col>
                </v-row>
                <v-row class="mt-2 pa-0" no-gutters>
                    <v-col class="d-flex align-center" cols="12">
                        <span class="d-inline-block" style="width: 130px;">
                            <v-text-field
                                v-model="schUnpd"
                                class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify"
                                width="100" height="35" label="이름조회"
                                hide-details single-line rounded outlined dense filled>
                            </v-text-field>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="mt-2 pa-0" no-gutters>
                    <v-col cols="12">
                        <v-data-table
                            :headers="initUnpdHeader"
                            :items="initUnpdList"
                            :loading="initUnpdLoader"
                            :search="schUnpd"
                            loading-text="Loading... Please wait"
                            fixed-header hide-default-header hide-default-footer dense disable-pagination                         
                            height='270'
                        >
                            <template v-slot:header="{ props }" >
                                <thead>
                                    <tr>
                                        <th class="black--text clmsDtlHeaderCommon py-2 tableBT tableBR" style="width:15%;">{{props.headers[0]?.text}}</th>
                                        <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:15%;">{{props.headers[1]?.text}}</th>
                                        <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:15%;">{{props.headers[2]?.text}}</th>
                                        <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:20%;">{{props.headers[3]?.text}}</th>
                                        <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:20%;">{{props.headers[4]?.text}}</th>
                                        <th class="black--text clmsDtlHeaderCommon tableBT" style="width:15%;">
                                            <v-btn @click="onModal(0,'reset'), sltPk = 0" icon class="mr-1" color="blue200">                                                
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon v-bind="attrs" v-on="on">mdi-plus-circle-outline</v-icon>
                                                    </template>
                                                    <span>미납금 추가</span>
                                                </v-tooltip>
                                            </v-btn>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:item='{ item }'>
                                <tr>
                                    <td class="clmsBodyCommon tableBR pa-0">{{item.name}}</td>
                                    <td class="clmsBodyCommon tableBR pa-0">{{ item.acknwRtngNm }}</td>
                                    <td class="clmsBodyCommon tableBR pa-0">{{ $moment(item.clmYymm,'YYYYMM').format('YYYY.MM')}}</td>
                                    <td class="clmsBodyCommon tableBR">{{item.copayRateNm}}</td>
                                    <td class="clmsBodyCommon tableBR">{{item.unpdAmt?.toLocaleString('ko-KR')}}</td>                                    
                                    <td class="clmsBodyCommon">
                                        <v-btn icon @click="onEdit(item.copayClmHisPk)">                                                
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-square-edit-outline</v-icon>
                                                </template>
                                                <span>{{item.name}} 미납금 수정</span>
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn :disabled="item.mdfAblYn !='1'" icon class="" @click="delUnpd(item)">                                                
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                                </template>
                                                <span>{{item.name}} 미납금 삭제</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="ma-0 pl-4" cols="6">
                <v-row class="pa-0" no-gutters>
                    <v-col class="d-flex align-center" cols="12">
                        <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold ml-3">본인부담금 초기 선납금 관리</span>
                    </v-col>
                 </v-row>
                 <v-row class="mt-2 pa-0" no-gutters>
                    <v-col class="d-flex align-center" cols="12">
                        <span class="d-inline-block" style="width: 130px;">
                            <v-text-field v-model="schPrpym" class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify" width="100px" height="35px"
                                hide-details single-line rounded outlined dense filled label="이름조회">
                            </v-text-field>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="mt-2 pa-0" no-gutters>
                    <v-col cols="12">
                        <v-data-table
                            :headers="initPrpymHeader"
                            :items="initPrpymList"
                            :loading="initUnpdLoader"
                            :search="schPrpym"
                            loading-text="Loading... Please wait"
                            fixed-header hide-default-header hide-default-footer dense disable-pagination                         
                            height='270'
                        >
                            <template v-slot:header="{ props }" >
                                <thead>
                                    <tr>
                                        <th class="black--text clmsDtlHeaderCommon py-2 tableBT tableBR" style="width:15%;">{{props.headers[0]?.text}}</th>
                                        <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:15%;">{{props.headers[1]?.text}}</th>
                                        <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:15%;">{{props.headers[2]?.text}}</th>
                                        <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:20%;">{{props.headers[3]?.text}}</th>
                                        <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:20%;">{{props.headers[4]?.text}}</th>
                                        <th class="black--text clmsDtlHeaderCommon tableBT" style="width:15%;">
                                            <v-btn @click="onModal(2,'reset'), sltPk = 0" icon class="mr-1" color="blue200">                                                
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon v-bind="attrs" v-on="on">mdi-plus-circle-outline</v-icon>
                                                    </template>
                                                    <span>선납금 추가</span>
                                                </v-tooltip>
                                            </v-btn>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:item='{ item }'>
                                <tr>
                                    <td class="clmsBodyCommon tableBR pa-0">{{item.name}}</td>
                                    <td class="clmsBodyCommon tableBR pa-0">{{ item.acknwRtngNm }}</td>                                    
                                    <td class="clmsBodyCommon tableBR">{{item.copayRateNm}}</td>
                                    <td class="clmsBodyCommon tableBR">{{$moment(item.dpsDt,'YYYYMMDD').format('YYYY.MM.DD')}}</td>
                                    <td class="clmsBodyCommon tableBR">{{ Number(item.dpsTot).toLocaleString('ko-KR') }} 원</td>
                                    <td class="clmsBodyCommon">
                                        <v-btn icon @click="onEditPrpym(item.dpsPrcHisPk)">                                                
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-square-edit-outline</v-icon>
                                                </template>
                                                <span>{{item.name}} 선납금 수정</span>
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn :disabled="item.mdfAblYn !='1'" icon class="" @click="delPrpym(item)">                                                
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                                </template>
                                                <span>{{item.name}} 선납금 삭제</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <div v-else class="mt-3 d-inline-block blue001 d-flex justify-center align-center rounded-lg" style="width: 100%; height: 200px; opacity: 0.7;">
            <span class="font-weight-bold white--text" style="font-size: 2rem;">본인부담금&nbsp;청구&nbsp;시작&nbsp;월&nbsp; 저장하여 주십시오.</span>
        </div> 
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="1000" eager>    
                <FacInfoUnpdWrt @isClose="isModal(0)" @reLoad="getFcltyCopayInit()"></FacInfoUnpdWrt>  
            </v-dialog>   
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[1]" max-width="500">    
                <btnModalDelete :title="delTitle" @madalState="onDeleteAct"></btnModalDelete>  
            </v-dialog>
        </div>  
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[2]" max-width="1000" eager>    
                <FacInfoPrpymWrt @isClose="isModal(2)" @reLoad="getFcltyCopayInit()"></FacInfoPrpymWrt>  
            </v-dialog>   
        </div>      
    </v-sheet>
</template>

<script>
import CmmDateYMComp from '../commons/CmmDateYMComp.vue';
import FacInfoUnpdWrt from './FacInfoUnpdWrt.vue';
import FacInfoPrpymWrt from './FacInfoPrpymWrt.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';

import { selFcltyCopayInit,insFcltyInit } from '../../api/opr.js';
import { delCopayClmHis, delDpsPrcHis } from '../../api/bnfcr.js';
export default {
    name: 'FacInfoMgmtTab5',

    components: {
        CmmDateYMComp,
        FacInfoUnpdWrt,
        FacInfoPrpymWrt,
        btnModalDelete,
    },

    created: function () {
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.inqYMD = this.$moment().format('YYYYMMDD')
            this.getFcltyCopayInit()
        })
    },

    computed: {

    },

    watch: {
    },

    methods: {    
        //본인부담금 초기정보 조회 
        getFcltyCopayInit:function(){
            this.vCd41.splice(0)
            this.vCd42.splice(0)
            this.vCd62.splice(0)
            this.vCd63.splice(0)
            this.vCd65.splice(0)
            this.vCd84.splice(0)

            this.mdfAblList.splice(0)
            this.preMdfAblList.splice(0)
            this.initUnpdList.splice(0)
            this.initPrpymList.splice(0)
            this.initUnpdLoader = true
            this.initDisd = false
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,                
                initClcds   : ['1'],
                valClcdS    : ['41','42','62','63','65','84'],
                inqYMD      : this.inqYMD,
            }

            selFcltyCopayInit(obj)
                .then((response) => ( this.getFcltyCopayInitAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selFcltyCopayInit : ' + error))
        },

        //기간기초정보 저장
        setFcltyInit:function(){
            if(this.$refs.form.validate()){
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm: this.$store.getters.getUserNm,
                        initClcd: '1',
                        itmVal1: this.$moment(this.initItm.itmVal1,'YYYY-MM').format('YYYYMM'),
                        fcltyInitPk: this.initItm.fcltyInitPk
                    }
        
                    insFcltyInit(obj)
                        .then((response) => (this.setFcltyInitAfter(response.data)))
                        .catch((error) => console.log('connect error /opr/insFcltyInit : ' + error))
            }else{
                this.snackControll([true, 5000, '본인부담금 청구 월을 선택해주세요.', 'error'])
            }

        },
        
        //미납금 삭제
        onDeleteAct:function(res){
            if(res == 9990){
                if(this.delTyp  == 'unpd'){
                    this.delCopayClmHis()
                }

                if(this.delTyp  == 'prpym'){
                    this.delDpsPrcHis()
                }
            }else if(res == 7){
                this.isModal(1)
            }
        },

        //미납금 삭제
        delCopayClmHis:function(){
            let idx     = this.initUnpdList.findIndex(v=>v.copayClmHisPk == this.sltPk)
            let target  = this.initUnpdList[idx]
            let bnMmbrPks    = []
            let bnfcrPks     = []
            bnMmbrPks.push(target.bnMmbrPk)
            bnfcrPks.push(target.bnfcrPk)

            delCopayClmHis(this.$store.getters.getFcltyNum, bnMmbrPks, bnfcrPks, target.clmYymm, this.$store.getters.getUserNm)
                .then((response) => (this.delCopayClmHisAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/delCopayClmHis : ' + error))
        },
        //선납금 삭제
        delDpsPrcHis:function(){            
            let obj = {
                fcltyNum        : this.$store.getters.getFcltyNum,
                userNm          : this.$store.getters.getUserNm,
                dpsPrcHisPk     : this.sltPrpPk,
            }

            if(this.sltPrpPk > 0){
                delDpsPrcHis(obj)
                    .then((response) => (this.delDpsPrcHisAfter(response.data)))
                    .catch((error) => console.log('connect error /bnfcr/delDpsPrcHis : ' + error))
            }
        },

        getFcltyCopayInitAfter:function(res){
            if (res.statusCode == 200) {
                
                if(res.result.codes != null){
                    res.result.codes.vCd41.forEach(cd => {
                        this.vCd41.push(cd)
                    });
                    res.result.codes.vCd42.forEach(cd => {
                        this.vCd42.push(cd)
                    });
                    res.result.codes.vCd62.forEach(cd => {
                        this.vCd62.push(cd)
                    });
                    res.result.codes.vCd63.forEach(cd => {
                        this.vCd63.push(cd)
                    });
                    res.result.codes.vCd65.forEach(cd => {
                        this.vCd65.push(cd)
                    });
                    res.result.codes.vCd84.forEach(cd => {
                        this.vCd84.push(cd)
                    });
                }

                if(res.result.initList != null){
                    res.result.initList.forEach(el => {
                        this.initItm.fcltyInitPk = el.fcltyInitPk
                        if(el.itmVal1 != null) this.initItm.itmVal1 =  this.$moment(el.itmVal1,'YYYYMM').format('YYYY-MM')
                    });
                }

                if(res.result.initUnpdList != null){
                    if(res.result.initUnpdList.length > 0) this.initDisd = true

                    res.result.initUnpdList.forEach(itm => {
                        this.vCd62.forEach((cd) => {
                            if (itm.acknwRtng == cd.valcd) itm.acknwRtngNm = cd.valcdnm
                        });                        
                        this.initUnpdList.push(itm)
                    });
                }

                if(res.result.initPrpymList != null){
                    if(res.result.initPrpymList.length > 0) this.initDisd = true

                    res.result.initPrpymList.forEach(itm => {
                        this.vCd62.forEach((cd) => {
                            if (itm.acknwRtng == cd.valcd) itm.acknwRtngNm = cd.valcdnm
                        });                        
                        this.initPrpymList.push(itm)
                    });

                }

                //초기 수급자 등록, 수정 체크
                if(res.result.mdfAblList != null){
                    res.result.mdfAblList.forEach(itm => {
                        let obj = {                    
                            name:itm.name,
                            mmbrpk:itm.mmbrPk,
                            disChekr:true,
                            checkr:'1'
                        }

                        if(itm.mdfAblYn == '2') this.mdfAblList.push(obj)

                        if(itm.preMdfAblYn == '2') this.preMdfAblList.push(obj)
                    })
                }

            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }

            this.initUnpdLoader = false
        },
               

        setFcltyInitAfter:function(res){
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.getFcltyCopayInit()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        delCopayClmHisAfter:function(res){
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.getFcltyCopayInit()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.sltPk = -1
            this.isModal(1)
        },
        delDpsPrcHisAfter:function(res){
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.getFcltyCopayInit()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.sltPrpPk = -1
            this.isModal(1)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },    
        onEdit:function(pk){
            this.sltPk = pk
            this.onModal(0,'')
        },
        onEditPrpym:function(pk){
            this.sltPrpPk = pk
            this.onModal(2,'')
        },
        //미납금 삭제
        delUnpd:function(item){
            this.sltPk      = item.copayClmHisPk
            this.delTitle   = item.name+'님의 '+this.$moment(item.clmYymm,'YYYYMM').format('YYYY.MM')+' 미납금'
            this.delTyp     = 'unpd'
            this.dialog.splice(1, 1, true)
        }, 
        //선납금 삭제
        delPrpym:function(item){
            this.sltPrpPk      = item.dpsPrcHisPk
            this.delTitle   = item.name+'님의 '+this.$moment(item.dpsDt,'YYYYMMDD').format('YYYY.MM.DD')+' 선납금'
            this.delTyp     = 'prpym'
            this.dialog.splice(1, 1, true)
        }, 
        onModal: function (index, typ) {
            if(typ == 'reset') {
                if(index == 0) this.sltPk = 0
                else if(index == 2) this.sltPrpPk = 0
            }

            if(index == 0) this.setFixList()
            else if(index == 2) this.setFixMdfAblList()

            this.dialog.splice(index, 1, true)
        },
        isModal: function (key) {

            if(key == 0) this.sltPk = -1
            else if(key == 2) this.sltPrpPk = -1

            this.delTyp  = ''
            this.dialog.splice(key, 1, false)
        },
        setFixList:function(){
            this.fixList.splice(0)

            this.mdfAblList.forEach(itm => {
                this.fixList.push(itm)
            })
        },
        setFixMdfAblList:function(){
            this.fixList.splice(0)

            this.preMdfAblList.forEach(itm => {
                this.fixList.push(itm)
            })
        }
    },

    data: () => ({
        key         : '',
        sltPk       : -1,
        sltPrpPk    : -1,
        inqYMD      : '',
        delTitle    : '',
        delTyp      : '',
        dialog      : [false,false,false],
        vCd41       : [],
        vCd42       : [],
        vCd62       : [],
        vCd63       : [],
        vCd65       : [],
        vCd84       : [],
        fixList     : [],
        initDisd    : false,
        initItm     : {
                fcltyInitPk:0,
                itmVal1:'',
        },
        schUnpd     : '',
        schPrpym    : '',
        mdfAblList  : [],
        preMdfAblList : [],
        initUnpdLoader  : false,
        initUnpdHeader  : [            
            { text: '수급자', align:'center', value: 'name',},            
            { text: '등급', align:'center', value: 'acknwRtngNm', filterable: false,},
            { text: '청구월도', align:'center', value: 'acknwRtngNm', filterable: false,},            
            { text: '본인부담률', align:'center', value: 'copayRateNm', filterable: false,},
            { text: '미납금', align:'center', value: 'unpdAmt', filterable: false,},
            { text: '처리', align:'center', value: 'actions', filterable: false,},
        ],
        initUnpdList    : [],
        initPrpymHeader  : [            
            { text: '수급자', align:'center', value: 'name',},            
            { text: '등급', align:'center', value: 'acknwRtngNm', filterable: false,},            
            { text: '본인부담률', align:'center', value: 'copayRateNm', filterable: false,},
            { text: '입금일자', align:'center', value: '', filterable: false,},            
            { text: '입금금액', align:'center', value: '', filterable: false,},
            { text: '처리', align:'center', value: 'actions', filterable: false,},
        ],
        initPrpymList   : [],
        items: [
            {
                name: '',
                acknwRtng: '',
                copayRate: '',
                bnMmbrPk: '',
                bnfcrPk: '',
            }
        ],
        snackOptions: [false, 3000, '', 'info'],
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>