<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" style="position:relative">
        <v-form ref="mdlform" lazy-validation>
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
            <div class="d-flex justify-center align-center">
                <span class="text-h4 font-weight-bold">기본값 설정</span>
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </div>
            <div class="d-flex pl-1 pr-1 mt-5">
                <v-spacer></v-spacer>
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'" :class="list.class" :disabled="list.disabled" samll height="30" :color="list.color"
                        @click="onClickBtn(list.text)" rounded>
                        <v-icon small>{{ list.icon }}</v-icon>
                        <span style="font-size: 1rem;">{{ list.text }}</span>
                    </v-btn>
                </span>
            </div>
            <div>
                <div class="d-flex align-center mt-3 ">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">근무현황 및 근무내용</span>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px;border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;">
                        <span class="black--text font-weight-medium">근무현황</span>
                    </div>
                    <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:20%;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; border-left: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; border-right: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">영양사</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; border-left: 1px solid #dbdbdb !important; border-right: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">조리사 및 조리원</span>
                        </div>
                    </div>
                    <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:65%; height: 63px;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; height: 50%; border-bottom: 1px solid #dbdbdb !important;">
                            <v-text-field v-model="rmrksVal[0]" height="28" hide-details outlined dense ></v-text-field>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; height: 50%; ">
                            <v-text-field v-model="rmrksVal[1]" height="28" hide-details outlined dense ></v-text-field>
                        </div>
                    </div>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px;border-bottom: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;">
                        <span class="black--text font-weight-medium">근무내용</span>
                    </div>
                    <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:20%;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; border-left: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; border-right: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">영양사</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; border-left: 1px solid #dbdbdb !important; border-right: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">조리사 및 조리원</span>
                        </div>
                    </div>
                    <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:65%; height: 63px;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; height: 50%; border-bottom: 1px solid #dbdbdb !important;">
                            <v-text-field v-model="rmrksVal[2]" height="28" hide-details outlined dense ></v-text-field>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; height: 50%; ">
                            <v-text-field v-model="rmrksVal[3]" height="28" hide-details outlined dense ></v-text-field>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-center mt-3">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">보존식관리</span>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;height: 170px;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:4%;">
                        <span class="black--text font-weight-medium">보존식</span>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:18%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">배식완료시간</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">조리완료시간</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;">
                            <span class="black--text font-weight-medium">담당자</span>
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:26%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">조식</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field  style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmTimeField v-model="brkfsVal[0]" :required="false"></CmmTimeField>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field  style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmTimeField v-model="brkfsVal[1]" :required="false"></CmmTimeField>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field  style="text-align: right !important;" dense outlined hide-details></v-text-field> -->
                                <span class="d-inline-block pa-1" style="width:120px;">
                                    <v-text-field
                                        v-model="brkfsVal[2]" height="28"
                                        readonly hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <v-btn 
                                    class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                                    min-width="30" height="28" @click="dialog.splice(0, 1, true)"
                                    dark small depressed>
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                        </template>
                                        <span>직원선택</span>
                                    </v-tooltip>
                                </v-btn>
                            </span>
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:26%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">중식</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field   style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmTimeField v-model="lunchVal[0]" :required="false"></CmmTimeField>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field   style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmTimeField v-model="lunchVal[1]" :required="false"></CmmTimeField>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field   style="text-align: right !important;" dense outlined hide-details></v-text-field> -->
                                <span class="d-inline-block pa-1" style="width:120px;">
                                    <v-text-field
                                        v-model="lunchVal[2]" height="28"
                                        readonly hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <v-btn 
                                    class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                                    min-width="30" height="28" @click="dialog.splice(1, 1, true)"
                                    dark small depressed>
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                        </template>
                                        <span>직원선택</span>
                                    </v-tooltip>
                                </v-btn>
                            </span>
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:26%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">석식</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field  style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmTimeField v-model="dinerVal[0]" :required="false"></CmmTimeField>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field  style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmTimeField v-model="dinerVal[1]" :required="false"></CmmTimeField>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field  style="text-align: right !important;" dense outlined hide-details></v-text-field> -->
                                <span class="d-inline-block pa-1" style="width:120px;">
                                    <v-text-field
                                    v-model="dinerVal[2]" height="28"
                                    readonly hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <v-btn 
                                    class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                                    min-width="30" height="28" @click="dialog.splice(2, 1, true)"
                                    dark small depressed>
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                        </template>
                                        <span>직원선택</span>
                                    </v-tooltip>
                                </v-btn>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-center mt-3">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">어르신의&nbsp;소리&nbsp;및&nbsp;특이사항</span>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px;height: 150px;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                        <v-textarea class="rmrksArea" style="font-size:0.9rem !important;"
                            v-model="rmrksVal[4]" rows="8" outlined dense hide-details no-resize>
                        </v-textarea>
                    </div>
                </div>
            </div>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'기본값'" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>   
        </div>   
        <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="dialog.splice(0, 1, false)" @outEmp="getEmpInfo"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="dialog[1]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="dialog.splice(1, 1, false)" @outEmp="getEmpInfo"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="dialog[2]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="dialog.splice(2, 1, false)" @outEmp="getEmpInfo"></EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import CmmTimeField from '../../components/commons/CmmTimeField.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';

import { insMealSrvcJrnl, delMealSrvcJrnl, selMealSrvcJrnlDefaultList } from '../../api/bnftrcrd.js';
export default {
    name: 'MealSrvcLogDefault',

    components: {
        CmmTimeField,
        btnModalDelete,
        EmpSelector,
    },

    mounted() {
        
    },
    
    methods: {
        pageReset: function () {
            this.rmrksVal.splice(0)
            this.brkfsVal.splice(0)
            this.lunchVal.splice(0)
            this.dinerVal.splice(0)
            this.wrtr = ''
        },
        getEmpInfo: function (obj) {
            if(this.dialog[0]==true){
                this.brkfsVal[2] = obj[0].name
            }else if(this.dialog[1]==true){
                this.lunchVal[2] = obj[0].name
            }else if(this.dialog[2]==true){
                this.dinerVal[2] = obj[0].name
            }
        },
        selMealSrvcJrnlDefaultList: function (){
            selMealSrvcJrnlDefaultList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.selMealSrvcJrnlDefaultListAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selMealSrvcJrnlDefaultList : ' + error))
        },
        selMealSrvcJrnlDefaultListAfter: function (res) {
            this.pageReset()
            this.sftyList = res
            this.sftyList.forEach(e => {
                if(e.clcd1 == '1' && e.clcd2 == '1' && e.clcd3 =='1'){
                    if(e.rmrks!=null){
                        this.filledBtn[1].disabled = false
                    }else{
                        this.filledBtn[1].disabled = true
                    }
                    this.rmrksVal.push(e.rmrks)
                }
                if(e.clcd1 == '1' && e.clcd2 == '2' && e.clcd3 =='1'){ 
                    this.rmrksVal.push(e.rmrks)
                }
                if(e.clcd1 == '2' && e.clcd2 == '1' && e.clcd3 =='1'){
                    this.rmrksVal.push(e.rmrks)
                }
                if(e.clcd1 == '2' && e.clcd2 == '2' && e.clcd3 =='1'){
                    this.rmrksVal.push(e.rmrks)
                }
                if(e.clcd1 == '6' && e.clcd2 == '8' && e.clcd3 =='1'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                }
                if(e.clcd1 == '6' && e.clcd2 == '9' && e.clcd3 =='1'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                }
                if(e.clcd1 == '6' && e.clcd2 == '10' && e.clcd3 =='1'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                }
                if(e.clcd1 == '7' && e.clcd2 == '1' && e.clcd3 =='1'){
                    this.rmrksVal.push(e.rmrks)
                }
            });
        },
        onSave:function(){
                let list = []
                this.sftyList.forEach(e=>{
                    if(e.clcd1 == '1' && e.clcd2 == '1' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '1',
                            clcd2 : '1',
                            clcd3 : '1',
                            mealSrvcDt : '29991231',
                            rmrks : this.rmrksVal[0],
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                            wrtr : this.wrtr,
                            fcltyNum : this.$store.getters.getFcltyNum
                        }
                        list.push(item)
                    }if (e.clcd1 == '1' && e.clcd2 == '2' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '1',
                            clcd2 : '2',
                            clcd3 : '1',
                            mealSrvcDt : '29991231',
                            rmrks : this.rmrksVal[1],
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                            wrtr : this.wrtr,
                            fcltyNum : this.$store.getters.getFcltyNum
                        }
                        list.push(item)
                    }if (e.clcd1 == '2' && e.clcd2 == '1' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '2',
                            clcd2 : '1',
                            clcd3 : '1',
                            mealSrvcDt : '29991231',
                            rmrks : this.rmrksVal[2],
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                            wrtr : this.wrtr,
                            fcltyNum : this.$store.getters.getFcltyNum
                        }
                        list.push(item)
                        
                    }if (e.clcd1 == '2' && e.clcd2 == '2' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '2',
                            clcd2 : '2',
                            clcd3 : '1',
                            mealSrvcDt : '29991231',
                            rmrks : this.rmrksVal[3],
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                            wrtr : this.wrtr,
                            fcltyNum : this.$store.getters.getFcltyNum
                        }
                        list.push(item)
                    }if(e.clcd1 == '6' && e.clcd2 == '8' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '6',
                            clcd2 : '8',
                            clcd3 : '1',
                            brkfs : this.brkfsVal[0],
                            lunch : this.lunchVal[0],
                            diner : this.dinerVal[0],
                            wrtr : this.wrtr,
                            fcltyNum : this.$store.getters.getFcltyNum,
                            mealSrvcDt : '29991231',
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        list.push(item)
                        
                    }if(e.clcd1 == '6' && e.clcd2 == '9' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '6',
                            clcd2 : '9',
                            clcd3 : '1',
                            brkfs : this.brkfsVal[1],
                            lunch : this.lunchVal[1],
                            diner : this.dinerVal[1],
                            wrtr : this.wrtr,
                            fcltyNum : this.$store.getters.getFcltyNum,
                            mealSrvcDt : '29991231',
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        list.push(item)
                        
                    }if(e.clcd1 == '6' && e.clcd2 == '10' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '6',
                            clcd2 : '10',
                            clcd3 : '1',
                            brkfs : this.brkfsVal[2],
                            lunch : this.lunchVal[2],
                            diner : this.dinerVal[2],
                            wrtr : this.wrtr,
                            fcltyNum : this.$store.getters.getFcltyNum,
                            mealSrvcDt : '29991231',
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        list.push(item)
                        
                    }if(e.clcd1 == '7' && e.clcd2 == '1' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '7',
                            clcd2 : '1',
                            clcd3 : '1',
                            rmrks : this.rmrksVal[4],
                            wrtr : this.wrtr,
                            fcltyNum : this.$store.getters.getFcltyNum,
                            mealSrvcDt : '29991231',
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        list.push(item)
                        
                    }
                })
                let obj = true
                if(obj){
                    insMealSrvcJrnl(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, list)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insMealSrvcJrnl : ' + error))
                }
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.$emit('reload', 0)
                this.snackControll([true, 2500, res.message, 'info'])
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onDelete:function(res){
            if(res == 9990){
                let cnt = 0;
                this.sftyList.forEach(x=>{
                    if(x.mealSrvcJrnlPk != undefined && x.mealSrvcJrnlPk != ''){
                        cnt++
                    }
                })
                if(cnt > 0){
                    delMealSrvcJrnl(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, this.sftyList)
                        .then((response) => ( this.delMealSrvcJrnlAfter(response.data) ))
                        .catch((error) => console.log('connect error /bnftoffer/insMenu : ' + error))
                }else{
                    this.snackControll([true, 5000, '삭제 대상이 없습니다.', 'error'])
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        delMealSrvcJrnlAfter(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.selMealSrvcJrnlDefaultList()
                this.$emit('reload', 0)
                this.pageReset()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false            
        },     
        snackControll: function (options) {
            this.snackOptions = options
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        onClickBtn: function (text) {
            if (text == '저장') {
                this.$emit('reload', '.')
                this.onSave()
            }
            else if (text == '삭제') {
                this.dialogDelete = true
            }
        },
    },
    data: () => ({
        dialog: [false,false,false,false],
        filledBtn: [
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '저장', type: 'action', disabled: false},
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color: 'grey006', text: '삭제', type: 'action', disabled: false },
        ],
        rmrksVal: [],
        brkfsVal: [],
        lunchVal: [],
        dinerVal: [],
        snackOptions: [false, 3000, '', 'info'], 
        sftyList:[],
        dialogDelete:false,
        wrtr: '',
    }),
};
</script>

<style lang="scss" scoped>

</style>