<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">
                <span class="blue001--text">{{ bscsInf.name }}</span>
                <span>&nbsp;퇴사일 수정</span>
            </span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">직원 기본정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">이름</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span class="fontOneRem">{{ bscsInf.name }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">입사일</span>
            </v-col>
            <v-col class="px-4" style="padding-top: 3px;" cols="7">
                <span class="fontOneRem">{{ bscsInf.entDt }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">퇴사일</span>
            </v-col>
            <v-col class="px-4" style="padding-top: 3px;" cols="7">
                <span class="fontOneRem">{{ bscsInf.rtrDt }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">변경정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="3">
                <span class="font-weight-medium">퇴사일</span>
            </v-col>
            <v-col class="px-3 py-1" cols="7">
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp
                        v-model="rtrDt" @input="aplctSavBtn" :required="true">
                    </CmmDateComp>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';
import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'EmpRtrDtChngMdl',

    components: {
        CmmDateComp,
    },

    props : {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getRootInObj
            this.aplctInf(obj)
        })
    },

    computed: {
      
    },

    watch: {
        
    },

    methods:{
        // 직원 퇴사일 수정
        setEmpRtrDt: function (obj){
            this.savBtn = true

            http.post('emp/updEmpRtrDt', obj)
                .then((response) => ( this.empRst(response.data) ))
                .catch((error) => console.log('connect error /emp/updEmpRtrDt : ' + error))
        },

        empRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200')
                this.$parent.$parent.$parent.$parent.mdlOff()
            else{
                obj.tmr = 5000
                obj.type = 'warning'
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.bscsInf.empPk = obj.empPk
            this.bscsInf.empHisPk = obj.empHisPk
            this.bscsInf.name = obj.name
            this.bscsInf.entDt = this.$moment(obj.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
            this.bscsInf.rtrDt = this.$moment(obj.rtrDt, 'YYYYMMDD').format('YYYY.MM.DD')
            this.bscsInf.wrkStcd = obj.wrkStcd

            //this.rtrDt = this.$moment(obj.rtrDt, 'YYYYMMDD').format('YYYY-MM-DD')

            this.aplctSavBtn()
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true
            let tmpDt = this.$moment(this.bscsInf.entDt, 'YYYY.MM.DD').format('YYYY-MM-DD')

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.rtrDt) == false)
                return 0
            if(this.$moment(tmpDt).isAfter(this.$moment(this.rtrDt)))
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 3000                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
            obj.title = '잘못된 요청입니다.'              // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(this.bscsInf.rtrDt == ''){
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            let insObj = {}
            insObj.empPk = this.bscsInf.empPk
            insObj.empHisPk = this.bscsInf.empHisPk
            insObj.wrkStcd = this.bscsInf.wrkStcd
            insObj.rtrDt = this.$moment(this.rtrDt, 'YYYY-MM-DD').format('YYYYMMDD')

            if(insObj.empPk != -1 && insObj.empHisPk != -1)
                this.setEmpRtrDt(insObj)
            else
                this.$store.commit('setWrnnInf', obj)
        },
    },

    data: () => ({
        savBtn: true,
        bscsInf: {
            empPk: -1, empHisPk: -1, name: '', entDt: '', rtrDt: '', wrkStcd: '', 
        },
        rtrDt: '',
    })
}
</script>