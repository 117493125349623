<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-1" color="" min-width="1260" max-width="1560" height="835">
        <!-- contents -->
        <div class="mt-2 d-flex align-center">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-1 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span>6-4. 시설정보관리</span>
                </span>
            </div>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i" class="mr-3">
                <v-btn v-if="list.type == 'action' && list.visible" @click="onClickBtn(list.text)" :class="list.class"
                    samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{ list.icon }}</v-icon>
                    <span style="font-size: 1rem;">{{ list.text }}</span>
                </v-btn>
            </span>
        </div>
        <div class="pr-2 mt-4 px-2">
            <v-tabs v-model="tab" class="tab-dft-Theme" active-class="black" center-active hide-slider>
                <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class">{{ item.tab }}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <v-tabs-items v-model="tab">
                <v-tab-item transition="false">
                    <tab1 ref="tab1" :tab="tab" @facInfoTrigger="facInfoTrigger"></tab1>
                </v-tab-item>
                <v-tab-item transition="false">
                    <tab2 ref="tab2" :tab="tab" @facInfoTrigger="facInfoTrigger"></tab2>
                </v-tab-item>
                <v-tab-item transition="false">
                    <tab3 ref="tab3" v-if="tab==2" :tab="tab" @facInfoTrigger="facInfoTrigger"></tab3>
                </v-tab-item>
                <v-tab-item transition="false">
                    <tab4 ref="tab4" :tab="tab" @facInfoTrigger="facInfoTrigger"></tab4>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-sheet>
</template>

<script>
import tab1 from '../../components/opr/FacInfoMgmtTab1.vue';
import tab2 from '../../components/opr/FacInfoMgmtTab2.vue';
import tab3 from '../../components/opr/FacInfoMgmtTab3.vue';
import tab4 from '../../components/opr/FacInfoMgmtTab4.vue';
export default {
    name: 'FacInfoMgmt',
        
    components: {
        tab1,
        tab2,
        tab3,
        tab4,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
 
        })
    },

    computed: {

    },

    watch:{ 
        'tab':function(){
            switch (this.tab) {
                case 0:
                    this.filledBtn[0].visible = true
                    break;
                case 1:
                    this.filledBtn[0].visible = false
                    break;
                case 2:
                    this.filledBtn[0].visible = false
                    break;
                case 3:
                    this.filledBtn[0].visible = false
                    break;                
                default:
                    break;
            }           
        }
    },
        
    methods: {
        onClickBtn: function(text) {
            if(text=="저장"){
                // this.filledBtn[0].disabled = true

                if(this.tab == 0){
                    this.$refs.tab1.insFcltyInfSeal();
                }
                else if(this.tab == 1){
                    this.$refs.tab2.insFcltyDpsInf();
                }
                else if(this.tab == 2){
                    this.$refs.tab3.insLvnRmInf();
                }
            } 
        },
        facInfoTrigger:function(event){
            if(event == 99) this.filledBtn[0].disabled = false
        }
    },
    
    data: () => ({
        tab:0,
        tabItems: [
            { index: 0, tab: '기관 기본정보', class: 'fontOneDotTwoRem px-15',},
            { index: 1, tab: '본인부담금 기초정보', class: 'fontOneDotTwoRem px-15',},
            { index: 2, tab: '생활실정보', class: 'fontOneDotTwoRem px-15',},
            { index: 3, tab: '요양시스 기본정보', class: 'fontOneDotTwoRem px-15',},            
        ],
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '저장', type: 'action', width: '500px', visible: true, disabled: false, },
        ],
    }),
};
</script>