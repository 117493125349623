<template>
    <v-sheet>
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <v-col cols="2"></v-col>
            <v-spacer></v-spacer>
            <expandYearOnlyPicker @nowYear = "getYear"></expandYearOnlyPicker>
            <v-spacer></v-spacer>
            <v-col class="txtAlgnEnd" cols="2">    
                <v-btn
                    class="rndClrBtn-Theme mr-2" height="28" color="grey006"
                    @click="crtExcel" :disabled="tblUse.serverItems.length == 0"
                    samll outlined rounded>
                    <v-icon small>mdi-microsoft-excel</v-icon>
                    <span>엑셀</span>
                </v-btn>         
            </v-col>
        </v-row>
        <div class="mt-1 pa-0 overflow-y-auto overflow-x-auto" style="width:1238px; height:676px;">
            <!-- header -->
            <div class="greyE01 posn-rltv allHdrFix zPstn10" style="top:0px; width:2445px;border-spacing: 0px !important;">
                <div class="d-flex tableBT align-center">
                    <div class="nmlBfClrImp-greyE01 allHdrFix zPstn10 fixPstn0 tableBR tableBB py-1" style="width:400px;height:30px;">
                        <span class="black--text font-weight-medium"></span>
                    </div>
                    <div
                        v-for="yy in 12" :key="yy"
                        :class="['tableBB txtAlgnMid py-1', yy != 12 ? 'tableBR' : '']"
                        style="width:170px;height:30px;">
                        <span class="black--text font-weight-medium">{{ yy }}월</span>
                    </div>
                </div>
                <!-- 손익 -->
                <div class="d-flex align-center">
                    <div class="nmlBfClrImp-greyE01 allHdrFix zPstn10 fixPstn0 tableBR tableBB txtAlgnMid py-1" style="width:400px;height:30px;">
                        <span class="black--text font-weight-medium">손익&#40;수익-비용&#41;</span>
                    </div>
                    <div
                        v-for="(tot, i) in Object.values(tblUse.impAmtMths)" :key="i"
                        class="tableBR tableBB txtAlgnMid py-1"
                        style="width:170px;height:30px;">
                        <span class="black--text font-weight-medium">
                            {{ (tot - Object.values(tblUse.expAmtMths)[i])?.toLocaleString('ko-KR') }}
                        </span>
                    </div>
                </div>
                <!-- 수익 합계 -->
                <div class="d-flex align-center">
                    <div class="nmlBfClrImp-greyE01 allHdrFix zPstn10 fixPstn0 tableBR tableBB txtAlgnMid py-1" style="width:400px;height:30px;">
                        <span class="black--text font-weight-medium">수익 합계</span>
                    </div>
                    <div
                        v-for="(tot, i) in Object.values(tblUse.impAmtMths)" :key="i"
                        class="tableBR tableBB txtAlgnMid py-1"
                        style="width:170px;height:30px;">
                        <span class="black--text font-weight-medium">{{ tot?.toLocaleString('ko-KR') }}</span>
                    </div>
                </div>
                <!-- 비용 합계 -->
                <div class="d-flex align-center">
                    <div class="nmlBfClrImp-greyE01 allHdrFix zPstn10 fixPstn0 tableBR txtAlgnMid py-1" style="width:400px;height:30px;">
                        <span class="black--text font-weight-medium">비용 합계</span>
                    </div>
                    <div
                        v-for="(tot, i) in Object.values(tblUse.expAmtMths)" :key="i"
                        class="tableBR txtAlgnMid py-1"
                        style="width: 170px; height: 30px;">
                        <span class="black--text font-weight-medium">{{tot ?.toLocaleString('ko-KR') }}</span>
                    </div>
                </div>
            </div>
            <!-- 수익 -->
            <table class="tableBT tableBB posn-rltv" style="width:2445px;border-spacing: 0px !important;">
                <tr>
                    <td class="nmlBfClrImp-white allHdrFix zPstn5 fixPstn0 tableBR tableBB txtAlgnMid" style="width:100px;">
                        <span class="black--text">수익</span>
                    </td>
                    <td class="posn-rltv" style="width:2345px;">
                        <div class="d-flex align-center"
                            v-for="(impRow, i) in tblUse.serverItems.filter(v=>v.calcClcd=='1')"  
                            :key="i">
                            <div class="tableBR tableBB nmlBfClrImp-white allHdrFix zPstn5 pa-1" style="left:100px;width:300px;">
                                <span class="black--text">{{ impRow.itmNm }}</span>
                            </div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ impRow.amtJan?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ impRow.amtFeb?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ impRow.amtMar?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ impRow.amtApr?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ impRow.amtMay?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ impRow.amtJun?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ impRow.amtJul?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ impRow.amtAug?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ impRow.amtSep?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ impRow.amtOct?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ impRow.amtNov?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBB pa-1 txtAlgnMid" style="width:170px;">{{ impRow.amtDec?.toLocaleString('ko-KR') }}</div>
                        </div>
                    </td>
                </tr>
           </table>
           <!-- 비용 -->
           <table class="tableBT tableBB posn-rltv" style="width:2445px;border-spacing: 0px !important;">
                <tr>
                    <td class="nmlBfClrImp-white tableBR txtAlgnMid allHdrFix zPstn5 fixPstn0" style="width:100px;">
                        <span class="black--text">비용</span>
                    </td>
                    <td class="posn-rltv" style="width:2345px;">
                        <div class="d-flex align-center"
                            v-for="(expRow, j) in tblUse.serverItems.filter(v=>v.calcClcd=='2')"  
                            :key="j">
                            <div class="tableBR tableBB nmlBfClrImp-white allHdrFix zPstn5 pa-1" style="left:100px;width:300px;">
                                <span class="black--text">{{ expRow.itmNm }}</span>
                            </div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ expRow.amtJan?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ expRow.amtFeb?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ expRow.amtMar?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ expRow.amtApr?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ expRow.amtMay?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ expRow.amtJun?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ expRow.amtJul?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ expRow.amtAug?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ expRow.amtSep?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ expRow.amtOct?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBR tableBB pa-1 txtAlgnMid" style="width:170px;">{{ expRow.amtNov?.toLocaleString('ko-KR') }}</div>
                            <div class="tableBB pa-1 txtAlgnMid" style="width:170px;">{{ expRow.amtDec?.toLocaleString('ko-KR') }}</div>
                        </div>
                    </td>
                </tr>
           </table>
        </div>
    </v-sheet>
</template>
<script>
import { http } from '@/api/baseAxios';
import { profitAndLossYear } from '../../api/global/cmmExcelJS.js';

import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';

export default {
    name: 'MnthlPrlssTab3',
    
    components: {  
        expandYearOnlyPicker
    },

    props : {
    },

    computed: {
        getStYymm:function(){
            return this.$parent.$parent.$parent.$parent.yymm
        }
    },

    created: function(){   
    },

    mounted: function(){
        this.$nextTick(function () {
            
        })
    },

    watch: {
        'year':function(v){
            this.getFcltyPrfLosYearList(v)
        }
    },

    methods: {  
        getYear: function (yyyy){
            this.year = yyyy       
        },

        //기관손익관리 연별 정보 조회
        getFcltyPrfLosYearList: function (v){   
            this.resetMthAmt() //월별 합계 수익,비용 초기화
            this.tblUse.serverItems.splice(0)

            let obj = {
                inqYYYY:v
            }

            http.post('impExp/selFcltyPrfLosYearList', obj)
                .then((response) => ( this.rstFcltyPrfLosYearList(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgInfShtlist : ' + error))
        },
        //결과 :: 기관손익관리 연별 정보 조회
        rstFcltyPrfLosYearList:function(res){     
            if(res.statusCode == '200'){
                if(res.resultObj != null){
                    res.resultObj.list.forEach(itm => {  
                        switch (itm.calcClcd) {
                            //수익
                            case '1':
                                this.tblUse.impAmtMths.janAmt += Number(itm.amtJan)
                                this.tblUse.impAmtMths.febAmt += Number(itm.amtFeb)
                                this.tblUse.impAmtMths.marAmt += Number(itm.amtMar)
                                this.tblUse.impAmtMths.aprAmt += Number(itm.amtApr)
                                this.tblUse.impAmtMths.mayAmt += Number(itm.amtMay)
                                this.tblUse.impAmtMths.junAmt += Number(itm.amtJun)
                                this.tblUse.impAmtMths.julAmt += Number(itm.amtJul)
                                this.tblUse.impAmtMths.augAmt += Number(itm.amtAug)
                                this.tblUse.impAmtMths.sepAmt += Number(itm.amtSep)
                                this.tblUse.impAmtMths.octAmt += Number(itm.amtOct)
                                this.tblUse.impAmtMths.novAmt += Number(itm.amtNov)
                                this.tblUse.impAmtMths.decAmt += Number(itm.amtDec)
                                break;
                            //비용
                            case '2':
                                this.tblUse.expAmtMths.janAmt += Number(itm.amtJan)
                                this.tblUse.expAmtMths.febAmt += Number(itm.amtFeb)
                                this.tblUse.expAmtMths.marAmt += Number(itm.amtMar)
                                this.tblUse.expAmtMths.aprAmt += Number(itm.amtApr)
                                this.tblUse.expAmtMths.mayAmt += Number(itm.amtMay)
                                this.tblUse.expAmtMths.junAmt += Number(itm.amtJun)
                                this.tblUse.expAmtMths.julAmt += Number(itm.amtJul)
                                this.tblUse.expAmtMths.augAmt += Number(itm.amtAug)
                                this.tblUse.expAmtMths.sepAmt += Number(itm.amtSep)
                                this.tblUse.expAmtMths.octAmt += Number(itm.amtOct)
                                this.tblUse.expAmtMths.novAmt += Number(itm.amtNov)
                                this.tblUse.expAmtMths.decAmt += Number(itm.amtDec)
                                break;
                            default:
                                break;
                        }

                        this.tblUse.serverItems.push(itm)
                    });
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        //기관손익관리 연별 합계 초기화
        resetMthAmt:function(){
            // 수입 월별 초기화
            Object.keys(this.tblUse.impAmtMths).forEach(itm => {
                this.tblUse.impAmtMths[itm] =  0
            });

            // 비용 월별 초기화
            Object.keys(this.tblUse.expAmtMths).forEach(itm => {
                this.tblUse.expAmtMths[itm] =  0
            });
        },

        // 연별 손익관리 엑셀 다운로드
        crtExcel: function(){
            profitAndLossYear(this.tblUse.serverItems, this.year)
        }
    },

    data: () => ({ 
        year:'',
        tblUse:{
            serverItems     : [],
            impAmtMths      : {     // 수입 월별 합계
                janAmt :0,
                febAmt :0,
                marAmt :0,
                aprAmt :0,
                mayAmt :0,
                junAmt :0,
                julAmt :0,
                augAmt :0,
                sepAmt :0,
                octAmt :0,
                novAmt :0,
                decAmt :0,
            },
            expAmtMths      : {     // 비용 월별 합계
                janAmt :0,
                febAmt :0,
                marAmt :0,
                aprAmt :0,
                mayAmt :0,
                junAmt :0,
                julAmt :0,
                augAmt :0,
                sepAmt :0,
                octAmt :0,
                novAmt :0,
                decAmt :0,
            },
        },
    }),
};
</script>