import { http } from '@/api/baseAxios';

//진찰기록 내역 다건 조회
function selClncRecList(code, bnmmbrPk, outhisPk, ym = null) {
   return http.post('bnftrcrd/selClncRecList', { fcltyNum: code, bnMmbrPk: bnmmbrPk, outHisPk: outhisPk,inqYYMM: ym})
}

//진찰기록 내역 다건 조회(년월로 전체 조회)
function selClncRecListYyMm(code, bnmmbrPk, yymm) {
   return http.post('bnftrcrd/selClncRecList', { fcltyNum: code, bnMmbrPk: bnmmbrPk, inqYYMM: yymm })
}

//진찰기록 내역 다건 조회 (clmYymm)
function selClncRecListClm(obj) {
   return http.post('bnftrcrd/selClncRecList', obj)
}

//진찰기록 내역 저장(수정)
function insClncRec(obj) {
   return http.post('bnftrcrd/insClncRec', obj)
}

//진찰기록 내역 삭제
function delClncRec(obj) {
   return http.post('bnftrcrd/delClncRec', obj)
}

//병의원 관리 다건 조회
function selHospInfList(code) {
   return http.post('cmm/selHospInfList', { fcltyNum: code })
}

//병원 정보 저장
function insHospInf(obj) {
   return http.post('cmm/insHospInf', obj)
}

//병원 정보 삭제
function delHospInf(code, hospPk, nm) {
   return http.post('cmm/delHospInf', { fcltyNum: code, hospInfPk: hospPk, userNm:nm })
}

//의사 정보 다건 조회
function selDctInfList(code) {
   return http.post('cmm/selDctInf', { fcltyNum: code })
}

//의사 정보 저장
function insDctInf(obj) {
   return http.post('cmm/insDctInf', obj)
}

//의사 정보 삭제
function delDctInf(code, dctPk, nm) {
   return http.post('cmm/delDctInf', { fcltyNum: code, dctInfPk: dctPk, userNm:nm })
}

//건강검진 한 건 조회
function selHltChcInf(code, pk, ymd=null, mmbr=null) {
   return http.post('bnftrcrd/selHltChcInf', 
        { fcltyNum: code, hltChcInfPk: pk, inqYMD:ymd, mmbrPk:mmbr})
}

//건강검진 저장
function insHltChcInf(obj) {
   return http.post('bnftrcrd/insHltChcInf', obj)
}

//건강검진 삭제
function delHltChcInf(code, pk, nm) {
   return http.post('bnftrcrd/delHltChcInf', { fcltyNum: code, hltChcInfPk: pk ,userNm:nm})
}

//수급자상태조회 : 배설상태,보행,낙상위험도,욕창위험도
function selBnfcrStInfo(code,state) {
   return http.post('bnftoffer/selBnfcrStInfo', { 
        fcltyNum: code, 
        bnMmbrPk : state.bnMmbrPk,
        inqYYYY:state.inqYYYY,
        inqYMD:state.inqYMD,
    })
}


//급여제공기록 다건 조회
function selBnftOfrRecList(obj) {
   return http.post('bnftrcrd/selBnftOfrRecList', obj)
}

//응급상황기록 다건 조회
function selBnftOfrRec305List(obj) {
   return http.post('bnftrcrd/selBnftOfrRec305List', obj)
}

//급여제공기록내역 저장
function insBnftOfrRecHis(obj) {
   return http.post('bnftrcrd/insBnftOfrRecHis', obj)
}

//급여제공기록 저장
function insBnftOfrRec(obj) {
   return http.post('bnftrcrd/insBnftOfrRec', obj)
}

//급여제공기록 저장(His 한건 REC 다건)
function insBnftOfrRecMultiHis(obj) {
   return http.post('bnftrcrd/insBnftOfrRecMultiHis', obj)
}

//급여제공기록 저장(His 한건 REC 다건)
function insBnftOfrRecMulti(obj) {
   return http.post('bnftrcrd/insBnftOfrRecMulti', obj)
}

//물리치료계획 저장(insBnftOfrRecMulti 기반 삭제 제거)
function insPhythrBnftOfrRecMulti(obj) {
   return http.post('bnftrcrd/insPhythrBnftOfrRecMulti', obj)
}

//건강바이탈기록 저장(다건)
function insHlthVtlMultiRec(obj) {
   return http.post('bnftrcrd/insHlthVtlMultiRec', obj)
}

//투약정보 저장(다건)
function insDoseInfMulti(obj) {
   return http.post('bnftrcrd/insDoseInfMulti', obj)
}

//투약상황기록 저장(REC HIS 포함)
function insDoseRecMulti(obj) {
   return http.post('bnftrcrd/insDoseRecMulti', obj)
}

//투약상황기록 저장(REC HIS 포함)
function insClncRecOutHis(obj) {
   return http.post('bnftrcrd/insClncRecOutHis', obj)
}

//신체제재 저장
function insPhysSnct(obj) {
   return http.post('bnftrcrd/insPhysSnct', obj)
}

//보호자통지 저장
function insGrdnNtc(obj) {
   return http.post('bnftrcrd/insGrdnNtc', obj)
}

//신체제재상세 저장 
function insPhysSnctDtl(obj) {
   return http.post('bnftrcrd/insPhysSnctDtl', obj)
}

//특별침실사용 저장
function insSpclbdUse(obj) {
   return http.post('bnftrcrd/insSpclbdUse', obj)
}

//건강바이탈기록 저장
function insHlthVtlRec(obj) {
   return http.post('bnftrcrd/insHlthVtlRec', obj)
}

//급여제공기록 삭제(단건)
function delBnftOfrRec(obj) {
   return http.post('bnftrcrd/delBnftOfrRec', obj)
}

//급여제공기록 삭제(전체)
function delAllBnftOfrRec(obj) {
   return http.post('bnftrcrd/delAllBnftOfrRec', obj)
}

//급여제공기록 삭제(목욕급여)
function delBathAllBnftOfrRec(obj) {
   return http.post('bnftrcrd/delBathAllBnftOfrRec', obj)
}

//건강바이탈기록 삭제
function delHlthVtlRec(obj) {
   return http.post('bnftrcrd/delHlthVtlRec', obj)
}

//건강 및 간호기록 삭제(전체)
function delHlthNsgRcrd(obj) {
   return http.post('bnftrcrd/delHlthNsgRcrd', obj)
}

//투약정보 삭제
function delDoseInf(obj) {
   return http.post('bnftrcrd/delDoseInf', obj)
}

//투약상황기록 삭제
function delDoseRec(obj) {
   return http.post('bnftrcrd/delDoseRec', obj)
}

//투약상황기록 삭제(전체)
function delDoseRecHis(obj) {
   return http.post('bnftrcrd/delDoseRecHis', obj)
}

//신체제재상세 삭제
function delPhysSnctDtl(code, wrt, pk) {
   return http.post('bnftrcrd/delPhysSnctDtl', {fcltyNum:code,userNm:wrt,physSnctDtlPk:pk})
}

//신체제재상세 삭제
function delAllPhysSnct(code, wrt, pk) {
   return http.post('bnftrcrd/delAllPhysSnct', {fcltyNum:code,userNm:wrt,physSnctPk:pk})
}

//보호자통지 삭제
function delGrdnNtc(code, wrt, pk) {
   return http.post('bnftrcrd/delGrdnNtc', {fcltyNum:code,userNm:wrt,grdnNtcPk:pk})
}

//특별침실사용 삭제
function delSpclbdUse(code, wrt, pk) {
   return http.post('bnftrcrd/delSpclbdUse', {fcltyNum:code,userNm:wrt,spclbdUsePk:pk})
}


//급여제공기록 신체활동지원 다건 조회
function selBnftOfrRec301List(obj) {
   return http.post('bnftrcrd/selBnftOfrRec301List', obj)
}

//급여제공기록 신체활동지원 다건 조회
function selBnfcrChgPosInfo(obj) {
   return http.post('bnftrcrd/selBnfcrChgPosInfo', obj)
}

//집중 배설 관찰기록 다건조회
function selAllExcrtObsRecList(code, entCd = null) {
   return http.post('bnftrcrd/selAllExcrtObsRecList', { fcltyNum: code, entStcd: entCd })
}

//건강 급여제공기록 다건조회
function selHlthVtlRecList(obj) {
   return http.post('bnftrcrd/selHlthVtlRecList', obj)
}

//간호 처치기록 다건조회
function selAllNsgCareList(code, ymd, entCd = null) {
   return http.post('bnftrcrd/selAllNsgCareList', { fcltyNum: code, inqYMD: ymd, entStcd: entCd })
}

//건강 급여제공기록 조회
function selAllHlthCareList(code, ymd, entCd = null) {
   return http.post('bnftrcrd/selAllHlthCareList', { fcltyNum: code, inqYMD: ymd, entStcd: entCd })
}

//건강및간호제공 기록내역  조회
function selHlthNrsRecHisList(obj) {
   return http.post('bnftrcrd/selHlthNrsRecHisList', obj)
}

//투약정보 조회
function selDoseInfList(obj) {
   return http.post('bnftrcrd/selDoseInfList', obj)
}

//투약상황기록 조회(REC HIS 포함)
function selDoseRecListHis(obj) {
   return http.post('bnftrcrd/selDoseRecListHis', obj)
}
//투약상황기록 조회(REC HIS 포함)
function selDoseRecList(obj) {
   return http.post('bnftrcrd/selDoseRecList', obj)
}

//진료기록 및 외출 외박 조회
function selClncRecOutHisInfo(obj) {
   return http.post('bnftrcrd/selClncRecOutHisInfo', obj)
}

//건강검진정보 다건 조회
function selHltChcInfList(obj) {
   return http.post('bnftrcrd/selHltChcInfList', obj)
}

//신체제재 기록 다건 조회
function selAllPhysSnctList(code, yyyy, entCd = null) {
   return http.post('bnftrcrd/selAllPhysSnctList', { fcltyNum: code, inqYYYY: yyyy, entStcd: entCd })
}

//특별침실사용기록 다건 조회
function selAllSpclbdUseList(code, yyyy, entCd = null) {
   return http.post('bnftrcrd/selAllSpclbdUseList', { fcltyNum: code, inqYYYY: yyyy, entStcd: entCd })
}

//응급상황기록 다건 조회
function selAllEmergRecList(code, yyyy, entCd = null) {
   return http.post('bnftrcrd/selAllEmergRecList', { fcltyNum: code, inqYYYY: yyyy, entStcd: entCd })
}

//전체 급여제공기록
function selAllBnftRecList(code, yymmdd, entCd = null) {
   return http.post('bnftrcrd/selAllBnftRecList', { fcltyNum: code, inqYMD : yymmdd, entStcd: entCd })
}

//욕창관찰기록 한건 조회
function selBdsrObsRecInfo(obj) {
   return http.post('bnftrcrd/selBdsrObsRecInfo', obj)
}

//욕창관찰기록내역 조회
function selBdsrObsRecList(obj) {
   return http.post('bnftrcrd/selBdsrObsRecList', obj)
}

//경관식제공기록 내역 조회
function selNsgstTubeRecList(obj) {
   return http.post('bnftrcrd/selNsgstTubeRecList', obj)
}

//경관식제공기록 내역 조회
function selExcrtNrsRecList(obj) {
   return http.post('bnftrcrd/selExcrtNrsRecList', obj)
}

//물리/작업치료 일일기록조회
function selPhyThrRecDdList(obj) {
   return http.post('bnftrcrd/selPhyThrRecDdList', obj)
}

//물리/작업치료 일일기록조회
function selPhyThrRecDdGrpList(obj) {
   return http.post('bnftrcrd/selPhyThrRecDdGrpList', obj)
}

//물리/작업치료 주간조회
function selPhyThrRecWkList(obj) {
   return http.post('bnftrcrd/selPhyThrRecWkList', obj)
}

//전체 신체제재 조회
function selBnfcrPhysSnctList(code, mmbrpk, yyyy) {
   return http.post('bnftrcrd/selBnfcrPhysSnctList', {fcltyNum:code,bnMmbrPk:mmbrpk,inqYYYY:yyyy})
}

//신체제재상세 한건 조회
function selPhysSnctInfo(code, pk) {
   return http.post('bnftrcrd/selPhysSnctInfo', {fcltyNum:code,physSnctPk:pk})
}

//보호자통지 한건 조회
function selGrdnNtcInfo(obj) {
   return http.post('bnftrcrd/selGrdnNtcInfo', obj)
}

//신체제재상세 내역 조회
function selPhysSnctDtlList(obj) {
   return http.post('bnftrcrd/selPhysSnctDtlList', obj)
}

//수급자 특별침실사용 조회
function selBnfcrSpclbdUseList(code, mmbrpk,yyyy) {
   return http.post('bnftrcrd/selBnfcrSpclbdUseList', {fcltyNum:code,bnMmbrPk:mmbrpk,inqYYYY:yyyy})
}

//수급자 특별침실사용 한건 조회
function selSpclbdUseInfo(code, pk) {
   return http.post('bnftrcrd/selSpclbdUseInfo', {fcltyNum:code,spclbdUsePk:pk})
}

//전체건강바이탈기록 조회
function selAllHlthVtlRecList(code, ymd) {
   return http.post('bnftrcrd/selAllHlthVtlRecList', {fcltyNum:code,recDt:ymd})
}

//건강및간호기록 특이사항 문구 다건 조회
function selHlthNrsRecCntntList(code, mmbr, ymd) {
   return http.post('bnftrcrd/selHlthNrsRecCntntList', {fcltyNum:code, bnMmbrPk:mmbr,inqYMD:ymd})
}

//전체 진료기록 조회
function selAllClncRecList(obj) {
   return http.post('bnftrcrd/selAllClncRecList', obj)
}

//진료기록 및 외출외박 정보 저장(다건)
function insMultiClncRecOutHis(obj) {
   return http.post('bnftrcrd/insMultiClncRecOutHis', obj)
}

//급여제공기록 저장(목욕기록 저장)
function insBathBnftOfrRec(obj) {
   return http.post('bnftrcrd/insBathBnftOfrRec', obj)
}


//급식운영일지 요약 조회
function selMealSrvcJrnlSmryList(code, strt, end) {
   return http.post('bnftrcrd/selMealSrvcJrnlSmryList', { fcltyNum: code, inqYmdStart: strt, inqYmdEnd: end})
}

//급식운영일지 다건 조회
function selMealSrvcJrnlList(code, inqYMD, inqPreYn) {
   return http.post('bnftrcrd/selMealSrvcJrnlList', { fcltyNum: code, inqYMD: inqYMD, inqPreYn: inqPreYn})
}

//급식운영일지 저장
function insMealSrvcJrnl(code, user, list) {
   return http.post('bnftrcrd/insMealSrvcJrnl', { fcltyNum: code, userNm:user, mealSrvcJrnlList: list})
   
}

//급식운영일지 삭제
function delMealSrvcJrnl(code, user, list) {
   return http.post('bnftrcrd/delMealSrvcJrnl', { fcltyNum: code, userNm:user, mealSrvcJrnlList: list})
}
//급식운영일지
function selMealSrvcJrnlDefaultList(code) {
   return http.post('bnftrcrd/selMealSrvcJrnlDefaultList', { fcltyNum: code})
}

//급여제공기록내역 저장(배설관찰용도)
function insObsExBnftOfrRec(obj) {
   return http.post('bnftrcrd/insObsExBnftOfrRec', obj)
}
 
//급여제공기록 이전 데이타 다건 조회
function selPreBnftOfrRecList(obj) {
   return http.post('bnftrcrd/selPreBnftOfrRecList', obj)
}

//생활실 투약정보 조회
function selLvnRmDoseList(code) {
   return http.post('bnftrcrd/selLvnRmDoseList', { fcltyNum: code})
}

//생활실별 투약기록 요약 다건 조회
function selLvnRmDoseSmryList(code, ymd) {
   return http.post('bnftrcrd/selLvnRmDoseSmryList', { fcltyNum: code, inqYMD: ymd})
}

//생활실 투약정보 저장
function insLvnRmDose(obj) {
   return http.post('bnftrcrd/insLvnRmDose', obj)
}

//생활실 투약정보 조회
function selRmDoseInfList(obj) {
   return http.post('bnftrcrd/selRmDoseInfList', obj)
}

//전체 투약정보 저장
function insMultiDoseRec(obj) {
   return http.post('bnftrcrd/insMultiDoseRec', obj)
}

//건강및간호기록 급여제공기록 다건 조회
function selHlthNrsRecList(code, ymd, mmbr) {
   return http.post('bnftrcrd/selHlthNrsRecList', {fcltyNum:code, inqYMD:ymd, bnMmbrPk:mmbr})
}

//집중배설관찰 정보 저장
function insExcrtObsrvRcrdHis(obj) {
   return http.post('bnftrcrd/insExcrtObsrvRcrdHis', obj)
}

//급여제공기록 다건 조회 (집중배설관찰기록)
function selExcrtObsrvRcrdList(obj) {
   return http.post('bnftrcrd/selExcrtObsrvRcrdList', obj)
}

function selExcrtObsrvRecList(obj) {
   return http.post('bnftrcrd/selExcrtObsrvRecList', obj)
}

//일일 급여제공기록
function selBnftOfrRecDayInfo(obj) {
   return http.post('bnftrcrd/selBnftOfrRecDayInfo', obj)
}

//급여제공기록내역 다건 조회
function selBnftOfrRecHisList(obj) {
   return http.post('bnftrcrd/selBnftOfrRecHisList', obj)
}

//요양 급여 제공기록 조회(주간)
function selBnftOfrRecWeekInfo(obj) {
   return http.post('bnftrcrd/selBnftOfrRecWeekInfo', obj)
}

//급여제공기록 삭제 및 저장
function delTitBnftOfrRec(obj) {
   return http.post('bnftrcrd/delTitBnftOfrRec', obj)
}

//집중배설관찰기록 상세 조회
function selExcrtObsrvRecInfo(obj) {
   return http.post('bnftrcrd/selExcrtObsrvRecInfo', obj)
}

//급여제공기록내역 저장(배설관찰용도)
function insObsExBnftOfrMultiRec(obj) {
   return http.post('bnftrcrd/insObsExBnftOfrMultiRec', obj)
}

//급여제공기록 저장(식사하기)
function insMealSrvRcrd(obj) {
   return http.post('bnftrcrd/insMealSrvRcrd', obj)
}

//급여제공기록 저장(3_2)
function insMultiBnftRcrd(obj) {
   return http.post('bnftrcrd/insMultiBnftRcrd', obj)
}

//급여제공기록 특이사항 문구 다건 조회
function selBnftOfrRecCntntList(obj) {
   return http.post('bnftrcrd/selBnftOfrRecCntntList', obj)
}

//간호서류 저장
function insNrsngDoc(obj) {
   return http.post('bnftrcrd/insNrsngDoc', obj)
}

//간호서류 단건 조회	
function selNrsngDocInfo(code, mmbr, pk) {
   return http.post('bnftrcrd/selNrsngDocInfo', {fcltyNum:code,bnMmbrPk:mmbr,nrsngDocPk:pk})
}

//간호서류 다건 조회
function selNrsngDocList(code, mmbr, strt, lmt) {
   return http.post('bnftrcrd/selNrsngDocList', {fcltyNum:code,bnMmbrPk:mmbr,rnStrt:strt,rnLmt:lmt})
}

//간호서류 다건 삭제
function delNrsngDoc(obj) {
   return http.post('bnftrcrd/delNrsngDoc', obj)
}

function delBnftOfrHisRec(obj) {
   return http.post('bnftrcrd/delBnftOfrHisRec', obj)
}

//물리치료계획 기록 이름별
function selBtcInqPhyThrRecList(code, yymm) {
   return http.post('bnftrcrd/selBtcInqPhyThrRecList', {fcltyNum:code, inqYYMM:yymm})
}
//물리치료계획 기록 날짜별
function selBtcInqPhyThrRecList1(code, yymm) {
   return http.post('bnftrcrd/selBtcInqPhyThrRecList1', {fcltyNum:code, inqYYMM:yymm})
}

//급여제공기록 저장
function insNDelBnftOfrRec(obj) {
   return http.post('bnftrcrd/insNDelBnftOfrRec', obj)
}

//3_5 건강 및 간호기록 화면 
function selHlthNsgRcrd(obj) {
   return http.post('bnftrcrd/selHlthNsgRcrd', obj)
}

//3-3 집중배설관찰기록 저장
function insExcrtObsrvRec(obj) {
   return http.post('bnftrcrd/insExcrtObsrvRec', obj)
}

//3-3 집중배설관찰기록 전체 삭제
function delAllExcrtObsrvRec(obj) {
   return http.post('bnftrcrd/delAllExcrtObsrvRec', obj)
}

//3-3 집중배설관찰기록 삭제
function delExcrtObsrvRec(obj) {
   return http.post('bnftrcrd/delExcrtObsrvRec', obj)
}

//3-10 응급상황기록 저장
function insEmrgnRcrd(obj) {
   return http.post('bnftrcrd/insEmrgnRcrd', obj)
}

//3-10 응급상황기록 삭제
function delEmrgnRcrd(obj) {
   return http.post('bnftrcrd/delEmrgnRcrd', obj)
}

export {
    selClncRecList,
    selClncRecListYyMm,
    selClncRecListClm,
    insClncRec,
    delClncRec,
    selHospInfList,
    insHospInf,
    delHospInf,
    selDctInfList,
    insDctInf,
    delDctInf,
    selHltChcInf,
    insHltChcInf,
    delHltChcInf,
    selBnfcrStInfo,
    selBnftOfrRecList,
    insBnftOfrRec,
    insBnftOfrRecHis,
    insBnftOfrRecMultiHis,
    insBnftOfrRecMulti,
    insHlthVtlMultiRec,
    insDoseInfMulti,
    insDoseRecMulti,
    insClncRecOutHis,
    insGrdnNtc,
    insPhysSnct,
    insPhysSnctDtl,
    insSpclbdUse,
    insHlthVtlRec,
    delBnftOfrRec,
    delAllBnftOfrRec,
    delBathAllBnftOfrRec,
    delHlthVtlRec,
    delHlthNsgRcrd,
    delDoseInf,
    delDoseRec,
    delDoseRecHis,
    delPhysSnctDtl,
    delAllPhysSnct,
    delGrdnNtc,
    delSpclbdUse,
    selBnftOfrRec301List,
    selBnfcrChgPosInfo,
    selAllExcrtObsRecList,
    selAllNsgCareList,
    selAllHlthCareList,
    selHlthVtlRecList,
    selHlthNrsRecHisList,
    selDoseInfList,
    selDoseRecListHis,
    selClncRecOutHisInfo,
    selHltChcInfList,
    selAllPhysSnctList,
    selAllSpclbdUseList,
    selAllEmergRecList,
    selAllBnftRecList,
    selBdsrObsRecInfo,
    selBdsrObsRecList,
    selNsgstTubeRecList,
    selExcrtNrsRecList,
    selPhyThrRecDdList,
    selPhyThrRecDdGrpList,
    selPhyThrRecWkList,
    selBnfcrPhysSnctList,
    selPhysSnctInfo,
    selGrdnNtcInfo,
    selPhysSnctDtlList,
    selBnfcrSpclbdUseList,
    selSpclbdUseInfo,
    selAllHlthVtlRecList,
    selHlthNrsRecCntntList,
    selAllClncRecList,
    insMultiClncRecOutHis,
    insBathBnftOfrRec,
    selMealSrvcJrnlSmryList,
    selMealSrvcJrnlList,
    insMealSrvcJrnl,
    delMealSrvcJrnl,
    insObsExBnftOfrRec,
    selPreBnftOfrRecList,
    selLvnRmDoseList,
    selLvnRmDoseSmryList,
    insLvnRmDose,
    selRmDoseInfList,
    insMultiDoseRec,
    selHlthNrsRecList,
    insExcrtObsrvRcrdHis,
    selExcrtObsrvRcrdList,
    selExcrtObsrvRecList,
    selBnftOfrRecDayInfo,
    selBnftOfrRecHisList,
    selMealSrvcJrnlDefaultList,
    selBnftOfrRecWeekInfo,
    delTitBnftOfrRec,
    selDoseRecList,
    selExcrtObsrvRecInfo,
    insObsExBnftOfrMultiRec,
    insMealSrvRcrd,
    insMultiBnftRcrd,
    insPhythrBnftOfrRecMulti,
    selBnftOfrRecCntntList,
    insNrsngDoc,
    selNrsngDocInfo,
    selNrsngDocList,
    delNrsngDoc,
    delBnftOfrHisRec,
    selBtcInqPhyThrRecList,
    selBtcInqPhyThrRecList1,
    insNDelBnftOfrRec,
    selHlthNsgRcrd,
    insExcrtObsrvRec,
    delAllExcrtObsrvRec,
    selBnftOfrRec305List,
    insEmrgnRcrd,
    delEmrgnRcrd,
    delExcrtObsrvRec
}