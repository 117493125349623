<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700" style="position:relative">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">급여제공 계획변경</span>
            <v-spacer></v-spacer>
            <v-btn v-for="(list, i) in filledBtn" :key="i"  @click = "onClickBtn(list.text)"
                :class="list.class" height="30" :color="list.color" rounded
                :disabled="btnDisabled"
                style="font-size: 14px;">
                <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
            <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j"
                :disabled="list.disabled"
                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                <v-icon small>{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>
        <v-form ref="form" lazy-validation>
            <div class="d-flex pa-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <div class="greyE01 d-flex justify-center align-center pa-0 ma-0" style="width:10%">
                    <span class="black--text font-weight-medium">급여제공연도</span>
                </div>
                <div class="py-1 pl-2 d-flex justify-center align-center" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                    {{items.plnYyyy}}
                </div>                
                <div class="greyE01 d-flex justify-center align-center pa-0 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">작성일</span>
                </div>
                <div class="pa-1 ma-0 d-flex justify-start align-center" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                    <span class="white d-inline-block py-1 pl-2" style="width: 154px;">    
                        <CmmDateComp v-model="items.wrtDt" :required="true" :minDt="inqYYYY+'-01-01'" :maxDt="inqYYYY+'-12-31'"></CmmDateComp>                                               
                    </span>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-0 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">작성자</span>
                </div>
                <div class="pa-1 ma-0 d-flex justify-start align-center" style="width:12%;border-left: 1px solid #dbdbdb !important;">
                    <span class="d-inline-block pa-1" style="width: 120px;">
                        <v-text-field
                            v-model="items.wrtr" :rules="[rules.required]" :key="forceRender"
                            color="blue" height="28" ref="info"
                            readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal"
                        >
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </div>     
                <div class="greyE01 d-flex justify-center align-center pa-0 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">변경 급여제공 반영일</span>
                </div>
                <div class="pa-1 d-flex justify-center align-center" style="width:13%;border-left: 1px solid #dbdbdb !important;">
                    <span class="white d-inline-block" style="width: 154px;">
                        <CmmDateComp v-model="items.bnftOfrDt" :required="true"></CmmDateComp>                       
                    </span>
                </div>       
            </div>
            <div class="d-flex pa-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <div class="greyE01 d-flex justify-center align-center pa-0 ma-0" style="width:10%">
                    <span class="black--text font-weight-medium">변경구분</span>
                </div>
                <div class="py-1 pl-2" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                    <v-radio-group
                        v-model="items.mdfRsncd" :rules="[rules.required]" :key="forceRender"
                        class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row>
                        <span v-for="(item,i) in codeItem" :key="i" style="width:100%">
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" :label="item.valcdnm" :value="item.valcd" @click="getBnftOfrMdfRsnList(item.valcd,true)"></v-radio>
                        </span>
                    </v-radio-group>
                </div>                
                <div class="pa-2 justify-center align-center" style="width:75%;border-left: 1px solid #dbdbdb !important;">
                    
                    <div v-if="items.mdfRsncd=='7'">
                        <div class="d-flex pa-0" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%">
                                <span class="black--text font-weight-medium">변경구분명</span>
                            </div>
                            <div class="d-flex justify-start align-center pa-1 ma-0" style="width:85%;border-left: 1px solid #dbdbdb !important;">
                                <span class="d-inline-block pa-1" style="width: 200px;">                                    
                                    <v-text-field
                                        v-model="items.mdfRsncdNm" :rules="[rules.required]" :key="forceRender"
                                        color="blue" height="28" ref="info"
                                        hide-details outlined dense>
                                    </v-text-field>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-else>   
                        <div class="d-flex pa-0" no-gutters style="width:980px;border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:7%">
                                <span class="black--text font-weight-medium">선택</span>
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">작성일</span>
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">작성자</span>
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:73%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">내용</span>
                            </div>
                        </div> 
                        <div v-if="mdfRsnList.length > 0" style="height:130px;overflow-y:scroll">                            
                            <div v-for="(item,i) in mdfRsnList" :key="i" class="d-flex pa-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:7%">
                                    <span class="d-inline-block" style="width: 24px; height: 32px;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            v-model="item.checkr" :key="forceRender" @change="onlyOneCheckr(item)"
                                            true-value="1" false-value="2"
                                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                    </span>                                
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                                    {{item.wrtDt?.beforeDateFormatHyp()}}
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                                    {{item.wrtr}}
                                </div>
                                <div class="d-flex justify-start align-center pa-1 ma-0" style="width:73%;border-left: 1px solid #dbdbdb !important;">
                                    {{item.cntnt}}
                                </div>
                            </div>
                        </div>
                        <div v-else class="d-flex pa-0" style="width:980px;border-bottom: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                                <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>                       
            </div>
            <div class="d-flex pa-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <div class="greyE01 d-flex justify-center align-center pa-0 ma-0" style="width:10%">
                    <span class="black--text font-weight-medium">변경사유</span>
                </div>
                <div class="pa-1" style="width:90%;border-left: 1px solid #dbdbdb !important;">
                    <span class="d-inline-block pa-1" style="width: 520px;">
                    <v-text-field
                        v-model="items.mdfRsn" :key="forceRender"
                        color="blue" height="28" ref="info"
                        hide-details outlined dense>
                    </v-text-field>
                    </span>
                </div>
            </div>
            <div class="d-flex pa-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <div class="greyE01 d-flex justify-center align-center pa-0 ma-0" style="width:10%">
                    <span class="black--text font-weight-medium">변경전<br/>급여제공내용</span>
                </div>
                <div class="pa-1" style="width:40%;border-left: 1px solid #dbdbdb !important;">
                    <v-textarea
                        v-model="items.bfrBnftCntnt" class="rmrksArea" no-resize  
                        rows="5" outlined dense hide-details>
                    </v-textarea>
                </div>                       
                <div class="greyE01 d-flex justify-center align-center pa-0 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">변경후<br/>급여제공내용</span>
                </div>
                <div class="pa-1" style="width:40%;border-left: 1px solid #dbdbdb !important;">
                    <v-textarea
                        v-model="items.aftBnftCntnt" class="rmrksArea" no-resize  
                        rows="5" outlined dense hide-details>
                    </v-textarea>
                </div>                       
            </div>  
            <div class="d-flex align-center mt-2">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">급여제공 계획변경 내역</span>
            </div>
            <div class="d-flex pa-0 mt-2" style="width:1330px;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>                
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;">
                    <span class="black--text font-weight-medium">변경구분</span>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">변경사유</span>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:40%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">변경 후 급여제공 내용</span>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">급여제공 반영일</span>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">작성자</span>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">작성일</span>
                </div>
            </div> 
            <div v-if="hisList.length > 0" style="height:130px;overflow-y:scroll">
                <div v-for="(list,j) in hisList" :key="j" 
                    :class="[
                        'fixedCol crsShp-Pointer d-flex pa-0',
                        list.bnftOfrPlnHisPk == bnftOfrPlnHisPk ? 'sltRow' : 'disSltRow'
                    ]" 
                    style="width:1330px;border-bottom: 1px solid #dbdbdb;" 
                    @click="setHisListInfo(j)"
                    no-gutters> 
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;">
                        {{list.mdfRsncdNm}}
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                        {{list.mdfRsn}}
                    </div>
                    <div class="d-flex justify-start align-center pa-1 ma-0" style="width:40%;border-left: 1px solid #dbdbdb !important;">
                        {{list.aftBnftCntnt}}
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                        {{list.bnftOfrDt}}
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                        {{list.wrtr}}
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                        {{list.wrtDt}}
                    </div>
                </div>         
            </div>
             <div v-else>
                <div
                    style="width:1330px;border-bottom: 1px solid #dbdbdb;" 
                    no-gutters> 
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                        <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                    </div>
                </div>
             </div>
        </v-form>
        
        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="'급여제공 계획변경'" @madalState="onDelete"></btnModalDelete>  
        </v-dialog>   
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
        </v-dialog> 
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import { getMultiStcd } from '../../api/index.js';
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import {getBnftOfrPlnHisInfo, insBnftOfrPlnMfd, delBnftOfrPlnHis, selBnftOfrMdfRsnList, selBnftOfrPlnHisList } from '../../api/bnftSrv.js';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import EmpSelector from '../commons/EmpSelector.vue';

export default {
    name: 'BnftSrvPln',

    props : {        
        inqYYYY: Number, 
        bnMmbrPk : { type: Number, default: -1 },
        btnDisabled : { type: Boolean, default: false },    
        mdfRsncd : { type: String, default: null },
        bnftSrvPlnBzClcd : { type: String, default: null },
        bnftSrvPlnBzPk : { type: Number, default: null },
        bnftSrvPlnPk : { type: Number, default: -1 },
    },
        
    components: {
        CmmDateComp,
        basicInfo,
        btnModalDelete,
        EmpSelector,
    },
    

    watch: {     
        'bnftSrvPlnPk':function(value){   
            if(value > -1){
                this.bnftOfrPlnHisPk = this.bnftSrvPlnPk
                this.onShow()
            }
        }
    },

    mounted: function(){             
        this.$nextTick(function () {  
            this.bnftOfrPlnHisPk = this.bnftSrvPlnPk
            this.onCodeload()
            this.onShow()
        })
    },
    
    methods: {
        onShow : function(){  
            this.frmReset()
            this.onCodeload()
            this.getInit()
            this.getOfrPlnHisInfo()
            this.getBnftOfrPlnHisList()
        },
        onClose: function () {
            //this.frmReset();
            this.$emit('isClose')
        },

         getInit(){
            this.items = Object.assign({wrtDt:'',bnftOfrDt:'',wrtr:'',bzClcd:0,bfrBnftCntnt:'',bzPk:0,mdfRsncd:''})

            // let date = new Date()                                    
            // let dateStr = date.toISOString().split('T',2)[0]
            
            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)
            let dateStr = timezoneDate.toISOString().split('T',2)[0]
            this.items.wrtDt = dateStr
            this.items.bnftOfrDt = dateStr
            this.items.wrtr = this.$store.getters.getUserNm
            this.minDate = this.inqYYYY+'-'+'01'+'-'+'01'
            this.maxDate = this.inqYYYY+'-'+'12'+'-'+'31'

        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        onClickBtn: function(text) {
            if(text=="저장"){
                this.insBnftOfrPlnHis()                               
            } else if(text=="삭제"){
                if(this.bnftOfrPlnHisPk > 0){
                    this.dialogDelete = true
                }
            }
        },

        insBnftOfrPlnHis(){
            let todo = true
            
            if(this.items.mdfRsncd != "7" && this.items.mdfRsncd != null){
                if(this.items.bzPk == 0 || this.items.bzPk == '' || this.items.bzPk == null){
                    todo = false
                }
            }
            
            if(todo){
                if(this.$refs.form.validate()){
                    let ofrPlnHisInfo = {
                        fcltyNum : this.$store.getters.getFcltyNum,
                        bnMmbrPk : this.bnMmbrPk,
                        bnftOfrPlnHisPk : this.bnftOfrPlnHisPk,
                        userNm : this.$store.getters.getUserNm,
                        wrtDt :  this.items.wrtDt?.afterDateFormatHyp(),
                        wrtr : this.items.wrtr,
                        bnftOfrDt : this.items.bnftOfrDt?.afterDateFormatHyp(), 
                        mdfClcd : '2',
                        plnClcd:'201',
                        cmnts : this.cmnts,
                        plnYyyy: this.items.plnYyyy,
                        aplStrt: this.items.plnYyyy,
                        bzClcd:this.items.bzClcd,
                        bzPk:this.items.bzPk,
                        bfrBnftCntnt:this.items.bfrBnftCntnt,
                        aftBnftCntnt:this.items.aftBnftCntnt,
                        mdfRsncdNm:this.items.mdfRsncdNm,
                        mdfRsncd:this.items.mdfRsncd,
                        mdfRsn:this.items.mdfRsn,
                    }

                    if(this.items.mdfRsncd != "7" && this.items.mdfRsncd != null){
                        this.codeItem.forEach(cd => {
                            if(cd.valcd == this.items.mdfRsncd) ofrPlnHisInfo.mdfRsncdNm = cd.valcdnm
                        });
                    }

                    insBnftOfrPlnMfd(ofrPlnHisInfo)
                        .then((response) => ( this.insBnftOfrPlnMfdAfter(response.data)))
                        .catch((error) => console.log(error))
                }
            }else{
                this.snackControll([true, 5000, '변경구분을 선택해주세요.', 'warning'])
            }
           
        },

        insBnftOfrPlnMfdAfter: function (res) {            
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.bnftOfrPlnHisPk = res.result
                this.getOfrPlnHisInfo();
                this.getBnftOfrPlnHisList();
                this.$emit('modalEvent', 99)
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
                // this.snackControll([true, 5000, res.message, 'error'])
            }     
        },

        onDelete:function(res){
            if(res == 9990){
                if(this.bnftOfrPlnHisPk > 0){
                    let code = this.$store.getters.getFcltyNum;
                    let value = this.bnftOfrPlnHisPk;
                    let user = this.$store.getters.getUserNm
                    delBnftOfrPlnHis(code, value, user)
                    .then((response) => ( this.delBnftOfrPlnHisAfter(response.data)))
                    .catch((error) => console.log(error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },

        delBnftOfrPlnHisAfter:function(res){
            if(res.statusCode == 200){                                                
                this.$emit('modalEvent', 99)
                this.onClose()
            }
            else{                
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        frmReset:function(){  
            
            this.mdfRsnList.splice(0)
            this.hisList.splice(0)
        },

        snackControll: function (options) {
            this.snackOptions = options
        },

        getOfrPlnHisInfo(){                       
            if(this.bnftOfrPlnHisPk !='' && this.bnftOfrPlnHisPk > 0){                
                let fcltyNum = this.$store.getters.getFcltyNum
                let state = {
                    bnMmbrPk :  this.bnMmbrPk,
                    inqYYYY : this.inqYYYY,
                    bnftOfrPlnHisPk : this.bnftOfrPlnHisPk,
                }

                getBnftOfrPlnHisInfo(fcltyNum, state)
                .then((response) => ( this.setData(response.data)))
                .catch((error) => console.log(error))
            }else{                
                this.items.plnYyyy = this.inqYYYY
                this.items.mdfRsncd = this.mdfRsncd
                this.items.bzClcd = this.bnftSrvPlnBzClcd
                this.items.bzPk = this.bnftSrvPlnBzPk

                if(this.items.mdfRsncd != "7" && this.items.mdfRsncd != null){
                    this.getBnftOfrMdfRsnList(this.items.mdfRsncd)
                }
                this.$refs.form.resetValidation()
            }

        },
        setData(data){
            data.wrtDt = data.wrtDt?.beforeDateFormatHyp()
            data.bnftOfrDt = data.bnftOfrDt?.beforeDateFormatHyp()
            this.items = data

            if(data.mdfRsncd != "7"){
                this.getBnftOfrMdfRsnList(data.mdfRsncd)
            }            
            this.$refs.form.resetValidation()
        },
        getBnftOfrMdfRsnList(cd,rest=false){
            this.mdfRsnList.splice(0)

            if(rest){
                this.items.bzClcd=''
                this.items.bzPk=''
            }

            if(cd !="7"){

                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    bnMmbrPk :  this.bnMmbrPk,
                    inqYYYY : this.inqYYYY,
                    mdfRsncd:cd,
                    // bzClcd:this.bnftSrvPlnBzClcd,
                    bzPk:this.items.bzPk,
                }

                selBnftOfrMdfRsnList(obj)
                    .then((response) => ( this.selBnftOfrMdfRsnListAfter(response.data)))
                    .catch((error) => console.log(error))
            }else{
                this.items.mdfRsncdNm = ''
            }
        },
        selBnftOfrMdfRsnListAfter:function(data){      
            this.mdfRsnList.splice(0)

            data.forEach(el => {
                if(el.bzPk == this.items.bzPk) {
                    el.checkr = '1'
                    if(this.bnftOfrPlnHisPk < 1){
                        this.items.bzPk         = el.bzPk
                        this.items.bzClcd       = el.bzClcd
                        this.items.bfrBnftCntnt = el.cntnt
                    }
                }else{
                    el.checkr = '2'
                } 
                this.mdfRsnList.push(el)
            })

            if(this.bnftOfrPlnHisPk == 0 ) this.setMdfRsnCntnt(this.bnftSrvPlnBzClcd)

            this.$refs.form.resetValidation()
        },
        getBnftOfrPlnHisList:function(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let state = {
                bnMmbrPk :  this.bnMmbrPk,
                inqYYYY : this.inqYYYY,
                mdfClcd : '2',
                // mdfRsncd : this.mdfRsncd,
                // bzClcd:this.bnftSrvPlnBzClcd,
                // bzPk:this.bnftSrvPlnBzPk,
            }

            selBnftOfrPlnHisList(fcltyNum,state)
                    .then((response) => ( this.getBnftOfrPlnHisListAfter(response.data)))
                    .catch((error) => console.log(error))
        },
        getBnftOfrPlnHisListAfter:function(data){
            this.hisList.splice(0)

            data.forEach(x=>{
                x.wrtDt = x.wrtDt?.beforeDateFormatHyp()
                x.bnftOfrDt = x.bnftOfrDt?.beforeDateFormatHyp()
            })

            this.hisList = data
        },
        onCodeload:function(){
            getMultiStcd(['6'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },

        codeMapping:function(res){
            this.codeItem = []
            this.codeItem = res["vCd6"]

        },
        onlyOneCheckr: function (item) {            
            this.mdfRsnList.forEach(el => {
                if(el.bzPk == item.bzPk) el.checkr = '1'
                else el.checkr = '2'
            });
            this.forceRender += 1
            this.items.bzPk         = item.bzPk
            this.items.bzClcd       = item.bzClcd
            this.items.bfrBnftCntnt = item.cntnt
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
        onModal: function () {
            this.prtWrtDt=this.items.wrtDt
            this.menu.splice(1, 1, true)
        },
        isModal: function () {
            this.menu.splice(1, 1, false)
        },
        setBzClcd:function(value){
            this.items.bzClcd = value
            this.setMdfRsnCntnt(value)
        },
        setMdfRsnCntnt(value){
            this.mdfRsnList.forEach(item => {
                if(item.bzClcd == value) this.items.bfrBnftCntnt = item.cntnt
            });
        },
        setHisListInfo:function(key){
            if(this.hisList[key].bnftOfrPlnHisPk != '' && this.hisList[key].bnftOfrPlnHisPk != null){                
                this.bnftOfrPlnHisPk = this.hisList[key].bnftOfrPlnHisPk
                this.items = Object.assign({}, this.hisList[key]) 
                
                if(this.items.mdfRsncd != "7"){
                    this.getBnftOfrMdfRsnList(this.items.mdfRsncd)
                }
            }
        }
    },
    
    data: () => ({       
        minDate:'',
        maxDate:'',
        prtWrtDt:'', 
        items:{wrtDt:'',bnftOfrDt:'',wrtr:'',mdfRsncd:'',bzClcd:0,bfrBnftCntnt:0,bzPk:0,aftBnftCntnt:'',mdfRsncdNm:'',mdfRsn:''},
        codeItem:[],
        mdfRsnList:[],
        hisList:[],
        menu: [false,false,false],
        bnftOfrPlnHisPk : -1,
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'dialogue', visible: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력', disabled:true },
        ],
        snackOptions: [false, 3000, '', 'info'],
        forceRender:0,
        dialogDelete:false,
    }),
};
</script>