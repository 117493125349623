<template>
    <v-sheet class="ma-0" rounded="md" style="position: relative;">
        <v-row class="align-center" no-gutters>
            <expandMonthPicker @nowYearWithMonth="getPickerDate" :prtDate="intlDt"></expandMonthPicker>            
            <span class="d-inline-block green lighten-1 ml-1" style="width:23px;height:23px;"></span>
            <span class="ml-1">기록완료</span>
            <span class="red lighten-1 ml-1" style="width:23px;height:23px;"></span>
            <span class="ml-1">외출&#183;외박 중 목욕기록</span>
            <v-spacer></v-spacer>
            <v-btn
                class="white--text mr-2" height="30" color="blue004"
                style="padding: 0 12px 0 10px !important;"                
                rounded samll
                @click="$emit('onBathRecSpclNote', sltDt)">
                <v-icon style="margin-top: 1px;" small>mdi-text-box-outline</v-icon>
                <span class="fontOneRem ml-2" style="margin-bottom: 1px;">특이사항</span>
            </v-btn>
            <v-btn
                class="font-weight-bold" height="28" color="grey006"     
                @click="bathBlnftPrt"          
                rounded outlined samll>
                <div class="d-flex justify-left align-center">
                    <v-icon style="margin-bottom: 1px;" size="18">mdi-printer-outline</v-icon>
                    <span class="ml-2" style="font-size: 1rem;">목욕기록&nbsp;출력</span>
                </div>
            </v-btn>
        </v-row>
        <div
            class="d-inline-block overflow-y-auto elevation-1 mt-1"
            :style="{ width: '100%', 'max-height': '670px' }">
            <v-calendar
                :class="'cmmClndr clndrBath'"
                color="" locale="ko" event-text-color="black"
                v-model="sltDt" :now="nowDt" :type="calendarObj.type"
                :events="clndrList" :event-height="60" :event-more="false" :event-ripple="false"
                @click:event="dayClck">
                <template #day-label="{ date, day }" >
                    <div class="d-flex align-center tableBB">
                        <!-- 달력 날짜 시작 -->
                        <span
                            :class="[
                                'mb-1',
                                date == nowDt && $moment(nowDt, 'YYYY-MM-DD').format('YYYYMM') == $moment(sltDt, 'YYYY-MM-DD').format('YYYYMM') ? 'ic-level nowDay dayNow' : '',
                                holiDeList.findIndex( v => v.locdateHyp == date ) != -1 && date != nowDt ? 'red--text' : ''
                                ]">
                            {{ day }}
                        </span>
                        <!-- 달력 날짜 끝 -->
                        <!-- 달력 공휴일 정보 시작 -->
                        <!-- <span
                            v-if="holiDeList.findIndex( v => v.locdateHyp == date ) != -1"
                            class="d-inline-block red--text ml-1"
                            style="width: 60px; font-size: 0.9rem; margin-bottom: 2px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                            {{ holiDeList[holiDeList.findIndex( v => v.locdateHyp == date )].dateName }}
                        </span> -->
                        <!-- 달력 공휴일 정보 끝 -->
                        <v-spacer></v-spacer>     
                         <!-- 달력 모드 구분 (합계)  -->
                        <span                            
                            class="d-inline-block" style="width: 100px; font-size: 0.875rem; margin-bottom: 3px; text-align: end;">                            
                            {{clndrList.filter( v => v.start == date && v.recYn =='1').length}}&#40;기록&#41;&#47;
                            {{clndrList.filter( v => v.start == date ).length}}&#40;계획&#41;
                        </span>
                    </div>
                </template>
                <template #event="{ event }">
                    

                    <div v-if="event.bathTm=='외출' || event.bathTm=='외박'">
                        <div v-if="event.recYn =='1'" class="d-flex align-center px-2 pb-1 crsPntr red lighten-1 rcdRow">
                            <span class="d-inline-block fontOneRem white--text">{{ event.name }}</span>
                            <v-spacer></v-spacer>                            
                            <span class="d-inline-block white--text fontOneRem" style="margin-right: 4px;">{{ event.bathTm }}</span>      
                        </div>

                        <div v-else class="d-flex align-center px-2 crsPntr">
                            <span class="d-inline-block fontOneRem">{{ event.name }}</span>
                            <v-spacer></v-spacer>
                            <span v-if="event.bathTm=='외출' || event.bathTm=='외박'" class="d-inline-block fontOneRem red--text" style="margin-right: 4px;font-weight: 500;">{{ event.bathTm }}</span>      
                            <span v-else class="d-inline-block fontOneRem" style="margin-right: 4px;">{{ event.bathTm }}</span>      
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="event.recYn =='1'" class="d-flex align-center px-2 pb-1 crsPntr green lighten-1 rcdRow">
                            <span class="d-inline-block fontOneRem white--text">{{ event.name }}</span>
                            <v-spacer></v-spacer>                            
                            <span class="d-inline-block white--text fontOneRem" style="margin-right: 4px;">{{ event.bathTm }}</span>      
                        </div>

                        <div v-else class="d-flex align-center px-2 crsPntr">
                            <span class="d-inline-block fontOneRem">{{ event.name }}</span>
                            <v-spacer></v-spacer>
                            <span class="d-inline-block fontOneRem" style="margin-right: 4px;">{{ event.bathTm }}</span>      
                        </div>
                    </div>
                </template>
            </v-calendar>
        </div>
    </v-sheet>
</template>
<script>
import { dataApis } from '@/api/global/datagokrAxios';
import { http } from '@/api/baseAxios';

import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';

export default {
    name: 'BathBlnftSrvRcrdClndr',
        

    props : {                
        intlDt: { type: String, default: '' },                      // 년월 픽커 초기 세팅
    },

    components: {
        expandMonthPicker
    },


    created: function(){
        this.nowDt = this.$moment().format('YYYY-MM-DD')
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {
        getBnfcrChg(){
            return this.$parent.$parent.bnfcrChg
        },
    },    

    watch: {        
        'sltDt': function(){
            // let tmp = this.$moment(this.sltDt, 'YYYY-MM-DD')
            // this.getHoliDeInf(tmp.format('YYYY'), tmp.format('MM'))
            this.getMnthList()
        },
        getBnfcrChg:function(v){
            if(v>0) this.getMnthList()
        }
    },
        
    methods: {
        // 국경일 정보 조회
        getHoliDeInf: function (year, mnth) {
            this.holiDeList.splice(0)

            dataApis.get('/getHoliDeInfo', { params: { solYear: year, solMonth: mnth } })
                .then((response) => ( this.holiDeRst(response.data) ))
                .catch((error) => console.log('connect error http://apis.data.go.kr/getHoliDeInfo : ' + error))
        },

        // 목욕급여제공기록 월별 다건 조회
        getMnthList: function () {
            this.clndrList.splice(0)

            //수급자 선택이 되어있을 경우에만
            if(this.$parent.$parent.bnMmbrPks.length > 0){
                let obj = {
                    inqYYMM     : this.$moment(this.sltDt, 'YYYY-MM-DD').format('YYYYMM'),
                    bnMmbrPks   : this.$parent.$parent.bnMmbrPks
                }
    
                http.post('bnftoffer/selBathSchdlRecMnthList', obj)
                    .then((response) => ( this.rstMnthList(response.data) ))
                    .catch((error) => console.log('connect error /bnftoffer/selBathSchdlRecMnthList : ' + error))
            }
        },

        // 결과 :: 국경일 정보 조회
        holiDeRst: function (res) {
           
           if(Object.keys(res.response?.body).length > 0){
               if(res.response.body.items.item instanceof Array){
                   res.response.body.items.item.forEach(itm => {
                       itm.locdateHyp = this.$moment(itm.locdate, 'YYYYMMDD').format('YYYY-MM-DD')
                   });

                   let chc = res.response.body.items.item.filter( v => v.isHoliday == 'Y' )
                   if(chc.length > 0)
                       this.holiDeList = res.response.body.items.item
               }
               else if(res.response.body.items.item instanceof Object){
                   res.response.body.items.item.locdateHyp = this.$moment(res.response.body.items.item.locdate, 'YYYYMMDD').format('YYYY-MM-DD')
                   if(res.response.body.items.item.isHoliday == 'Y')
                       this.holiDeList.push(res.response.body.items.item)
               }

               if(this.$moment(this.sltDt, 'YYYY-MM-DD').format('MM') == '05'){
                   let obj = {}
                   obj.dateName = '근로자의날'
                   obj.isHoliday = 'Y'
                   obj.locdate = this.$moment().format('YYYY') + '0501'
                   obj.locdateHyp = this.$moment(obj.locdate, 'YYYYMMDD').format('YYYY-MM-DD')
                   this.holiDeList.push(obj)
               }
           }
        },
       
        //결과 :: 목욕급여제공기록 월별 다건 조회
        rstMnthList:function(res){            
            if(res.statusCode == '200'){
                if(res.result.list !=null && res.result.list != undefined){
                    res.result.list.forEach(itm => {
                        itm.start = this.$moment(itm.bathDt, 'YYYYMMDD').format('YYYY-MM-DD')
                        this.clndrList.push(itm)
                    });
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 달력 클릭 이벤트
        dayClck: function (v) {
            this.$emit("onBathObsrvRcrd",v.event)
        },

        // 날짜 변경시 호출
        getPickerDate: function (date) {
            this.sltDt = this.$moment(date, 'YYYYMM').format('YYYY-MM-DD')
        },

        bathBlnftPrt: function (){
            
            if(this.$parent.$parent.bnMmbrPks.length > 0){
                this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum            
                this.prtThrowObj.bnMmbrPks = this.$parent.$parent.bnMmbrPks
                this.prtThrowObj.list[0].bzPk.splice(0)
                this.prtThrowObj.list[0].bzClcd.splice(0)
                this.prtThrowObj.inqYmdStart    = this.$moment(this.sltDt, 'YYYY-MM-DD').format('YYYYMMDD')
                this.prtThrowObj.inqYmdEnd      = this.$moment(this.sltDt, 'YYYY-MM-DD', true).endOf('month').format('YYYYMMDD')
                this.prtThrowObj.prtCd = '60'
    
                this.$store.commit('setRootInf', {
                    actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
                });
            }
            else {
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 3000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '수급자를 선택해주세요!'         // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }
        },
    },

    data: () => ({
        sltDt: '',
        nowDt: '',
        holiDeList  : [],
        clndrList   : [],
        calendarObj: 
        { 
            type: 'month', types: ['month', 'week', 'day', '4day'],
            weekday: [0, 1, 2, 3, 4, 5, 6], mode: 'stack',
            weekdays: [
                { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
                { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
                { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
                { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
            ],
        },
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: null, prtClcd: '1', inqClcd: 2, prtCd: '60', inqYYYY: '', inqYmdStart:'', inqYmdEnd:'', bnMmbrPks:[],
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>
<style>
.clndrBath .v-calendar-weekly__week .v-calendar-weekly__day .v-event {
    width: 97% !important; height: 23px !important; margin-top: 2px !important; background-color: initial !important; margin-bottom: initial !important;
}
.crsPntr { cursor: pointer; }
.rcdRow {height:23px !important;}
</style>