<template>
    <v-row class="ma-0 pa-0" no-gutters>
        <v-spacer></v-spacer>
        <v-col class="" cols="9">
            <div class="d-flex">
                <span
                    class="d-inline-block rounded-lg rounded-r-0 elevation-7 py-2 pl-3"
                    style="width: 50%; height: 720px; background-color: #f9f7fb; position: relative;">
                    <!-- <div class="" style="position: absolute; left: 50%; top: 100px; transform: translateX(-50%);">
                        <span class="font-weight-bold" style="font-size: 3rem; color: #3a3d4c;">환영합니다!</span>
                        <v-divider class=""
                            style="margin: 14px 66% 60px 4px; border-width: thin 0px 3px 0; border-color: #a7abb7;">
                        </v-divider>
                    </div> -->
                    <div class="d-inline-block d-flex justify-center" style="width: 50%; position: absolute; top: 0px; left: 44px;">
                        <v-img min-width="100%" max-width="100%" :key="forceRender"
                            :aspect-ratio="2.14/1" src="../../assets/loginDecoTop.png" eager>
                        </v-img>
                    </div>
                    <div class="d-inline-block d-flex justify-center"
                        style="width: 57%; position: absolute; top: 47%; left: 50%; transform: translate(-50%, -50%);">
                        <v-img min-width="100%" max-width="100%" :key="forceRender"
                            :aspect-ratio="2.5555/1" src="../../assets/loginLogo.png" eager>
                        </v-img>
                    </div>
                    <div class="d-inline-block d-flex justify-center" style="width: 100%; position: absolute; bottom: -1px;">
                        <v-img min-width="90%" max-width="90%" :key="forceRender"
                            :aspect-ratio="3.8/1" src="../../assets/loginDecoBottom.png" eager>
                        </v-img>
                    </div>
                </span>
                <span
                    class="d-inline-block rounded-lg rounded-l-0 elevation-7 py-2 pr-3"
                    style="width: 50%; height: 720px; background-color: white; position: relative;">
                    <v-row class="mt-12 mb-4 pa-0" no-gutters>
                        <v-col class="d-flex justify-center" cols="12">
                            <span class="font-weight-bold" style="font-size: 3rem; color: #3688c1;">환영합니다!</span>
                        </v-col>
                    </v-row>
                    <v-divider class=""
                        style="margin: 0 45% 60px 45%; border-width: thin 0px 3px 0; border-color: #195086;">
                    </v-divider>
                    <div class="d-inline-block" style="width: 100%; height: 30px;"></div>
                    <v-form ref="authLogin" lazy-validation>
                        <v-row class="ma-0 pa-0" no-gutters>
                            <v-spacer></v-spacer>
                            <v-col class="d-flex justify-center" cols="8">
                                <v-text-field
                                    class="authTxtField" v-model="authInfo.fcltyNum"
                                    hint="'-' 를 제외한 11자리 숫자만 입력해주세요." label="기관번호"
                                    :rules="[rules.required, rules.fcltyConfirm, rules.numberCheck]"
                                    counter outlined>
                                </v-text-field>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row class="mt-3 pa-0" no-gutters>
                            <v-spacer></v-spacer>
                            <v-col class="d-flex justify-center" cols="8">
                                <v-text-field
                                    v-model="authInfo.id"
                                    class="authTxtField" label="아이디" :rules="[rules.required]" outlined>
                                </v-text-field>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row class="mt-3 pa-0" no-gutters>
                            <v-spacer></v-spacer>
                            <v-col class="d-flex justify-center" cols="8">
                                <v-text-field
                                    :class="['authTxtField', pswdShow==true ? 'authTxtFieldPwdIcon' : '' ]"
                                    v-model="authInfo.passwd" :rules="[rules.required]"
                                    :append-icon="pswdShow ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="pswdShow ? 'text' : 'password'"
                                    @click:append="pswdShow = !pswdShow"
                                    @keyup.enter="preCnfLogin"
                                    label="비밀번호" outlined>
                                </v-text-field>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                    </v-form>
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-spacer></v-spacer>
                        <v-col class="d-flex align-center" cols="8">
                            <v-checkbox
                                class="dftChcBox-Theme authCheck pt-0" style="margin-top: 0px !important;"
                                v-model="authCheck" true-value="1" false-value="2"
                                label="정보 유지하기" :key="forceRender" hide-details>
                            </v-checkbox>
                            <v-spacer></v-spacer>
                            <v-btn class="pa-0" color="authTxtClr" @click="$parent.$parent.$parent.authStep=3" text>아이디 및 비밀번호를 잊으셨나요?</v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="mt-7 pa-0" no-gutters>
                        <v-spacer></v-spacer>
                        <v-col class="d-flex align-center" cols="8">
                            <v-btn class="py-5" width="100%" color="#3688c1" @click="preCnfLogin">
                                <v-icon class="white--text" style="padding-top: 3px;" size="22">mdi-login-variant</v-icon>
                                <span class="white--text ml-4" style="font-size: 1.3rem;">로그인</span>
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="mt-7 pa-0" no-gutters>
                        <v-spacer></v-spacer>
                        <v-col class="" cols="8">
                            <v-divider class=""></v-divider>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="mt-3 pa-0" no-gutters>
                        <v-spacer></v-spacer>
                        <v-col class="d-flex justify-center align-center" cols="8">
                            <span class="mr-4 authTxtClr--text" style="font-size: 0.975rem;">처음 방문하셨나요?</span>
                            <v-btn
                                class="authBtn" color="#dee1e9" height="32"
                                @click="$parent.$parent.$parent.authStep=1"
                                outlined rounded>
                                계정생성
                            </v-btn>
                            <v-spacer></v-spacer>
                            <span
                                v-if="!deferredPrompt"
                                class="mr-4 authTxtClr--text"
                                style="font-size: 0.975rem;">
                                바탕화면에
                            </span>
                            <v-btn
                                v-if="!deferredPrompt"
                                class="authBtn" color="#dee1e9" height="32"
                                @click="clickCallback"
                                outlined rounded>
                                추가
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <span
                        class="d-inline-block"
                        style="width: 20px; height: 100%; position: absolute; left: 0; top: 0;">
                    </span>
                </span>
            </div>
        </v-col>
        <v-spacer></v-spacer>
    </v-row>
</template>

<script>
import { http } from '@/api/baseAxios';
import VueJwtDecode from 'vue-jwt-decode'

export default {
    name: 'AuthLogin',

    components: {

    },

    computed:{

    },
    created: function(){

    },
    mounted: function(){
        this.$nextTick(function () {
            // Service Worker
            this.captureEvent()

            // 쿠키정보 존재하면 세팅
            if(this.$cookies.get("keepFclty") != null)
                this.authInfo.fcltyNum = this.$cookies.get("keepFclty")
            if(this.$cookies.get("keepId") != null)
                this.authInfo.id = this.$cookies.get("keepId")

            this.authCheck = this.$cookies.get("keepAuth")
            this.forceRender += 1
        })
    },

    methods: {
        //로그인
        cnfLogin: function (obj){
            http.post('auth/login', obj)
                .then((response) => ( this.cnfLoginAfter(response.data) ))
                .catch((error) => console.log('axios error /auth/login : ' + error))
        },
        cnfLoginAfter: function (res){
            if(res.statusCode == '200'){
                let jwtDecode = null

                //로그인 인증 토큰 복호화
                let bytes = this.$crypto.AES.decrypt(res.token, this.$crypto.enc.Hex.parse(res.key), {
                    iv: this.$crypto.enc.Hex.parse("000102030405060708090A0B0C0D0E0F"),
                    padding: this.$crypto.pad.Pkcs7,
                    mode: this.$crypto.mode.CBC
                })

                //토큰정보를 얻어옴
                jwtDecode = VueJwtDecode.decode(bytes.toString(this.$crypto.enc.Utf8))

                //토큰정보를 vuex에 저장
                this.$store.commit('setAuthToken', bytes.toString(this.$crypto.enc.Utf8))
                this.$store.commit('setFcltyNum', jwtDecode.fcltyNum)
                this.$store.commit('setUserNm', decodeURI(jwtDecode.name))
                this.$store.commit('setSsMmbrPk', jwtDecode.mmbrPk)
                this.$store.commit('setExpTime', jwtDecode.exp)
                this.$store.commit('setJobNm', decodeURI(jwtDecode.jobNm))
                this.$store.commit('setNtyAct', true)

                //권한정보를 조회
                if(res.auths != null){
                    //권한정보를 vuex에 저장

                    this.$store.commit('setAcesAthrt', res.auths)

                    //페이지 이동
                    if(res.auths.length > 0)
                        this.$router.push({ name: res.auths[0].viewId })
                    else
                        this.$parent.$parent.$parent.snackControll([true, 5000, '이동할 페이지 정보가 없습니다.', 'error'])
                }       
            }
            else{
                this.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
            }
        },
        preCnfLogin: function (){
            let chc = this.$refs.authLogin.validate()

            this.$cookies.set("keepAuth", this.authCheck)
            if(this.authCheck == '1'){
                this.$cookies.set("keepFclty", this.authInfo.fcltyNum)
                this.$cookies.set("keepId", this.authInfo.id)
            }

            else{
                this.$cookies.remove("keepFclty")
                this.$cookies.remove("keepId")
            }
                
            if(chc)
                this.cnfLogin(this.authInfo)
        },

        captureEvent: function (){
            window.addEventListener('beforeinstallprompt', (e) => {
                // Prevent Chrome 67 and earlier from automatically showing the prompt
                e.preventDefault()
                // Stash the event so it can be triggered later.
                this.deferredPrompt = e
            })
        },
        clickCallback: function (){
            // Show the prompt
            this.deferredPrompt.prompt()
            // Wait for the user to respond to the prompt
            this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                // Add analyticcs event
                this.$gtag.event('add_to_home_screen')
                }
                this.deferredPrompt = null
            })
        },
    },

    data: () => ({
        forceRender: 0,
        deferredPrompt: null,
        authCheck: '2',
        pswdShow: false,
        authInfo: { fcltyNum: '', id: '', passwd: '' },
        rules: {
            fcltyConfirm: value => value.length == 11 || '잘못된 기관코드 입니다.',
            numberCheck: value => /^[0-9]*$/.test(value) || '숫자만 입력 가능합니다.',
            required: value => !!value || '필수입력 항목 입니다.',
        },
        iv: [ 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00 ],
    }),
};
</script>