<template>
    <v-sheet class="ml-0">
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <v-form class="mt-2" ref="tabform" lazy-validation>              
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center pb-2" cols="10">
                <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold ml-3">본인부담금 입금정보</span>
            </v-col>
            <v-col class="d-flex align-center" cols="2">
                <v-spacer></v-spacer>
                <v-btn
                    class="ml-4" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"  
                    @click="insFcltyDpsInf"             
                    min-width="30" height="25" small outlined>
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                <v-icon size="20">mdi-content-save-edit-outline</v-icon>
                                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">저장</span>
                            </div>  
                        </template>
                        <span>본인부담금 입금정보 저장</span>
                    </v-tooltip>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider> 
        <v-row class="pa-0" no-gutters>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">청구기준</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span class="d-inline-block" style="width: 90%;">
                    <span style="width: 30%" class="">선청구</span>
                    <!-- <v-radio-group v-model="items.clmStdCd" :rules="[rules.required]" class="pl-2 pt-1"
                        hide-details row>
                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="선청구" value="1"></v-radio>
                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="후청구" value="2"></v-radio>
                    </v-radio-group> -->
                </span>
            </v-col>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">진료비/약제비 청구기준</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="6">
                <span class="d-inline-block" style="width: 90%;">
                    <span style="width: 30%" class="ml-2">후청구</span>
                    <!-- <v-radio-group v-model="items.clmStdClncCd" :rules="[rules.required]" class="pl-2 pt-1"
                        hide-details row>
                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="선청구" value='1'></v-radio>
                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="후청구" value='2'></v-radio>
                    </v-radio-group> -->
                </span>
            </v-col>            
        </v-row>  
        <v-divider class=""></v-divider> 
        <v-row no-gutters>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">납부방법</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 150px">
                    <v-select dense hide-details outlined style="margin-top: 3px;" v-model="items.dpsMthdCd"
                        :items="codeItem" item-text="valcdnm" item-value="valcd"
                        :rules="[rules.required]"></v-select>
                </span>
            </v-col>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">납부일</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="6">
                    <span class="">매월</span>
                    <span style="width: 50px" class="ml-2">
                        <v-text-field
                            v-model="items.dpsDt" :rules="[rules.required]"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="ml-2">일</span>
            </v-col>            
        </v-row>  
        <v-divider class=""></v-divider> 
        <v-row no-gutters>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">입금은행</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 190px">
                    <v-text-field
                        v-model="items.dpsBnkNm1" :rules="[rules.required]"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">계좌번호</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 190px">
                    <v-text-field
                        v-model="items.dpsBnkAcct1" :rules="[rules.required]"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>    
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">예금주명</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 190px">
                    <v-text-field
                        v-model="items.dpsBnkHld1" :rules="[rules.required]"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>            
        </v-row>  
        <v-divider class=""></v-divider>
        <v-row no-gutters>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">입금은행</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 190px">
                    <v-text-field
                        v-model="items.dpsBnkNm2"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">계좌번호</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 190px">
                    <v-text-field
                        v-model="items.dpsBnkAcct2"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>    
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">예금주명</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 190px">
                    <v-text-field
                        v-model="items.dpsBnkHld2"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>            
        </v-row>  
        <v-divider class=""></v-divider>
        <v-row no-gutters>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">입금은행</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 190px">
                    <v-text-field
                        v-model="items.dpsBnkNm3"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">계좌번호</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 190px">
                    <v-text-field
                        v-model="items.dpsBnkAcct3"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>    
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">예금주명</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 190px">
                    <v-text-field
                        v-model="items.dpsBnkHld3"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>            
        </v-row>  
        <v-divider class=""></v-divider>
        <v-row no-gutters>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">입금은행</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 190px">
                    <v-text-field
                        v-model="items.dpsBnkNm4"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">계좌번호</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 190px">
                    <v-text-field
                        v-model="items.dpsBnkAcct4"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>    
            <v-col class="greyE01 py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">예금주명</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 190px">
                    <v-text-field
                        v-model="items.dpsBnkHld4"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>            
        </v-row>  
        <v-divider class=""></v-divider> 
        </v-form>
        <v-divider class="my-2" color="white"></v-divider>  
        <v-row no-gutters>
            <v-col cols="12">
                <FacInfoMgmtTab5></FacInfoMgmtTab5>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
import FacInfoMgmtTab5 from './FacInfoMgmtTab5.vue';

import { selFcltyDpsInfInfo, insFcltyDpsInf } from '../../api/opr.js';
import { getStcd } from '../../api/index.js';
export default {
    name: 'FacInfoMgmtTab2',

    props: {
        tab: Number,
    },
    components: {
        FacInfoMgmtTab5
    },

    computed: {
    },

    watch: {
    },

    created: function () {
    },

    mounted: function () {
        this.$nextTick(function () {
            this.selFcltyDpsInfInfo()
            this.onCodeload()
        })
    },

    methods: {
        selFcltyDpsInfInfo: function () {
            this.valClcd = '84'
            this.valClcdnm = '입금방법'
            selFcltyDpsInfInfo(this.$store.getters.getFcltyNum)
            .then((response) => (this.selFcltyDpsInfInfoAfter(response.data)))
            .catch((error) => console.log('connect error opr/selBnftFeeHisList : ' + error))
        },
        selFcltyDpsInfInfoAfter: function (res) {
            if(res != ''){
                this.items = res
                this.$refs.tabform.resetValidation();
            }
        },
        insFcltyDpsInf: function () {
            if (this.$refs.tabform.validate()) {
                let obj = {
                    clmStdCd: 1,
                    clmStdClncCd: 1,
                    dpsBnkAcct1: this.items.dpsBnkAcct1,
                    dpsBnkAcct2: this.items.dpsBnkAcct2,
                    dpsBnkAcct3: this.items.dpsBnkAcct3,
                    dpsBnkAcct4: this.items.dpsBnkAcct4,
                    dpsBnkHld1: this.items.dpsBnkHld1,
                    dpsBnkHld2: this.items.dpsBnkHld2,
                    dpsBnkHld3: this.items.dpsBnkHld3,
                    dpsBnkHld4: this.items.dpsBnkHld4,
                    dpsBnkNm1: this.items.dpsBnkNm1,
                    dpsBnkNm2: this.items.dpsBnkNm2,
                    dpsBnkNm3: this.items.dpsBnkNm3,
                    dpsBnkNm4: this.items.dpsBnkNm4,
                    dpsDt: this.items.dpsDt,
                    dpsMthdCd: this.items.dpsMthdCd,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    userNm: this.$store.getters.getUserNm,
                }
                insFcltyDpsInf(obj)
                    .then((response) => (this.insFcltyDpsInfAfter(response.data)))
                    .catch((error) => console.log('connect error /opr/insFcltyDpsInf : ' + error))
            }else{
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            }
        },
        insFcltyDpsInfAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selFcltyDpsInfInfo()
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.$emit("facInfoTrigger",99)
        },
        onCodeload: function () {
            getStcd('84')
                .then((response) => (this.codeMapping(response.data)))
                .catch((error) => console.log(error))
        },
        codeMapping: function (res) {
            this.codeItem = res
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
        },
        items: {
            clmStdCd: '1',
            clmStdClncCd: '1',
            dpsBnkAcct1: '',
            dpsBnkAcct2: '',
            dpsBnkAcct3: '',
            dpsBnkAcct4: '',
            dpsBnkHld1: '',
            dpsBnkHld2: '',
            dpsBnkHld3: '',
            dpsBnkHld4: '',
            dpsBnkNm1: '',
            dpsBnkNm2: '',
            dpsBnkNm3: '',
            dpsBnkNm4: '',
            dpsDt: '',
            dpsMthdCd: "1",
            fcltyNum: '',
        },
        codeItem: [],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>