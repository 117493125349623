<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">직원&nbsp;{{ midTtl }}&nbsp;처리</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
        </v-row>
        <v-form ref="wrkState" lazy-validation>
            <v-divider class="mt-5"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">직원명</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBL" cols="2">
                    <span class="">{{ prtEmpInfo != null ? prtEmpInfo.name : '' }}</span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">성별&#40;나이&#41;</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBL" cols="2">
                    <span v-if="prtEmpInfo != null">
                        <v-img class="ic-sex" v-if="prtEmpInfo.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                        <v-img class="ic-sex" v-else-if="prtEmpInfo.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                    </span>
                    <span class="ml-1">
                        {{ prtEmpInfo != null ? '( ' + prtEmpInfo.age + ' )' : '' }}
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">생년월일</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL" cols="2">
                    <span class="">{{ prtEmpInfo != null ? prtEmpInfo.rsdntBirth : '' }}</span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">입사일</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBL" cols="2">
                    <span class="">{{ prtEmpInfo != null ? prtEmpInfo.entDtNm : '' }}</span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">퇴사일</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBL" cols="2">
                    <span class="">{{ prtEmpInfo != null ? prtEmpInfo.rtrDtNm : '' }}</span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">담당직종</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL" cols="2">
                    <span class="">{{ prtEmpInfo != null ? prtEmpInfo.jobCd : '' }}</span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-divider class="mt-3"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                    <span class="black--text font-weight-medium">변경할 직원상태</span>
                </v-col>
                <v-col class="tableBL" cols="10">
                    <span class="d-inline-block ml-2 py-2" style="width: 100%; height: 24px;">
                        <v-radio-group
                            class="radio-dft-Theme mt-0 d-flex"
                            v-model="wrkChgInfo.wrkStcd" hide-details row>
                            <v-radio class="pa-0 mr-2" v-for="(cd, w) in wrkCode" :key="w"
                                :ripple="false" :label="cd.valcdnm" :value="cd.valcd" :disabled="cd.ableChk">
                            </v-radio>
                        </v-radio-group>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                    <span class="black--text font-weight-medium" v-if="wrkChgInfo.wrkStcd == '3'">휴직기간</span>
                    <span class="black--text font-weight-medium" v-else-if="wrkChgInfo.wrkStcd == '2'">퇴사일</span>
                    <span class="black--text font-weight-medium" v-else>변경일</span>
                </v-col>
                <v-col class="d-flex align-center tableBL" cols="10">
                    <span class="d-inline-block mx-2" style="width: 133px;">
                        <CmmDateComp
                            v-model="wrkChgInfo.aplStrt"
                            :minDt="prtEmpInfo != null ? $moment(prtEmpInfo.entDt, 'YYYY.MM.DD').format('YYYY-MM-DD') : ''"
                            :required="true">
                        </CmmDateComp>
                    </span>
                    <span class="d-flex align-center mx-2" v-if="wrkChgInfo.wrkStcd == '3'">
                        <v-icon size="16" color="greyE00">mdi-tilde</v-icon>
                    </span>
                    <span class="d-inline-block mx-2" style="width: 133px;" v-if="wrkChgInfo.wrkStcd == '3'">
                        <CmmDateComp
                            v-model="wrkChgInfo.aplEnd" :required="wrkChgInfo.wrkStcd == '3' ? true : false">
                        </CmmDateComp>
                    </span>
                    <span class="red--text" v-if="wrkChgInfo.wrkStcd == '2'">
                        ※ 퇴사일자는 마지막 근무한 다음날로 입력하세요.
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                    <span class="black--text font-weight-medium">사유</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL" cols="10">
                    <span class="d-inline-block px-2" style="width: 100%;">
                        <v-text-field
                            v-model="wrkChgInfo.rsn"
                            height="28" color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                    <span class="black--text font-weight-medium">업무&nbsp;인수인계</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL" cols="10">
                    <span class="d-inline-block px-2" style="width: 100%;">
                        <v-text-field
                            v-model="wrkChgInfo.tknOvr"
                            height="28" color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0 mt-4" no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <span v-if="prtEmpInfo == null ?  false: true">
                        <v-btn
                            v-if="midTtl == '재입사'"
                            class="white--text" color="blueBtnColor" @click="preReEnt()" height="26" samll rounded>
                            <v-icon small>mdi-content-save-outline</v-icon>
                            <span class="fontOneRem">재입사</span>
                        </v-btn>
                        <v-btn
                            v-else
                            class="white--text" color="blueBtnColor" @click="preEmpHis()" height="26" samll rounded>
                            <v-icon small>mdi-content-save-outline</v-icon>
                            <span class="fontOneRem">저장</span>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
        </v-form>
    </v-sheet>
</template>

<script>
import { insEmpHis, insReEmp } from '../../api/emp.js';

import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'WrkChgState',

    props : {
   
    },
        
    components: {
        CmmDateComp,
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$parent.$parent.$parent.$parent.filledBtn[2].visible)
                this.midTtl = '퇴사/휴직'
            else
                this.midTtl = '재입사'

            let cdTmp = this.$parent.$parent.$parent.$parent.$parent.$parent.vCd70
            if(this.wrkCode.length == 0 && cdTmp.length > 0){
                this.wrkCode = Object.assign([], cdTmp)
                this.wrkCode.forEach(el => {
                    el.ableChk = false
                });
            }
        })
    },

    computed: {
        getEmpInfo: function() {
            return this.$parent.$parent.$parent.$parent.$refs.empDtl.empInfo
        },
        getWrkStateCode: function() {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.vCd70
        },
        getBtnInf: function() {
            return this.$parent.$parent.$parent.$parent.filledBtn[2].visible
        },
    },

    watch:{
        getEmpInfo: function(value) {
            this.prtEmpInfo = value
            if(this.prtEmpInfo != null){
                switch (this.prtEmpInfo.wrkStcd) {
                    case '1':
                        this.wrkCode[0].ableChk = true
                        this.wrkCode[1].ableChk = false
                        this.wrkCode[2].ableChk = false
                        this.wrkChgInfo.wrkStcd = '3'
                        break;
                    case '2':
                        this.wrkCode[0].ableChk = false
                        this.wrkCode[1].ableChk = true
                        this.wrkCode[2].ableChk = true
                        this.wrkChgInfo.wrkStcd = '1'
                        break;
                    case '3':
                        this.wrkCode[0].ableChk = false
                        this.wrkCode[1].ableChk = false
                        this.wrkCode[2].ableChk = true
                        this.wrkChgInfo.wrkStcd = '1'
                        break;
                    default:
                        break;
                }
            }
        },
        getWrkStateCode: function(value) {
            this.wrkCode = Object.assign([], value)
            this.wrkCode.forEach(el => {
                el.ableChk = false
            });
        },
        getBtnInf: function(v) {
            if(v)
                this.midTtl = '퇴사/휴직'
            else{
                this.midTtl = '재입사'
            }
        },
    },
    
    methods: {
        setEmpHis: function (obj) {
            insEmpHis(obj)
                .then((response) => ( this.asyncResultChk(response.data) ))
                .catch((error) => console.log('connect error /emp/insEmpHis : ' + error))
        },
        setReEnt: function (obj) {
            insReEmp(obj)
                .then((response) => ( this.asyncResultChk(response.data) ))
                .catch((error) => console.log('connect error /emp/insReEmp : ' + error))
        },
        asyncResultChk: function (res) {
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.clearForm()
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])

            this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk = -1
            this.$parent.$parent.$parent.$parent.$parent.$parent.schChkField.jobCd.splice(0)
            this.$parent.$parent.$parent.$parent.$parent.$parent.schChkField.target = 'wrkStcd'
            this.$parent.$parent.$parent.$parent.$parent.$parent.sltElmnt = '재직'

            this.onClose()
        },
        preEmpHis: function () {
            let obj = Object.assign({}, this.wrkChgInfo)
            let arrTarget = this.$parent.$parent.$parent.$parent.$parent.$parent.empList.filter(v => v.empPk == this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk)

            //convert date format
            obj.aplStrt = obj.aplStrt.afterDateFormatHyp()
            obj.wrkStDt = obj.aplStrt
            if(obj.wrkStcd == '2')
                obj.rtrDt = obj.wrkStDt
            else
                obj.rtrDt = ''

            if(obj.wrkStcd == '3')
                obj.aplEnd = obj.aplEnd.afterDateFormatHyp()
            else
                obj.aplEnd = '29991231'

            //base data add
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.empPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk
            obj.hisClcd = obj.wrkStcd
            obj.hisCdAr = []

            arrTarget.forEach(el => {
                obj.mmbrPk = el.mmbrPk
            });

            //check validate
            //console.log(obj)
            let chk = this.$refs.wrkState.validate()
            if(chk)
                this.setEmpHis(obj)
        },
        preReEnt: function () {
            let obj = Object.assign({}, this.wrkChgInfo)
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.empPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk

            obj.wrkStcd = '5'
            obj.hisClcd = '5'
            obj.hisCdAr = []
            obj.aplStrt = this.$moment(obj.aplStrt, 'YYYY-MM-DD').format('YYYYMMDD')
            obj.wrkStDt = obj.aplStrt
       
            let chk = this.$refs.wrkState.validate()
            if(chk){
                if(this.$moment(obj.aplStrt, 'YYYY-MM-DD').isAfter(this.$moment(this.prtEmpInfo.rtrDt, 'YYYY.MM.DD')))
                    this.setReEnt(obj)
                else
                    this.snackControll([true, 2500, '변경일은 퇴사일 이후만 가능합니다.', 'info'])
            }
            else
                this.snackControll([true, 2500, '필수항목을 확인해 주세요.', 'info'])
            
        },
        clearForm: function () {
            this.wrkChgInfo = Object.assign({
                fcltyNum: 0, empPk: 0, mmbrPk: 0, wrkStDt: '', rtrDt: '', wrkStcd: '3', rsn: '', tknOvr: '',
            })
            this.forceRender += 1
            this.$refs.wrkState.resetValidation()
        },
        onClose: function () {
            this.$emit('modalClose', '2')
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        midTtl: '',
        prtEmpInfo: null,
        menu: [false],
        wrkCode: [],
        forceRender: 0,
        wrkChgInfo: {
            fcltyNum: 0, empPk: 0, mmbrPk: 0, aplStrt: '', aplEnd:'', wrkStDt: '', wrkStcd: '3', rsn: '', tknOvr: '',
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>