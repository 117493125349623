<template>
    <v-sheet class="d-inline" color="midAreaColor">
        <v-btn
            class="rounded py-4 px-2" style="background-color: white;" 
            color="btnBrdrColor" min-width="24" height="22"
            @click="$emit('input', !value)"
            rounded outlined>
            <v-icon class="black--text" size="20">mdi-text-search-variant</v-icon>
            <span class="fontOneRem black--text" style="margin-left: 2px;">{{title}}</span>
        </v-btn>
        <v-expand-transition>
            <v-card
                v-show="value"
                class="pl-2 pr-1" style="position: absolute; z-index: 11;"
                min-width="320" max-width="360" max-height="670"
                outlined>
                <v-row class="pa-0 my-2 align-center" no-gutters>
                    <span class="d-inline-block ml-1 pb-1" style="width: 92px; height: 32px;">
                        <v-checkbox
                            v-model="isAll"  
                            @click="allApply()"                          
                            class="expnChcBtn-Theme"
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details="true" label="전체" dense>
                        </v-checkbox>
                    </span>
                    <v-spacer></v-spacer>
                    <span class="mr-2" style="padding-top: 8px;">
                        <v-btn class="" @click="$emit('input', !value)" small icon>
                            <v-icon size="20">mdi-close-thick</v-icon>
                        </v-btn>
                    </span>
                </v-row>
                <div class="overflow-y-auto mb-2" style="max-height: 610px; min-height: 320px;">
                    <div
                        class="posn-rltv brdSurrd rounded px-3 py-2 mt-4 mb-6 mr-1">                        
                        <div>                            
                            <v-row class="pa-0" no-gutters>
                                <v-col
                                    v-for="(itm, k) in propList"
                                    :key="k" cols="6">
                                    <span class="d-inline-block" style="width: 135px; height: 28px;">
                                        <v-checkbox                 
                                            v-model="itm.isOn"      
                                            @click="ctrlArrApply()"                                                                 
                                            class="expnChcBtn-Theme"
                                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            :label="itm.valcdnm"
                                            hide-details="true" dense>
                                        </v-checkbox>
                                    </span>
                                </v-col>
                            </v-row>                           
                        </div>
                    </div>
                </div>
            </v-card>
        </v-expand-transition>
    </v-sheet>
</template>

<script>

export default {
    name: 'CmmPrgrmBtn',

    props : {
        value   : { type: Boolean, default: false },               // 메뉴 활성여부
        title   : { type:String, default:''},
        list    : { type: Array, default: () => { return [] } }
    },
    
    components: {
       
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
           
        })
    },

    watch: {
        'list':function(v){
            if(v.length > 0) this.setList(v)
        }
    },

    methods: {
        //리스트 셋팅
        setList:function(olist){
            this.propList.splice(0)

            olist.forEach(itm => {
                let obj = {
                    isOn    : false,
                    valcd   : itm.valcd,
                    valcdnm : itm.valcdnm,
                }
                this.propList.push(obj)
            });
        },

        // 체크박스 전체 선택 이벤트
        allApply: function() {
            this.propList.forEach(itm => {
                itm.isOn = this.isAll
            });

            this.sttsUpdt()
        },

        // 체크박스 클릭 이벤트
        ctrlArrApply: function() {
            let arr = this.propList.filter( v => v.isOn == true )

            if(arr.length == this.propList.length)
                this.isAll = true
            else
                this.isAll = false

            if(arr.length == 0) this.isAll = true

            this.sttsUpdt()
        },

        // 체크박스 변경사항 전달
        sttsUpdt: function() {
            if(this.sttList.length != this.propList.length){
                this.propList.forEach(itm => {
                    let obj = {}
                    obj.valcd = itm.valcd
                    obj.isOn = itm.isOn
                    this.sttList.push(obj)
                });
            }
            else{
                this.sttList.forEach((itm, i) => {
                    itm.isOn = this.propList[i].isOn
                });
            }

            if(!this.isAll){
                let chc = this.sttList.findIndex( v => v.valcd == null )
                if(chc > -1)
                    this.sttList[chc].isOn = false
            }
            
            this.$emit('onList', this.sttList)
        },
    },

    data: () => ({
        isAll: true,                // 전체 선택   
        propList:[],
        sttList: [],
    }),
};
</script>