<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">{{inqYYMM?.beforeDateFormatMonthDot()}}&nbsp;임금명세서&nbsp;발송</span>
            <v-spacer></v-spacer>                        
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>  
        <div class="mt-2" style="height:650px;">
            <v-tabs
                v-model="tab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab class="fmlySend" v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <div>
                <v-tabs-items v-model="tab">
                    <v-tab-item eager transition="fade-transition">
                        <EmpWageStmtMsgAlim ref="empWageStmtMsgAlim" :empWageloader="empWageloader" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></EmpWageStmtMsgAlim>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <EmpWageStmtMsgSms ref="empWageStmtMsgSms" :empWageloader="empWageloader" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></EmpWageStmtMsgSms>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <EmpWageStmtMsgEmail ref="empWageStmtMsgEmail" :empWageloader="empWageloader" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></EmpWageStmtMsgEmail>
                    </v-tab-item>
                    <v-tab-item transition="false">
                        <EmpWageStmtMsgHist v-if="tab == 3" ref="empWageStmtMsgHist"></EmpWageStmtMsgHist>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>         
    </v-sheet>
</template>
<script>
import EmpWageStmtMsgAlim from './EmpWageStmtMsgAlim.vue'
import EmpWageStmtMsgSms from './EmpWageStmtMsgSms.vue'
import EmpWageStmtMsgEmail from './EmpWageStmtMsgEmail.vue'
import EmpWageStmtMsgHist from './EmpWageStmtMsgHist.vue'
import { selWgeNtcSndList } from '../../api/impExp.js';

export default {
    name: 'EmpWageStmtMsgSend',
    
    components: {  
        EmpWageStmtMsgAlim,
        EmpWageStmtMsgSms,
        EmpWageStmtMsgEmail,
        EmpWageStmtMsgHist,
    },

    props : {
        date: { type: String, default: '' },
        empWageStmtMsgSendPk : { type: Number, default: 0 },        
    },

    computed: {
        
    },

    created: function(){ 
    },

    mounted: function(){
        this.$nextTick(function () {
            this.inqYYMM = this.date            
            this.getWgeNtcSndList()
        })
    },

    watch: {
        'empWageStmtMsgSendPk':function(value){
            this.inqYYMM = this.date            
            if(value>0) this.getWgeNtcSndList()
        }
    },

    methods: {   
        onLoad:function(){
            this.getWgeNtcSndList()
        },    
        onClose: function () {
            this.tab=0
            this.$emit('modalEvent', 1)
        },       
        getEmpMsgPickerDate: function (date) {
            this.inqYYMM = date
            this.getWgeNtcSndList()
        },
        getWgeNtcSndList: function(){
            if(this.dataCall){
                this.empWageloader = true
                this.dataCall = false
                
                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    inqYYMM:this.inqYYMM,
                    valClcdS: ['42']
                }

                selWgeNtcSndList(obj)
                    .then((response) => ( this.getWgeNtcSndListAfter(response.data) ))
                    .catch((error) => console.log('connect error /impExp/selWgeNtcSndList : ' + error))
            }
        },
        getWgeNtcSndListAfter:function(res){
            this.empWageloader = false
            this.vCd42 = res.code.vCd42
            this.empMsgList.splice(0)

            if(res.list != null){
                res.list.forEach(el => {
                    el.yymm     = this.inqYYMM 
                    el.lnkUrl   = this.encUrlObj(el.empPk, this.inqYYMM)   
                    this.empMsgList.push(el)
                });
            }

            this.$refs.empWageStmtMsgAlim.setData(this.empMsgList)
            this.$refs.empWageStmtMsgSms.setData(this.empMsgList)
            this.$refs.empWageStmtMsgEmail.setData(this.empMsgList)
            this.dataCall = true
        },        
        msgTrigger:function(msg){
            this.snackControll([true, 2500, msg, 'warning'])
        },
        mdlTrigger:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getWgeNtcSndList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },

        //Url Encrypt
        encUrlObj: function(pk, yymm){
            let linkURI = ''

            //개발, 운영 모드에 따라 링크 URL을 환경변수에서 불러옴
            switch (process.env.NODE_ENV) {
                case 'development':
                    linkURI = process.env.VUE_APP_MOBILE_DEV_URI
                    break;
                case 'production':
                    linkURI = process.env.VUE_APP_MOBILE_PROD_URI
                    break;
                default:
                    break;
            }

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.empPk = pk
            obj.inqYYMM = yymm

            //오브젝트를 JSON 직렬화를 사용해 암호화 (문자열만 암호화 가능)
            let encrypted = this.$crypto.AES.encrypt(JSON.stringify(obj), this.$store.getters.getCrypKey)
            let bs64 = this.$crypto.enc.Base64.stringify(this.$crypto.enc.Utf8.parse(encrypted.toString()))
            return linkURI + 'out/empWage?dstnt=' + bs64
        },
    },
    data: () => ({   
        dataCall:true, 
        inqYYMM:'',                
        empMsgList:[],
        empWageloader:false,
        filledBtn: [
            // { icon: 'mdi-arrow-expand-right', class: 'ml-1 white--text', color:'blueBtnColor', text: '발송', type: 'action', width:'800px', disabled: false, },
        ],
        outlinedBtn: [            
        ],
        tab:0,
        tabItems: [
            { index: 0, tab: '알림톡', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 1, tab: '문자', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 2, tab: 'E-MAIL', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 3, tab: '발송내역', class: 'fontOneDotTwoRem px-15', enter: false },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>