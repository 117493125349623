<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">         
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">프로그램&nbsp;선택</span>
            <v-spacer></v-spacer> 
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex justify-start align-center" cols="12">
                <CmmPrgrmBtn
                    v-model="isShtTypStt"
                    class="ml-1"
                    :title="'유형'"
                    :list="tblUse.vCd133"
                    @onList="shtTypApply">
                </CmmPrgrmBtn>
                <span class="d-inline-block pl-2" style="width: 200px; height: 34px;">
                    <v-text-field
                        v-model="prgInfParams.srchWd1" @input="getPage(1)"
                        class="txt-Theme" height="34" label="프로그램"
                        background-color="white" prepend-inner-icon="mdi-magnify"
                        single-line outlined dense filled hide-details>
                    </v-text-field>
                </span>
                <v-spacer></v-spacer>
            </v-col>
        </v-row>
        <v-divider class="mt-3" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-data-table class="dft-hvr-tbl" style="position: relative;" locale="ko"
                :height="460"
                :headers="tblUse.headers" :items="tblUse.serverItems" 
                disable-pagination hide-default-header fixed-header hide-default-footer dense>
                <template v-slot:header="props">
                    <th class="black--text bottomBorder pa-3" style="width: 200px;">{{ props.props.headers[0].text }}</th>
                    <th class="black--text bottomBorder pa-3" style="width: 200px;">{{ props.props.headers[1].text }}</th>
                    <th class="black--text bottomBorder pa-3" style="width: 400px;">{{ props.props.headers[2].text }}</th>
                </template>
                
                <template v-slot:item='{ item }'>
                    <tr @click="aplPrgInfo(item)"
                        class="fixedCol disSltRow">
                        <td class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                            <span class="cmmFontSizeOne">{{item.prgTypNm}}</span>
                        </td>     
                        <td class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                            <span class="cmmFontSizeOne">{{item.idxCdNm}}</span>
                        </td>   
                        <td class="fixedCol0 tableBT removeTableBB pa-0" style="height:40px !important; text-align: center;">
                            <span class="cmmFontSizeOne">{{item.prgNm}}</span>
                        </td>                          
                    </tr>
                </template>
            </v-data-table>            
        </v-row>


        <div class="mt-2">            
            <v-pagination
                v-model="tblUse.page"
                :length="tblUse.pageCount"
                @input="getPage"
                total-visible="5">
            </v-pagination>
        </div>
    </v-sheet>
</template>
<script>
import CmmPrgrmBtn from '../../components/commons/CmmPrgrmBtn.vue';

import { http } from '@/api/baseAxios';

export default {
    name: 'PrgrmCmmSelect',
    
    components: {  
        CmmPrgrmBtn
    },

    props : {
        
    },

    computed: {
        
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {  
            let obj = {}
            obj.page = 1
            obj.itemsPerPage = this.prgInfParams.rnLmt
            this.getCdPrgInfShtlist(obj)
        })
    },

    watch: {
        
    },

    methods: {  
        
        onClose: function () {
            this.$emit('isClose')
        },

       // 프로그램정보 활동지 다건 조회
       getCdPrgInfShtlist: function (obj){    

            this.tblUse.serverItems.splice(0)
            this.tblUse.prgTyplist.splice(0)
            this.tblUse.vCd38.splice(0)
            this.tblUse.vCd40.splice(0)            
            this.tblUse.vCd133.splice(0)
            this.tblUse.vCd134.splice(0)
            this.tblUse.vCd135.splice(0)

            this.tblUse.loading = true
            this.prgInfParams.rnStrt = ((obj.page-1) * obj.itemsPerPage)

            http.post('prgrm/selCdPrgInfShtlist', this.prgInfParams)
                .then((response) => ( this.prgInfShtRstCd(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgInfShtlist : ' + error))
        },

        //프로그램정보 활동지 결과 반영
        prgInfShtRstCd:function(res){

            this.tblUse.loading = false
            if(res.statusCode == '200'){
                this.tblUse.totalItems = res.result.rnTot

                this.tblUse.pageCount = Math.ceil(Number(res.result.rnTot)/this.prgInfParams.rnLmt)


                if(res.result.prgTyplist != null && res.result.prgTyplist != undefined){
                    res.result.prgTyplist.forEach(itm => {
                        this.tblUse.prgTyplist.push(itm)
                    });
                } 
                
                if(res.result.codes != null){
                    if(res.result.codes.vCd38 != undefined) {
                        res.result.codes.vCd38.forEach(cd => {
                            this.tblUse.vCd38.push(cd)                            
                        });
                    }

                    if(res.result.codes.vCd40 != undefined) {
                        res.result.codes.vCd40.forEach(cd => {
                            this.tblUse.vCd40.push(cd)                            
                        });
                    }

                    if(res.result.codes.vCd133 != undefined) {
                        res.result.codes.vCd133.forEach(cd => {
                            this.tblUse.vCd133.push(cd)                            
                        });
                    }

                    if(res.result.codes.vCd134 != undefined) {
                        res.result.codes.vCd134.forEach(cd => {
                            this.tblUse.vCd134.push(cd)
                        });
                    }

                    if(res.result.codes.vCd135 != undefined) {
                        res.result.codes.vCd135.forEach(cd => {
                            this.tblUse.vCd135.push(cd)
                        });
                    }

                }

                res.result.list.forEach(itm => {        

                    this.tblUse.prgTyplist.forEach(cd => {
                        if(cd.prgTypMngPk == itm.prgTypMngPk) {
                            itm.prgTypNm = cd.prgTypNm
                            itm.idxCd = cd.idxCd
                        }
                    });

                    if(itm.idxCd != undefined){

                        this.tblUse.vCd40.forEach(cd => {
                            if(cd.valcd == itm.idxCd) itm.idxCdNm = cd.valcdnm
                        });
                    }

                    this.tblUse.serverItems.push(itm)
                })
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 프로그램정보 활동지 다건 조회
        getPrgInfShtlist: function (obj){    

            this.tblUse.serverItems.splice(0)

            this.tblUse.loading = true
            this.prgInfParams.rnStrt = ((obj.page-1) * obj.itemsPerPage)


            http.post('prgrm/selPrgInfShtlist', this.prgInfParams)
                .then((response) => ( this.prgInfShtRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgInfShtlist : ' + error))
        },

        //프로그램정보 활동지 결과 반영
        prgInfShtRst:function(res){

            this.tblUse.loading = false
            if(res.statusCode == '200'){
                this.tblUse.totalItems = res.result.rnTot

                this.tblUse.pageCount = Math.ceil(Number(res.result.rnTot)/this.prgInfParams.rnLmt)

                res.result.list.forEach(itm => {        

                    this.tblUse.prgTyplist.forEach(cd => {
                        if(cd.prgTypMngPk == itm.prgTypMngPk) {
                            itm.prgTypNm = cd.prgTypNm
                            itm.idxCd = cd.idxCd
                        }
                    });

                    if(itm.idxCd != undefined){

                        this.tblUse.vCd40.forEach(cd => {
                            if(cd.valcd == itm.idxCd) itm.idxCdNm = cd.valcdnm
                        });
                    }

                    this.tblUse.serverItems.push(itm)
                })
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        //유형 검색 적용
        shtTypApply:function (arr){
            this.prgInfParams.shtTyp.splice(0)
            arr.forEach(itm => {
                if(itm.isOn) this.prgInfParams.shtTyp.push(itm.valcd)
            });
            this.getPage(1)
        },

        //페이지 이동
        getPage:function(val){
            this.prgInfParams.page = val
            let obj = {}
            obj.page = this.prgInfParams.page
            obj.itemsPerPage = this.prgInfParams.rnLmt
            this.getPrgInfShtlist(obj)
        },
        
        //프로그램 적용
        aplPrgInfo:function(itm){
            this.$emit('setPrgInfo', itm)
            this.onClose()
        }
    },
    data: () => ({   
        sltObj          : {},
        prgInfParams:{
            rnStrt      : 0,
            rnLmt       : 10,
            srchWd1     : '',
            shtTyp      :[],
            shtMda      :[],
            shtSbjt     :[],
            shtTyps     :[],
            shtMdas     :[],
            shtSbjts    :[],
        },
        tblUse:{
            loading         : false,
            totalItems      : 0,
            prgTyplist      : [],
            vCd38           : [],
            vCd40           : [],
            vCd133          : [],
            vCd134          : [],
            vCd135          : [],
            serverItems     : [],
            page            : 1,
            pageCount       : 0,
            headers:[
                { text: '유형', key: 'prgTypNm', sortable: false, align: 'center', minWidth: '150' },
                { text: '지표', key: 'idxCdNm', sortable: false, align: 'center', minWidth: '120' },  
                { text: '프로그램', key: 'prgNm', sortable: false, align: 'center', width: '300' },
            ],
        },
        isShtTypStt     : false,
        isShtMdaStt     : false,
        isShtSbjtStt    : false,
    }),
};
</script>