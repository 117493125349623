<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col cols="5">
                <span class="text-h4 font-weight-bold">수급자 선택</span>                
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-right" cols="4">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-4" no-gutters>  
            <span class="mr-1">
                <CmmLvnExpnBtn                
                    v-model="isLvnStt"                
                    @onList="lvnSttApply">
                </CmmLvnExpnBtn>
            </span>          
                
            <span  style="width:150px;">
                <v-text-field
                    class="ma-0 pa-0 nameSearch" width="100px" height="35px"
                    label="이름조회" background-color="white" append-icon="mdi-magnify"
                    @input="setSearchText" 
                    single-line rounded outlined dense filled hide-details>
                </v-text-field>
            </span>
        </v-row>
        <v-row class="pa-0 mt-3" no-gutters>
            <v-col cols="12">
                <v-data-table
                    :headers="bnfcrHeaders"
                    :items="bnfcrList"
                    :loading="loader" loading-text="Loading... Please wait"
                    :search="schDummy" :custom-filter="customFilter"
                    fixed-header hide-default-header hide-default-footer dense disable-pagination                         
                    height='580'
                >
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th class="black--text clmsDtlHeaderCommon py-1 tableBT tableBR" style="width:10%;" cols="1">
                                    <span class="d-inline-block ml-1" style="width: 26px;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            v-model="allChckr" :key="forceRender" @click="allChnger"
                                            true-value="1" false-value="2"
                                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:20%;">{{props.headers[0]?.text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:15%;">{{props.headers[1]?.text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:15%;">{{props.headers[2]?.text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:20%;">{{props.headers[3]?.text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT" style="width:20%;">{{props.headers[4]?.text}}</th>                               
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr>
                            <td class="clmsBodyCommon tableBR pa-0">
                                <span class="d-inline-block ml-1" style="width: 26px;">
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                        v-model="item.checkr" :disabled="item.disChekr" :key="forceRender" @change="allChekrFunc"
                                        true-value="1" false-value="2"
                                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                        hide-details>
                                    </v-checkbox>
                                </span>
                            </td>
                            <td class="clmsBodyCommon tableBR py-2">{{item.name}}</td>
                            <td class="clmsBodyCommon tableBR py-2">
                                <div class="d-flex justify-center">
                                    <div>
                                        <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                        <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                    </div>
                                </div>
                            </td>
                            <td class="clmsBodyCommon tableBR py-2">{{ item.age }}</td>
                            <td class="clmsBodyCommon tableBR py-2">{{ item.entStcd }}</td>
                            <td class="clmsBodyCommon py-2">{{ item.lvnRmNm }}</td>  
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row class="mt-1 pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="mr-2__5" cols="2">                
                <span class="fontEighthRem">
                    기준일&#58;&nbsp;{{ $moment(prtWrtDt,'YYYYMMDD').format('YYYY.MM.DD') }}&nbsp;
                </span>
            </v-col>
        </v-row>
        <v-row class="mt-4 pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" @click="intgrThrw" height="30" samll rounded>
                    <v-icon>mdi-account-plus</v-icon>
                    <span class="fontOneDotOneRem ml-2" style="padding-bottom: 2px;">선택</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import CmmLvnExpnBtn from '../../components/commons/CmmLvnExpnBtn';
import { selBnfcrCntctSttList } from '../../api/bnfcr.js';

export default {
    name: 'BnfcrOnlySelector',

    props : {
        prtWrtDt: { type: String, default: '' },
        cd41: { type: Array, default: () => { return [] } },
        cd42: { type: Array, default: () => { return [] } },
        cd65: { type: Array, default: () => { return [] } },
        fixList: { type: Array, default: () => { return [] } },
    },
            
    components: {
        CmmLvnExpnBtn
    },

    created: function(){
   
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getBnfcrCntctSttList()
        })
    },

    computed: {

    },

    watch: {
        
    },
        
    methods: {
        // 수급자 정보 입소현황 다건 조회 (일자별)
        getBnfcrCntctSttList: function () {
            this.bnfcrList.splice(0)
     
            selBnfcrCntctSttList({ fcltyNum:this.$store.getters.getFcltyNum, inqYMD: this.prtWrtDt })
                .then((response) => ( this.getBnfcrCntctListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selBnfcrCntctSttList : ' + error))
        },

        getBnfcrCntctListAfter: function (res) {            
            this.forceRender += 1

            res.forEach(item => {
                let findIdx = this.fixList.findIndex(v=>v.mmbrpk == item.bnMmbrPk)

                if(findIdx > -1){
                    item.disChekr = true
                    item.checkr = '1'
                } else {
                    item.disChekr = false
                    item.checkr = '2'
                }

                item.dtTyp = 'bnfcr'

                this.cd65.forEach(cd => {
                    if(cd.valcd == item.entStcd) item.entStcd = cd.valcdnm
                });
            });

            this.bnfcrList = res
        },

        onClose: function () {
            this.$emit('onClose', '0')
        },

        filterSearchD1: function (sch) {
            if(sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },

        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },

        expandClose: function (index) {
            switch(index) {
                case 0: this.$refs.exBtnClose1.isClose()
                    break;
                default: this.$refs.exBtnClose0.isClose()                    
                    break;
            }

        },

        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column

            let col0 = filter.name.includes(this.schField)
            //console.log('text : ' + this.schField)
            

            let col1 = true
            // 생활실 검색
            let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )
            if(lvnRunChc.length == 0)
                col1 = false
            else{
                lvnRunChc.forEach((itm, i) => {
                    if(i == 0) col1 = filter.lvnRmInfPk == itm.lvnRmInfPk
                    else col1 = col1 || filter.lvnRmInfPk == itm.lvnRmInfPk
                });
            }

            return col0 && col1;
        },

        allChnger: function () {
            this.getSelctList()

            if(this.allChckr == '1') {
                this.bnfcrList.filter(x=>this.selctList.indexOf(x.bnMmbrPk) > -1).forEach(item => {

                    if(item.disChekr == false) item.checkr = '1'

                });
            }
            else if(this.allChckr == '2') {
                this.bnfcrList.filter(x=>this.selctList.indexOf(x.bnMmbrPk) > -1).forEach(item => {
                    if(item.disChekr == false) item.checkr = '2'
                });
            }
        },

        getSelctList:function(){            
            this.selctList.splice(0)
            this.bnfcrList.forEach(el => {
                let bl1 = true
                let bl2 = true

                let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )

                if(lvnRunChc.length == 0)
                    bl1 = false
                else{
                    lvnRunChc.forEach((itm, i) => {
                        if(i == 0) bl1 = el.lvnRmInfPk == itm.lvnRmInfPk
                        else bl1 = bl1 || el.lvnRmInfPk == itm.lvnRmInfPk
                    });
                }

                if(this.schField != ''){
                    if(!el.name.includes(this.schField)) bl2 = false
                }

                if(bl1 && bl2) this.selctList.push(el.bnMmbrPk)
            })                 
        },

        allChekrFunc: function () {
            let tCh = '1'
            this.bnfcrList.forEach(el => {
                if(el.checkr == '2') tCh = '2'
            });
            this.allChckr = tCh
        },

        intgrThrw: function () {
            let list = []

            let arr = this.bnfcrList.filter(v => v.disChekr == false)
            list = arr.filter(v => v.checkr == '1')

            this.$emit('intgrResList', list)
            this.onClose()
        },

         // 생활실 선택 컴포넌트 변경 이벤트
         lvnSttApply: function (arr){
            this.aslLvnStt = arr
            this.schDummy = String(Number(this.schDummy)+1)
        },
    },
    
    data: () => ({
        forceRender:0,
        allChckr:'2',
        schField: '',
        schDummy: '',
        selctList:[],
        schChkField1: [],        
        loader:false,
        bnfcrHeaders: [            
            { text: '수급자', value: 'name', sortable: true, width: '90', class: 'black--text bottomBorder fixedPstn1 bnfcrMidIndex'},
            { text: '성별', value: 'gndr', sortable: true, width: '80', class: 'black--text bottomBorder'},
            { text: '나이', value: 'age', sortable: true, width: '80', class: 'black--text bottomBorder'},
            { text: '현황', value: 'entStcd', sortable: true, width: '80', class: 'black--text bottomBorder'},
            { text: '생활실', value: 'lvnRmNm', sortable: true, width: '90', class: 'black--text bottomBorder'},
        ],
        bnfcrList:[],
        isLvnStt            : false,                            // 생활실 확장 활성화 여부
        aslLvnStt           : [],
    }),
}
</script>
