<template>
    <v-sheet class="d-inline" color="midAreaColor">
        <v-btn
            class="rounded py-4 px-2" style="background-color: white;" 
            color="btnBrdrColor" min-width="24" height="22"
            @click="$emit('input', !value)"
            rounded outlined>
            <v-img
                class="ic-notice black--text"
                :src="require(`@/assets/icon/ic-bed.svg`)">
            </v-img>
            <span class="fontOneRem black--text" style="margin-left: 2px;">생활실</span>
        </v-btn>
        <v-expand-transition>
            <v-card
                v-show="value"
                class="pl-2 pr-1" style="position: absolute; z-index: 11;"
                min-width="320" max-width="360" max-height="670"
                outlined>
                <v-row class="pa-0 my-2 align-center" no-gutters>
                    <span class="d-inline-block ml-1 pb-1" style="width: 92px; height: 32px;">
                        <v-checkbox
                            v-model="isAll"
                            @click="allApply()"
                            class="expnChcBtn-Theme"
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details="true" label="전체" dense>
                        </v-checkbox>
                    </span>
                    <v-spacer></v-spacer>
                    <span class="mr-2" style="padding-top: 8px;">
                        <v-btn class="" @click="$emit('input', !value)" small icon>
                            <v-icon size="20">mdi-close-thick</v-icon>
                        </v-btn>
                    </span>
                </v-row>
                <div class="overflow-y-auto mb-2" style="max-height: 550px; min-height: 320px;">
                    <div
                        v-for="(arr, i) in bldList" :key="i"
                        class="posn-rltv brdSurrd rounded px-3 py-2 mt-4 mb-6 mr-1">
                        <span
                            class="px-1"
                            style="position: absolute; top: -18px; left: 5px; background-color: white;">
                            <span class="font-weight-medium mr-2">{{ arr.bldNm }}</span>
                            <span class="fontEighthRem">&#40; {{ getBldPrsnl(arr.bldInfPk) }}명 &#41;</span>
                        </span>
                        <div class="pt-1" v-for="(flr, j) in arr.flrList" :key="j">
                            <span class="font-weight-medium fontOneRem">{{ flr }}층 </span>
                            <span class="fontEighthRem">&#40; {{ getBldFlrPrsnl(arr.bldInfPk, flr) }}명 &#41;</span>
                            <v-divider></v-divider>
                            <v-row class="pa-0" no-gutters>
                                <v-col
                                    v-for="(lvn, k) in lvnRmList.filter( v => v.bldInfPk == arr.bldInfPk && v.bldFlr == flr )"
                                    :key="k" cols="4">
                                    <span class="d-inline-block" style="width: 92px; height: 28px;">
                                        <v-checkbox
                                            v-model="lvn.isOn"
                                            @click="ctrlArrApply()"
                                            class="expnChcBtn-Theme"
                                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            :label="lvn.lvnRmNm"
                                            hide-details="true" dense>
                                        </v-checkbox>
                                    </span>
                                </v-col>
                            </v-row>
                            <v-divider v-if="arr.flrList.length != (j+1)" class="my-2"></v-divider>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-expand-transition>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'CmmLvnExpnBtn',

    props : {
        value: { type: Boolean, default: false },               // 메뉴 활성여부
    },
    
    components: {
       
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
           this.getLvnRmList()
        })
    },

    watch: {
        
    },

    methods: {
        // 생활실 목록 조회
        getLvnRmList: function () {
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('opr/selLvnRmList', obj)
                .then((response) => ( this.lvnRmRst(response.data) ))
                .catch((error) => console.log('connect error /opr/selLvnRmList : ' + error))
        },

        lvnRmRst: function (res) {
            if(Object.keys(res.result).length > 0){
                this.vCd114 = res.result.codes.vCd114
                res.result.bldInfList.forEach(itm => {
                    let tmp = []
                    let arr = res.result.lvnRmList.filter( v => v.bldInfPk == itm.bldInfPk )
                    arr.forEach(idx => {
                        let chc = tmp.indexOf(idx.bldFlr)
                        if(chc == -1)
                            tmp.push(idx.bldFlr)
                    });
                    itm.flrList = tmp
                });
                res.result.lvnRmList.push({
                    bldFlr: '', bldInfPk: -1, bldNm: '', fxNum: 0, isOn: true, lvnRmCnt: 0,
                    lvnRmInfPk: null, lvnRmNm: null, lvnRmTypPk: -1, ord: 0, rmnCnt: 0, upBedYn: '',
                })
                res.result.lvnRmList.forEach(itm => {
                    itm.isOn = true
                });

                this.bldList = res.result.bldInfList
                this.lvnRmList = res.result.lvnRmList

                this.sttsUpdt()
            }

        },

        // 건물 정원값 반환
        getBldPrsnl: function (bldPk) {
            let arr = this.lvnRmList.filter( v => v.bldInfPk == bldPk )
            return arr.reduce((acc, cur) => { return acc += cur.fxNum }, 0)
        },

        // 건물 층별 정원값 반환
        getBldFlrPrsnl: function (bldPk, flr) {
            let arr = this.lvnRmList.filter( v => v.bldInfPk == bldPk && v.bldFlr == flr )
            return arr.reduce((acc, cur) => { return acc += cur.fxNum }, 0)
        },

        // 체크박스 전체 선택 이벤트
        allApply: function() {
            this.lvnRmList.forEach(itm => {
                itm.isOn = this.isAll
            });

            this.sttsUpdt()
        },

        // 체크박스 클릭 이벤트
        ctrlArrApply: function() {
            let arr = this.lvnRmList.filter( v => v.isOn == true )

            if(arr.length == this.lvnRmList.length)
                this.isAll = true
            else
                this.isAll = false

            this.sttsUpdt()
        },

        // 체크박스 변경사항 전달
        sttsUpdt: function() {
            if(this.sttList.length != this.lvnRmList.length){
                this.lvnRmList.forEach(itm => {
                    let obj = {}
                    obj.lvnRmInfPk = itm.lvnRmInfPk
                    obj.isOn = itm.isOn
                    this.sttList.push(obj)
                });
            }
            else{
                this.sttList.forEach((itm, i) => {
                    itm.isOn = this.lvnRmList[i].isOn
                });
            }

            if(!this.isAll){
                let chc = this.sttList.findIndex( v => v.lvnRmInfPk == null )
                if(chc > -1)
                    this.sttList[chc].isOn = false
            }
            
            this.$emit('onList', this.sttList)
        },

    },

    data: () => ({
        isAll: true,                // 전체 선택
        vCd114: [],                 // 상급침실구분
        bldList: [],                // 건물 목록
        lvnRmList: [],              // 생활실 목록
        sttList: [],
    }),
};
</script>