<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">         
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">{{ prgInfo.prgNm }}</span>
            <v-spacer></v-spacer> 
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="overflow-y-auto pr-2" style="height:700px;">
            <div class="pt-3" v-for="(itm, i) in prgInfo.actshtlist" :key="i">
                <div v-if="itm.shtClcd != '3'">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="" cols="12">            
                            <span class="gothicA1 fnt1-2 ml-1 font-weight-bold">{{itm.shtClcdNm}}</span>
                        </v-col>
                    </v-row>                     
                    <v-row class="mt-3 pa-0" no-gutters>
                        <v-col v-if="itm.fileLoc=='1'" class="pl-2" cols="12">
                            <div style="width:700">
                                <v-img
                                    referrerpolicy="no-referrer" 
                                    :src="itm.nvrUrl" :aspect-ratio="itm.ratio" :max-width="itm.width">
                                </v-img>
                            </div>
                        </v-col> 
                        <v-col v-if="itm.fileLoc=='2'" class="pl-2" cols="12">  
                            <div style="width:700">
                                <v-img
                                    referrerpolicy="no-referrer"
                                    :src="itm.src" :aspect-ratio="itm.ratio" :max-width="itm.width">
                                </v-img>
                            </div>
                        </v-col> 
                        <v-col v-if="itm.fileLoc=='3'" class="pl-2" cols="12">
                            <iframe width="560" height="315" :src="itm.nvrUrl" frameborder="0" referrerpolicy="unsafe-url"></iframe>
                        </v-col>
                    </v-row>  
                </div>
                <div v-else>
                    <v-row class="pa-0" no-gutters>                
                        <v-col class="d-flex align-center" cols="12">
                            <v-icon class="mt-1 opct0-6" color="blue102" size="20">mdi-folder-check</v-icon>                    
                            <a
                                v-if="itm.fileLoc=='1'" :href="itm.nvrUrl"
                                :download="itm.orgFileNm" @click="nvrDown(itm)">
                                {{ itm.orgFileNm }}
                            </a>
                            
                            <!-- <span v-if="itm.fileLoc=='1'"
                                class="d-inline-block crsShp-Pointer overflow-text-hidden px-2"
                                style="max-width: 300px; height: auto;"
                                @click="nvrDown(itm)"
                                >
                                <span class="fnt0-8">{{ itm.orgFileNm }}</span>
                            </span> -->
                            <span v-else-if="itm.fileLoc=='2'"
                                class="d-inline-block crsShp-Pointer overflow-text-hidden px-2"
                                style="max-width: 300px; height: auto;"
                                @click="fileDown(itm)"
                                >
                                <span class="fnt0-8">{{ itm.orgFileNm }}</span>
                            </span>
                        </v-col>
                    </v-row> 
                </div>                
            </div>
        </div>
    </v-sheet>
</template>
<script>
import { http } from '@/api/baseAxios';
import { remoteURL } from '@/api/baseAxios';

export default {
    name: 'PrgActshtInfo',
    
    components: {  
    },

    props : {
        sltItm:{type:Object, default:new Object}
    },

    computed: {
        
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {  
            this.getPrgInfShtInfo()
        })
    },

    watch: {
        
    },

    methods: {  
        
        onClose: function () {
            this.$emit('isClose')
        },

        // 프로그램정보 활동지 다건 조회
        getPrgInfShtInfo: function (){    
            this.prgInfo.actshtlist.splice(0)

            http.post('prgrm/selPrgInfShtInfo', this.sltItm)
                .then((response) => ( this.prgInfShtRst(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgInfShtlist : ' + error))
        },

        //프로그램정보 활동지 결과 반영
        prgInfShtRst:function(res){

            if(res.statusCode == '200'){
                
                this.prgInfo.prgNm  = res.result.prgInf.prgNm

                res.result.actshtlist.forEach(itm => {
                    //이미지 비율
                    itm.ratio ='1:1'

                    if(itm.fileLoc == '2'){
                        itm.src = remoteURL + 'opr/imgView?fileNm='+itm.fileNm+'&filePth='+itm.filePath+'&fileKndCd=2'
                    }

                    if(itm.shtClcd != '3' && itm.fileLoc == '3'){
                        itm.nvrUrl = '//www.youtube.com/embed/'+itm.nvrUrl+'?feature=oembed'
                    }   

                    if(itm.width != '' &&  itm.width != null && itm.height != '' && itm.height != null){
                        if(Number(itm.width) > Number(itm.height)) {
                            let val = itm.width/itm.height
                            val = val.toFixed(1)
                            itm.ratio = val/1
                        } else if(Number(itm.width) < Number(itm.height)) {
                            let val = itm.height/itm.width
                            val = val.toFixed(1)
                            itm.ratio = 1/val
                        } 
                    }

                    this.prgInfo.shtClcdItem.forEach(cd => {
                        if(cd.valcd == itm.shtClcd) itm.shtClcdNm = cd.valcdnm
                    });

                    this.prgInfo.actshtlist.push(itm)
                })
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        // 프로그램정보 활동지 다건 조회
        fileDown: function (itm){    

            let obj = {
                fileNm      : itm.fileNm,
                filePth     : itm.filePath,
                orgFileNm   : itm.orgFileNm,
                atchdFilePk   : itm.prgActshtPk,
            }

            http({method: 'post', url: 'cmm/fileDownload', responseType: 'arraybuffer', data: obj })
                .then((response) => ( this.createFile(response) ))
                .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
        },

        createFile: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=')
            }

            link.href = url

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },

        nvrDown:function(itm){
            http.post('prgrm/updPrgActshtDown', itm)
                .then((response) => ( this.rstNvrDown(response.data) ))
                .catch((error) => console.log('connect error /prgrm/selPrgInfShtlist : ' + error))
        },
        
        rstNvrDown:function(res){
            if(res.statusCode == '200') console.log('success')
        }
        
    },
    data: () => ({   
        prgInfo:{
            prgNm:'',
            actshtlist:[],
            shtClcdItem     : [
                { valcdnm: '활동예제', valcd: '1'},
                { valcdnm: '활동지', valcd: '2'},
                { valcdnm: '첨부화일', valcd: '3'}
            ],
            rprsYnItem     : [
                { valcdnm: '예', valcd: '1'},
                { valcdnm: '아니오', valcd: '2'},
            ],
        }
    }),
};
</script>