<template>
    <v-dialog
        content-class="round"
        :value="$store.getters.getSubActv"
        :max-width="$store.getters.getSubMxWdth"
        :persistent="$store.getters.getSubPrsst"
        :scrollable="false" :transition="false"
        @click:outside="outSideEvt">
        <v-card class="pa-7">
            <div class="overflow-hidden rounded" style="max-height: 740px;">
                <CmmCnfrmMdl v-if="$store.getters.getSubTyp == '1001'"></CmmCnfrmMdl>
                <RtrmnStlmnDtl
                    v-else-if="$store.getters.getSubTyp == '2001'"
                    :prtCd62="vCd62" :prtCd63="vCd63">
                </RtrmnStlmnDtl>
                <CopaySavMdl
                    v-else-if="$store.getters.getSubTyp == '2002'"
                    :prtCd63="vCd63">
                </CopaySavMdl>
                <CopayLogMdl
                    v-else-if="$store.getters.getSubTyp == '2003'">
                </CopayLogMdl>
                <AcknwSavMdl
                    v-else-if="$store.getters.getSubTyp == '2004'"
                    :prtCd62="vCd62" :prtYearPeriod="yearPeriod">
                </AcknwSavMdl>
                <AcknwLogMdl
                    v-else-if="$store.getters.getSubTyp == '2005'">
                </AcknwLogMdl>
                <GrantsSavMdl
                    v-else-if="$store.getters.getSubTyp == '2006'">
                </GrantsSavMdl>
                <GrantsLogMdl
                    v-else-if="$store.getters.getSubTyp == '2007'">
                </GrantsLogMdl>
                <AcknwNumSavMdl
                    v-else-if="$store.getters.getSubTyp == '2008'">
                </AcknwNumSavMdl>
                <LvnSavMdl
                    v-else-if="$store.getters.getSubTyp == '2009'">
                </LvnSavMdl>
                <LvnLogMdl
                    v-else-if="$store.getters.getSubTyp == '2010'">
                </LvnLogMdl>
                <SlctEmpSchdl 
                    v-else-if="$store.getters.getSubTyp == '3001'"
                    :prtCd41="vCd41" :prtCd42="vCd42" :prtCd70="vCd70">
                </SlctEmpSchdl>
                <UseSvcPymSavMdl 
                    v-else-if="$store.getters.getSubTyp == '6001'"
                    :prtCd64="vCd64">
                </UseSvcPymSavMdl>
                <v-row v-else class="pa-0" no-gutters>
                    <span class="text-h4 font-weight-bold">잘못된&nbsp;요청&nbsp;입니다.</span>
                    <v-spacer></v-spacer>
                    <v-icon color="black" @click="mdlOff" x-large>mdi-close</v-icon>
                </v-row>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';

import CmmCnfrmMdl from './CmmCnfrmMdl.vue';                        // 확인 팝업 (1001)
import RtrmnStlmnDtl from '../bnfcr/RtrmnStlmnDtl.vue';             // 수급자 퇴소정산내역 팝업(2001)
import CopaySavMdl from '../bnfcr/infMng/CopaySavMdl.vue'           // 수급자 본인부담률 저장 팝업(2002)
import CopayLogMdl from '../bnfcr/infMng/CopayLogMdl.vue'           // 수급자 본인부담률 변경이력 팝업(2003)
import AcknwSavMdl from '../bnfcr/infMng/AcknwSavMdl.vue'           // 수급자 인정등급 저장 팝업(2004)
import AcknwLogMdl from '../bnfcr/infMng/AcknwLogMdl.vue'           // 수급자 인정등급 변경이력 팝업(2005)
import GrantsSavMdl from '../bnfcr/infMng/GrantsSavMdl.vue'         // 수급자 보조금 저장 팝업(2006)
import GrantsLogMdl from '../bnfcr/infMng/GrantsLogMdl.vue'         // 수급자 보조금 변경이력 팝업(2007)
import AcknwNumSavMdl from '../bnfcr/infMng/AcknwNumSavMdl.vue'     // 수급자 인정번호 수정 팝업(2008)
import LvnSavMdl from '../bnfcr/infMng/LvnSavMdl.vue'               // 수급자 생활실 저장 팝업(2009)
import LvnLogMdl from '../bnfcr/infMng/LvnLogMdl.vue'               // 수급자 생활실 변경이력 팝업(2010)
import SlctEmpSchdl from './SlctEmpSchdl.vue';                      // 직원선택 팝업 (3001)
import UseSvcPymSavMdl from '../othr/UseSvcPymSavMdl.vue';          // 서비스 요금 결제 팝업 (6001)

export default {
    name: 'CmmSubMdl',

    components: {
        CmmCnfrmMdl,
        RtrmnStlmnDtl,
        CopaySavMdl,
        CopayLogMdl,
        AcknwSavMdl,
        AcknwLogMdl,
        GrantsSavMdl,
        GrantsLogMdl,
        AcknwNumSavMdl,
        LvnSavMdl,
        LvnLogMdl,
        SlctEmpSchdl,
        UseSvcPymSavMdl,
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
        getSubTyp: function () {
            return this.$store.getters.getSubTyp
        },
    },

    watch: {
        getSubTyp: function (txt) {
            if(txt != '')
                this.ctgr = txt
            else
                this.ctgr = ''
        },
        'ctgr': function () {
            if(this.ctgr != '')
                this.ldrItmCd()
        },
    },

    methods: {
        getCode: function (arr) {
            getMultiStcd(arr)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res){
            switch (this.ctgr) {
                case '2001':
                    this.vCd62 = res.vCd62
                    this.vCd63 = res.vCd63
                    break;
                case '2002':
                    this.vCd63 = res.vCd63
                    break;
                case '2004':
                    this.vCd62 = res.vCd62
                    break;
                case '3001':
                    this.vCd41 = res.vCd41
                    this.vCd42 = res.vCd42
                    this.vCd70 = res.vCd70
                    break;
                case '6001':
                    this.vCd64 = res.vCd64
                    break;
                default:
                    break;
            }

            this.ctgr = ''
        },
        ldrItmCd: function (){
            let chc = false
            switch (this.ctgr) {
                case '2001':
                    if(this.vCd62.length == 0)
                        chc = true
                    if(this.vCd63.length == 0)
                        chc = true
                    if(chc)
                        this.getCode(['62', '63'])
                    break;
                case '2002':
                    if(this.vCd63.length == 0)
                        chc = true
                    if(chc)
                        this.getCode(['63'])
                    break;
                case '2004':
                    if(this.vCd62.length == 0)
                        chc = true
                    if(chc)
                        this.getCode(['62'])
                    break;
                case '3001':
                    if(this.vCd41.length == 0)
                        chc = true
                    if(this.vCd42.length == 0)
                        chc = true
                    if(this.vCd70.length == 0)
                        chc = true
                    if(chc)
                        this.getCode(['41', '42', '70'])
                    break;
                case '6001':
                    if(this.vCd64.length == 0)
                        chc = true
                    if(chc)
                        this.getCode(['64'])
                    break;
                default:
                    break;
            }
        },
        mdlOff: function () {
            this.$store.commit('setSubInf', {
                actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
            });
        },
        outSideEvt: function () {
            if(this.$store.getters.getSubPrsst == false)
                this.$store.commit('setSubActv', false)
        },
    },

    data: () => ({
        ctgr: '',
        vCd41: [],          // 근무구분코드
        vCd42: [],          // 담당직종
        vCd62: [],          // 인정등급
        vCd63: [],          // 본인부담률
        vCd64: [],          // 이메일
        vCd70: [],          // 재직현황
        yearPeriod: [       // 년도
            { valcd: '1', valcdnm: '1년' }, { valcd: '2', valcdnm: '2년' },
            { valcd: '3', valcdnm: '3년' }, { valcd: '4', valcdnm: '4년' }, { valcd: '5', valcdnm: '5년' }
        ]
    }),
};
</script>