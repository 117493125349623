<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">         
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">{{$moment(inqYYMM,'YYYYMM').format('YYYY.MM')}} 목욕급여&nbsp;제공기록&nbsp;특이사항</span>
            <v-spacer></v-spacer> 
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-data-table
            class="dftTbl rounded" height="660" locale="ko"
            :headers="noteHeader" :items="noteList"
            :loading="isLoad" :loading-text="loadMsg"
            fixed-header disable-pagination hide-default-footer dense>
            <template v-slot:item='{ item }'>
                <tr>
                    <td>                        
                        <span>{{ item.name }}</span>
                    </td>
                    <td>{{ item.recDt }}</td>
                    <td>
                        {{ item.spclNote }}
                    </td>
                </tr>
            </template>           
        </v-data-table>
    </v-sheet>
</template>
<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'BathRecSpclNote',
    
    props : {                
        inqYYMM: { type: String, default: '' },       
        bnMmbrPks:{type: Array, default: () => { return [] }},
    },

    components: {
    },


    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getNoteList()
        })
    },

    computed: {
    },    

    watch: {        
        
    },
        
    methods: {
        onClose:function(){
            this.$emit('isClose')
        },

        // 급여제공기록 특이사항 문구 다건 조회
        getNoteList: function () {
            this.noteList.splice(0)
            this.isLoad = true
            let obj = {
                inqYYMM: this.inqYYMM,
                bnMmbrPks : this.bnMmbrPks,
                clsfc1Cd: '301',
                clsfc2Cd: '5',
                clsfc3Cd: '1'

            }

            http.post('bnftrcrd/selBnftRecSpclNoteList', obj)
                .then((response) => ( this.rstNoteList(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selBnftRecSpclNoteList : ' + error))
        },

        //결과 :: 급여제공기록 특이사항 문구 다건 조회
        rstNoteList:function(res){
            if(res.statusCode == '200'){
                if(res.result !=null && res.result != undefined){
                    res.result.forEach(itm => {
                        itm.recDt = this.$moment(itm.recDt, 'YYYYMMDD').format('YYYY-MM-DD')
                        this.noteList.push(itm)
                    });
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }

            this.isLoad = false
        }
    },

    data: () => ({
        isLoad      : true,
        loadMsg     : '잠시만 기다려주세요.',
        noteList    :[],
        noteHeader  : [            
            { text: '수급자',align:'center', value: 'name', sortable: true, filterable: true, width: '120' },
            { text: '일정',align:'center', value: 'recDt', sortable: true, filterable: true, width: '120' },
            { text: '특이사항',align:'center', value: 'spclNote', sortable: true, filterable: false, width: '400' },
        ],
    }),
};
</script>