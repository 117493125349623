<template>
    <v-sheet class="d-flex rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <v-sheet class="d-inline-block" :width="midAreaWidth" height="835" color="midAreaColor">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span v-if="routerRocation=='EmpInfMgmt'">7-1. 직원정보관리</span>
                    <span v-else-if="routerRocation=='WrkngJrnl'">7-3. 근무일지&#40;출퇴근&#41;</span>
                    <span v-else-if="routerRocation=='OtsdExprtMgmt'">7-5. 외부강사&#47;자원봉사자&nbsp;관리</span>
                    <span v-else-if="routerRocation=='EmpAnlWageStmtMgmt'">8-7. 직원&nbsp;연간임금&nbsp;대장</span>
                    
                </span>
                <div class="d-flex align-center mt-2">
                    <exBtn class="mr-1" v-if="routerRocation!='OtsdExprtMgmt'" :btnData="exBtn[0]" :sltElmnt="sltElmnt" @btnClosed="expandClose" @changeCheck="filterSearch" />
                    <exBtn class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                
                    <v-spacer></v-spacer>
                    
                    <span class="d-inline-block mt-1" style="width: 130px;">
                        <v-text-field class="ma-0 pa-0 nameSearch" height="33px" background-color="white" append-icon="mdi-magnify"
                            single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                        </v-text-field>
                    </span>
                </div>
                <div class="mt-2 rounded-lg">
                    <div class="mt-2 rounded-lg">
                        <v-data-table
                            class="dft-hvr-tbl msclnTable empCheckTable pt-1" height="732" locale="ko"
                            :headers="headerList" :items="empList" :search="schDummy" :custom-filter="customFilter"
                            fixed-header disable-pagination hide-default-footer dense>
                            <template v-slot:item='{ item, index }'>
                                <tr :class="[sltEmpPk == item.empPk ? 'onClr' : '']"
                                    v-if="routerRocation=='EmpInfMgmt' || routerRocation=='EmpAnlWageStmtMgmt'"
                                    @click="tableClickEvent(item.empPk), tblEvt(item.mmbrPk)">
                                    <td 
                                        class="fixPstn0 allHdrFix fixedCol0 tableBB nmlBfClrImp-white hvrClr"
                                        style="height:40px !important; text-align: center;">
                                        <span class="cmmFontSizeOne">{{ ++index }}</span>
                                    </td>
                                    <td
                                        class="fixPstn8 allHdrFix fixedCol0 tableBB nmlBfClrImp-white hvrClr"
                                        style="height:40px !important; text-align: center;">
                                        <span class="cmmFontSizeOne">{{ item.name }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                    </td>
                                    <td class="d-flex justify-center align-center fixedCol0 tableBB" style="height:40px !important; text-align: center;">
                                        <span class="d-inline-block pr-4" style="width: 32px; height: 20px;">
                                            <v-img class="ic-sex" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                            <v-img class="ic-sex" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                        </span>
                                    </td>
                                    <td class="fixedCol0 tableBB" style="height:40px !important; text-align: center;">
                                        <span class="cmmFontSizeOne">{{ item.wrkStcd }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                    </td>
                                    <td class="fixedCol0 tableBB" style="height:40px !important; text-align: center;">
                                        <span class="cmmFontSizeOne">{{ item.jobCd }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                    </td>
                                    <td class="fixedCol0 tableBB" style="height:40px !important; text-align: center;">
                                        <span class="cmmFontSizeOne">{{ item.entDtDot }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                    </td>
                                </tr>
                                <tr :class="[sltEmpPk == item.empPk ? 'onClr' : '']"
                                    v-else-if="routerRocation=='WrkngJrnl'"
                                    @click="tableClickEvent(item.empPk)">
                                    <td
                                        class="fixPstn0 allHdrFix fixedCol0 tableBB nmlBfClrImp-white hvrClr"
                                        style="height:40px !important; text-align: center;">
                                        <span class="cmmFontSizeOne">{{ item.name }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                    </td>
                                    <td
                                        class="fixPstn9 allHdrFix fixedCol0 tableBB nmlBfClrImp-white hvrClr"
                                        style="height:40px !important; text-align: center;">
                                        <span class="cmmFontSizeOne">{{ item.wrkStcd }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                    </td>
                                    <td class="fixedCol0 tableBB" style="height:40px !important; text-align: center;">
                                        <span class="cmmFontSizeOne">{{ item.jobCd }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                    </td>
                                    <td class="fixedCol0 tableBB" style="height:40px !important; text-align: center;">
                                        <span class="cmmFontSizeOne">{{ item.wrkHr }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                    </td>
                                    <td class="fixedCol0 tableBB" style="height:40px !important; text-align: center;">
                                        <span class="cmmFontSizeOne">{{ item.brkHr }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                    </td>
                                </tr>
                                <tr :class="[sltEmpPk == item.empPk ? 'onClr' : '']"
                                    v-else-if="routerRocation=='OtsdExprtMgmt'"
                                    @click="tableClickEvent(item.empPk)">
                                    <td
                                        class="fixPstn0 allHdrFix fixedCol0 tableBB nmlBfClrImp-white hvrClr"
                                        style="height:40px !important; text-align: center;">
                                        <span class="cmmFontSizeOne">{{ item.name }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                    </td>
                                    <td
                                        class="fixPstn9 allHdrFix fixedCol0 tableBB nmlBfClrImp-white hvrClr"
                                        style="height:40px !important; text-align: center;">
                                        <span class="cmmFontSizeOne">{{ item.jobCd }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                    </td>
                                    <td class="fixedCol0 tableBB" style="height:40px !important; text-align: center;">
                                        <span class="cmmFontSizeOne">{{ item.afltnClNm }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                    </td>
                                    <td class="fixedCol0 tableBB" style="height:40px !important; text-align: center;">
                                        <span class="cmmFontSizeOne">{{ item.celphn }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </v-sheet>
        <router-view ref="empChild"></router-view>
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';
import { selEmpList, selEmpAtndHrlList, selExtEmpList } from '../../api/emp.js';

import exBtn from '../../components/commons/expandButtonCustom.vue';

export default {
    name: 'EmpCnfgr',
        
    components: {
        exBtn,
    },

    created: function(){
        this.getCode(['41', '42', '64', '65', '70', '73', '107', '115', '127', '128'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.sltEmpPk = -1
            this.sltMmbrPk = -1
            this.routerRocation = this.$route.name
        })
    },

    computed: {

    },

    watch:{ 
        '$route' (to, from) {
            this.routerRocation = to.name
            console.log('Url Event from: ' + from.name)
        },
        'routerRocation': function (){
            this.midTblChg()
        },
    },
        
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        getEmpList: function (code) {
            this.empList.splice(0)

            selEmpList(this.$store.getters.getFcltyNum, code)
                .then((response) => ( this.getEmpListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpList : ' + error))
        },
        getEmpAtndHrlList: function (yymm, code) {
            this.empList.splice(0)

            selEmpAtndHrlList(this.$store.getters.getFcltyNum, code, yymm)
                .then((response) => ( this.getEmpAtndHrlListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpAtndHrlList : ' + error))
        },
        getExtEmpList: function () {
            this.empList.splice(0)

            selExtEmpList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getExtEmpListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selExtEmpList : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd41 = res.vCd41
            this.vCd42 = res.vCd42
            this.vCd65 = res.vCd65
            this.vCd73 = res.vCd73
            this.vCd107 = res.vCd107
            this.vCd115 = res.vCd115
            this.vCd127 = res.vCd127
            this.vCd128 = res.vCd128

            this.vCd42.forEach(code => {
                this.exBtn[1].expandList.push(code.valcdnm)
            })

            res.vCd64.forEach(cd => {
                this.vCd64.push(cd)
            });

            this.vCd70 = res.vCd70
            this.vCd70.forEach(code => {
                this.exBtn[0].expandList.push(code.valcdnm)
            })

            if(this.empList.length > 0)
                this.empList.forEach(items => {
                    this.vCd42.forEach(item => {
                        if(items.jobCd == item.valcd)
                            items.jobCd = item.valcdnm
                    });
                    this.vCd70.forEach(item => {
                        if(items.wrkStcd == item.valcd)
                            items.wrkStcd = item.valcdnm
                    });
                });

        },
        getEmpListAfter: function (res) {
            // mmbrPk
            res.forEach(items => {
                this.vCd42.forEach(item => {
                    if(items.jobCd == item.valcd)
                        items.jobCd = item.valcdnm
                });
                this.vCd70.forEach(item => {
                    if(items.wrkStcd == item.valcd)
                        items.wrkStcd = item.valcdnm
                });
         
                if(items.entDt == null)
                    items.entDtDot = ''
                else
                    items.entDtDot = this.$moment(items.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
                if(items.rtrDt == null)
                    items.rtrDt = ''
            });

            this.empList = res
        },
        getEmpAtndHrlListAfter: function (res) {
            res.forEach(items => {
                this.vCd42.forEach(item => {
                    if(items.jobCd == item.valcd)
                        items.jobCd = item.valcdnm
                });
                this.vCd70.forEach(item => {
                    if(items.wrkStcd == item.valcd)
                        items.wrkStcd = item.valcdnm
                });
            });

            this.empList = res
        },

        getExtEmpListAfter: function (res) {
            this.exBtn[1].expandList = ['외부강사', '자원봉사자']

            res.forEach(items => {
                items.wrkStcd = ''
                this.vCd42.forEach(item => {
                    if(items.jobCd == item.valcd)
                        items.jobCd = item.valcdnm
                });
                this.empList.push(items)
            });

        },
        // empPk
        tableClickEvent: function (pk){
            if(this.sltEmpPk == pk)
                this.sltEmpPk = -1
            else
                this.sltEmpPk = pk
        },
        // mmbrPk
        tblEvt: function (pk){
            if(this.sltMmbrPk == pk)
                this.sltMmbrPk = -1
            else
                this.sltMmbrPk = pk
        },
        setSearchText: function (value) {
            this.schField = value
            this.tableSelected.splice(0)
            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
            switch(this.schChkField.target) {
                case 'wrkStcd': this.schChkField.wrkStcd.splice(0)
                    sch.forEach((item) => {
                        this.schChkField.wrkStcd.push(item)
                        this.vCd70.forEach(code => {
                            if(item == code.valcdnm){
                                this.schChkField.trgCd = code.valcd
                                if(this.routerRocation=='EmpInfMgmt' || this.routerRocation=='EmpAnlWageStmtMgmt')
                                    this.getEmpList(code.valcd)
                                else if(this.routerRocation=='WrkngJrnl')
                                    this.getEmpAtndHrlList(this.$store.getters.getPrsntDt('yyyy')+this.$store.getters.getPrsntDt('mm'), code.valcd)
                                this.sltEmpPk = -1
                                this.sltMmbrPk = -1
                            }
                        })
                    });
                    break;
                case 'jobCd': this.schChkField.jobCd.splice(0)
                    sch.forEach((item) => { this.schChkField.jobCd.push(item) });
                    break;
            }

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)

            let col1 = true
            if(this.schChkField.wrkStcd.length != 0){
                this.schChkField.wrkStcd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.wrkStcd.includes(stcd)
                    else col1 = col1 || filter.wrkStcd.includes(stcd)
                });
            }

            let col2 = true
            if(this.schChkField.jobCd.length != 0){
                this.schChkField.jobCd.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.jobCd.includes(stcd)
                    else col2 = col2 || filter.jobCd.includes(stcd)
                });
            }

            return col0 && col1 && col2;
        },

        midTblChg: function () {
            this.headerList.forEach(item => {
                item.class = item.class.replaceAll('dspHid', '')
            });

            if(this.routerRocation == 'EmpInfMgmt' || this.routerRocation == 'EmpAnlWageStmtMgmt'){
                if(this.empList.length == 0)
                    this.getEmpList('1')

                this.headerList[1].class = 'black--text sftyHeader bnfcrMidIndex fixPstn8 zPstn3 py-2'
                this.headerList[3].class = 'black--text sftyHeader py-2'
                this.headerList[4].class = 'black--text sftyHeader py-2'

                this.headerList.forEach(item => {
                    if(item.text == '근무시간' || item.text == '휴게시간' || item.text == '소속' || item.text == '연락처')
                        item.class += ' dspHid'
                });
                this.vCd42.forEach(code => {
                    this.exBtn[1].expandList.push(code.valcdnm)
                })
                this.schChkField.jobCd.splice(0)
                this.schChkField.target = 'wrkStcd'
                this.sltElmnt = '재직'

                if(this.schChkField.trgCd == '2')
                    this.filterSearch(['퇴직'])
                else if(this.schChkField.trgCd == '3')
                    this.filterSearch(['휴직'])
                else
                    this.filterSearch(['재직'])
            }
            else if(this.routerRocation == 'WrkngJrnl'){

                this.headerList[1].class = 'black--text sftyHeader bnfcrMidIndex fixPstn0 zPstn3 py-2'
                this.headerList[3].class = 'black--text sftyHeader bnfcrMidIndex fixPstn9 zPstn3 py-2'
                this.headerList[4].class = 'black--text sftyHeader py-2'

                this.headerList.forEach(item => {
                    if(item.text == '연번' || item.text == '성별' || item.text == '입사일' || item.text == '소속' || item.text == '연락처')
                        item.class += ' dspHid'
                });
                this.vCd42.forEach(code => {
                    this.exBtn[1].expandList.push(code.valcdnm)
                })
                this.schChkField.jobCd.splice(0)
                this.sltElmnt = '재직'
                //this.filterSearch(['재직'])
                this.getEmpAtndHrlList(this.$store.getters.getPrsntDt('yyyy')+this.$store.getters.getPrsntDt('mm'), '1')
            }
            else if(this.routerRocation == 'OtsdExprtMgmt'){
                this.headerList[1].class = 'black--text sftyHeader bnfcrMidIndex fixPstn0 zPstn3 py-2'
                this.headerList[3].class = 'black--text sftyHeader py-2'
                this.headerList[4].class = 'black--text sftyHeader bnfcrMidIndex fixPstn9 zPstn3 py-2'

                this.headerList.forEach(item => {
                    if(item.text == '연번' || item.text == '성별' || item.text == '상태' || item.text == '입사일' || item.text == '근무시간' || item.text == '휴게시간')
                        item.class += ' dspHid'
                });

                this.schChkField.wrkStcd.splice(0)
                this.schChkField.jobCd.splice(0)
                this.sltEmpPk = -1
                this.sltMmbrPk = -1
                this.sltElmnt = ''
                this.getExtEmpList('1')
            }
        },

        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'wrkStcd'
                    break;
                case 1: this.schChkField.target = 'jobCd'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
    },
    
    data: () => ({
        sltElmnt: '',
        midAreaWidth: '380',
        routerRocation: '',
        tableSelected: [],
        sltEmpPk: -1,
        sltMmbrPk: -1,
        vCd41: [],                                              //근무 구분 코드
        vCd42: [],                                              //직원 직종 코드
        vCd64: [{ "valcd": "0", "valcdnm": "직접입력" },],       //이메일 도메인 코드
        vCd65: [],                                              //수급자 입소현황
        vCd70: [],                                              //직원 상태 코드
        vCd73: [],                                              //근무 상태 코드
        vCd107: [],                                             //휴가 종류 코드
        vCd115: [],                                             //근로시간 구분
        vCd127: [],                                             //직원현황내역
        vCd128: [],                                             //직원내역상세구분
        empList: [],
        schField: '',
        schDummy: '',
        schChkField: { wrkStcd: [], jobCd: [], target: 'wrkStcd', trgCd: '' },
        groupList:{
            WrkngSchdl: ['직원명', '직종', '근무일수', '근무시간'],
        },
        exBtn: [
            { index:  0, btnTitle: '상태', btnIcon: 'house', mode: 'single', exWidth: 94, exHeigth: 100, expandList: [], },
            { index:  1, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
        ], 
        headerList: [
            { text: '연번', value: 'rn', align: 'center', on: false, sortable: false, width: '70', class: 'black--text sftyHeader bnfcrMidIndex fixPstn0 zPstn3 py-2'},
            { text: '직원명', value: 'name', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader bnfcrMidIndex fixPstn8 zPstn3 py-2'},
            { text: '성별', value: 'gndr', align: 'center', on: false, sortable: true, width: '60', class: 'black--text sftyHeader py-2'},
            { text: '상태', value: 'wrkStcd', align: 'center', on: false, sortable: true, width: '60', class: 'black--text sftyHeader py-2'},
            { text: '직종', value: 'jobCd', align: 'center', on: false, sortable: true, width: '90', class: 'black--text sftyHeader py-2'},
            { text: '입사일', value: 'entDtDot', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader py-2'},
            { text: '근무시간', value: 'wrkHr', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader py-2'},
            { text: '휴게시간', value: 'brkHr', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader py-2'},
            { text: '소속', value: 'afltnClNm', align: 'center', on: false, sortable: true, width: '60', class: 'black--text sftyHeader py-2'},
            { text: '연락처', value: 'celphn', align: 'center', on: false, sortable: true, width: '130', class: 'black--text sftyHeader py-2'},
        ],
    }),
};
</script>