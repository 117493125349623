<template>
    <v-sheet class="d-inline-block rounded-lg rounded-l-0 px-3 py-4" min-width="1260" max-width="1560" height="835">
        <v-row class="pa-0 mb-6" no-gutters>
            <v-col class="text-h4 d-flex align-center" cols="12">
                <!-- <v-icon color="topTitleColor" style="margin-top: 2px;">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">10-1. SMS&nbsp;발송관리</span>                
                <v-spacer></v-spacer>                
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-2" no-gutters>
            <v-col cols="12">
                <v-tabs
                    v-model="smsSndTab" class="tab-dft-Theme" active-class="black"
                    center-active hide-slider>
                    <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class">{{item.tab}}</v-tab>
                </v-tabs>
                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
                <v-tabs-items v-model="smsSndTab">
                    <v-tab-item eager transition="fade-transition">
                        <SmsSndWrt ref="smsSndWrt"></SmsSndWrt>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <SmsSndHist ref="smsSndHist" :smsSndHistPk="smsSndHistPk"></SmsSndHist>
                    </v-tab-item>                
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import SmsSndWrt from '../../components/othr/SmsSndWrt.vue';
import SmsSndHist from '../../components/othr/SmsSndHist.vue';
export default {
    name: 'SmsSndMgmt',

    components: {
        SmsSndWrt,
        SmsSndHist
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            switch (this.$store.getters.getFcltyNum) {
                case '14159000743':
                case '14146000682':
                case '14111000790':
                    this.artBtn = false
                    break;
                default:
                    break;
            }
        })
    },

    computed: {

    },

    watch: {
        'smsSndTab':function(value){
            if(value == 1) this.smsSndHistPk = 1
            else this.smsSndHistPk = -1
        }
    },

    methods: {

    },

    data: () => ({
        smsSndTab:0,
        smsSndHistPk:-1,
        artBtn: true,
        tabItems: [
            { index: 0, tab: '문자전송', class: 'fontOneDotTwoRem px-15'},
            { index: 1, tab: '발송내역', class: 'fontOneDotTwoRem px-15'},
        ],
    }),
}
</script>