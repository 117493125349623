<template>
    <v-sheet class="pt-2" rounded="md">
        <v-row class="d-flex justify-right align-center" no-gutters>
            <span class="font-weight-medium fontOneRem ml-1" style="">주간&nbsp;:&nbsp;&nbsp;</span>
            <span class="d-inline-block ml-1" style="width: 90px;">
                <v-select class="mdclSelect"
                    v-model="sltWklyWrkPtn" height="28"
                    item-text="wrkPtnNm" item-value="wrkPtnPk"
                    :items="wrkPtnList.filter( v => v.wrkTypCd == '1')" :key="forceRender"
                    dense hide-details outlined>
                </v-select>
            </span>
            <span class="ml-3 font-weight-medium fontOneRem ml-1" style="">야간&nbsp;:&nbsp;&nbsp;</span>
            <span class="d-inline-block ml-1" style="width: 90px;">
                <v-select class="mdclSelect"
                    v-model="sltNghtWrkPtn" height="28"
                    item-text="wrkPtnNm" item-value="wrkPtnPk"
                    :disabled="sltLopType == '0'"
                    :items="wrkPtnList.filter( v => v.wrkTypCd == '1')" :key="forceRender"
                    dense hide-details outlined>
                </v-select>
            </span>
            <span class="ml-4 font-weight-medium fontOneRem ml-1" style="">반복&nbsp;:&nbsp;&nbsp;</span>
            <span class="d-inline-block mx-1" style="width: 56px;">
                <v-select class="mdclSelect"
                    v-model="sltLopType" height="28"
                    item-text="valCdNm" item-value="valCd"
                    :items="lopCdDay" :key="forceRender"
                    dense hide-details outlined>
                </v-select>
            </span>
            <v-btn-toggle
                v-model="sltRfList"
                background-color="white" color="#ff8500"
                multiple dense>
                <v-btn
                    class="px-0" v-for="(list, i) in lopCd" :key="i" style="position: relative;"
                    min-width="32" text small>
                    <span class="fontOneRem black--text">{{ list.txt }}</span>
                    <span
                        v-if="sltRfList.findIndex( v => v == i ) > -1"
                        class="rounded-circle white--text red"
                        style="position: absolute; top: -13px; left: 18px; padding: 0px 4px; font-size: 0.875rem;">
                        {{ sltRfList.findIndex( v => v == i ) + 1 }}
                    </span>
                </v-btn>
            </v-btn-toggle>
            <v-spacer></v-spacer>
            <span>
                <v-btn
                    class="white--text" height="30" color="blue001"
                    style="padding: 0 12px 0 10px !important;"
                    @click="dialog.splice(0, 1, true)"
                    rounded samll>
                    <v-icon style="margin-top: 1px;" small>mdi-cogs</v-icon>
                    <span class="fontOneRem ml-1" style="margin-bottom: 1px;">패턴관리</span>
                </v-btn>
            </span>
            <span class="ml-1" v-if="savBtn">
                <v-btn
                    class="white--text" height="30" color="#FB8C00"
                    style="padding: 0 12px 0 10px !important;"
                    @click="clndrSchdlAply()"
                    :disabled="getSltEmpObjList().length == 0"
                    rounded samll>
                    <v-icon style="margin-top: 1px;" small>mdi-calendar-edit-outline</v-icon>
                    <span class="fontOneRem ml-1" style="margin-bottom: 1px;">반영</span>
                </v-btn>
            </span>
            <span class="ml-1" v-if="savBtn">
                <v-btn
                    class="white--text" height="30" color="blueBtnColor"
                    style="padding: 0 12px 0 10px !important;"
                    @click="preInsEmpWrkSchdl()"
                    :disabled="wrkSchdlList.length == 0"
                    rounded samll>
                    <v-icon style="margin-top: 1px;" small>mdi-content-save-outline</v-icon>
                    <span class="fontOneRem ml-1" style="margin-bottom: 1px;">저장</span>
                </v-btn>
            </span>
            <span class="ml-1" v-if="savBtn">
                <v-btn
                    class="white--text" height="30" color="grey006"
                    style="padding: 0 12px 0 10px !important;"
                    @click="dialog.splice(2, 1, true)"
                    :disabled="getSltEmpObjList().length == 0"
                    rounded samll>
                    <v-icon style="margin-top: 1px;" small>mdi-trash-can-outline</v-icon>
                    <span class="fontOneRem ml-1" style="margin-bottom: 1px;">삭제</span>
                </v-btn>
            </span>
        </v-row>

        <v-divider class="my-2"></v-divider>

        <CmmClndr
            ref="cmmClndr"
            :clndrHght="'680'" :hdrTyp="'checkbox'" :clndrList="wrkSchdlList" :vsbYn="!savBtn"
            @chgDtAct="getPickerDate" @refresh="rclngWrkSchdl" >
        </CmmClndr>

        <v-dialog v-model="dialog[0]" content-class="round" max-width="1100" persistent eager>
            <WrkPtrCnfgr ref="wrkPtrCnfgr" @isClose="dialog.splice(0, 1, false)"></WrkPtrCnfgr>
        </v-dialog>
        <v-dialog v-model="dialog[1]" content-class="round" max-width="1300" persistent>
            <DlySchdlRgstr
                v-if="dialog[1]"
                ref="dlySchdlRgstr"
                :empPk="sltDtlEmpPk" :mmbrPk="sltDtlMmbrPk" :ptnList="wrkPtnList"
                :prtCd70="$parent.$parent.$parent.$parent.$parent.$parent.vCd70"
                :sltYyMm="$moment(this.start, 'YYYY-MM-DD').format('YYYYMM')"
                @isClose="dialog.splice(1, 1, false)">
            </DlySchdlRgstr>
        </v-dialog>
        <v-dialog v-model="dialog[2]" max-width="500">  
            <btnModalDelete :title="shdlRmTtl" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>

    </v-sheet>
</template>

<script>
import { selWrkPtnList, selEmpWrkSchdlMnthList, insEmpWrkSchdlMulti, delMultiEmpWrkSchdl } from '../../api/emp.js';

import CmmClndr from '../../components/commons/CmmClndr.vue';
import WrkPtrCnfgr from '../../components/emp/WrkPtrCnfgr.vue';
import DlySchdlRgstr from '../../components/emp/DlySchdlRgstr.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';

export default {
    name: 'SchdlMgmt',
    
    components: {
        CmmClndr,
        WrkPtrCnfgr,
        DlySchdlRgstr,
        btnModalDelete,
    },

    props : {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getWrkPtnList()
        })
    },

    computed: {
        getSltEmpList: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.tableSelected
        },
    },  

    watch: {
        'start': function () {
            let pkS = []
            this.$parent.$parent.$parent.$parent.$parent.$parent.tableSelected.forEach(items => {
                pkS.push(items.mmbrPk)
            });

            if(pkS.length > 0)
                this.getWrkSchdlList(this.$moment(this.start, 'YYYY-MM-DD').format('YYYYMM'), pkS)
        },
        getSltEmpList: {
            deep: true,
            handler: function (value){
                let pkS = []
                value.forEach(items => {
                    pkS.push(items.mmbrPk)
                });
              
                if(pkS.length == 0)
                    this.wrkSchdlList.splice(0)
                else if(pkS.length > 0)
                    this.getWrkSchdlList(this.$moment(this.start, 'YYYY-MM-DD').format('YYYYMM'), pkS)
            }
        },
    },

    methods: {
       // 근무패턴 다건 조회
       getWrkPtnList: function () {
            selWrkPtnList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getWrkPtnRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selWrkPtnList : ' + error))
        },

        // 근무일정 월간 조회
        getWrkSchdlList: function (yymm, pks) {
            this.wrkSchdlList.splice(0)

            selEmpWrkSchdlMnthList(this.$store.getters.getFcltyNum, yymm, pks)
                .then((response) => ( this.getWrkSchdlRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpWrkSchdlMnthList : ' + error))
        },

        // 근무일정 다건 등록
        setInsWrkSchdl: function (list) {
            this.wrkSchdlList.splice(0)

            insEmpWrkSchdlMulti(list)
                .then((response) => ( this.setWrkSchdlRst(response.data) ))
                .catch((error) => console.log('connect error /emp/insEmpWrkSchdlMulti : ' + error))
        },

        // 근무일정 다건 삭제
        rmMultiWrkSchdl: function (obj) {
            this.wrkSchdlList.splice(0)

            delMultiEmpWrkSchdl(obj)
                .then((response) => ( this.setWrkSchdlRst(response.data) ))
                .catch((error) => console.log('connect error /emp/delMultiEmpWrkSchdl : ' + error))
        },

        getWrkPtnRst: function (res) {
            if(Object.keys(res).length == 0){
                let obj = Object.assign({}, this.wrkPtnObj)
                this.$refs.wrkPtrCnfgr.editYwrkPtnList.push(obj)
                this.$refs.wrkPtrCnfgr.workTimeStrt.push(false)
                this.$refs.wrkPtrCnfgr.workTimeEnd.push(false)
                this.$refs.wrkPtrCnfgr.color.push('#000000')
                this.$refs.wrkPtrCnfgr.menu.push(false)
            }
            else{
                res.forEach(items => {
                    items.wrkPtnColrStyle = 'backgroundColor:' + items.wrkPtnColr + '; width: 20px; height: 20px; border-radius: 4px; margin: 1px 0px 0px 0px !important;'
                });

                this.wrkPtnList = res
                this.sltWklyWrkPtn = this.wrkPtnList[0].wrkPtnPk
                this.sltNghtWrkPtn = this.wrkPtnList[0].wrkPtnPk
            }
        },

        getWrkSchdlRst: function (res) {
            if(res.length > 0){
                let rst = []
                let wrkDtDupl = []
                let mmbrDupl = []

                res.forEach(itm => {
                    if(itm.vctnTypCd == null || itm.vctnTypCd == '0')
                        itm.vctnTypCd = ''
                    mmbrDupl.push(itm.mmbrPk)
                    wrkDtDupl.push(itm.wrkDt)
                });

                let wrkDtList = [...new Set(wrkDtDupl)]
                let mmbrList = [...new Set(mmbrDupl)]

                wrkDtList.forEach(itm => {
                    let obj = {}
                    obj.start = this.$moment(itm, 'YYYYMMDD').format('YYYY-MM-DD')
                    obj.end = this.$moment(itm, 'YYYYMMDD').format('YYYY-MM-DD')
                    obj.color = '#000'
                    obj.name = null
                    obj.mmbrPk = null
                    obj.empPk = null
                    obj.list = null

                    
                    mmbrList.forEach(pks => {
                        let sObj = Object.assign({}, obj)

                        sObj.list = res.filter( v => v.wrkDt == itm && v.mmbrPk == pks )

                        let idx = res.findIndex( v => v.wrkDt == itm && v.mmbrPk == pks )
                        if(idx > -1){
                            sObj.color = res[idx].wrkPtnColr
                            sObj.name = res[idx].name
                            sObj.mmbrPk = res[idx].mmbrPk
                            sObj.empPk = res[idx].empPk
                        }
              
                        if(sObj.list.length > 0)
                            rst.push(sObj)
                    });

                });
                //console.log(rst)
                this.wrkSchdlList = rst
            }         

            this.savBtn = true
        },

        setWrkSchdlRst: function (res) {
            let pkS = []
            this.$parent.$parent.$parent.$parent.$parent.$parent.tableSelected.forEach(items => {
                pkS.push(items.mmbrPk)
            });

            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 4000                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                if(pkS.length > 0)
                    this.getWrkSchdlList(this.$moment(this.start, 'YYYY-MM-DD').format('YYYYMM'), pkS)

                this.rclngWrkSchdHrl()
                this.clearForm()
            }
            else{
                obj.tmr = 5000
                obj.type = 'warning'
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // 달력에 반영된 정보 저장
        preInsEmpWrkSchdl: function () {
            this.savBtn = false

            let rst = []
   
            this.wrkSchdlList.forEach(seed => {
                seed.list.forEach(itm => {
                    itm.fcltyNum = this.$store.getters.getFcltyNum
                    itm.userNm = this.$store.getters.getUserNm
                    rst.push(itm)
                });
            });

            if(rst.length > 0)
                this.setInsWrkSchdl(rst)
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 2000, '달력에 반영된 일정이 없습니다.', 'warning'])
        },

        // 스케쥴 삭제
        rmInsEmpWrkSchdl: function () {
            this.savBtn = false

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.inqYYMM = this.$moment(this.start, 'YYYY-MM-DD').format('YYYYMM')
            obj.wrkDts = []
            obj.mmbrPks = []
            obj.list = []

            this.$parent.$parent.$parent.$parent.$parent.$parent.tableSelected.forEach(items => {
                obj.mmbrPks.push(items.mmbrPk)
            });

            this.$refs.cmmClndr.getSltBoxList().forEach(itm => {
                let oObj = {}
                oObj.inqYMD = this.$moment(itm.locDate, 'YYYY-MM-DD').format('YYYYMMDD')

                this.wrkSchdlList.filter( v => v.start == itm.locDate ).forEach(idx => {
                    idx.list.forEach(chc => {
                        obj.list.push(chc)
                    });
                });
                
                obj.wrkDts.push(this.$moment(itm.locDate, 'YYYY-MM-DD').format('YYYYMMDD'))
            });

            if(obj.mmbrPks.length == 0){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2000, '선택된 직원이 없습니다.', 'info'])
                return
            }
            if(obj.list.length == 0){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2000, '지정된 날짜가 없습니다.', 'info'])
                return
            }

            //console.log(obj)
            this.rmMultiWrkSchdl(obj)

        },

        // 선택된 직원 리스트 구하기
        getSltEmpObjList: function () {
            let list = []
            this.$parent.$parent.$parent.$parent.$parent.$parent.tableSelected.forEach(items => {
                list.push(items)
            });
            return list
        },

        // 날짜 변경 호출
        getPickerDate: function (date) {
            this.start = this.$moment(date, 'YYYYMM').format('YYYY-MM-DD')
            this.$parent.$parent.$parent.$parent.$parent.$parent.sltDt = this.$moment(date, 'YYYYMM').format('YYYY-MM-DD')
        },

        // 스케쥴 달력 반영
        clndrSchdlAply: function () {
            let empList = this.getSltEmpObjList()

            // 예외사항 처리
            if(this.wrkPtnList.filter( v => v.wrkTypCd == '1').length == 0){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2000, '근무 패턴이 존재하지 않습니다.', 'info'])
                return
            }
            if(empList.length == 0){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2000, '선택된 직원이 없습니다.', 'info'])
                return
            }

            // 스케쥴 일반
            if(this.sltLopType == '0')
                this.nmlySchdlAply(empList)
            // 스케쥴 반복
            else
                this.lopSchdlAply(empList)
        },

        // 선택된 패턴 오브젝트 반환
        getSltPtnObj: function (type) {
            let obj = {}
            let tmpPtn = -1

            switch (type) {
                case 'wkly':     // 주간 패턴
                    tmpPtn = this.wrkPtnList.findIndex( v => v.wrkPtnPk == this.sltWklyWrkPtn )
                    break;
                case 'nght':     // 야간 패턴
                    tmpPtn = this.wrkPtnList.findIndex( v => v.wrkPtnPk == this.sltNghtWrkPtn )
                    break;
                default:
                    break;
            }

            if(tmpPtn > -1)
                obj = this.wrkPtnList[tmpPtn]

            return obj
        },

        // 스케쥴 일반 (선택된 직원 리스트)
        nmlySchdlAply: function (arr) {
            let clndrList = this.$refs.cmmClndr.getSltBoxList()
            let ptnObj = this.getSltPtnObj('wkly')

            if(clndrList.length == 0){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2000, '날짜가 선택되지 않았습니다.', 'info'])
                return
            }
           
            if(this.wrkPtnList.findIndex( v => v.wrkPtnPk == this.sltWklyWrkPtn ) == -1){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2000, '패턴정보 로드 실패', 'info'])
                return
            }

            let failList = []
            let entRng = []
            clndrList.forEach(itm => {
                arr.forEach(emp => {
                    let chc = this.wrkSchdlList.findIndex( v => v.start == itm.locDate && v.mmbrPk == emp.mmbrPk )
                    
                    // 신규 등록
                    if(chc == -1){
                        let oObj = {}
                        oObj.start = itm.locDate
                        oObj.end = itm.locDate
                        oObj.color = ptnObj.wrkPtnColr
                        oObj.mmbrPk = emp.mmbrPk
                        oObj.empPk = emp.empPk
                        oObj.name = emp.name
                        oObj.rtrDt = emp.rtrDt
                        oObj.list = []
                        
                        let pObj = Object.assign({}, ptnObj)
                        pObj.wrkDt = this.$moment(itm.locDate, 'YYYY-MM-DD').format('YYYYMMDD')
                        pObj.mmbrPk = emp.mmbrPk
                        pObj.empPk = emp.empPk
                        pObj.name = emp.name
                        pObj.rtrDt = emp.rtrDt
                        pObj.vctnTypCd = ''

                        oObj.list.push(pObj)

                        let cnfm = this.$moment(emp.entDt, 'YYYYMMDD').isSameOrBefore(this.$moment(oObj.start, 'YYYY-MM-DD').format('YYYYMMDD'))
                        if(cnfm)
                            this.wrkSchdlList.push(oObj)
                        else
                            entRng.push({ name: emp.name, entDt: emp.entDt, aplStrt: this.$moment(oObj.start, 'YYYY-MM-DD').format('YYYYMMDD') })
                    }
                    else{
                        // 등록되어있는 스케쥴의 근무유형을 확인
                        let kndWrkTyp = []
                        this.wrkSchdlList[chc].list.forEach(itm => {
                            kndWrkTyp.push(itm.wrkTypCd)
                        });
               
                        // 근무정보가 없으면 스케쥴에 추가
                        let tmp = [...new Set(kndWrkTyp)]
                        let ext = tmp.findIndex( v => v == '1' )
                        if(ext == -1){
                            let pObj = Object.assign({}, ptnObj)
                            pObj.wrkDt = this.$moment(itm.locDate, 'YYYY-MM-DD').format('YYYYMMDD')
                            pObj.mmbrPk = emp.mmbrPk
                            pObj.empPk = emp.empPk
                            pObj.name = emp.name
                            pObj.rtrDt = emp.rtrDt
                            pObj.vctnTypCd = ''
                     
                            let cnfm = this.$moment(emp.entDt, 'YYYYMMDD').isSameOrBefore(this.$moment(pObj.wrkDt, 'YYYYMMDD').format('YYYYMMDD'))
                            if(cnfm)
                                this.wrkSchdlList[chc].list.push(pObj)
                            else
                                entRng.push({ name: emp.name, entDt: emp.entDt, aplStrt: pObj.wrkDt })
                        }
                        else{
                            let fObj = {}
                            fObj.fDt = itm.locDate
                            fObj.name = emp.name
                            fObj.rtrDt = emp.rtrDt
                            failList.push(fObj)
                        }
                    }
                
                });
                
            });

            if(failList.length > 0 && entRng.length == 0)
                this.$parent.$parent.$parent.$parent.snackControll(
                    [true, 2500, '근무 정보가 중복되는 ' + failList.length + '건을 제외하고 반영 되었습니다.', 'info']
                )
            else if(failList.length == 0 && entRng.length > 0)
                this.$parent.$parent.$parent.$parent.snackControll(
                    [true, 2500, '입사 이전 근무 ' + entRng.length + '건을 제외하고 반영 되었습니다.', 'info']
                )
            else if(failList.length > 0 && entRng.length > 0)
                this.$parent.$parent.$parent.$parent.snackControll(
                    [true, 2500, '입사 이전 근무 ' + entRng.length + '건, 중복근무 ' + failList.length + '건을 제외하고 반영 되었습니다.', 'info']
                )
        },

        // 스케쥴 반복
        lopSchdlAply: function (arr) {
            if(this.sltRfList.length == 0){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2000, '반복 정보가 없습니다.', 'info'])
                return
            }

            if(this.sltWklyWrkPtn == 0){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2000, '선택된 주간패턴이 없습니다.', 'info'])
                return
            }

            if(this.sltNghtWrkPtn == 0){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2000, '선택된 야간패턴이 없습니다.', 'info'])
                return
            }

            // 반복정보 정리
            let lsDt = Number(this.$moment(this.start, 'YYYY-MM-DD').endOf('month').format('DD'))
            let totCnt = lsDt - Number(this.sltLopType)
            let lopArr = []
            this.sltRfList.forEach(itm => {
                switch (itm) {
                    case 0:
                    case 1:
                        lopArr.push('주')
                        break;
                    case 2:
                    case 3:
                        lopArr.push('야')
                        break;
                    case 4:
                    case 5:
                        lopArr.push('비')
                        break;
                    default:
                        break;
                }
            });

            let lopCnt = Math.ceil(totCnt / lopArr.length)
            let lopRst = []
            for (let index = 0; index < lopCnt; index++) {
                lopArr.forEach(itm => {
                    lopRst.push(itm)
                });
            }
            if(Number(this.sltLopType) > 1){
                for (let index = 0; index < Number(this.sltLopType) - 1; index++) {
                    lopRst.unshift('')
                }
            }

            // 달력에 반복될 날짜 지정
            let clndrLopList = []
            for (let idx = Number(this.sltLopType); idx <= lsDt ; idx++) {
                let obj = {}
                obj.locDate = this.$moment(this.start, 'YYYY-MM-DD').format('YYYY-MM') + '-' + ('00' + idx).slice(-2)
                obj.locDay = String(idx)
                obj.ptnType = lopRst[idx-1]
                
                clndrLopList.push(obj)
            }

            // 달력 체크박스 초기화
            this.$refs.cmmClndr.clndrAllChkr('2')

            // 데이터 적용
            let ptnWklyObj = this.getSltPtnObj('wkly')
            let ptnNghtObj = this.getSltPtnObj('nght')

            let failList = []
            let entRng = []
            clndrLopList.forEach(itm => {
                if(itm.ptnType == '주' || itm.ptnType == '야'){
                    arr.forEach(emp => {
                        let chc = this.wrkSchdlList.findIndex( v => v.start == itm.locDate && v.mmbrPk == emp.mmbrPk )

                        // 신규 등록
                        if(chc == -1){
                            let oObj = {}
                            oObj.start = itm.locDate
                            oObj.end = itm.locDate
                            oObj.mmbrPk = emp.mmbrPk
                            oObj.empPk = emp.empPk
                            oObj.name = emp.name
                            oObj.rtrDt = emp.rtrDt
                            oObj.list = []
                            
                            if(itm.ptnType == '주'){
                                oObj.color = ptnWklyObj.wrkPtnColr

                                let pObj = Object.assign({}, ptnWklyObj)
                                pObj.wrkDt = this.$moment(itm.locDate, 'YYYY-MM-DD').format('YYYYMMDD')
                                pObj.mmbrPk = emp.mmbrPk
                                pObj.empPk = emp.empPk
                                pObj.name = emp.name
                                pObj.rtrDt = emp.rtrDt
                                pObj.vctnTypCd = ''
            
                                oObj.list.push(pObj)
                            }
                            else if(itm.ptnType == '야'){
                                oObj.color = ptnNghtObj.wrkPtnColr

                                let pObj = Object.assign({}, ptnNghtObj)
                                pObj.wrkDt = this.$moment(itm.locDate, 'YYYY-MM-DD').format('YYYYMMDD')
                                pObj.mmbrPk = emp.mmbrPk
                                pObj.empPk = emp.empPk
                                pObj.name = emp.name
                                pObj.rtrDt = emp.rtrDt
                                pObj.vctnTypCd = ''
            
                                oObj.list.push(pObj)
                            }

                            let cnfm = this.$moment(emp.entDt, 'YYYYMMDD').isSameOrBefore(this.$moment(oObj.start, 'YYYY-MM-DD').format('YYYYMMDD'))
                            if(cnfm)
                                this.wrkSchdlList.push(oObj)
                            else
                                entRng.push({ name: emp.name, entDt: emp.entDt, aplStrt: this.$moment(oObj.start, 'YYYY-MM-DD').format('YYYYMMDD') })
                        }
                        else{
                            // 등록되어있는 스케쥴의 근무유형을 확인
                            let kndWrkTyp = []
                            this.wrkSchdlList[chc].list.forEach(itm => {
                                kndWrkTyp.push(itm.wrkTypCd)
                            });

                            // 근무정보가 없으면 스케쥴에 추가
                            let tmp = [...new Set(kndWrkTyp)]
                            let ext = tmp.findIndex( v => v == '1' )
                            if(ext == -1){
                                if(itm.ptnType == '주'){
                                    let pObj = Object.assign({}, ptnWklyObj)
                                    pObj.mmbrPk = emp.mmbrPk
                                    pObj.empPk = emp.empPk
                                    pObj.name = emp.name
                                    pObj.rtrDt = emp.rtrDt
                                    pObj.vctnTypCd = ''

                                    let cnfm = this.$moment(emp.entDt, 'YYYYMMDD').isSameOrBefore(this.$moment(this.wrkSchdlList[chc].start, 'YYYY-MM-DD').format('YYYYMMDD'))
                                    if(cnfm)
                                        this.wrkSchdlList[chc].list.push(pObj)
                                    else
                                        entRng.push({ name: emp.name, entDt: emp.entDt, aplStrt: pObj.wrkDt })
                                }
                                else if(itm.ptnType == '야'){
                                    let pObj = Object.assign({}, ptnNghtObj)
                                    pObj.mmbrPk = emp.mmbrPk
                                    pObj.empPk = emp.empPk
                                    pObj.name = emp.name
                                    pObj.rtrDt = emp.rtrDt
                                    pObj.vctnTypCd = ''

                                    let cnfm = this.$moment(emp.entDt, 'YYYYMMDD').isSameOrBefore(this.$moment(this.wrkSchdlList[chc].start, 'YYYY-MM-DD').format('YYYYMMDD'))
                                    if(cnfm)
                                        this.wrkSchdlList[chc].list.push(pObj)
                                    else
                                        entRng.push({ name: emp.name, entDt: emp.entDt, aplStrt: pObj.wrkDt })
                                }
                            }
                            else{
                                let fObj = {}
                                fObj.fDt = itm.locDate
                                fObj.name = emp.name
                                fObj.rtrDt = emp.rtrDt
                                failList.push(fObj)
                            }
                        }
                    });
                }
            });

            if(failList.length > 0 && entRng.length == 0)
                this.$parent.$parent.$parent.$parent.snackControll(
                    [true, 2500, '근무 정보가 중복되는 ' + failList.length + '건을 제외하고 반영 되었습니다.', 'info']
                )
            else if(failList.length == 0 && entRng.length > 0)
                this.$parent.$parent.$parent.$parent.snackControll(
                    [true, 2500, '입사 이전 근무 ' + entRng.length + '건을 제외하고 반영 되었습니다.', 'info']
                )
            else if(failList.length > 0 && entRng.length > 0)
                this.$parent.$parent.$parent.$parent.snackControll(
                    [true, 2500, '입사 이전 근무 ' + entRng.length + '건, 중복근무 ' + failList.length + '건을 제외하고 반영 되었습니다.', 'info']
                )
        },

        setDlyMdlProp: function(obj){
            this.sltDtlEmpPk = obj.ePk
            this.sltDtlMmbrPk = obj.mPk

            this.dialog.splice(1, 1, true)
        },

        // 좌측 메뉴 리스트 갱신
        rclngWrkSchdHrl: function(){
            let trgDt = this.$moment(this.start, 'YYYYMMDD').format('YYYYMM')

            if(this.$parent.$parent.$parent.$parent.$parent.$parent.schChkField.wrkStcd.length > 0){
                this.$parent.$parent.$parent.$parent.$parent.$parent.vCd70.forEach(code => {
                    if(this.$parent.$parent.$parent.$parent.$parent.$parent.schChkField.wrkStcd[0] == code.valcdnm)
                        this.$parent.$parent.$parent.$parent.$parent.$parent.getEmpWrkSchdHrlList(trgDt, code.valcd)
                })
            }

            //this.getStdWrkHrInfo(trgDt)
        },

        // 근무일정 갱신
        rclngWrkSchdl: function () {
            let pkS = []
            this.$parent.$parent.$parent.$parent.$parent.$parent.tableSelected.forEach(items => {
                pkS.push(items.mmbrPk)
            });

            if(pkS.length > 0)
                this.getWrkSchdlList(this.$moment(this.start, 'YYYY-MM-DD').format('YYYYMM'), pkS)
        },

        // 데이터 초기화
        clearForm: function () {
            this.sltLopType = '0'
            this.sltRfList.splice(0)
            this.wrkSchdlList.splice(0)

            this.forceRender += 1
        },

        // 삭제 팝업 제어
        delModalAct: function(delMsg){
            if(delMsg == 9990)   //OK
                this.rmInsEmpWrkSchdl()

            this.dialog.splice(2, 1, false)
        },
    },

    data: () => ({
        start: '',
        savBtn: true,
        sltDtlEmpPk: 0,
        sltDtlMmbrPk: 0,
        sltWklyWrkPtn: 0,
        sltNghtWrkPtn: 0,
        sltLopType: '0',
        forceRender: 0,
        shdlRmTtl: '선택된 모든 근무일정',
        sltRfList: [],
        dialog: [false, false, false, false],
        lopCd: [
            { txt: '주', value: false }, { txt: '주', value: false },
            { txt: '야', value: false }, { txt: '야', value: false },
            { txt: '비', value: false }, { txt: '비', value: false },
        ],
        wrkPtnList: [],
        wrkSchdlList: [],
        wrkPtnObj: {
            wrkPtnPk: null, brkDay: 0, brkNgt: 0, brkOvn: 0, actWrkHr: 0, wrkPtnColr: '#000000', wrkStrtTm: '', wrkEndTm: '', wrkTm: 'NaN', wrkTypCd: '1',
            wrkPtnNm: '', wrkTmHhStrt: '', wrkTmHhEnd: '', wrkTmMmStrt: '', wrkTmMmEnd: '', wrkPtnColrStyle: 'cursor: pointer; height: 20px; width: 20px;' +
                    'margin: 1px 0px 0px 0px !important; transition: border-radius 200ms ease-in-out; background-color: #000000; border-radius: 4px;'
        },
        lopCdDay: [
            { valCd: '0', valCdNm: '없음' }, { valCd: '1', valCdNm: '1일' },
            { valCd: '2', valCdNm: '2일' }, { valCd: '3', valCdNm: '3일' },
            { valCd: '4', valCdNm: '4일' }, { valCd: '5', valCdNm: '5일' },
            { valCd: '6', valCdNm: '6일' },
        ],
    }),
};
</script>