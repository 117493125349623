<template>
    <v-sheet>
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <v-row no-gutters>
            <v-col class="d-flex pr-3" cols="12">
                <v-spacer></v-spacer>
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                        :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        <v-form ref="form" lazy-validation>
            <v-row class="mt-3">
                <v-col cols="7">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <div class="d-flex align-center">
                                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                                <span class="text-h6 font-weight-bold">수신 대상자</span>
                                <v-spacer></v-spacer>
                                <span>
                                    <v-btn
                                        class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                                        min-width="30" height="25" small outlined @click="onDialog(1)">
                                        <v-icon size="20">mdi-clipboard-text-outline</v-icon>
                                        수신자 선택
                                    </v-btn>
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3" no-gutters>
                        <v-col cols="12">                        
                            <v-data-table hide-default-footer dense locale="ko" height="610" min-width="1360"
                                hide-default-header fixed-header 
                                :headers="rcvHeader" 
                                :items="rcvList" 
                                class="" disable-pagination>

                                <template v-slot:header="{ props }" >
                                    <thead>
                                        <tr>                            
                                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 120px !important;" colspan="1" rowspan='2'>{{props.headers[0].text}}</th>
                                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 200px !important;" colspan="1" rowspan='2'>{{props.headers[1].text}}</th>
                                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 180px !important;" colspan="1" rowspan='2'>{{props.headers[2].text}}</th>                                        
                                            <th class="black--text clmsHeaderCommon tableBT" style="width: 50px !important;" colspan="1" rowspan='2'>
                                                <v-btn 
                                                    color="blue200" 
                                                    height="26" 
                                                    @click="addRow"
                                                    icon>
                                                    <v-icon>mdi-plus-circle-outline</v-icon>
                                                </v-btn>
                                            </th>
                                        </tr>
                                    </thead>
                                </template>     
                                <template v-slot:item='{ item, index }'>
                                    <tr v-if="item.dtTyp == 'dict'">  
                                        <td class="clmsBodyCommon tableBR">
                                            <v-text-field v-model="item.name" :rules="[rules.required]" height="28" hide-details outlined dense></v-text-field>
                                        </td>
                                        <td class="clmsBodyCommon tableBR">
                                            <v-text-field v-model="item.rltns" :rules="[rules.required]" height="28" hide-details outlined dense></v-text-field>
                                        </td>
                                        <td class="clmsBodyCommon tableBR">
                                            <span class="d-inline-block pt-0">
                                                <span class="d-inline-block" style="width: 47px;">
                                                    <v-text-field v-model="item.rcvCelphn_1" :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]" height="28" @click="rcvDplcChk" hide-details outlined dense></v-text-field>
                                                </span>
                                                <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                                                &#45;
                                                </span>
                                                <span class="d-inline-block" style="width: 56px;">
                                                    <v-text-field v-model="item.rcvCelphn_2" :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]" height="28" @click="rcvDplcChk" hide-details outlined dense></v-text-field>
                                                </span>
                                                <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                                                &#45;
                                                </span>
                                                <span class="d-inline-block" style="width: 56px;">
                                                    <v-text-field v-model="item.rcvCelphn_3" :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]" height="28" @click="rcvDplcChk" hide-details outlined dense></v-text-field>
                                                </span>
                                            </span> 
                                        </td>
                                        <td class="clmsBodyCommon">
                                            <div class="d-flex justify-center align-center">                                                                         
                                                <v-btn icon color='grey006' @click="delRow(index)">
                                                    <v-icon>mdi-minus-circle-outline</v-icon>
                                                </v-btn>                                
                                            </div>
                                        </td>                      
                                    </tr>
                                    <tr v-else>                        
                                        <td class="clmsBodyCommon tableBR">{{item.name}}</td>
                                        <td class="clmsBodyCommon tableBR">{{item.rltns}}</td>
                                        <td class="clmsBodyCommon tableBR">{{item.rcvCelphn}}</td>
                                        <td class="clmsBodyCommon">
                                            <div class="d-flex justify-center align-center">                                                                         
                                                <v-btn icon color='grey006' @click="delRow(index)">
                                                    <v-icon>mdi-minus-circle-outline</v-icon>
                                                </v-btn>                                
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="5">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <div class="d-flex align-center">
                                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                                <span class="text-h6 font-weight-bold">문자 내용</span>
                                <v-spacer></v-spacer>
                                <span>
                                    <v-btn
                                        class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                                        min-width="30" height="25" small outlined
                                        @click="onDialog(0)">
                                        <v-icon size="20">mdi-clipboard-text-outline</v-icon>
                                        문자 선택
                                    </v-btn>
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3" no-gutters>
                        <v-col cols="12">
                            <v-textarea v-model="msgCntnt" :rules="[rules.required]"
                                rows="15" class="rmrksArea" outlined dense hide-details no-resize>
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3" no-gutters>
                        <v-col class="d-flex align-center" cols="12">
                            <v-spacer></v-spacer>
                            <div class="pr-2" style="font-size:1.0rem !important;">
                                <span>{{msgCntnt?.getBytes()}}</span>
                                <span>/</span>
                                <span>80bytes</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="800">    
                <SmsSndMsgMgmt ref="smsSndMsgMgmt" @outSndMsg="setSndMsg" @isClose="isDialog(0)"></SmsSndMsgMgmt>  
            </v-dialog>   
        </div>  
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[1]" max-width="800">    
                <SmsMmbrListMgmt ref="smsMmbrListMgmt" 
                    :rcvList="rcvList" 
                    :smsMmbrListMgmt="smsMmbrListMgmt"
                    @smsMmbrList="setRcvList" 
                    @onClose="isDialog(1)"
                ></SmsMmbrListMgmt>  
            </v-dialog>   
        </div>    
    </v-sheet>
</template>
<script>
import SmsSndMsgMgmt from './SmsSndMsgMgmt.vue';
import SmsMmbrListMgmt from './SmsMmbrListMgmt.vue';
import { insBizMsgSmsSnd } from '../../api/othr.js'

export default {
    name: 'SmsSndWrt',

    components: {
        SmsSndMsgMgmt,
        SmsMmbrListMgmt,
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {

    },

    watch: {

    },

    methods: {
        onClickBtn:function(key){
            switch (key) {
                case 0:
                    this.onSmsSend()
                    break
                case 1:
                    this.onDialog(0)
                    break
                default:
                    break
            }
        },
        onSmsSend:function(){      
            if(this.$refs.form.validate()){

                let pObj = {  
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,                
                    bizMsglist:[]
                }
    
                this.rcvList.forEach(item => {
    
                    let obj = {
                        sndMda:'2',
                        fcltyNum:this.$store.getters.getFcltyNum,
                        userNm:this.$store.getters.getUserNm,
                        bzClcd:'',
                        bzPk:0,
                        eDocClcd:'99',
                        prcClcdAr:[],
                        prcCnfmAr:[],
                        rcvCelphn1:item.rcvCelphn_1,
                        rcvCelphn2:item.rcvCelphn_2,
                        rcvCelphn3:item.rcvCelphn_3,
                        rcvNm:item.name,
                        rcvInfm:item.rltns,
                        msgTtl:'SMS 발송관리 >  문자전송',
                        msgCntnt:this.msgCntnt,
                    }
    
                    pObj.bizMsglist.push(obj)
                });
    
                if(pObj.bizMsglist.length > 0){
                    insBizMsgSmsSnd(pObj)
                        .then((response) => ( this.onSmsSendAfter(response.data) ))
                        .catch((error) => console.log('connect error /othr/insBizMsgNoSnd : ' + error))
                }else{
                    this.snackControll([true, 5000, '수신자를 선택해주세요.', 'error'])
                }
            }
        },
        onSmsSendAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.rcvList.splice(0)
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        addRow:function(){
            this.rcvList.push({
                rcvClcd:'99',
                name:'',
                rltns:'',
                dtTyp:'dict',
                mmbr:0,
                rcvCelphn:'',
                rcvCelphn_1:'',
                rcvCelphn_2:'',
                rcvCelphn_3:'',
            })
        },
        delRow:function(idx){
            this.rcvList.splice(idx,1)
        },
        rcvDplcChk:function(idx){
            let rcvCelphn = this.rcvList[idx].rcvCelphn_1+this.rcvList[idx].rcvCelphn_2+this.rcvList[idx].rcvCelphn_3
            console.log(rcvCelphn)
        },
        setRcvList:function(list){
            list.forEach(el => {
                let obj = {
                    rcvClcd:'99',
                    name:'',
                    rltns:'',
                    dtTyp:'',
                    mmbr:0,
                    rcvCelphn:'',
                    rcvCelphn_1:'',
                    rcvCelphn_2:'',
                    rcvCelphn_3:'',
                }

                switch (el.dtTyp) {
                    case 'bnfcr':
                        obj.mmbr = el.bnMmbrPk
                        obj.name = el.name
                        obj.rltns = '수급자'
                        obj.dtTyp = el.dtTyp
                        obj.rcvCelphn = el.celphn
                        obj.rcvCelphn_1 = el.celphn_1
                        obj.rcvCelphn_2 = el.celphn_2
                        obj.rcvCelphn_3 = el.celphn_3
                        break;
                    case 'fmly':
                        obj.mmbr = el.mmbrPk
                        obj.name = el.name
                        obj.rltns = el.bnName+' '+el.rltns
                        obj.dtTyp = el.dtTyp
                        obj.rcvCelphn = el.celphn
                        obj.rcvCelphn_1 = el.celphn_1
                        obj.rcvCelphn_2 = el.celphn_2
                        obj.rcvCelphn_3 = el.celphn_3
                        break;
                    case 'emp':
                        obj.mmbr = el.mmbrPk
                        obj.name = el.name
                        obj.rltns = '직원'
                        obj.dtTyp = el.dtTyp
                        obj.rcvCelphn = el.celphn
                        obj.rcvCelphn_1 = el.celphn_1
                        obj.rcvCelphn_2 = el.celphn_2
                        obj.rcvCelphn_3 = el.celphn_3
                        break;
                    default:
                        break;
                }
                
                if(obj.rcvCelphn != '' && this.rcvList.findIndex(v=>v.rcvCelphn == obj.rcvCelphn) == -1){
                    this.rcvList.push(obj)
                }
            });
        },
        onDialog:function(key){
            if(key == 1) this.smsMmbrListMgmt = 1
            this.dialog.splice(key,1,true)
        },
        isDialog:function(key){
            if(key == 1) this.smsMmbrListMgmt = -1
            this.dialog.splice(key,1,false)
        },
        setSndMsg:function(msg){
            this.msgCntnt = msg.itmCntnt
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        smsMmbrListMgmt:-1,
        msgCntnt:'',
        rcvHeader:[
            { text: '수신자', value: '', align: 'center'},
            { text: '수신자 정보', value: '', align: 'center'},
            { text: '수신자 연락처', value: '', align: 'center'},
            { text: '처리', value: '', align: 'center'},
        ],
        rcvList:[], //수신자 목록
        dialog:[false,false,],
        filledBtn: [
            { icon: 'mdi-send-variant', class: 'ml-1 white--text', color:'blueBtnColor', text: '발송', type: 'action', width:'600px', disabled: false, },
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '문자내용 관리', type: 'action', width:'600px', disabled: false, },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }), 
}
</script>