import Vue from 'vue'
import App from './App.vue'
import { router } from './router/index.js'
import { store } from "./store.js"
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueDaumPostcode from "vue-daum-postcode"
import vueEsign from 'vue-esign'
import { customJs } from "./api/global/common"
import vueCookies from "vue-cookies";
import crypto from 'crypto-js'

import moment from 'moment'
import 'moment/locale/ko'

import './registerServiceWorker'

Vue.prototype.$axios = axios
Vue.prototype.$moment = moment
Vue.prototype.$crypto = crypto

new Vue({
    vuetify,
    router,
    VueDaumPostcode,
    customJs,
    store: store,
    render: h => h(App)
}).$mount('#app')

Vue.use(vueEsign)
Vue.use(vueCookies)

Vue.$cookies.config("30d")