<template>
    <v-sheet class="overflow-y-auto" width="100%" height="620">
        <div class="pr-2">
            <v-form ref="form" lazy-validation>
                <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="d-flex justify-center align-center greyE01" cols="2">
                        <span class="black--text font-weight-medium py-2">계획일시</span>
                    </v-col>
                    <v-col class="pa-1 ma-0 d-flex justify-left align-center" cols="5">
                        <span>{{prgOpPlnInfo.opDt?.beforeDateFormatDot()}}&nbsp;/</span>
                        <span class="pl-1">{{prgOpPlnInfo.optm}}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center greyE01" cols="2">
                        <span class="black--text font-weight-medium py-2">프로그램 그룹</span>
                    </v-col>
                    <v-col class="pa-1 ma-0 d-flex justify-left align-center" cols="3">
                        <div class="pt-1 overflow-y-auto" style="height:50px;">
                            <v-chip 
                                v-for="(itm, j) in prgOpPlnInfo.bnfcrgrnms" :key="j"
                                class="pa-2 mr-1"    
                                label            
                                color="grey" 
                                outlined                           
                                small        
                                style="background-color: white !important;"                                                          
                                >
                                {{itm}}
                            </v-chip>
                        </div>
                    </v-col>
                </v-row>

                <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="d-flex justify-center align-center greyE01" cols="2">
                        <span class="black--text font-weight-medium py-2">운영일시</span>
                    </v-col>
                    <v-col class="pa-1 ma-0 d-flex justify-left align-center" cols="10">
                        <!-- 운영일시 textfield -->
                        <!-- <span class="d-inline-block" style="width:22%;">
                                <v-menu ref="menu1" v-model="menu[0]" min-width="100" max-width="300"
                                    :close-on-content-click="false" :return-value.sync="opDt" transition="scale-transition"
                                    offset-y dense>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="opDt" :rules="[rules.required]" readonly hide-details
                                            outlined dense v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="opDt" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn class="font-weight-bold" color="blueBtnColor" text
                                            @click="isModal(0)">Cancel</v-btn>
                                        <v-btn class="font-weight-bold" color="blueBtnColor" text
                                            @click="$refs.menu1.save(opDt),items1.mc=''">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                        </span>
                        <span class="d-inline-block ml-1" style="width: 60px;">
                            <v-menu ref="menu2" v-model="menu[1]" :close-on-content-click="false" :nudge-right="40"
                                :return-value.sync="opTmStrt" transition="scale-transition" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="opTmStrt" width="140" :rules="[rules.required]" readonly
                                        hide-details outlined dense v-bind="attrs" v-on="on">
                                    </v-text-field>
                                </template>
                                <v-time-picker v-if="menu[1]" v-model="opTmStrt" scrollable
                                    @click:minute="$refs.menu2.save(opTmStrt)">
                                </v-time-picker>
                            </v-menu>
                        </span>
                        <span class="white d-inline-block pa-1" style="width: 14px;">
                            ~
                        </span>
                        <span class="d-inline-block ml-1" style="width: 60px;">
                            <v-menu ref="menu3" v-model="menu[2]" :close-on-content-click="false" :nudge-right="40"
                                :return-value.sync="opTmEnd" transition="scale-transition" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="opTmEnd" width="140" :rules="[rules.required]" readonly
                                        hide-details outlined dense v-bind="attrs" v-on="on">
                                    </v-text-field>
                                </template>
                                <v-time-picker v-if="menu[2]" v-model="opTmEnd" scrollable
                                    @click:minute="$refs.menu3.save(opTmEnd)">
                                </v-time-picker>
                            </v-menu>
                        </span> -->
                        <span class="white d-inline-block py-1" style="width: 150px;">
                            <CmmDateComp v-model="opDt" :required="true"></CmmDateComp>
                        </span>
                        <span class="d-inline-block ml-1 mr-1" style="width: 60px;">
                            <CmmTimeField v-model="opTmStrt" :required="true"></CmmTimeField>
                        </span>
                        ~
                        <span class="d-inline-block ml-1" style="width: 60px;">
                            <CmmTimeField v-model="opTmEnd" :required="true"></CmmTimeField>
                        </span>
                    </v-col>               
                </v-row>

                <v-row style="border-top: 1px solid #dbdbdb !important;" no-gutters>
                    <v-col class="d-flex justify-center align-center greyE01" cols="2">
                        <span class="black--text font-weight-medium ">프로그램</span>
                    </v-col>
                    <v-col class="pa-1 ma-0 d-flex justify-left align-center" cols="5">
                        <v-text-field
                            v-model="items1.prgNm" :rules="[rules.required]" :key="forceRender"
                            color="blue" height="28" ref="info"
                            hide-details outlined dense>
                        </v-text-field>
                    </v-col>
                    <v-col class="d-flex justify-center align-center greyE01" cols="2">
                        <span class="black--text font-weight-medium py-2">지표</span>
                    </v-col>
                    <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="3">
                        <!-- 지표 textfield -->
                        <span class="d-inline-block ml-2" style="width:130px;">
                            <v-text-field
                                v-model="idxCdNm" :rules="[rules.required]" :key="forceRender"
                                color="blue" height="28" ref="info"
                                hide-details outlined dense readonly>
                            </v-text-field>
                        </span>
                    </v-col>
                </v-row>
                <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="d-flex justify-center align-center greyE01" cols="2">
                        <span class="black--text font-weight-medium " style="text-align:center">기능회복훈련<br>(급여제공기록지포함)</span>
                    </v-col>
                    <v-col class="pa-1 ma-0" cols="10">
                        <!-- 기능회복훈련 checkbox -->
                        <div
                            v-for="(item, i) in $parent.$parent.$parent.$parent.$parent.prtCd38" :key="i"
                            class="d-inline-block" style="width: 200px; height: 30px;">
                            <v-checkbox
                                v-model="fncRcvCd"
                                :label="item.valcdnm" :value="item.valcd"
                                class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;">
                            </v-checkbox>
                        </div>
                    </v-col>
                </v-row>
                <v-row style="border-top: 1px solid #dbdbdb !important;" no-gutters>
                    <v-col class="d-flex justify-center align-center greyE01" cols="2">
                        <span class="black--text font-weight-medium py-2">장소</span>
                    </v-col>
                    <v-col class="pa-1 ma-0 d-flex justify-left align-center" cols="5">
                        <v-text-field
                            v-model="items1.plc" :rules="[rules.required]"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </v-col>
                    <v-col class="d-flex justify-center align-center greyE01" cols="2">
                        <span class="black--text font-weight-medium py-2">진행자</span>
                    </v-col>
                    <v-col class="pa-1 ma-0 d-flex justify-left align-center" cols="3">
                        <div class="d-inline-block" style="width: 200px;">
                            <v-text-field
                                v-model="items1.mc" :rules="[rules.required]" :key="forceRender"
                                color="blue" ref="info" height="28"
                                hide-details outlined dense readonly>
                            </v-text-field>
                        </div>
                        <div style="width:85%" >
                            <v-btn class="brown lighten-3 d-inline-block ml-1" style="padding:0px 1px 0px 2px !important"
                                dark small depressed min-width="30" height="28" @click="onEmpSlct">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                    </template>
                                    <span>직원선택</span>
                                </v-tooltip>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-row style="border-top: 1px solid #dbdbdb !important;" no-gutters>
                    <v-col class="d-flex justify-center align-center greyE01" cols="2">
                        <span class="black--text font-weight-medium ">준비물</span>
                    </v-col>
                    <v-col class="pa-1 ma-0 d-flex justify-center align-center" cols="5">
                        <v-textarea dense outlined hide-details rows="4" v-model="items1.mtrl" class="rmrksArea" no-resize :rules="[rules.required]"></v-textarea>
                    </v-col>
                    <v-col class="d-flex justify-center align-center greyE01" cols="2">
                        <span class="black--text font-weight-medium py-2">사진첨부</span>
                        <span>
                            <v-file-input class="pl-1" v-model="images" :accept="imgAccept" dense hide-details
                            truncate-length="5" multiple counter prepend-icon="mdi-camera-outline" hide-input
                            @change="upImgFile()"></v-file-input>
                        </span>
                    </v-col>
                    <v-col class="pa-0 ma-0 d-flex justify-left align-center overflow-y-auto" cols="3" style="min-height:100px;max-height:100px;">
                        <span class="ma-1 d-inline-block" v-for="(item, i) in imageRes" :key="i">
                            <imageCard 
                                ref="imageCard" 
                                :imgSrc="item.src" 
                                :imgWidth="'80'" 
                                @isEvent="deleteImg(i)"
                                @onDown="fileDownload(i)"
                            >
                            </imageCard>
                        </span>
                    </v-col>
                </v-row>

                <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="d-flex justify-center align-center greyE01" cols="2">
                        <span class="black--text font-weight-medium" style="text-align:center">목표</span>
                    </v-col>
                    <v-col class="pa-1 ma-0 d-flex justify-left align-center" style="">
                        <v-textarea dense outlined hide-details rows="5" v-model="items1.goal" class="rmrksArea" no-resize  
                            :rules="[rules.required]"></v-textarea>
                    </v-col>
                </v-row>
                <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="d-flex justify-center align-center greyE01" cols="2">
                        <span class="black--text font-weight-medium" style="text-align:center">프로그램&nbsp;내용<br />(진행과정)</span>
                    </v-col>
                    <v-col class="pa-1 ma-0 d-flex justify-left align-center" style="">
                        <v-textarea dense outlined hide-details rows="13" v-model="items1.prgCntnt" class="rmrksArea" no-resize  
                            :rules="[rules.required]"></v-textarea>
                    </v-col>
                </v-row>
                <v-row style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="d-flex justify-center align-center greyE01" cols="2">
                        <span class="black--text font-weight-medium py-2" style="text-align:center">전반적 평가</span>

                    </v-col>
                    <v-col class="pa-1 ma-0 d-flex justify-left align-center" style="">
                            <!-- 프로그램 내용 textfield -->
                        <v-textarea dense outlined hide-details rows="4" v-model="eval" :rules="[rules.required]" class="rmrksArea" no-resize ></v-textarea>
                    </v-col>
                </v-row>
            </v-form>
        </div>
    </v-sheet>
</template>

<script>
import imageCard from '../commons/imageCard.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

import { remoteURL } from '@/api/baseAxios';
import {
    selPrgOpRecInfo, selPrgOpPlnInfo, insPrgOpRecFiles, delPrgOpRec, selPrgInfInfo, selPrgTypMnglist
} from '../../api/prgrm.js';
import { tmpFileUpload, selAtchdFileList, fileDownload, delAtchdFile } from '../../api/index.js';

export default {
    name: 'PrgrmRecTab1',

    props: {
        tab: Number,

    },
    components: {
        imageCard,
        CmmDateComp,
        CmmTimeField,
    },

    computed: {
        getCd40: function () {
            return this.$parent.$parent.$parent.$parent.$parent.prtCd40
        },
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getCd40: function (arr) {
            if(arr?.length > 0){
                arr.forEach(e => {
                    if(this.idxCd == e.valcd)
                        this.idxCdNm = e.valcdnm
                });
            }
        },
        getSubEvt: function (bol) {
            if(bol && this.rmCnfm){
                this.rmAtchdFile()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmCnfm = false
            }
            else if(bol && this.empChc){
                let obj = this.$store.getters.getSubOutObj
                this.items1.mc = obj.name
                this.items1.mcMmbrPk = obj.mmbrPk
                
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.empChc = false
                ++this.forceRender
            }
        },
    },

    created: function () {

    },

    mounted: function () {
        this.$nextTick(function () {
            if(this.$parent.$parent.$parent.$parent.$parent.prtCd40.length > 0){
                this.$parent.$parent.$parent.$parent.$parent.prtCd40.forEach(e => {
                    if(this.idxCd == e.valcd)
                        this.idxCdNm = e.valcdnm
                });
            }
        })
    },

    methods: {
        // 프로그램 운영기록 한건 조회
        selPrgOpRecInfo: async function (pk) {
            this.fncRcvCd.splice(0)
            this.getAtchdFileList()

            await selPrgOpRecInfo(this.$store.getters.getFcltyNum, pk)
                .then((response) => (this.selPrgOpRecInfoAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgOpRecInfo : ' + error))
        },
        selPrgOpRecInfoAfter: function (res) {
            this.eval = res.eval
            this.idxCdNm = res.idxCdNm
            
            res.fncRcvCd.forEach(itm => {
                this.fncRcvCd.push(itm)
            });

            this.items1.eval = res.eval
            this.items1.fncRcvCd = res.fncRcvCd
            this.items1.goal = res.goal
            this.items1.idxCdNm = res.idxCdNm
            this.items1.mc = res.mc
            this.items1.mcMmbrPk = res.mcMmbrPk
            this.items1.mtrl = res.mtrl
            this.items1.opDt = res.opDt
            this.items1.opTmHhEnd = res.opTmHhEnd
            this.items1.opTmHhStrt = res.opTmHhStrt
            this.items1.opTmMmEnd = res.opTmMmEnd
            this.items1.opTmMmStrt = res.opTmMmStrt
            this.items1.plc = res.plc
            this.items1.prgCntnt = res.prgCntnt
            this.items1.prgInfPk = res.prgInfPk
            this.items1.prgOpPlnPk = res.prgOpPlnPk
            this.items1.prgOpRecPk = res.prgOpRecPk
            this.items1.wrtr = res.wrtr

            if(this.$parent.$parent.$parent.$parent.$parent.prgOpRecPk > 0)
                this.items1.prgNm = res.prgNm
            
            this.opDt = res.opDt?.beforeDateFormatHyp()
            this.opTmStrt = res.opTmHhStrt+':'+res.opTmMmStrt
            this.opTmEnd = res.opTmHhEnd+':'+res.opTmMmEnd
        },
        // 프로그램 정보 한건 조회
        getPrgInfInfo: async function () {
            await selPrgInfInfo(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.prgInfPk)
                .then((response) => (this.getPrgInfInfoAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgInfInfo : ' + error))
        },
        getPrgInfInfoAfter: function (res) {
            this.eval = res.eval
            this.prgTyPk = res.prgTypMngPk

            this.items1 = Object.assign({})
            this.items1.eval = res.eval
            this.items1.fncRcvCd = res.fncRcvCd
            this.items1.goal = res.goal
            this.items1.idxCdNm = res.idxCdNm
            this.items1.mc = res.mc
            this.items1.mcMmbrPk = res.mcMmbrPk
            this.items1.mtrl = res.mtrl
            this.items1.opDt = res.opDt
            this.items1.opTmHhEnd = res.opTmHhEnd
            this.items1.opTmHhStrt = res.opTmHhStrt
            this.items1.opTmMmEnd = res.opTmMmEnd
            this.items1.opTmMmStrt = res.opTmMmStrt
            this.items1.plc = res.plc
            this.items1.prgCntnt = res.prgCntnt
            this.items1.prgInfPk = res.prgInfPk
            this.items1.prgOpPlnPk = res.prgOpPlnPk
            this.items1.prgOpRecPk = res.prgOpRecPk
            this.items1.wrtr = res.wrtr

            if(res.prgNm != null)
                this.items1.prgNm = res.prgNm

            //this.$refs.form.resetValidation();
            this.selPrgTypMnglist()
        },
        // 프로그램 계획 한건 조회
        getPrgOpPlnInfo: function (pk) {
            this.fncRcvCd.splice(0)

            selPrgOpPlnInfo(this.$store.getters.getFcltyNum, pk)
                .then((response) => (this.getPrgOpPlnInfoAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgOpPlnInfo : ' + error))
        },
        getPrgOpPlnInfoAfter: function (res) {
            if(this.opDt == '')
                this.opDt = this.$moment(res.opDt, 'YYYYMMDD').format('YYYY-MM-DD')

            this.prgOpPlnInfo.opDt = ''
            this.prgOpPlnInfo.optm = ''
            this.prgOpPlnInfo.bnfcrgrnms.splice(0)

            if(res != null && res != '') {
                this.prgOpPlnInfo.opDt = res.opDt
                this.prgOpPlnInfo.optm = res.optm
                // this.opDt = res.opDt

                res.bnfcrgrnms.forEach(el => {                    
                    this.prgOpPlnInfo.bnfcrgrnms.push(el)
                });

                // this.opDt = this.prgOpPlnInfo.opDt?.beforeDateFormatHyp()
            }

            if(this.$parent.$parent.$parent.$parent.$parent.prgOpRecPk == 0)
                this.items1.prgNm = res.prgNm
            else if(this.$parent.$parent.$parent.$parent.$parent.prgOpRecPk == null){
                this.items1.prgNm = res.prgNm

                this.eval = res.eval
                this.idxCdNm = res.idxCdNm
                res.fncRcvCd.forEach(itm => {
                    this.fncRcvCd.push(itm)
                });

                this.items1.eval = res.eval
                this.items1.fncRcvCd = res.fncRcvCd
                this.items1.goal = res.goal
                this.items1.idxCdNm = res.idxCdNm
                this.items1.mc = res.mc
                this.items1.mcMmbrPk = res.mcMmbrPk
                this.items1.mtrl = res.mtrl
                this.items1.opDt = res.opDt
                this.items1.opTmHhEnd = res.opTmHhEnd
                this.items1.opTmHhStrt = res.opTmHhStrt
                this.items1.opTmMmEnd = res.opTmMmEnd
                this.items1.opTmMmStrt = res.opTmMmStrt
                this.items1.plc = res.plc
                this.items1.prgCntnt = res.prgCntnt
                this.items1.prgInfPk = res.prgInfPk
                this.items1.prgOpPlnPk = res.prgOpPlnPk
                this.items1.prgOpRecPk = res.prgOpRecPk
                this.items1.wrtr = res.wrtr

                this.selPrgTypMnglist()
            }
        },
        // 프로그램 유형 관리 조회
        selPrgTypMnglist: function () {
            selPrgTypMnglist(this.$store.getters.getFcltyNum)
                .then((response) => (this.selPrgTypMnglistAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgTypMnglist : ' + error))
        },
        selPrgTypMnglistAfter: function (res) {
            res.forEach(e => {
                if(e.prgTypMngPk==this.prgTyPk){
                    this.idxCdNm = e.prgTypNm
                }
            });
        },
        rmAtchdFile: function() {
            let pk = 0
            if (this.key == 1)
                pk = this.fileRes[this.idx].atchdFilePk
            else
                pk = this.imageRes[this.idx].atchdFilePk

            if (pk > 0) {
                delAtchdFile(this.$store.getters.getFcltyNum, pk)
                    .then((response) => (this.delAtchdFileAfter(response.data)))
                    .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
            }
        },
        onShow: function (recpk) {
            this.selPrgOpRecInfo(recpk)
        },
        // 프로그램 운영기록 작성
        onSave: function () {
            if(this.$refs.form.validate()){
                this.$emit('progressOff')
                let opTmHMStrt = this.opTmStrt?.split(":")
                let opTmHMEnd = this.opTmEnd?.split(":")
                let obj = {
                    opDt: this.opDt?.afterDateFormatHyp(),
                    prgOpPlnPk: this.$parent.$parent.$parent.$parent.$parent.prgOpPlnPk,
                    prgInfPk: this.$parent.$parent.$parent.$parent.$parent.prgInfPk,
                    opTmHhStrt: opTmHMStrt[0],
                    opTmMmStrt: opTmHMStrt[1],
                    opTmHhEnd: opTmHMEnd[0],
                    opTmMmEnd: opTmHMEnd[1],
                    fncRcvCd: this.fncRcvCd,
                    plc: this.items1.plc,
                    mcMmbrPk: this.items1.mcMmbrPk,
                    mc: this.items1.mc,
                    mtrl: this.items1.mtrl,
                    prgNm: this.items1.prgNm,
                    prgCntnt: this.items1.prgCntnt,
                    eval: this.eval,
                    wrtr: this.items1.mc,
                    goal: this.items1.goal,
                    userNm: this.$store.getters.getUserNm,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    prgOpRecPk: this.$parent.$parent.$parent.$parent.$parent.prgOpRecPk,
                    files:[],
                }
                this.imageRes.forEach(x => {
                    if (x.atchdFilePk == undefined) {
                        obj.files.push(x)
                    }
                })

                insPrgOpRecFiles(obj)
                    .then((response) => (this.insPrgOpRecFilesAfter(response.data)))
                    .catch((error) => console.log('connect error prgrm/insPrgOpRecFiles : ' + error))
            }
            else{
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 2500                              // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = '필수 항목을 입력해주세요.'       // 경고 타이틀
                obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        insPrgOpRecFilesAfter: function (res) {
            this.$emit('prgrmRecTrigger', 'ins', res)
        },
        // 프로그램 운영기록 삭제
        onDelete(){
            if(this.items1.prgOpRecPk != undefined){
                let obj = {
                    fcltyNum : this.$store.getters.getFcltyNum,
                    prgOpRecPk  : this.items1.prgOpRecPk,
                    userNm: this.$store.getters.getUserNm,
                } 
                delPrgOpRec(obj)
                    .then((response) => ( this.delPrgOpRecAfter(response.data)))
                    .catch((error) => console.log(error))  
            }
        },
        delPrgOpRecAfter(res){
            this.$emit('prgrmRecTrigger', 'del', res)
        },    
        delAtchdFileAfter: function (res) {
            if (res.statusCode == 200) {
                let obj = {}
                obj.actv = true              // 경고 노출여부
                obj.tmr = 2500               // 경고 노출시간(-1: 무제한)
                obj.type = 'info'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message      // 경고 타이틀
                obj.cntnt = []               // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
                this.getAtchdFileList()
                this.idx = 0
            }
            else {
                let obj = {}
                obj.actv = true              // 경고 노출여부
                obj.tmr = 5000               // 경고 노출시간(-1: 무제한)
                obj.type = 'error'           // 경고 유형 (success, info, warning, error)
                obj.title = res.message      // 경고 타이틀
                obj.cntnt = []               // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        // 파일 멀티 업로드
        upImgFile: function () {
            let formData = new FormData();
            if (this.images != null) {
                this.images.forEach(element => {
                    formData.append("files", element)
                })
                if (this.images.length > 0) {
                    tmpFileUpload(formData)
                        .then((response) => (this.upImgFileAfter(response.data)))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }
        },
        upImgFileAfter: function (res) {
            res.files.forEach(x => {
                x.src = remoteURL + 'opr/imgPrview?fileNm=' + x.fileNm + '&filePth=' + x.filePth + '&fileKndCd=' + x.fileKndCd
                this.imageRes.push(x)
            })
            this.images = null
        },
        // 첨부파일 목록 조회
        getAtchdFileList: function () {
            this.images = null
            this.imageRes.splice(0)
            let fcltyNum = this.$store.getters.getFcltyNum
            let clcd = '4'
            
            selAtchdFileList(fcltyNum, clcd, this.$parent.$parent.$parent.$parent.$parent.prgOpRecPk)
                .then((response) => (this.getAtchdFileListAfter(response.data)))
                .catch((error) => console.log('upload error /opr/selAtchdFileList : ' + error))
        },
        getAtchdFileListAfter: function (res) {
            this.images = null
            this.imageRes.splice(0)

            res.forEach(x => {
                if (x.fileKndCd == "2") {
                    x.src = remoteURL + 'opr/imgView?fileNm=' + x.fileNm + '&filePth=' + x.filePth + '&fileKndCd=' + x.fileKndCd
                    this.imageRes.push(x)
                } else {
                    let fileNm = x.fileNm.split(".")
                    x.ext = fileNm[1]
                    this.fileRes.push(x)
                }
            })

        },
        // 첨부파일 다운로드
        fileDownload: function (idx) {
            if (this.imageRes[idx] != undefined) {
                fileDownload(this.imageRes[idx])
                    .then((response) => (this.fileDownLoaderAfter(response)))
                    .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
            }
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [fileNameMatch] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [, fileName] = fileNameMatch.split('=');
            }
            link.href = url;

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        onModal: function (value) {
            if(value == 1)
                this.prtWrtDt = this.opDt
        },
        isModal: function (value) {
            this.menu.splice(value, 1, false)
        },
        deleteImg(idx) {
            if (this.imageRes[idx].atchdFilePk != undefined) {
                this.key = 2
                this.idx = idx

                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'orange darken-3'
                obj.title = '프로그램 운영기록 첨부사진 삭제'
                obj.cntnt = ['프로그램 운영기록에 첨부된 사진을 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.rmCnfm = true
            } else {
                this.imageRes.splice(idx, 1)
            }
        },
        resetPage: function () {
            this.items1 = Object.assign({}, {bnfcrgrNms:[], prgNm:'', fncRcvCd: [], plc: "", mc: "", mtrl: "", prgCntnt: "", imprvRfl: "", imprvOpn: ""})
            this.opDt = ''
            this.opTmStrt = ''
            this.opTmEnd = ''
            this.idxCdNm = ''
            this.idxCd = ''
            this.images = null
            this.eval = ''
            this.$refs.form.resetValidation()
        },
        onEmpSlct: function () {
            let obj = {}
            obj.empTab = true
            obj.extEmpTab = true
            obj.wrtDt = this.$moment(this.opDt,'YYYY-MM-DD').format('YYYYMMDD')

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 700, prsst: true, typ: '3001', inObj: obj, outObj: null, runEvt: false,
            });

            this.empChc = true
        },
    },  

    data: () => ({
        fncRcvCd: [],
        prtWrtDt:'',
        rmCnfm: false,
        empChc: false,
        menu: [false, false, false],
        images:null, 
        imageRes:[],  
        items: {},
        items1: {},
        items2: [],
        prgOpPlnInfo:{opDt:'',optm:'',bnfcrgrnms:[]},
        opDt: '',
        eval: '',
        opTmStrt: '',
        opTmEnd: '',
        idxCd: '',
        forceRender: 0,
        btnDisabled: true,
        prgTyPk: 0,
        idxCdNm: '',
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        imgAccept:'image/*',
    }),
};
</script>