<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-2" color="" min-width="1260" max-width="1560" height="835">
        <div class="px-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span>6-2. 생활실 현황</span>
                </span>
            </div>
            <v-spacer></v-spacer>
        </div>
        <v-row class="justify-left mt-3 mx-3">
            <CmmLvnExpnBtn                
                v-model="isLvnStt"
                class="mr-1"
                @onList="lvnSttApply">
            </CmmLvnExpnBtn>
            
            <span class="d-inline-block" style="width: 130px;">
                <v-text-field class="ma-0 pa-0 nameSearch" height="35px" background-color="white" append-icon="mdi-magnify"
                    single-line rounded outlined dense filled hide-details label="생활실조회" @input="setSearchText">
                </v-text-field>
            </span>
        </v-row>
        <div class="mt-5 ml-3 mr-2">
            <v-data-table         
                class="dft-hvr-tbl"  locale="ko" height="700"                                         
                :headers="sttsHeaders" 
                :items="sttsList" 
                :search="schDummy"
                :custom-filter="customFilter" 
                fixed-header
                hide-default-footer dense
                disable-pagination>                           
                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="clmsBodyCommon tableBR">{{ item.bldNm }}</td>
                        <td class="clmsBodyCommon tableBR">{{ item.bldFlr }}</td>
                        <td class="clmsBodyCommon tableBR">{{ item.lvnRmNm }}</td>
                        <td class="clmsBodyCommon tableBR">{{ item.lvnRmTypNm }}</td>
                        <td class="clmsBodyCommon tableBR">{{ item.ord }}</td>
                        <td class="clmsBodyCommon tableBR">{{ item.fxNum }}</td>
                        <td class="clmsBodyCommon tableBR">{{ item.lvnRmCnt }}</td>
                        <td class="clmsBodyCommon tableBR">{{ item.rmnCnt }}</td>
                        <td class="clmsBodyCommon pa-1">
                            <v-row no-gutters>
                                <v-col cols="12" class="overflow-y-auto" style="height:43px;">
                                    <div class="d-inline-block" style="width:100px;" v-for="(cdItme, n) in item.bnfcrs" :key="n">
                                        <div class="d-flex">
                                            <span>{{ cdItme.name }}</span>
                                            <span>&#40;</span>                                            
                                            <span>
                                                <v-img class="ic-sex" v-if="cdItme.gndr == 'F'" style=""
                                                src="../../assets/icon/ic-female.svg"></v-img>
                                                <v-img class="ic-sex" v-else-if="cdItme.gndr == 'M'"
                                                src="../../assets/icon/ic-male.svg"></v-img>
                                            </span>
                                            <span>{{ cdItme.age }}</span>
                                            <span>&#41;</span>
                                        </div>                                        
                                    </div>                                    
                                </v-col>
                            </v-row >
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>

    </v-sheet>
</template>

<script>
import { selLvnRmStsList, } from '../../api/opr.js';
import CmmLvnExpnBtn from '../../components/commons/CmmLvnExpnBtn';
export default {
    name: 'LvnRmStts',

    components: {
        CmmLvnExpnBtn
    },

    created: function () {
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.selLvnRmStsList()
        })
    },

    computed: {

    },

    watch: {
    },

    methods: {
        selLvnRmStsList: function () {
            this.sttsList.splice(0)
            selLvnRmStsList(this.$store.getters.getFcltyNum)
                .then((response) => (this.selLvnRmStsListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selLvnRmStsList : ' + error))
        },
        selLvnRmStsListAfter: function (res) {
            this.remainder = 0
            res.forEach(list => {
                list.bnfcrs = []
                if (list.jsonBnfcr != null) {
                    let jsonBnfcr = JSON.parse(list.jsonBnfcr.value)
                    jsonBnfcr.forEach(x => {
                        list.bnfcrs.push(x)
                    });
                }

                this.sttsList.push(list)
            });
        },
        expandClose: function (index) {
            switch (index) {
                case 0: this.$refs.exBtnClose1.isClose()
                    break;
                default: this.$refs.exBtnClose1.isClose()
                    break;
            }
        },
        filterSearchD1: function (sch) {

            if (sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function (item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.lvnRmNm.includes(this.schField)

            let col1 = true
            if (this.schChkField1.length != 0) {
                this.schChkField1.forEach((lvnRmNm, i) => {
                    if (i == 0) col1 = filter.lvnRmNm == lvnRmNm
                    else col1 = col1 || filter.lvnRmNm == lvnRmNm
                });
            }

            let col2 = true

            // 생활실 검색
            let lvnRunChc = this.aslLvnStt.filter( v => v.isOn == true )
            if(lvnRunChc.length == 0)
                col2 = false
            else{
                lvnRunChc.forEach((itm, i) => {
                    if(i == 0) col2 = filter.lvnRmInfPk == itm.lvnRmInfPk
                    else col2 = col2 || filter.lvnRmInfPk == itm.lvnRmInfPk
                });
            }

            

            return col0 && col1 && col2;
        },        

        // 생활실 선택 컴포넌트 변경 이벤트
        lvnSttApply: function (arr){
            this.aslLvnStt = arr
            this.schDummy = String(Number(this.schDummy)+1)
        },
    },

    data: () => ({
        sttsHeaders: [
            { text: '건물', value: 'bldNm', width: '120' ,sortable: true, align: 'center', class: 'py-4  black--text greyE01 fontOneRem' },
            { text: '층', value: 'bldFlr', width: '80' ,sortable: true, align: 'center', class: 'black--text greyE01 fontOneRem' },
            { text: '생활실', value: 'lvnRmNm', width: '150' ,sortable: true, align: 'center', class: 'black--text greyE01 fontOneRem' },
            { text: '생활실구분', value: 'lvnRmTypNm', width: '120' ,sortable: true, align: 'center', class: 'black--text greyE01 fontOneRem' },
            { text: '순서', value: 'ord', width: '80' ,sortable: true, align: 'center', class: 'black--text greyE01 fontOneRem' },
            { text: '정원수', value: 'fxNum', width: '100' ,sortable: true, align: 'center', class: 'black--text greyE01 fontOneRem' },
            { text: '입실인원', value: 'lvnRmCnt', width: '120' ,sortable: true, align: 'center', class: 'black--text greyE01 fontOneRem' },
            { text: '잔여수', value: 'rmnCnt', width: '100' ,sortable: true, align: 'center', class: 'black--text greyE01 fontOneRem' },
            { text: '수급자', value: 'bnfcrs', width: '' ,sortable: false, align: 'center', class: 'black--text greyE01 fontOneRem' },
        ],
        isLvnStt            : false,                            // 생활실 확장 활성화 여부
        aslLvnStt           : [],
        schDummy: '',
        schField: '',
        sttsList: [],
        bnfcrs: [],
        schChkField1: [],
    }),
};
</script>
<style>.fmlyLeterHeader {
    border-top: 1px solid #dbdbdb !important;
    border-right: 1px solid #dbdbdb !important;
    background-color: #ededed;
    text-align: center !important;
    font-size: 1rem !important;
}

.fmlyHeaderCheck div i {
    font-size: 20px !important;
}

.fmlyHeaderCheck div div div i {
    color: #666 !important;
}

.fmlyHeaderCheck .v-input__control {
    width: 25px !important;
}

.fmlyHeaderCheck label {
    color: rgba(0, 0, 0) !important;
}

.sltRow {
    background: #ebf6fe !important;
}

.disSltRow {
    background: white !important;
}
</style>