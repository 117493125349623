<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700" style="position:relative">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">식이정보관리</span>
            <v-spacer></v-spacer>
            <span v-if="progress">
                <v-progress-circular            
                :size="50"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>                
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="d-flex">
            <v-spacer></v-spacer>
            <span v-if="!progress">
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" @click="onClickBtn(list.text)"
                        :disabled="list.disabled" :class="list.class" samll height="30" :color="list.color" rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>                    
                </span>
            </span>        
        </div>
        <basicInfo class="mt-3" :bnMmbrPk="bnMmbrPk"></basicInfo>
        <div class="mt-3">
        <v-form ref="form" lazy-validation>
            <v-divider class=""></v-divider>
            <v-row no-gutters>
                <v-col class="d-flex py-2 justify-center align-center greyE01" cols="1">
                    <span class="black--text font-weight-medium">시작일자</span>
                </v-col>
                <v-col class="d-flex pa-1 justify-left align-center tableBL tableBR" cols="2">      
                    <span v-if="aplStrtMod" class="white d-inline-block" style="width: 134px;"> 
                        <CmmDateComp v-model="aplStrt" :required="true" :btnActv="true" :minDt="minDt"></CmmDateComp>
                    </span>                     
                    <span v-else class="d-flex justify-center align-center">
                        <span>{{aplStrt}}</span>
                        <span>
                             <v-btn
                                class="pl-2" color="blueBtnColor"
                                @click="onAplStrtMod"
                                icon small>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="25" v-bind="attrs" v-on="on">mdi-pencil-box-outline</v-icon>
                                    </template>
                                    <span>시작일자 수정</span>
                                </v-tooltip>
                            </v-btn>
                        </span>
                    </span>   
                    <span v-if="dummyItems.bnfcrDietsHisPk > 0" class="white d-inline-block pl-1">
                         <v-btn
                            class="pl-2" color="grey006"
                            @click="offAplStrtMod"
                            style="padding:0px 1px !important;"
                            icon small>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="25" v-bind="attrs" v-on="on">mdi-close-box-outline</v-icon>
                                </template>
                                <span>시작일자 취소</span>
                            </v-tooltip>
                        </v-btn>
                    </span>          
                </v-col>
                <v-col class="d-flex py-2 justify-center align-center greyE01" cols="1">
                    <span class="black--text font-weight-medium">종료일자</span>
                </v-col>
                <v-col class="d-flex pa-1 justify-left align-center tableBL tableBR" cols="2">      
                    {{items.aplEnd}}
                </v-col>
                <v-col class="d-flex py-2 justify-center align-center greyE01" cols="1">
                    <span class="black--text font-weight-medium">작성일자</span>
                </v-col>
                <v-col class="pa-1 ma-0 tableBL tableBR" cols="2">
                    <span class="white d-inline-block" style="width: 154px;"> 
                        <CmmDateComp v-model="items.wrtDt" :required="true" ></CmmDateComp>
                    </span> 
                </v-col>
                <v-col class="d-flex greyE01 justify-center align-center pa-0 ma-0" cols="1">                    
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>  
                <v-col class="tableBL d-flex justify-start align-center pa-1 ma-0" cols="2">
                    <span class="d-inline-block" style="width: 80px;">
                        <v-text-field
                            v-model="items.wrtr" :rules="[rules.required]" :key="forceRender"
                            color="blue" height="28" ref="info"
                            readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal(0)"
                        >
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>      
            <v-row no-gutters>
                <v-col class="d-flex py-2 justify-center align-center greyE01" cols="1">
                    <span class="black--text font-weight-medium">치료식이</span>
                </v-col>
                <v-col class="d-flex pa-1 justify-left align-center tableBL" cols="11"> 
                    <div v-for="(list, k) in fcltyList" :key="k" class="d-inline-block" style="width:100px;">
                        <v-checkbox
                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                            v-model="items.thrpCdAr" :ripple="false"
                            :label="list.itmVal1" :value="list.itmCd1" :key="forceRender"
                            hide-details>
                        </v-checkbox>          
                    </div>
                </v-col>                
            </v-row>
            <v-divider class=""></v-divider>
            <v-row no-gutters>
                <v-col class="d-flex py-2 justify-center align-center greyE01" cols="1">
                    <span class="black--text font-weight-medium">특이사항</span>
                </v-col>
                <v-col class="d-flex pa-1 justify-left align-center tableBL tableBR" cols="5">      
                    <v-textarea v-model="items.spclNote" class="rmrksArea"
                        rows="2" outlined dense hide-details no-resize :key="forceRender">
                    </v-textarea>           
                </v-col>         
                <v-col class="d-flex py-2 justify-center align-center greyE01" cols="1">
                    <span class="black--text font-weight-medium">비고</span>
                </v-col>
                <v-col class="d-flex pa-1 justify-left align-center tableBL" cols="5">      
                    <v-textarea v-model="items.rmrks" class="rmrksArea"
                        rows="2" outlined dense hide-details no-resize :key="forceRender">
                    </v-textarea>              
                </v-col>         
            </v-row>
            <v-divider class=""></v-divider>
            <v-divider class="mt-2"></v-divider>
            <v-row class="greyE01" no-gutters>
                <v-col class="d-flex py-1 justify-center align-center" cols="1">
                    <span class="black--text font-weight-medium"></span>
                </v-col>
                <v-col class="d-flex py-1 justify-center align-center tableBL" cols="1">
                    <span class="black--text font-weight-medium">시간</span>
                </v-col>
                <v-col class="d-flex py-1 justify-center align-center tableBL" cols="2">
                    <span class="black--text font-weight-medium">적용시작일자</span>
                </v-col>
                <v-col class="d-flex py-1 justify-center align-center tableBL" cols="2">
                    <span class="black--text font-weight-medium">적용종료일자</span>
                </v-col>
                <v-col class="d-flex py-1 justify-center align-center tableBL" cols="6">
                    <span class="black--text font-weight-medium">식사종류</span>
                </v-col>
            </v-row>
            <div v-for="(diets, k) in dietsList" :key="k" class="ma-0 pa-0">
                <v-divider class=""></v-divider>
                <v-row class="" no-gutters>
                    <v-col class="d-flex py-1 justify-center align-center greyE01" cols="1">
                        <span class="black--text font-weight-medium">{{diets.mlClcdNm}}</span>
                    </v-col>
                    <v-col class="d-flex py-1 justify-center align-center tableBL" cols="1">
                        <span class="d-inline-block" style="width: 80px;">
                            <CmmTimeField v-model="diets.mlTm" :ref="`cmmtime_`+(k)"  :required="true" :key="forceRender" @nxtFocus="nxtAction(k)"></CmmTimeField>
                        </span>
                    </v-col>
                    <v-col class="d-flex py-1 justify-center align-center tableBL" cols="2">
                        <span v-if="aplStrtMod" class="white d-inline-block" style="width: 134px;"> 
                            <CmmDateComp v-model="diets.aplStrt" :required="true" :btnActv="true" :minDt="diets.minDt" :key="forceRender"></CmmDateComp>
                        </span>  
                        <span v-else>
                            {{diets.aplStrt}}
                        </span>
                    </v-col>
                    <v-col class="d-flex py-1 justify-center align-center tableBL" cols="2">
                        {{diets.aplEnd}}
                    </v-col>
                    <v-col class="d-flex py-1 justify-center align-center tableBL" cols="6">
                        <div v-if="diets.mlClcd != '4' && diets.mlClcd != '5'" style="width:100%">
                            <v-radio-group
                                v-model="diets.mlTycd" :rules="[rules.required]" :key="forceRender"
                                class="radio-dft-Theme mt-0 pl-1 pt-1" hide-details row>                                            
                                <div v-for="(list, i) in vCd14" :key="i">
                                    <span class="d-inline-block pa-0 ma-0" >
                                        <v-radio :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>
                                    </span>
                                    <span v-if="list.valcd=='7'">&#40;</span>
                                    <span class="d-inline-block pa-0 ma-0" style="width:60px" v-if="list.valcd=='7'">
                                        <v-text-field v-model="diets.mlTyOtr" height="28" hide-details outlined dense></v-text-field>
                                    </span>           
                                    <span v-if="list.valcd=='7'">&#41;</span>                               
                                </div>
                                <!-- <div class="pl-1">
                                    <span class="d-inline-block ma-0" >
                                        <v-radio :ripple="false" :label="'외출·외박'" value="9"></v-radio>
                                    </span>
                                </div> -->
                            </v-radio-group>
                        </div>
                        <div v-else style="width:100%;">
                            <v-radio-group
                                v-model="diets.useYn" :key="forceRender"
                                class="radio-dft-Theme mt-0 pl-1 pt-1" hide-details row>       
                                <v-radio :ripple="false" :label="'유'" value="1"></v-radio>
                                <v-radio :ripple="false" :label="'무'" value="2"></v-radio>
                                <!-- <v-radio :ripple="false" :label="'외출·외박'" value="9"></v-radio> -->
                            </v-radio-group>
                        </div>
                    </v-col>
                </v-row>            
            </div>
            <v-divider class=""></v-divider>
        </v-form>
        </div>
        <div class="d-flex align-center mt-3">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">식이정보내역</span>
        </div>
        <div class="mt-2">
            <v-data-table
                class="dft-hvr-tbl" height="150" locale="ko"
                :headers="headers" :items="dtryList" 
                hide-default-header hide-default-footer dense disable-pagination>

                <template v-slot:header="{ props }" >
                    <thead>
                        <tr> 
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[2].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[3].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:100px !important;">{{props.headers[4].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[5].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:90px !important;">{{props.headers[6].text}}</th>
                            <th class="black--text clmsFixedHeader" style="width:60px !important;">{{props.headers[7].text}}</th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:item='{ item }'>
                    <tr :class="[items.bnfcrDietsHisPk == item.bnfcrDietsHisPk ? 'onClr' : '']" @click="sltItem(item)">
                        <td class="txtAlgnMid">{{item.aplStrt?.beforeDateFormatDot()}}</td>
                        <td class="txtAlgnMid">
                             <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        <div>{{item.brkfs}}</div>
                                        <div style="font-size:0.775rem !important">{{item.brkfsStrtFm}}&#126;{{item.brkfsEndFm}}</div>
                                    </div>                                    
                                </template>
                                <span>{{item.brkfsStrtFm}}&nbsp;&#126;&nbsp;{{item.brkfsEndFm}}</span>
                            </v-tooltip>
                        </td>
                        <td class="txtAlgnMid">    
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        <div>{{item.lunch}}</div>
                                        <div style="font-size:0.775rem !important">{{item.lunchStrtFm}}&#126;{{item.lunchEndFm}}</div>
                                    </div>                                    
                                </template>
                                <span>{{item.lunchStrtFm}}&nbsp;&#126;&nbsp;{{item.lunchEndFm}}</span>
                            </v-tooltip>                                                    
                        </td>
                        <td class="txtAlgnMid">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        <div>{{item.diner}}</div>
                                        <div style="font-size:0.775rem !important">{{item.dinerStrtFm}}&#126;{{item.dinerEndFm}}</div>
                                    </div>                                    
                                </template>
                                <span>{{item.dinerStrtFm}}&nbsp;&#126;&nbsp;{{item.dinerEndFm}}</span>
                            </v-tooltip>                                                    
                        </td>                        
                        <td class="txtAlgnMid">{{item.wrtDt?.beforeDateFormatDot()}}</td>                        
                        <td class="txtAlgnMid">{{item.wrtr}}</td>                        
                        <td class="txtAlgnMid">
                            <div style="width:200px;" class="text-overflow">{{item.spclNote}}</div>
                        </td>   
                        <td class="txtAlgnMid">
                            <span v-if="item.rn == 1">
                                <v-btn @click="removeBnfcrDiets()" icon>
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </span>
                        </td>          
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div class="mt-2">            
            <v-pagination
                v-model="page"
                :length="pageCount"
                @input="getPage"
                total-visible="5">
            </v-pagination>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'식이정보'" @madalState="setDelBnfcrDiets"></btnModalDelete>  
            </v-dialog>  
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import { selBnfcrDietsMgmt, selBnfcrDietsHisList, selBnfcrDiets, 
        insBnfcrDietsHis, updBnfcrDietsHis, delBnfcrDietsHis } from '../../api/bnftSrv.js';

export default {
    name: 'BnfcrDtryMdl',

    props : {
        bnMmbrPk : { type: Number, default: -1 },
    },
        
    components: {
        basicInfo,
        CmmDateComp,
        CmmTimeField,
        btnModalDelete,
        EmpSelector,
    },
    

    watch: {     
       'aplStrt':function(value){
            this.dietsList.forEach(el => {
                el.aplStrt = value
            });
       }
    },

    mounted: function(){             
        this.$nextTick(function () {  
            this.clearForm()
            this.getBnfcrDietsMgmt()           
        })
    },
    
    methods: {
        clearForm(){
            this.items.bnfcrDietsHisPk = 0
            this.items.aplStrt          = ''
            this.items.aplEnd           = ''            
            this.items.thrpCdAr.splice(0)
            this.items.spclNote         = ''
            this.items.rmrks            = ''
            this.items.wrtDt            = this.$moment().format('YYYY-MM-DD')
            this.items.wrtr             = ''
            this.$refs.form.resetValidation();
            this.aplStrtMod             = true
            // this.minDt                  = ''
            this.aplStrt                = ''
            this.items.mnsnackUseYn     = '2'
            this.items.afsnackUseYn     = '2'

            this.dietsList.splice(0)

            this.dietsList.push({bnfcrDietsPk:0, aplStrt:'', aplEnd:'' , minDt:'', mlTm:'', mlTmHh:'', mlTmMm:'', mlClcd:'1', mlClcdNm:'아침', mlTyOtr:'',useYn:'1',})
            this.dietsList.push({bnfcrDietsPk:0, aplStrt:'', aplEnd:'' , minDt:'', mlTm:'', mlTmHh:'', mlTmMm:'', mlClcd:'2', mlClcdNm:'점심', mlTyOtr:'',useYn:'1',})
            this.dietsList.push({bnfcrDietsPk:0, aplStrt:'', aplEnd:'' , minDt:'', mlTm:'', mlTmHh:'', mlTmMm:'', mlClcd:'3', mlClcdNm:'저녁', mlTyOtr:'',useYn:'1',})
            this.dietsList.push({bnfcrDietsPk:0, aplStrt:'', aplEnd:'' , minDt:'', mlTm:'', mlTmHh:'', mlTmMm:'', mlClcd:'4', mlClcdNm:'오전간식', mlTyOtr:'',useYn:'2',})
            this.dietsList.push({bnfcrDietsPk:0, aplStrt:'', aplEnd:'' , minDt:'', mlTm:'', mlTmHh:'', mlTmMm:'', mlClcd:'5', mlClcdNm:'오후간식', mlTyOtr:'',useYn:'2',})

            this.dummyItems = {bnfcrDietsHisPk:0}
            this.dummyDietsList = []

            this.filledBtn[0].disabled = false
        },
        //치료식이 저장
        preSetBnfcrDtry:function(){
            if(this.$refs.form.validate()){

                let obj = {
                    fcltyNum        : this.$store.getters.getFcltyNum,
                    userNm          : this.$store.getters.getUserNm,
                    bnMmbrPk        : this.bnMmbrPk,
                    bnfcrDietsHisPk : this.items.bnfcrDietsHisPk,
                    aplStrt         : this.aplStrt?.afterDateFormatHyp(), 
                    thrpCdAr        : [],
                    spclNote        : this.items.spclNote, 
                    rmrks           : this.items.rmrks,
                    wrtDt           : this.items.wrtDt?.afterDateFormatHyp(),
                    wrtr           : this.items.wrtr,
                    dietsList       : []
                }

                this.items.thrpCdAr.forEach(el => {
                    obj.thrpCdAr.push(el)
                });

                this.dietsList.forEach(el => {

                    let item = {
                        bnfcrDietsPk    : el.bnfcrDietsPk, 
                        aplStrt         : el.aplStrt?.afterDateFormatHyp(), 
                        mlTmHh          : '', 
                        mlTmMm          : '', 
                        mlClcd          : el.mlClcd, 
                        mlTycd          : el.mlTycd, 
                        mlTyOtr         : el.mlTyOtr,
                        useYn           : el.useYn,
                    }
                    
                    if(el.mlTm != '') {                        
                        let mlTm = el.mlTm.split(":")
                        item.mlTmHh = mlTm[0]
                        item.mlTmMm = mlTm[1]
                    }

                    //간식일 경우
                    switch (el.mlClcd) {
                        case '4':
                            item.mlTycd = '5'
                            break;
                        case '5':
                            item.mlTycd = '5'
                            break;
                        default:
                            break;
                    }

                    obj.dietsList.push(item)
                });

                this.setBnfcrDietsHis(obj)
            }
        },
        //치료식이 상세 선택
        sltItem:function(obj){
            this.clearForm()
            this.aplStrt    = this.$moment(obj.aplStrt, 'YYYYMMDD').format('YYYY-MM-DD')
            
            this.items.bnfcrDietsHisPk  = obj.bnfcrDietsHisPk
            if(obj.aplEnd == '29991231'){
                this.items.aplEnd   = '~(현재)'
            }else{
                this.items.aplEnd   = obj.aplEnd?.beforeDateFormatHyp()
            }
            this.items.thrpCdAr.splice(0)
            this.items.spclNote     = obj.spclNote
            this.items.rmrks        = obj.rmrks
            
            if(obj.thrpCdAr != null){
                obj.thrpCdAr.forEach(el => {
                    this.items.thrpCdAr.push(el)
                });
            }

            this.aplStrtMod = false

            this.items.wrtr = obj.wrtr
            this.items.wrtDt = obj.wrtDt?.beforeDateFormatHyp()

            if(obj.rn == 1){
                this.filledBtn[0].disabled = false
            } else {
                this.filledBtn[0].disabled = true
            }

            this.getBnfcrDiets()
        },
        //치료식이 삭제
        removeBnfcrDiets:function(){
            this.dialogDelete = true
        },        
        setBnfcrDietsHis:function(obj){   
            if(obj.bnfcrDietsHisPk > 0){                
                updBnfcrDietsHis(obj)
                    .then((response) => ( this.setBnfcrDietsHisAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftoffer/updBnfcrDietsHis : ' + error))
            }else{                
                insBnfcrDietsHis(obj)
                    .then((response) => ( this.setBnfcrDietsHisAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftoffer/insBnfcrDietsHis : ' + error))
            }
        },     
        getBnfcrDietsMgmt:function(){
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                bnMmbrPk    : this.bnMmbrPk,
                rnLmt       : this.itemsRows,
                rnStrt      : 0,
            }

            selBnfcrDietsMgmt(obj)
                    .then((response) => ( this.getBnfcrDietsMgmtAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftoffer/selBnfcrDietsMgmt : ' + error))
        },
        getBnfcrDietsHisList:function(start){
            selBnfcrDietsHisList(this.$store.getters.getFcltyNum,this.bnMmbrPk,start,this.itemsRows)
                    .then((response) => ( this.getBnfcrDietsHisListAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftoffer/selBnfcrDietsHisList : ' + error))
        },
        getBnfcrDiets:function(){
            let obj = {
                fcltyNum        : this.$store.getters.getFcltyNum,
                bnMmbrPk        : this.bnMmbrPk,
                bnfcrDietsHisPk : this.items.bnfcrDietsHisPk
            }
            selBnfcrDiets(obj)
                    .then((response) => ( this.getBnfcrDietsAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftoffer/selBnfcrDiets : ' + error))
        },
        //치료식이내역 삭제
        setDelBnfcrDiets:function(res){
            if(res == 9990){  
                if(this.items.bnfcrDietsHisPk > 0) {
                    let obj = {
                        fcltyNum        : this.$store.getters.getFcltyNum,
                        bnMmbrPk        : this.bnMmbrPk,
                        bnfcrDietsHisPk : this.items.bnfcrDietsHisPk,
                        userNm          : this.$store.getters.getUserNm,
                    }
                    delBnfcrDietsHis(obj)
                            .then((response) => ( this.setDelBnfcrDietsAfter(response.data) ))
                            .catch((error) => console.log('upload error /bnftoffer/delBnfcrDietsHis : ' + error))
                }else{
                    this.snackControll([true, 5000, '대상이 없습니다.', 'error'])
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },            
        setBnfcrDietsHisAfter:function(res){
             if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])     
                this.clearForm()
                this.getBnfcrDietsHisList(0)
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },        
        getBnfcrDietsMgmtAfter:function(res){                
            this.fcltyList.splice(0)
            this.vCd14 = res.codes.vCd14.filter(x=>x.valcd !='5') 
            res.fcltyList.forEach(el => {
                this.fcltyList.push(el)
            });

            this.pageCount = Math.ceil(Number(res.resInfo.rnTot)/this.itemsRows)

            this.dtryList.splice(0)
            res.resInfo.list.forEach(el => {

                let thrpCdArNm = []
                this.vCd14.forEach(code => {
                    if(el.mlTycd == code.valcd) el.mlTycdNm = code.valcdnm
                });
                
                this.fcltyList.forEach(itm => {
                    if(el.thrpCdAr.findIndex(v => v == itm.itmCd1) > -1){
                        thrpCdArNm.push(itm.itmVal1)
                    }
                });

                if(el.brkfsCd == '9') el.brkfs = '외출·외박' 
                if(el.lunchCd == '9') el.lunch = '외출·외박' 
                if(el.dinerCd == '9') el.diner = '외출·외박' 

                el.thrpCdArNm = thrpCdArNm
                
                if(el.rn == 1){
                    this.sltItem(el)           
                    this.minDt      = this.$moment(el.aplStrt, 'YYYYMMDD').add(1, 'days').format('YYYY-MM-DD')         
                }

                if(el.brkfsStrt != "" && el.brkfsStrt != null) el.brkfsStrtFm = this.$moment(el.brkfsStrt, 'YYYYMMDD').format('YYYY.MM.DD')    
                if(el.lunchStrt != "" && el.lunchStrt != null) el.lunchStrtFm = this.$moment(el.lunchStrt, 'YYYYMMDD').format('YYYY.MM.DD')    
                if(el.dinerStrt != "" && el.dinerStrt != null) el.dinerStrtFm = this.$moment(el.dinerStrt, 'YYYYMMDD').format('YYYY.MM.DD') 
                
                if(el.brkfsEnd == '29991231') el.brkfsEndFm = '현재' 
                else el.brkfsEndFm = this.$moment(el.brkfsEnd, 'YYYYMMDD').format('YYYY.MM.DD') 

                if(el.lunchEnd == '29991231') el.lunchEndFm = '현재' 
                else el.lunchEndFm = this.$moment(el.lunchEnd, 'YYYYMMDD').format('YYYY.MM.DD') 

                if(el.dinerEnd == '29991231') el.dinerEndFm = '현재' 
                else el.dinerEndFm = this.$moment(el.dinerEnd, 'YYYYMMDD').format('YYYY.MM.DD') 

                this.dtryList.push(el)
            });

            if(this.dtryList.length == 0){
                if(res.basicHisList != null && res.basicHisList !=undefined && typeof res.basicHisList == 'object'){
                    this.setbasItem(res.basicHisList)
                }
            }
        },
        getBnfcrDietsHisListAfter:function(res){            
            this.pageCount = Math.ceil(Number(res.rnTot)/this.itemsRows)
            this.dtryList.splice(0)
            res.list.forEach(el => {
                let thrpCdArNm = []
                this.vCd14.forEach(code => {
                    if(el.mlTycd == code.valcd) el.mlTycdNm = code.valcdnm
                });

                if(el.brkfsCd == '9') el.brkfs = '외출·외박' 
                if(el.lunchCd == '9') el.lunch = '외출·외박' 
                if(el.dinerCd == '9') el.diner = '외출·외박' 
                
                this.fcltyList.forEach(itm => {
                    if(el.thrpCdAr.findIndex(v => v == itm.itmCd1) > -1){
                        thrpCdArNm.push(itm.itmVal1)
                    }
                });

                el.thrpCdArNm = thrpCdArNm

                if(el.rn == 1){
                    this.sltItem(el)       
                    this.minDt      = this.$moment(el.aplStrt, 'YYYYMMDD').add(1, 'days').format('YYYY-MM-DD')                   
                }

                if(el.brkfsStrt != "" && el.brkfsStrt != null) el.brkfsStrtFm = this.$moment(el.brkfsStrt, 'YYYYMMDD').format('YYYY.MM.DD')    
                if(el.lunchStrt != "" && el.lunchStrt != null) el.lunchStrtFm = this.$moment(el.lunchStrt, 'YYYYMMDD').format('YYYY.MM.DD')    
                if(el.dinerStrt != "" && el.dinerStrt != null) el.dinerStrtFm = this.$moment(el.dinerStrt, 'YYYYMMDD').format('YYYY.MM.DD') 
                
                if(el.brkfsEnd == '29991231') el.brkfsEndFm = '현재' 
                else el.brkfsEndFm = this.$moment(el.brkfsEnd, 'YYYYMMDD').format('YYYY.MM.DD') 

                if(el.lunchEnd == '29991231') el.lunchEndFm = '현재' 
                else el.lunchEndFm = this.$moment(el.lunchEnd, 'YYYYMMDD').format('YYYY.MM.DD') 

                if(el.dinerEnd == '29991231') el.dinerEndFm = '현재' 
                else el.dinerEndFm = this.$moment(el.dinerEnd, 'YYYYMMDD').format('YYYY.MM.DD') 

                this.dtryList.push(el)
            });
        },
        getBnfcrDietsAfter:function(res){

            this.dietsList.forEach(el => {
                let idx = res.findIndex(x => x.mlClcd == el.mlClcd);
                
                if(idx > -1){
                    let target = res[idx]
                    
                    if(target.aplStrt != '') el.aplStrt      = this.$moment(target.aplStrt, 'YYYYMMDD').format('YYYY-MM-DD')
                    else el.aplStrt = ''

                    el.aplEnd       = (target.aplEnd == '29991231')?'~ 현재':this.$moment(target.aplEnd, 'YYYYMMDD').format('YYYY-MM-DD')  
                    
                    if(target.aplStrt != '')  el.minDt  = this.$moment(target.aplStrt, 'YYYYMMDD').add(1, 'days').format('YYYY-MM-DD')
                    else el.minDt  = this.minDt

                    el.useYn        = target.useYn
                    el.mlTycd       = target.mlTycd    
                    el.mlTyOtr    = target.mlTyOtr  

                    if(target.mlTmHh != '' && target.mlTmMm != '') el.mlTm  = target.mlTmHh + ":" + target.mlTmMm
                    else el.mlTm  = ''

                    el.bnfcrDietsPk = target.bnfcrDietsPk
                }

            });
        },
        setDelBnfcrDietsAfter:function(res){
             if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info']) 
                this.minDt = ''    
                this.clearForm()
                this.getBnfcrDietsHisList(0)
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }

            this.dialogDelete = false
        },  
        setbasItem:function(res){
            this.dietsList.forEach(el => {
                let idx = res.findIndex(x => x.itmCd1 == el.mlClcd);

                if(idx > -1){
                    let target = res[idx]

                    if(target.itmVal1 != '' && target.itmVal2 != '') el.mlTm  = target.itmVal1 + ":" + target.itmVal2
                    else el.mlTm  = ''
                }
            });
        },
        getPage:function(){
            let strt = ((this.page-1)*this.itemsRows)
            this.getBnfcrDietsHisList(strt)
        },
        onClickBtn: function(text) {
            if(text=="저장"){   
                this.preSetBnfcrDtry()
            } else if(text=="삭제"){
                this.dialogDelete = true
            }
        },
        onClose: function () {            
            this.$emit('isClose')
        },
        onAplStrtMod:function(){
            if(this.items.bnfcrDietsHisPk > 0){
                this.aplStrtMod = true
                this.dummyItems =  JSON.parse(JSON.stringify(this.items))
                this.dummyDietsList.splice(0)
                this.dietsList.forEach(el => {
                    let item = JSON.parse(JSON.stringify(el))
                    this.dummyDietsList.push(item)
                    el.bnfcrDietsPk = 0
                });

                this.items.bnfcrDietsHisPk = 0
            }
        },
        offAplStrtMod:function(){           
            this.aplStrtMod = false
            this.items =  JSON.parse(JSON.stringify(this.dummyItems))
            this.dietsList.splice(0)
            this.dummyDietsList.forEach(el => {
                this.dietsList.push(el)
            });

            this.dummyItems = {bnfcrDietsHisPk:0}
            this.dummyDietsList = []

            this.forceRender++
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
        onModal: function (key) {
            this.prtWrtDt = this.items.wrtDt
            this.dialog.splice(key, 1, true)
        },
        isModal: function (key) {
            this.dialog.splice(key, 1, false)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        nxtAction:function(index){
            let target =  'cmmtime_'+(index+1)
            if(this.$refs[target] != undefined){
                this.$refs[target][0].$refs.cmmTimeField.focus()
            }else{
                this.$refs['cmmtime_0'][0].$refs.cmmTimeField.focus()
            }
        },
    },
    
    data: () => ({  
        forceRender:0,
        minDt:'',
        aplStrt:'',
        items:{
            bnfcrDietsHisPk:0,
            aplStrt:'', aplEnd:'',            
            mlTycd:'', mlTyOtr:'', thrpCdAr:[],
            spclNote:'', rmrks:'',
            wrtDt:'', wrtr:'',
        },    
        dietsList:[{bnfcrDietsPk:0, aplStrt:'', aplEnd:'' , minDt:'', mlTm:'', mlTmHh:'', mlTmMm:'', mlTycd:'', mlClcd:'', mlClcdNm:'', mlTyOtr:'',useYn:'',}],
        dummyItems:{bnfcrDietsHisPk:0},
        dummyDietsList:[],
        vCd14:[],
        fcltyList:[],
        pageCount:0,
        itemsRows: 3,
        page:1,
        headers: [
            { text: '적용일자',width: '', value: '', align: 'center', },
            { text: '아침',width: '', value: '', align: 'center', },
            { text: '점심',width: '', value: '', align: 'center', },
            { text: '저녁',width: '', value: '', align: 'center', },
            { text: '작성일',width: '', value: '', align: 'center', },
            { text: '작성자',width: '', value: '', align: 'center', },
            { text: '특이사항',width: '', value: '', align: 'center', },
            { text: '처리',width: '', value: '', align: 'center', },
        ],
        dtryList:[],
        progress:false,
        snackOptions: [false, 3000, '', 'info'],
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', type: 'action', visible: true, disabled: false,},            
            // { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', visible: true, disabled: false,},
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        prtWrtDt:'',
        dialog:[],
        dialogDelete:false,
        aplStrtMod:true,
    }),
};
</script>