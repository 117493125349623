<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold blue--text">'{{itmNm}}'</span>
            <span class="pl-2 text-h4 font-weight-bold">손익항목상세관리</span>
            <v-spacer></v-spacer>                                   
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-row class="ma-0 pa-0 mt-2" no-gutters>
            <v-col cols="12" class="ma-0 pa-0">
                <v-data-table 
                     locale="ko"
                    :headers="prfLosItmDtlHeaders" 
                    :items="prfLosItmDtlList"
                    height="350"
                    fixed-header
                    hide-default-header 
                    hide-default-footer 
                    disable-pagination
                    dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr> 
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width:25% !important;">{{props.headers[0].text}}</th>
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width:20% !important;">{{props.headers[1].text}}</th>
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width:10% !important;">{{props.headers[2].text}}</th>                                
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width:10% !important;">{{props.headers[3].text}}</th>
                                <th class="black--text clmsFixedHeader tableBT" style="width:10% !important;">
                                    <v-btn 
                                        class="mr-1" 
                                        color="blue200" 
                                        height="26" 
                                        @click="addDtlRow"
                                        icon>
                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                    </v-btn>    
                                </th> 
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item, index }'>
                        <tr>
                            <td class="clmsBodyCommon fixedCol0 tableBR">
                                <v-text-field color="blue" v-model="item.itmDtlNm" hide-details outlined dense></v-text-field>
                            </td> 
                            <td class="clmsBodyCommon fixedCol0 tableBR">
                                <span class="d-inline-block" style="width: 147px;">
                                    <v-radio-group v-model="item.useYn" class="radio-dft-Theme mt-0 pl-2 pt-1" hide-details row>
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="사용" value="1"></v-radio>                                
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="미사용" value="2"></v-radio>                                
                                    </v-radio-group>
                                </span>
                            </td>      
                            <td class="clmsBodyCommon fixedCol0 tableBR">
                                {{item.calcClcdNm}}
                            </td>    
                            <td class="clmsBodyCommon fixedCol0 tableBR">                               
                                <div class="d-flex justify-center align-center py-2 px-1">
                                    <span style="width:70px;">
                                        <v-text-field color="blue" v-model="item.ord" type="number" hide-details outlined dense></v-text-field>
                                    </span>
                                    <span>
                                        <v-btn icon small style="padding:0px !important" @click="onCalculOrd(item)">                                                
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-calculator</v-icon>
                                                </template>
                                                <span>순서 계산</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </span>
                                </div>
                            </td>      
                            <td class="clmsBodyCommon fixedCol0">    
                                <v-row no-gutters>
                                    <v-col class="ma-0 pa-0 d-flex justify-center align-center" cols="6">
                                        <v-btn icon color='blue200' @click="onSaveDtlRow(item)">
                                            <v-icon>mdi-content-save-outline</v-icon>
                                        </v-btn>

                                    </v-col>
                                    <v-col class="ma-0 pa-0 d-flex justify-center align-center" cols="6">
                                        <v-btn icon color='grey006' @click="onDelDtlRow(index)">                                            
                                            <v-icon v-if="item.prfLosItmDtlPk > 0">mdi-trash-can-outline</v-icon>
                                            <v-icon v-else>mdi-minus-circle-outline</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>

                            </td>      
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
import { insPrfLosItmDtl, delPrfLosItmDtl, selPrfLosItmDtlList, } from '../../api/impExp.js';

export default {
    name: 'PrfLosItmDtlMdl',
    
    components: {            
    },

    props : {        
        itmCd    : { type: String, default: '' },
        itmNm    : { type: String, default: '' },
        calcClcd : { type: String, default: '' },
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    created: function(){  

    },

    mounted: function(){
        this.$nextTick(function () {
            this.getPrfLosItmDtlList()
        })
    },

    watch: {
        getSubEvt: function (bol) {
            if(bol && this.rmLosItmCnfm){
                this.delPrfLosItmDtl()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmLosItmCnfm = false
            }
        },
    },

    methods: {     
        getPrfLosItmDtlList: function () {
            this.prfLosItmDtlList.splice(0)
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                itmCd       : this.itmCd,
            }
            selPrfLosItmDtlList(obj)
                .then((response) => ( this.getPrfLosItmDtlListAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selPrfLosItmDtlList : ' + error))
        },

        setPrfLosItmDtl: function (obj) {
            insPrfLosItmDtl(obj)
                .then((response) => ( this.setPrfLosItmDtlAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selPrfLosItmDtlList : ' + error))
        },

        delPrfLosItmDtl: function () {

            if(this.prfLosItmDtlList[this.cidx].prfLosItmDtlPk > 0){
                
                let obj = {
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    userNm      : this.$store.getters.getUserNm,
                    calcClcd    : this.calcClcd,
                    itmCd       : this.itmCd,
                    itmDtlCd    : this.prfLosItmDtlList[this.cidx].itmDtlCd,
                }

                delPrfLosItmDtl(obj)
                    .then((response) => ( this.delPrfLosItmDtlAfter(response.data) ))
                    .catch((error) => console.log('connect error /impExp/selPrfLosItmDtlList : ' + error))
            }else{
                this.snackControll([true, 5000, '세부항목을 조회 할 수 없습니다.', 'error'])
            }
        },

        getPrfLosItmDtlListAfter:function(res){
            if (res.statusCode == 200) {
                if(res.result != null){
                    res.result.forEach(el => {
                        el.calcClcdNm = this.getClcdNm(this.calcClcd)
                        this.prfLosItmDtlList.push(el)
                    });
                }
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        setPrfLosItmDtlAfter:function(res){
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info']) 
                this.getPrfLosItmDtlList()
                // this.$emit('modalEvent', 99)
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        delPrfLosItmDtlAfter:function(res){
            if (res.statusCode == '200') {
                this.snackControll([true, 2500, res.message, 'info']) 
                this.getPrfLosItmDtlList()
                // this.$emit('modalEvent', 99)
            } else if (res.statusCode == '810') {
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 5000                              // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = res.message       // 경고 타이틀
                obj.cntnt = ['손익 항목별 금액이 존재합니다.']                              // 경고 내용 (arr만 출력됨!)

                if(res.messagesList != null){
                    res.messagesList.forEach(el => {
                        obj.cntnt.push(el)
                    });
                }

                this.$store.commit('setWrnnInf', obj)
            } else {                
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        addDtlRow:function(){
            this.prfLosItmDtlList.push({
                prfLosItmDtlPk  : 0,
                calcClcd    : this.calcClcd,
                itmCd       : this.itmCd,
                itmDtlNm    : '',
                itmDtlCd    : '',
                ord         : '',                
                useYn       : '1',
                calcClcdNm  : this.getClcdNm(this.calcClcd),
            })
        },

        onSaveDtlRow:function(target){
            if(this.calcClcd != '' && this.itmCd != ''){
                let obj = {
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    userNm      : this.$store.getters.getUserNm,
                    calcClcd    : this.calcClcd,
                    itmCd       : this.itmCd,
                    itmDtlNm    : target.itmDtlNm,
                    itmDtlCd    : target.itmDtlCd,
                    ord         : target.ord,
                    useYn       : target.useYn,
                }
                this.setPrfLosItmDtl(obj)
            }else{
                this.snackControll([true, 5000, '항목코드를 조회 할 수 없습니다.', 'error'])                
            }
        },

        onDelDtlRow:function(idx){
            if(this.prfLosItmDtlList[idx].itmDtlCd != ''){            
                this.cidx = idx
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'orange darken-3'
                obj.title = this.prfLosItmDtlList[idx].itmDtlNm
                obj.cntnt = ['세부항목을 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });
                this.rmLosItmCnfm = true
            } else {
                this.prfLosItmDtlList.splice(idx,1)
            }
        },

        getClcdNm(val){
            let restn = ''
            switch (val) {
                case '1':
                    restn = '수익'
                    break;
                case '2':
                    restn = '비용'
                    break;
                default:
                    break;
            }

            return restn
        },
        getValidate:function(pram){
            let rbl = true
            let msg = ''

            if(pram.ord == '' || pram.ord == 0){
                rbl = false
                msg = '순서를 선택해주세요.'
            }

            if(pram.itmNm == ''){
                rbl = false
                msg = '항목명을 입력해주세요.'
            }

            if(!rbl) this.snackControll([true, 3500, msg, 'warning'])

            return rbl
        },
        onClose: function () {
            this.$emit('isClose')
        },
        onCalculOrd:function(obj){
            var max = 0;
            this.prfLosItmDtlList.forEach(el => {
                max = Math.max(max, el.ord);
            });
            obj.ord = max+1
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({ 
        forceRender  : 0,
        cidx         : 0,
        prfLosItmDtlHeaders:[            
            { text: '세부항목', value: '', align: 'center', },
            { text: '사용여부', value: '', align: 'center', },
            { text: '계산구분', value: '', align: 'center', },
            { text: '순서', value: '', align: 'center', },
            { text: '처리', value: '', align: 'center', },
        ],
        prfLosItmDtlList:[],
        snackOptions: [false, 3000, '', 'info'],
        delTitle    : '',
        dialogDelete:false,
        rmLosItmCnfm:false,
    }),
};
</script>