import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '../store.js';

import Tpl001 from '../layouts/Tpl001.vue'
import Tpl002 from '../layouts/Tpl002.vue'
import ExtAuth from '../components/auth/ExtLogin.vue'

import NotFound from '../layouts/NotFound.vue'
import AccessDenied from '../layouts/AccessDenied.vue'

import BnfcrCnfgr from '../views/bnfcr/BnfcrCnfgr.vue'
import BnftSrvPlnCnfgr from '../views/bnftSrv/BnftSrvPlnCnfgr.vue'
import BnfcrCnslnJrnls from '../views/bnfcr/BnfcrCnslnJrnls.vue'
import BnfcrInfrmMgmt from '../views/bnfcr/BnfcrInfrmMgmt.vue'
import StayOutMgmt from '../views/bnfcr/StayOutMgmt.vue'
import FmlyLeter from '../views/bnfcr/FmlyLeter.vue'
import AllBnfcrStts from '../views/bnfcr/AllBnfcrStts.vue'
import CopayClmsMgmt from '../views/bnfcr/CopayClmsMgmt.vue'
import PayLevy from '../views/bnfcr/PayLevy.vue'
import CopayDpstMgmt from '../views/bnfcr/CopayDpstMgmt.vue'
import CopayUnpdMgmt from '../views/bnfcr/CopayUnpdMgmt.vue'
import CopaymdcexDdctn from '../views/bnfcr/CopaymdcexDdctn.vue'
import BnfcrAdv1 from '../views/bnfcr/BnfcrAdv1.vue'

import BnftSrvMgmt from '../views/bnftSrv/BnftSrvMgmt.vue'
import AllBnftSrvPrcs from '../views/bnftSrv/AllBnftSrvPrcs.vue'
import PhyscThrpyInspcPln from '../views/bnftSrv/PhyscThrpyInspcPln.vue'
import WeklyMenu from '../views/bnftSrv/WeklyMenu.vue'
import BathSchdl from '../views/bnftSrv/BathSchdl.vue'
import CaseMtng from '../views/bnftSrv/CaseMtng.vue'
import CaseMgmt from '../views/bnftSrv/CaseMgmt.vue'

import AllBlnftSrvRcrd from '../views/bnftrcrd/AllBlnftSrvRcrd.vue'
import BnftrcrdCnfgr from '../views/bnftrcrd/BnftrcrdCnfgr.vue'
import CareBlnftSrvRcrd from '../views/bnftrcrd/CareBlnftSrvRcrd.vue'
import CncntExcrtObsrvRcrd from '../views/bnftrcrd/CncntExcrtObsrvRcrd.vue'
import BathBlnftSrvRcrd from '../views/bnftrcrd/BathBlnftSrvRcrd.vue'
import HlthBlnftSrvRcrd from '../views/bnftrcrd/HlthBlnftSrvRcrd.vue'
import NsgCareRcrd from '../views/bnftrcrd/NsgCareRcrd.vue'
import PhyscThrpyRcrd from '../views/bnftrcrd/PhyscThrpyRcrd.vue'
import PhyscSnctnRcrd from '../views/bnftrcrd/PhyscSnctnRcrd.vue'
import SpclBdrmUseRcrd from '../views/bnftrcrd/SpclBdrmUseRcrd.vue'
import EmrgnRcrd from '../views/bnftrcrd/EmrgnRcrd.vue'

import SftyCnfgr from '../views/sfty/SftyCnfgr.vue'
import NtyInspJrnls from '../views/sfty/NtyInspJrnls.vue'
import DailyDsnfcJrnls from '../views/sfty/DailyDsnfcJrnls.vue'
import FoodHygnMgmt from '../views/sfty/FoodHygnMgmt.vue'
import NsgEqpmnMgmt from '../views/sfty/NsgEqpmnMgmt.vue'
import FcsftInspc from '../views/sfty/FcsftInspc.vue'
import InfctCntrlInspc from '../views/sfty/InfctCntrlInspc.vue'

import SftySpCnfgr from '../views/sfty/SftySpCnfgr.vue'
import MealSrvcLog from '../views/sfty/MealSrvcLog.vue'

import PrgrmCnfgr from '../views/prgrm/PrgrmCnfgr.vue'
import PrgrmvCnfgr from '../views/prgrm/PrgrmvCnfgr.vue'
import PrgSrvRcrd from '../views/prgrm/PrgSrvRcrd.vue'
import PrgInfrmMgmt from '../views/prgrm/PrgInfrmMgmt.vue'
import PrgBnfcrGrpMgmt from '../views/prgrm/PrgBnfcrGrpMgmt.vue'
import BnfcrAdv2 from '../views/bnfcr/BnfcrAdv2.vue'
import GrpstRcpnt from '../components/prgrm/GrpstRcpnt.vue'
import PrpBnfcrGrp from '../components/prgrm/PrpBnfcrGrp.vue'
import PrgImprv from '../views/prgrm/PrgImprv.vue'
import PrgSchdlMgmt from '../views/prgrm/PrgSchdlMgmt.vue'
import PrgActshtMgmt from '../views/prgrm/PrgActshtMgmt.vue'

import OprCnfgr from '../views/opr/OprCnfgr.vue'
import LvnRmStts from '../views/opr/LvnRmStts.vue'
import BlnftAmntInf from '../views/opr/BlnftAmntInf.vue'
import FacInfoMgmt from '../views/opr/FacInfoMgmt.vue'
import CnfrnRcrd from '../views/opr/CnfrnRcrd.vue'
import AnlWrkPln from '../views/opr/AnlWrkPln.vue'

import EmpCnfgr from '../views/emp/EmpCnfgr.vue'
import EmpUnqCnfgr from '../views/emp/EmpUnqCnfgr.vue'
import EmpInfMgmt from '../views/emp/EmpInfMgmt.vue'
import WrkngJrnl from '../views/emp/WrkngJrnl.vue'
import WrkngSchdl from '../views/emp/WrkngSchdl.vue'
import EmpHlthMgmt from '../views/emp/EmpHlthMgmt.vue'
import DlyWrkStt from '../views/emp/DlyWrkStt.vue'
import OtsdExprtMgmt from '../views/emp/OtsdExprtMgmt.vue'
import GrvncHndlnMgmt from '../views/emp/GrvncHndlnMgmt.vue'
import EdctnJrnl from '../views/emp/EdctnJrnl.vue'

import EmpWageStmtMgmt from '../views/impExp/EmpWageStmtMgmt.vue'
import EmpWageInfMgmt from '../views/impExp/EmpWageInfMgmt.vue'
import CareBlnftAddMgmt from '../views/impExp/CareBlnftAddMgmt.vue'
import MnthlPrlssMgmt from '../views/impExp/MnthlPrlssMgmt.vue'
import LbrCstExpRtCalc from '../views/impExp/LbrCstExpRtCalc.vue'
import FcltylWgeMgmt from '../views/impExp/FcltylWgeMgmt.vue'
import EmpAnlWageStmtMgmt from '../views/impExp/EmpAnlWageStmtMgmt.vue'

import BtcInq from '../views/btcInq/BtcInq.vue'
import BnfcrStts from '../views/btcInq/BnfcrStts.vue'
import EmpInfStts from '../views/btcInq/EmpInfStts.vue'
import CopayStts from '../views/btcInq/CopayStts.vue'
import NsgRcrdStts from '../views/btcInq/NsgRcrdStts.vue'
import PrgBnfcrPrtcpStts from '../views/btcInq/PrgBnfcrPrtcpStts.vue'
import FcsftOprtnJrnl from '../views/btcInq/FcsftOprtnJrnl.vue'
import PhyscRec from '../views/btcInq/PhyscRec.vue'

import SmsSndMgmt from '../views/othr/SmsSndMgmt.vue'
import NtcMgmt from '../views/othr/NtcMgmt.vue'
import CmmCdMgmt from '../views/othr/CmmCdMgmt.vue'
import CmmAuthCnfgr from '../views/othr/CmmAuthCnfgr.vue'
import CmmAuthMgmt from '../views/othr/CmmAuthMgmt.vue'
import HomMgmt from '../views/othr/HomMgmt.vue'
import PaymentMgmt from '../views/othr/PaymentMgmt.vue'

Vue.use(VueRouter);

//페이지 이동 권한 검사
const prmsnChcks = () => (to, from, next) =>  {
    let ar = store.getters.getAcesAthrt
 
    if(ar.findIndex( v => v.viewId == to.name ) != -1)
        next()
    else
        next({ name: 'AccessDenied' })
}

// Vue Router를 관리하는 객체
export const router = new VueRouter({
    mode: 'history',

    scrollBehavior() {
        return { x: 0, y: 0 }
    },

    routes: [
        {
            path: "*",
            component: NotFound,
        },
        {
            path: "/forbidden",
            name: 'AccessDenied',
            component: AccessDenied,
        },
        {
            path: "/extAuth",
            name: 'ExtLAuth',
            component: ExtAuth,
            props: true,
        },
        {
            path: "/",
            name: 'Auth',
            component: Tpl001,
            beforeEnter: (to, from, next) => {
                let ar = store.getters.getAcesAthrt
      
                if(ar.length > 0)
                    next({ name: ar[0].viewId })
                else
                    next()
            },
            props: true,
        },
        {
            path: "/:step",
            name: 'Auth',
            component: Tpl001,
            beforeEnter: (to, from, next) => {
                let ar = store.getters.getAcesAthrt
      
                if(ar.length > 0)
                    next({ name: ar[0].viewId })
                else
                    next()
            },
            props: true,
        },
        {
        // path: url 경로, component: 표시될 컴포넌트
        path: '/tpl002',
        name: 'App',
        component: Tpl002,
        props: true,
        children: [{
                path: "bnfcr",
                name: 'Bnfcr',
                props: true,
                //redirect: '/bnfcr/bnfcrInfrmMgmt',
                component: BnfcrCnfgr,
                children: [{
                        //수급자 정보관리
                        path: "bnfcrInfrmMgmt",
                        name: 'BnfcrInfrmMgmt',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: BnfcrInfrmMgmt,
                    },
                    {
                        //외출 외박 관리
                        path: "stayOutMgmt",
                        name: 'StayOutMgmt',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: StayOutMgmt,
                    },
                    {
                        //급여제공 계획 및 결과평가
                        path: "bnftSrvMgmt",
                        name: 'BnftSrvMgmt',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: BnftSrvMgmt,
                    },
                ],
            },
            {
                //상담일지
                path: "cnslnJrnls",
                name: 'BnfcrCnslnJrnls',
                props: true,
                beforeEnter: prmsnChcks(),
                component: BnfcrCnslnJrnls,
            },
            {
                //가정통신문
                path: "fmlyLeter",
                name: 'FmlyLeter',
                props: true,
                beforeEnter: prmsnChcks(),
                component: FmlyLeter,
            },
            {
                //전체수급자현황
                path: "allBnfcrStts",
                name: 'AllBnfcrStts',
                props: true,
                beforeEnter: prmsnChcks(),
                component: AllBnfcrStts,
            },
            {
                //본인부담금 청구관리
                path: "copayClmsMgmt",
                name: 'CopayClmsMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: CopayClmsMgmt,
            },
            {
                path: "payLevy",
                name: 'PayLevy',
                props: true,
                component: PayLevy,
                children: [{
                    //본인부담금 입금관리
                    path: "copayDpstMgmt",
                    name: 'CopayDpstMgmt',
                    props: true,
                    beforeEnter: prmsnChcks(),
                    component: CopayDpstMgmt,
                },],
            },
            {
                //본인부담금 미납관리
               path: "copayUnpdMgmt",
               name: 'CopayUnpdMgmt',
               props: true,
               beforeEnter: prmsnChcks(),
               component: CopayUnpdMgmt,
           },
            {
                 //본인부담금 의료비공제
                path: "copaymdcexDdctn",
                name: 'CopaymdcexDdctn',
                props: true,
                beforeEnter: prmsnChcks(),
                component: CopaymdcexDdctn,
            },
            {
                //전체 급여제공과정
                path: "allBnftSrvPrcs",
                name: 'AllBnftSrvPrcs',
                props: true,
                beforeEnter: prmsnChcks(),
                component: AllBnftSrvPrcs,
            },
            {
                path: "bnftSrvPlnCnfgr",
                name: 'BnftSrvPlnCnfgr',
                props: true,
                component: BnftSrvPlnCnfgr,
                children: [{
                    //물리(작업)치료 평가 및 계획
                    path: "physcThrpyInspcPln",
                    name: 'PhyscThrpyInspcPln',
                    props: true,
                    beforeEnter: prmsnChcks(),
                    component: PhyscThrpyInspcPln,
                },],
            },
            {
                //주간 식단표
                path: "weklyMenu",
                name: 'WeklyMenu',
                props: true,
                beforeEnter: prmsnChcks(),
                component: WeklyMenu,
            },
            {
                //목욕 일정
                path: "bathSchdl",
                name: 'BathSchdl',
                props: true,
                beforeEnter: prmsnChcks(),
                component: BathSchdl,
            },
            {
                path: "caseMtng",
                name: 'CaseMtng',
                props: true,
                component: CaseMtng,
                children: [{
                    //사례관리
                    path: "caseMgmt",
                    name: 'CaseMgmt',
                    props: true,
                    beforeEnter: prmsnChcks(),
                    component: CaseMgmt,
                },],
            },
            {
                path: "sfty",
                name: 'SftyCnfgr',
                props: true,
                component: SftyCnfgr,
                children: [
                    {
                        //야간점검(근무)일지
                        path: "ntyInspJrnls",
                        name: 'NtyInspJrnls',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: NtyInspJrnls,
                    },
                    {
                        //일상소독일지
                        path: "dailyDsnfcJrnls",
                        name: 'DailyDsnfcJrnls',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: DailyDsnfcJrnls,
                    },
                    {
                        //식품위생관리
                        path: "foodHygnMgmt",
                        name: 'FoodHygnMgmt',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: FoodHygnMgmt,
                    },
                    {
                        //간호비품관리
                        path: "nsgEqpmnMgmt",
                        name: 'NsgEqpmnMgmt',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: NsgEqpmnMgmt,
                    },
                    {
                        //시설안전점검
                        path: "fcsftInspc",
                        name: 'FcsftInspc',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: FcsftInspc,
                    },
                    {
                        //감염관리점검
                        path: "infctCntrlInspc",
                        name: 'InfctCntrlInspc',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: InfctCntrlInspc,
                    },
                ],
            },
            {
                path: "sftySp",
                name: 'SftySpCnfgr',
                props: true,
                component: SftySpCnfgr,
                children: [
                    {
                        //급식운영일지
                        path: "mealSrvcLog",
                        name: 'MealSrvcLog',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: MealSrvcLog,
                    },
                ],
            },
            {
                path: "bnftrcrd",
                name: 'BnftrcrdCnfgr',
                props: true,
                component: BnftrcrdCnfgr,
                children: [
                    {
                        //급여제공기록 전체급여제공기록
                        path: "allBlnftSrvRcrd",
                        name: 'AllBlnftSrvRcrd',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: AllBlnftSrvRcrd,
                    },
                    {
                        //급여제공기록 요양급여제공기록
                        path: "careBlnftSrvRcrd",
                        name: 'CareBlnftSrvRcrd',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: CareBlnftSrvRcrd,
                    },
                    {
                        //급여제공기록 집중배설관찰기록
                        path: "cncntExcrtObsrvRcrd",
                        name: 'CncntExcrtObsrvRcrd',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: CncntExcrtObsrvRcrd,
                    },
                    {
                        //급여제공기록 건강급여제공기록
                        path: "hlthBlnftSrvRcrd",
                        name: 'HlthBlnftSrvRcrd',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: HlthBlnftSrvRcrd,
                    },
                    {
                        //급여제공기록 간호처치기록
                        path: "nsgCareRcrd",
                        name: 'NsgCareRcrd',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: NsgCareRcrd,
                    },
                    {
                        //급여제공기록 신체제재기록
                        path: "physcSnctnRcrd",
                        name: 'PhyscSnctnRcrd',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: PhyscSnctnRcrd,
                    },
                    {
                        //급여제공기록 특별침실사용기록
                        path: "spclBdrmUseRcrd",
                        name: 'SpclBdrmUseRcrd',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: SpclBdrmUseRcrd,
                    },
                    {
                        //급여제공기록 응급상황기록
                        path: "emrgnRcrd",
                        name: 'EmrgnRcrd',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: EmrgnRcrd,
                    },
                    {
                        //보고서 간호기록현황
                        path: "nsgRcrdStts",
                        name: 'NsgRcrdStts',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: NsgRcrdStts,
                    },
                ],
            },
            {
                //급여제공기록 목욕급여제공기록
                path: "bathBlnftSrvRcrd",
                name: 'BathBlnftSrvRcrd',
                props: true,
                beforeEnter: prmsnChcks(),
                component: BathBlnftSrvRcrd,
            },
            {
                //급여제공기록 물리(작업)치료기록
                path: "physcThrpyRcrd",
                name: 'PhyscThrpyRcrd',
                props: true,
                beforeEnter: prmsnChcks(),
                component: PhyscThrpyRcrd,
            },
            {
                //프로그램 운영기록
                path: "prgSrvRcrd",
                name: 'PrgSrvRcrd',
                props: true,
                beforeEnter: prmsnChcks(),
                component: PrgSrvRcrd,
            },
            {
                //프로그램 자료
                path: "prgActsht",
                name: 'PrgActshtMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: PrgActshtMgmt,
            },
            {
                path: "prgrm",
                name: 'Prgrm',
                props: true,
                component: PrgrmCnfgr,
                children: [{
                    //프로그램 정보관리
                    path: "prgInfrmMgmt",
                    name: 'PrgInfrmMgmt',
                    props: true,
                    beforeEnter: prmsnChcks(),
                    component: PrgInfrmMgmt,
                },],
            },
            {
                //프로그램 일정관리
                path: "prgSchdlMgmt",
                name: 'PrgSchdlMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: PrgSchdlMgmt,
            },
            {
                path: "bnfcrAdv1",
                name: 'BnfcrAdv1',
                props: true,
                component: BnfcrAdv1,
                children: [{
                    path: "prgBnfcrGrpMgmt",
                    name: 'PrgBnfcrGrpMgmt1',
                    props: true,
                    component: PrgBnfcrGrpMgmt,
                    children: [{
                        //프로그램 수급자 그룹관리
                        path: "grpstRcpnt",
                        name: 'GrpstRcpnt',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: GrpstRcpnt,
                    },],
                },],
            },
            {
                path: "bnfcrAdv2",
                name: 'BnfcrAdv2',
                props: true,
                component: BnfcrAdv2,
                children: [{
                    path: "prgBnfcrGrpMgmt",
                    name: 'PrgBnfcrGrpMgmt2',
                    props: true,
                    component: PrgBnfcrGrpMgmt,
                    children: [{
                        //그룹별 수급자 설정
                        path: "prpBnfcrGrp",
                        name: 'PrpBnfcrGrp',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: PrpBnfcrGrp,
                    },],
                },],
            },
            {
                path: "prgrmv",
                name: 'Prgrmv',
                props: true,
                component: PrgrmvCnfgr,
                children: [{
                    //프로그램개선(의견수렴)
                    path: "prgImprv",
                    name: 'PrgImprv',
                    props: true,
                    beforeEnter: prmsnChcks(),
                    component: PrgImprv,
                },],
            },
            {
                path: "opr",
                name: 'Opr',
                props: true,
                component: OprCnfgr,
                children: [{
                    //회의기록
                    path: "cnfrnRcrd",
                    name: 'CnfrnRcrd',
                    props: true,
                    beforeEnter: prmsnChcks(),
                    component: CnfrnRcrd,
                },],
            },
            {
                //생활실 현황
                path: "lvnRmStts",
                name: 'LvnRmStts',
                props: true,
                beforeEnter: prmsnChcks(),
                component: LvnRmStts,
            },
            {
                //시설정보관리
                path: "facInfoMgmt",
                name: 'FacInfoMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: FacInfoMgmt,
            },
            {
                //급여수가정보
                path: "blnftAmntInf",
                name: 'BlnftAmntInf',
                props: true,
                beforeEnter: prmsnChcks(),
                component: BlnftAmntInf,
            },
            {
                //급여수가정보
                path: "anlWrkPln",
                name: 'AnlWrkPln',
                props: true,
                beforeEnter: prmsnChcks(),
                component: AnlWrkPln,
            },
            {
                path: "emp",
                name: 'Emp',
                props: true,
                component: EmpCnfgr,
                children: [{
                    //직원관리 직원정보관리
                    path: "empInfMgmt",
                    name: 'EmpInfMgmt',
                    props: true,
                    beforeEnter: prmsnChcks(),
                    component: EmpInfMgmt,
                },
                {
                    //직원관리 근무일지(출퇴근)
                    path: "wrkngJrnl",
                    name: 'WrkngJrnl',
                    props: true,
                    beforeEnter: prmsnChcks(),
                    component: WrkngJrnl,
                },
                {
                    //외부강사 자원봉사자 관리
                    path: "otsdExprtMgmt",
                    name: 'OtsdExprtMgmt',
                    props: true,
                    beforeEnter: prmsnChcks(),
                    component: OtsdExprtMgmt,
                },
                {
                    //직원연간임금대장
                    path: "empAnlWageStmtMgmt",
                    name: 'EmpAnlWageStmtMgmt',
                    props: true,
                    beforeEnter: prmsnChcks(),
                    component: EmpAnlWageStmtMgmt,
                },],
            },
            {
                path: "empSlt",
                name: 'EmpSlt',
                props: true,
                component: EmpUnqCnfgr,
                children: [{
                    //직원관리 근무일정현황
                    path: "wrkngSchdl",
                    name: 'WrkngSchdl',
                    props: true,
                    beforeEnter: prmsnChcks(),
                    component: WrkngSchdl,
                },],
            },
            {
                //일별 근무현황
                path: "dlyWrkStt",
                name: 'DlyWrkStt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: DlyWrkStt,
            }, 
            {
                //교육 및 훈련일지
                path: "edctnJrnl",
                name: 'EdctnJrnl',
                props: true,
                beforeEnter: prmsnChcks(),
                component: EdctnJrnl,
            }, 
            {
                //고충처리관리
                path: "grvncHndlnMgmt",
                name: 'GrvncHndlnMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: GrvncHndlnMgmt,
            }, 
            {
                //직원건강관리
                path: "empHlthMgmt",
                name: 'EmpHlthMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: EmpHlthMgmt,
            },          
            {
                //직원 임금명세서 관리
                path: "empWageStmtMgmt",
                name: 'EmpWageStmtMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: EmpWageStmtMgmt,
            },
            {
                //기관 부담금관리
                path: "fcltylWgeMgmt",
                name: 'FcltylWgeMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: FcltylWgeMgmt,
            },
            {
                //직원 임금정보 관리
                path: "empWageInfMgmt",
                name: 'EmpWageInfMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: EmpWageInfMgmt,
            },
            {
                //요양급여 가산관리
                path: "careBlnftAddMgmt",
                name: 'CareBlnftAddMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: CareBlnftAddMgmt,
            },
            {
                //월별손익관리
                path: "mnthlPrlssMgmt",
                name: 'MnthlPrlssMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: MnthlPrlssMgmt,
            },
            {
                //인건비 지출비율
                path: "lbrCstExpRtCalc",
                name: 'LbrCstExpRtCalc',
                props: true,
                beforeEnter: prmsnChcks(),
                component: LbrCstExpRtCalc,
            },
            {
                //보고서 수급자 상태현황
                path: "bnfcrStts",
                name: 'BnfcrStts',
                props: true,
                beforeEnter: prmsnChcks(),
                component: BnfcrStts,
            },
            {
                //보고서 직원정보현황
                path: "empInfStts",
                name: 'EmpInfStts',
                props: true,
                beforeEnter: prmsnChcks(),
                component: EmpInfStts,
            },
            {
                //보고서 본인부담금
                path: "copayStts",
                name: 'CopayStts',
                props: true,
                beforeEnter: prmsnChcks(),
                component: CopayStts,
            },
            {
                path: "btcInq",
                name: 'BtcInq',
                props: true,
                component: BtcInq,
                children: [{
                    //보고서 프로그램 수급자 참여현황
                    path: "prgBnfcrPrtcpStts",
                    name: 'PrgBnfcrPrtcpStts',
                    props: true,
                    beforeEnter: prmsnChcks(),
                    component: PrgBnfcrPrtcpStts,
                },],
            },
            {
                //보고서 시설운영일지
                path: "fcsftOprtnJrnl",
                name: 'FcsftOprtnJrnl',
                props: true,
                beforeEnter: prmsnChcks(),
                component: FcsftOprtnJrnl,
            },
            {
                //보고서 시설운영일지
                path: "PhyscRec",
                name: 'PhyscRec',
                props: true,
                beforeEnter: prmsnChcks(),
                component: PhyscRec,
            },
            {
                //SMS 발송관리
                path: "smsSndMgmt",
                name: 'SmsSndMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: SmsSndMgmt,
            },
            {
                //공지사항 관리
                path: "ntcMgmt",
                name: 'NtcMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: NtcMgmt,
            },
            {
                //코드관리
                path: "cmmCdMgmt",
                name: 'CmmCdMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: CmmCdMgmt,
            },            
            {
                path: "auth",
                name: 'CmmAuthCnfgr',
                props: true,
                component: CmmAuthCnfgr,
                children: [
                    {
                        //권한 그룹 관리
                        path: "cmmAuthMgmt",
                        name: 'CmmAuthMgmt',
                        props: true,
                        beforeEnter: prmsnChcks(),
                        component: CmmAuthMgmt,
                    },
                ]
            },
            {
                //기관 홈페이지 관리
                path: "homMgmt",
                name: 'HomMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: HomMgmt,
            },
            {
                //기관 홈페이지 관리
                path: "payments",
                name: 'PaymentMgmt',
                props: true,
                beforeEnter: prmsnChcks(),
                component: PaymentMgmt,
            },
        ],
    },]
})

//전역가드
router.beforeEach(function( to, from, next ) {
    if(store.state.sessionInfo.fcltyNum == 0 && to.name?.indexOf('Auth') == -1)
        next({ name: 'Auth' })
    else if(store.state.sessionInfo.fcltyNum == 0 && to.name?.indexOf('Auth') > -1)
        next()
    else if(store.state.sessionInfo.fcltyNum != 0)
        next()
})

//헤더 타이틀 수정
router.afterEach(() => {
    Vue.nextTick(() => {
        document.title = '요양SYS'
    });
});