<template>
    <v-sheet class="rounded-lg rounded-l-0" min-width="1260" max-width="1560" height="835" color="">       
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">8-5. 손익관리</span>
            </div>
            <v-spacer></v-spacer>            
        </div>        
        <div class="mt-2 px-2">
            <v-tabs
                v-model="tab" class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <v-tabs-items v-model="tab">            
                <v-tab-item eager transition="fade-transition">
                    <mnthlPrlssTab1 v-if="tab == 0"></mnthlPrlssTab1>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <mnthlPrlssTab3 v-if="tab == 1"></mnthlPrlssTab3>
                </v-tab-item>            
            </v-tabs-items>
        </div>
    </v-sheet>
</template>
<script>
import mnthlPrlssTab1 from '../../components/impExp/MnthlPrlssTab1.vue';
import mnthlPrlssTab3 from '../../components/impExp/MnthlPrlssTab3.vue';

export default {
    name: 'MnthlPrlssMgmt',
        
    components: {
        mnthlPrlssTab1,
        mnthlPrlssTab3
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    watch:{
       
    },

    methods: {        
               
    },
    data: () => ({   
        tab         : 0,           
        tabItems: [
            { index: 0, tab: '월별', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 1, tab: '연별', class: 'fontOneDotTwoRem px-15', enter:false},
        ],                 
    })
}
</script>