<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">         
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">프로그램&nbsp;일정등록</span>
            <v-spacer></v-spacer> 
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="pa-2">
            <v-row class="pa-0" no-gutters>
                <v-col cols="7">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="d-flex justify-start align-center" cols="6">
                            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                            <span class="text-h6 font-weight-bold">프로그램&nbsp;일정등록</span>
                        </v-col>                        
                        <v-col class="d-flex justify-end align-center" cols="6">
                            <v-btn
                                class="ml-1" 
                                color="blueBtnColor" 
                                style="padding: 13px 12px 12px 10px !important"                            
                                min-width="30" height="25" small outlined
                                @click="setPrgOpPln"
                            >
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                            <v-icon size="20">mdi-content-save-edit-outline</v-icon>
                                            <span class="fontOneRem ml-1" style="padding-bottom: 2px;">저장</span>
                                        </div>
                                    </template>
                                    <span>프로그램 일정등록 저장</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-1"></v-divider>
                    <v-form ref="prgPlnform" lazy-validation>                   
                        <v-row class="pa-0" no-gutters>
                            <v-col class="tblHdr-greyE01 tableBL tableBR py-1" cols="2">
                                프로그램
                            </v-col>
                            <v-col class="tableBR fontOneRem txtAlgnMid py-1" cols="10">
                                {{prgPlnInfo.prgNm}}
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="tblHdr-greyE01 tableBL tableBR py-1" cols="2">
                                프로그램 유형
                            </v-col>
                            <v-col class="tableBR fontOneRem txtAlgnMid py-1" cols="5">
                                {{prgPlnInfo.prgTypNm}}
                            </v-col>
                            <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                                지표
                            </v-col>
                            <v-col class="tableBR fontOneRem txtAlgnMid py-1" cols="3">
                                {{prgPlnInfo.idxNm}}
                            </v-col>
                        </v-row>
                        <v-divider class=""></v-divider>  
                        <v-row class="pa-0" no-gutters>
                            <v-col class="tblHdr-greyE01 tableBL tableBR py-1" cols="2">
                                계획일시
                            </v-col>
                            <v-col class="tableBR fontOneRem txtAlgnMid py-1" cols="5">
                                <span class="d-inline-block pl-2" style="width: 140px;">
                                    <CmmDateComp
                                        v-model="prgPlnInfo.opDt"                                    
                                        :required="true">
                                    </CmmDateComp>
                                </span>
                                <span class="d-inline-block ml-2" style="width: 60px;">
                                    <CmmTimeField v-model="prgPlnInfo.opTmStrt" :required="true"></CmmTimeField>
                                </span>
                                <span class="d-inline-block ml-2">-</span>
                                <span class="d-inline-block ml-2" style="width: 60px;">
                                    <CmmTimeField v-model="prgPlnInfo.opTmEnd" :required="true"></CmmTimeField>
                                </span>                            
                            </v-col>
                            <v-col class="tblHdr-greyE01 tableBR py-1" cols="2">
                                작성자
                            </v-col>
                            <v-col class="tableBR fontOneRem py-1" cols="3">
                                <div class="d-flex justify-center align-center">
                                    <span class="d-inline-block" style="width: 90px;">
                                        <v-text-field
                                            v-model="prgPlnInfo.wrtr" color="blue" ref="info" height="28"
                                            :rules="[rules.required, rules.strCheck]"
                                            hide-details outlined readonly dense>
                                        </v-text-field>
                                    </span>
                                    <v-btn 
                                        class="ml-1 brown lighten-3" 
                                        small 
                                        min-width="30"
                                        height="26"  
                                        style="padding:0px 1px 0px 2px !important"
                                        @click="onIntgrAct"
                                        >
                                        <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-divider class="mb-2"></v-divider>                     
                    <v-row class="pa-0" no-gutters>
                        <v-col class="" cols="12">
                            <v-data-table class="dft-hvr-tbl pt-1" height="430" locale="ko"
                                :headers="prgPlnInfo.bnfcrgrHeader"
                                :items="prgPlnInfo.bnfcrgr" 
                                disable-pagination fixed-header hide-default-header hide-default-footer dense>
                                <template v-slot:header="{ props }" >
                                    <thead>
                                        <tr>
                                            <th class="black--text clmsFixedHeader tableBR" style="width:250px !important;">{{props.headers[0].text}}</th>
                                            <th class="black--text clmsFixedHeader tableBR" style="width:250px !important;">{{props.headers[1].text}}</th>
                                            <th class="black--text clmsFixedHeader" style="width:80px !important;">
                                                <v-btn
                                                    class="mr-1" color="blue200" height="26"
                                                    @click="onGrpRow()"
                                                    icon>
                                                    <v-icon>mdi-plus-circle-outline</v-icon>
                                                </v-btn>
                                            </th>
                                        </tr>
                                    </thead>
                                </template>
                
                                <template v-slot:item='{ item }'>
                                    <tr>
                                        <td class="clmsBodyCommon tableBR">
                                            {{ item.bnfcrgrNm }}
                                        </td>
                                        <td class="clmsBodyCommon tableBR">
                                            <div class="text-overflow" style="width:230px;">{{item.bnfcrgrDesc}}</div>
                                        </td>
                                        <td class="clmsBodyCommon tableBR">
                                            <v-btn
                                                class="" color="grey006" height="26"
                                                @click="rmGrpRow(item)"
                                                icon>
                                                <v-icon>mdi-trash-can-outline</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="5" class="pl-2">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="d-flex justify-start align-center" cols="12">
                            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                            <span class="text-h6 font-weight-bold">참여대상&nbsp;수급자&nbsp;정보</span>
                            <v-spacer></v-spacer>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-1"></v-divider>
                    <v-data-table class="dft-hvr-tbl" 
                        height="660" locale="ko"
                        :headers="prgPlnInfo.bnfcrHeaders" 
                        :items="prgPlnInfo.bnfcrItems"
                        item-key="rn"
                        :loading="prgPlnInfo.isLoad" :loading-text="prgPlnInfo.loadMsg"
                        fixed-header hide-default-header disable-pagination hide-default-footer dense>
                        <template v-slot:header="{ props }" >
                            <thead>
                                <tr>
                                    <th class="black--text clmsFixedHeader tableBR" style="width:80px !important;">{{props.headers[0].text}}</th>
                                    <th class="black--text clmsFixedHeader tableBR" style="width:80px !important;">{{props.headers[1].text}}</th>
                                    <th class="black--text clmsFixedHeader tableBR" style="width:80px !important;">{{props.headers[2].text}}</th>
                                    <th class="black--text clmsFixedHeader tableBR" style="width:80px !important;">{{props.headers[3].text}}</th>
                                    <th class="black--text clmsFixedHeader tableBR" style="width:80px !important;">{{props.headers[4].text}}</th>
                                    <th class="black--text clmsFixedHeader" style="width:80px !important;">{{props.headers[5].text}}</th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item='{ item }'>
                            <tr>
                                <td class="clmsBodyCommon tableBR">{{ item.rn }}</td>
                                <td class="clmsBodyCommon tableBR">{{ item.bnfcrgrNm }}</td>
                                <td class="clmsBodyCommon tableBR">{{ item.name }}</td>
                                <td class="clmsBodyCommon tableBR">{{ item.age }}</td>
                                <td class="clmsBodyCommon tableBR">
                                    <span class="d-inline-block pt-1">
                                        <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'"
                                            src="../../assets/icon/ic-female.svg"></v-img>
                                        <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'"
                                            src="../../assets/icon/ic-male.svg"></v-img>
                                    </span>
                                </td>
                                <td class="clmsBodyCommon tableBR">
                                    <div class="d-flex justify-center align-center">
                                        <span
                                            :class="'ic-level level0'+item.acknwRtng"
                                            style="margin-left: 3px; line-height: 0;">
                                            {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                        </span>
                                    </div>
                                </td>                                
                            </tr>
                        </template>                       
                    </v-data-table>
                </v-col>
            </v-row>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" max-width="700" content-class="round" persistent eager>           
                <IntgrSlctn
                    :mdlTtl="'진행자 선택'" :tabInfList="['2']" :fixList="[]" mode="1"
                    :cd41="vCd41"
                    :cd42="vCd42"
                    :cd65="vCd65"
                    :prtWrtDt = "prgPlnInfo.opDt"
                    @intgrResList="getAplyList" @onClose="dialog.splice(0,1,false)">
                </IntgrSlctn>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[1]" max-width="800" content-class="round" persistent eager>           
                <PrgGrpMulti 
                    ref="prgGrpMulti"
                    v-if="dialog[1]" 
                    :prgBnfcrgrChkItems = "bnfcrgrChkItems"
                    @outEmp="setPrgrmGrp"
                    @isClose="dialog.splice(1,1,false)">                    
                </PrgGrpMulti>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import IntgrSlctn from '../commons/IntgrSlctn.vue';
import PrgGrpMulti from './PrgrmgrMultiSelector.vue';


import { http } from '@/api/baseAxios';

export default {
    name: 'PrgPlnMdl',
    
    components: {  
        CmmDateComp,
        CmmTimeField,
        IntgrSlctn,
        PrgGrpMulti,
    },

    props : {
        sltData:{type:Object, default:new Object}
    },

    computed: {
        
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {  
            this.setData(this.sltData)
            this.getCode(['41', '42', '65'])
        })
    },

    watch: {
        
    },

    methods: {  
        
        onClose: function () {
            this.$emit('isClose')
        },

        //프로그램 정보 셋팅
        setData:function(obj){
            this.prgPlnInfo.bnfcrgr.splice(0)
            if(obj != null){
                this.prgPlnInfo.prgInfPk       = obj.prgInfPk
                this.prgPlnInfo.prgTypMngPk 	= obj.prgTypMngPk 
                this.prgPlnInfo.prgTypMngPk 	= obj.prgTypMngPk       //프로그램유형관리 고유번호
                this.prgPlnInfo.prgNm 	        = obj.prgNm    		    //프로그램명
                this.prgPlnInfo.idxNm           = obj.idxNm
                this.prgPlnInfo.prgTypNm        = obj.prgTypNm
                
            }
          
        },
        //공통코드 조회
        getCode: function (array) {
            http.post('cmm/selCmnItmValCdJsn', {valClcdS: array})
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },

        //수급자그룹별수급자관리 다건 조
        getBnfcrgrBnfcrList: function () {
            this.prgPlnInfo.isLoad = true
            this.prgPlnInfo.bnfcrItems.splice(0)

            let obj = {
                prgBnfcrgrInfPks : []
            }

            this.prgPlnInfo.bnfcrgr.forEach(itm => {
                obj.prgBnfcrgrInfPks.push(itm.prgBnfcrgrInfPk)
            });

            if(obj.prgBnfcrgrInfPks.length > 0){
                http.post('prgrm/selBnfcrgrBnfcrList', obj)
                    .then((response) => ( this.setBnfcrgrBnfcr(response.data) ))
                    .catch((error) => console.log('connect error /prgrm/selBnfcrgrBnfcrList : ' + error))
            } else {
                this.prgPlnInfo.isLoad = false
            }
        },

        //프로그램 일정등록
        setPrgOpPln: function () {
            if (this.$refs.prgPlnform.validate()) {

                let opTmStrt = this.prgPlnInfo.opTmStrt.split(':', 2)
                let opTmEnd = this.prgPlnInfo.opTmEnd.split(':', 2)

                let obj = {
                    opDt            : this.prgPlnInfo.opDt?.afterDateFormatHyp(),
                    opTmHhStrt      : opTmStrt[0],
                    opTmMmStrt      : opTmStrt[1],
                    opTmHhEnd       : opTmEnd[0],
                    opTmMmEnd       : opTmEnd[1],
                    prgInfPk        : this.prgPlnInfo.prgInfPk,
                    prgNm           : this.prgPlnInfo.prgNm,
                    userNm          : this.$store.getters.getUserNm,
                    wrtr            : this.prgPlnInfo.wrtr,
                    fcltyNum        : this.$store.getters.getFcltyNum,
                    list            : [],
                }

                this.prgPlnInfo.bnfcrgr.forEach(itm => {
                    let item = {
                        prgBnfcrgrInfPk : itm.prgBnfcrgrInfPk,
                        userNm          : this.$store.getters.getUserNm,
                        fcltyNum        : this.$store.getters.getFcltyNum,
                        prgInfPk    : this.prgPlnInfo.prgInfPk,
                    }
                    obj.list.push(item)
                });

                if(obj.list.length > 0) {
                    
                    http.post('prgrm/insPrgOpPln', obj)
                        .then((response) => ( this.rstPrgOpPln(response.data) ))
                        .catch((error) => console.log('connect error /prgrm/insPrgOpPln : ' + error))
                        
                } else {
                    let obj = {}
                    obj.actv = true                 // 경고 노출여부
                    obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                    obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                    obj.title = '수급자 그룹을 선택해주세요.'         // 경고 타이틀

                    this.$store.commit('setWrnnInf', obj)
                }

            }else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '필수 항목을 입력해주세요.'         // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }
        },

        //프로그램 일정등록 결과
        rstPrgOpPln:function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀

            if (res.statusCode == 200) {
                obj.tmr = 3000
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.cntnt = ['4-1. 프로그램 운영기록에서 확인 하실 수 있습니다.']                                           // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
                this.onClose()               
            } else {
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                this.$store.commit('setWrnnInf', obj)
            }            
        },

        //수급자정보 조회
        setBnfcrgrBnfcr:function(res){
            this.prgPlnInfo.isLoad = false
            if(res != null){
                res.forEach(itm => {
                    this.prgPlnInfo.bnfcrItems.push(itm)
                });
            }
            
        },


        //공통코드 셋팅
        stCdMapping: function (res) {
            this.vCd41 = res.vCd41
            this.vCd42 = res.vCd42
            this.vCd65 = res.vCd65
        },

        getAplyList: function (arr){
            arr.forEach(e => {  
                this.prgPlnInfo.wrtr = e.name
            });            
        },

        onIntgrAct:function (){
            // if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.prgPlnInfo.opDt) == false){
            //     let obj = {}
            //     obj.actv = true                 // 경고 노출여부
            //     obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
            //     obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
            //     obj.title = '계획일시를 선택해주세요.'         // 경고 타이틀

            //     this.$store.commit('setWrnnInf', obj)
            // } else {
            // }
            this.dialog.splice(0,1,true)
        },

        //그룹팝업 오픈
        onGrpRow:function(){
            this.dialog.splice(1,1,true)
        },

        //그룹추가
        setPrgrmGrp:function(arr){            
            if(arr.length > 0){                
                arr.forEach(itm => {
                    let idx = this.prgPlnInfo.bnfcrgr.findIndex(v=>v.prgBnfcrgrInfPk == itm.prgBnfcrgrInfPk)

                    if(idx == -1){
                        let obj = {
                            prgBnfcrgrInfPk : itm.prgBnfcrgrInfPk,       
                            bnfcrgrNm       : itm.bnfcrgrNm,
                            bnfcrgrDesc     : itm.bnfcrgrDesc,
                        }
                        this.prgPlnInfo.bnfcrgr.push(obj)                        
                    }

                });

                this.getBnfcrgrBnfcrList()
            }
        },
        //그룹삭제
        rmGrpRow:function(itm){
            let idx = this.prgPlnInfo.bnfcrgr.findIndex(v=>v.prgBnfcrgrInfPk == itm.prgBnfcrgrInfPk)
            this.prgPlnInfo.bnfcrgr.splice(idx,1)
            this.getBnfcrgrBnfcrList()
        },  
        //그룹선택(사용X)
        tblBnfcrgrAct:function(itm){
            console.log(itm)
            if(this.sltBnfcrgrInfPk == -1){
                this.sltBnfcrgrInfPk = itm.prgBnfcrgrInfPk
            } else {
                if(this.sltBnfcrgrInfPk == itm.prgBnfcrgrInfPk){
                    this.sltBnfcrgrInfPk = -1
                    console.log('clear')
                } else {
                    this.sltBnfcrgrInfPk = itm.prgBnfcrgrInfPk
                }
            }
        }
    },
    data: () => ({   
        btnSave         : true,
        prgPlnInfo:{        
            isLoad          : false,    
            loadMsg         : '잠시만 기다려주세요.',
            prgInfPk        : 0,            
            prgNm 	        :'',    		//프로그램명
            prgTypNm        :'',            //프로그램 유형
            idxNm           :'',            //지표
            opDt	        :'',            //운영일자
            opTmStrt        :'',
            opTmHhStrt 	    :'',            //운영시작시간_시
            opTmMmStrt 	    :'',            //운영시작시간_분
            opTmEnd         :'',
            opTmHhEnd  	    :'',            //운영종료시간_시
            opTmMmEnd  	    :'',            //운영종료시간_분
            wrtr            :'',
            bnfcrgrHeader   :[
                { text: '수급자 그룹명',width: '200', value: '', align: 'center', },
                { text: '설명',width: '150', value: '', align: 'center', },
                { text: '처리',width: '100', value: '', align: 'center', },
            ],
            bnfcrgr         :[{
                prgBnfcrgrInfPk : 0,        //프로그램수급자그룹정보 고유번호
                bnfcrgrNm       : '',
                bnfcrgrDesc     : '',
            }],            //수급자그룹별프로그램관리 저장

            bnfcrHeaders: [
                { text: '연번', value: 'rn', sortable: false, filterable: false, width: '80'  },
                { text: '그룹명', value: 'bnfcrgrNm', sortable: false, filterable: false, width: '80' },
                { text: '수급자', value: 'name', sortable: false, filterable: false, width: '80' },
                { text: '나이', value: 'age', sortable: false, filterable: false, width: '80'},
                { text: '성별', value: 'gndr', sortable: false, filterable: false, width: '80' },
                { text: '등급', value: 'acknwRtng', sortable: false, filterable: false, width: '80'},
            ],
            bnfcrItems:[

            ],
        },
        bnfcrgrChkItems     : [],
        sltBnfcrgrInfPk     : -1,
        vCd41       :[],
        vCd42       :[],
        vCd65       :[],
        vCd74       :[],
        dialog      :[false, false],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
    }),
};
</script>