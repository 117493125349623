<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-2" color="" min-width="1260" max-width="1260" height="835">        
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">3-4. 목욕&nbsp;급여제공기록</span>
            </div>
            <v-spacer></v-spacer>
        </div>        
        <div class="pl-1 mt-5">
            <v-tabs
                v-model="tab"
                class="tab-dft-Theme" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :class="item.class" :key="item.tab" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>

            <v-tabs-items v-model="tab">                
                <v-tab-item transition="fade-transition">
                    <BathRcrdDay v-if="tab==0" style="height:708px;"></BathRcrdDay>
                </v-tab-item>                
                <v-tab-item transition="fade-transition">
                    <BathRcrdMonth 
                        v-if="tab==1"                        
                        :cd62="vCd62"
                        :cd63="vCd63"
                        :cd64="vCd64"
                        :cd65="vCd65"
                        style="height:708px;"
                    ></BathRcrdMonth>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-sheet>
</template>

<script>

import BathRcrdDay from '../../components/bnftrcrd/BathBlnftSrvRcrdDay';
import BathRcrdMonth from '../../components/bnftrcrd/BathBlnftSrvRcrdMth';
import { http } from '@/api/baseAxios';

export default {
    name: 'BathBlnftSrvRcrd',
        
    components: {
        BathRcrdDay,
        BathRcrdMonth
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCode(['62', '63', '64', '65'])
        })
    },

    computed: {

    },

    watch:{ 
        
    },
        
    methods: {
        // 공통 코드 다중 조회
        getCode: function (arr) {
            let obj = {}
            obj.valClcdS = arr
            obj.fcltyNum = this.$store.getters.getFcltyNum

            http.post('cmm/selCmnItmValCdJsn', obj)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },

        stCdMapping: function (res){
            this.vCd62 = res.vCd62
            this.vCd63 = res.vCd63
            this.vCd64 = res.vCd64
            this.vCd64.unshift({ valcd: '0', valcdnm: '직접입력' })            
            this.vCd65 = res.vCd65
        },
    },
    
    data: () => ({
        tab         : 0,
        tabItems    : [
            { index: 0, tab: '일별', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 1, tab: '월별', class: 'fontOneDotTwoRem px-10', enter: false },
        ],
        vCd62       : [],                              // 인정등급
        vCd63       : [],                              // 본인부담률
        vCd64       : [],                              // 이메일도메인
        vCd65       : [],                              // 입소현황
    }),
};
</script>