import excInst from 'exceljs'

const styleObj = { 
    alignment: { horizontal: 'center', vertical: 'middle' },
}

const fillObj = {}
      fillObj.type = 'pattern'
      fillObj.pattern = 'solid'
      fillObj.fgColor = { argb: 'ffededed' }
      fillObj.bgColor = { argb: 'ffededed' }

const borderObj = {}
      borderObj.top = { style:'thin', color: { argb:'ff000000' } }
      borderObj.left = { style:'thin', color: { argb:'ff000000' } }
      borderObj.bottom = { style:'thin', color: { argb:'ff000000' } }
      borderObj.right = { style:'thin' , color: { argb:'ff000000' } }

/**
 * 
 * @param { Number } num
 * @returns 변환된 알파벳
 */
function numericToStr(num) {
    let rst = num

    if( 64 < num && num < 91)
        rst = String.fromCharCode(num)
    else if( 90 < num && num < 116)
        rst = 'A' + String.fromCharCode((num - 90) + 64)
    else if( 115 < num && num < 141)
        rst = 'B' + String.fromCharCode((num - 90) + 64)
    // .....추가 작성 요망

    return rst
}

/**
 * 
 * @param { Workbook } wrkbk
 * @param { String } wrkbkTitle
 * @returns 엑셀 워크북 객체를 파일로 다운로드
 */
function xlsxDwlr(wrkbk, wrkbkTitle){
    wrkbk.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        let url = window.URL.createObjectURL(blob)
        let anchor = document.createElement('a')

        anchor.href = url
        anchor.download = wrkbkTitle.replaceAll(' ', '_') + '.xlsx'
        anchor.click()

        window.URL.revokeObjectURL(url)
    })
}

/**
 * 
 * @param { Array } header
 * @param { Array } data
 * @param { String } shtNm
 * @param { String } ttl
 * @returns 1행 헤더 생성 후 다운로드
 */
function snglHdrXlsxPrdc(header, data, shtNm, ttl){
    console.log('>> excel create Start : ')

    let start  = performance.now()

    header.forEach(itm => {
        itm.style = styleObj
    });

    // excel 생성
    let workbook = new excInst.Workbook()
    workbook.title = ttl
    workbook.description = ''
    workbook.creator = ''
    workbook.lastModifiedBy = ''
    workbook.company = '요양SYS'
    workbook.manager = '요양SYS'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    // 시트 생성
    let sheet = workbook.addWorksheet(shtNm)
    sheet.columns = header
    sheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 1, }
    ]

    data.forEach((itm, i) => {
        sheet.addRow(itm)
        sheet._rows[i].height = 22

        sheet._rows[i]._cells.forEach(itm => {
            itm.border = borderObj
        });
    });

    sheet.lastRow.height = 22
    sheet.lastRow._cells.forEach(itm => {
        itm.border = borderObj
    });

    // 헤더 스타일 적용
    let topRow = sheet.getRow(1)
 
    topRow.height = 33
    topRow._cells.forEach(itm => {
        itm.style.fill = fillObj
        itm.border = borderObj
        itm.font = { bold: true }
    });

    // 엑셀 다운로드
    xlsxDwlr(workbook, ttl)

    let end  = performance.now()

    console.log('estimated time: ' + (end-start).toFixed(2) + ' ms')
    console.log(' excel create End <<')
}

/**
 * 
 * @param { Array } header
 * @param { Array } data
 * @param { Array } mrgArr
 * @param { String } shtNm
 * @param { String } ttl
 * @param { String } wrtr
 * @param { String } totYn
 * @returns 2행 헤더(병합) 생성 후 다운로드
 */
function dblHdrXlsxPrdc(header, data, mrgArr, shtNm, ttl, wrtr, totYn){
    console.log('>> excel create Start : ')

    let start  = performance.now()

    // excel 생성
    let workbook = new excInst.Workbook()
    workbook.title = ttl
    workbook.description = ''
    workbook.creator = wrtr
    workbook.lastModifiedBy = wrtr
    workbook.company = '요양SYS'
    workbook.manager = '요양SYS'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    // 시트 생성 및 정보 설정
    let sheet = workbook.addWorksheet(shtNm)
    sheet.columns = header
    sheet.columns.forEach(itm => {
        itm.style.alignment = styleObj.alignment
    });
    sheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 2, }
    ]

    // 헤더 커스텀 (병합, 스타일)
    sheet.duplicateRow(1, 1, false)

    let colKnd = []
    mrgArr.forEach(itm => {
        if(typeof itm.colStrt == 'string' && itm.colStrt?.length == 2)
            colKnd.push(itm.colStrt.slice(0, 1).charCodeAt(0))
        if(typeof itm.colEnd == 'string' && itm.colEnd?.length == 2)
            colKnd.push(itm.colEnd.slice(0, 1).charCodeAt(0))

        sheet.mergeCells(itm.colStrt + ':' + itm.colEnd)
        sheet.getCell( itm.colStrt ).value = itm.colNm
    });

    let colMin = Math.min(...colKnd)
    let colMax = Math.max(...colKnd)

    if(colMin > 65){
        for (let index = 65; index < colMin; index++) {
            sheet.mergeCells(numericToStr(index) + '1:' + numericToStr(index) + '2' )
        }
    }
    if((header.length + 64) > colMax){
        for (let index = (colMax + 1); index <= (header.length + 64); index++) {
            sheet.mergeCells(numericToStr(index) + '1:' + numericToStr(index) + '2' )
        }
    }

    for (let index = 1; index < 3; index++) {
        let hdrRow = sheet.getRow(index)
        hdrRow.height = 28

        hdrRow._cells.forEach(itm => {
            itm.style.fill = fillObj
            itm.style.alignment = styleObj.alignment
            itm.border = borderObj
            itm.font = { bold: true }
        });
    }

    // 데이터 적용
    data.forEach((itm, i) => {
        sheet.addRow(itm)
        sheet._rows[i+2].height = 22
    
        for (let index = 1; index <= header.length; index++) {
            let sltCell = sheet._rows[i+2].getCell(index)
            sltCell.border = borderObj
        }
    });

    // 합계 행 추가
    if(totYn == 'Y'){
        sheet.addRow(data[data.length-1])
        let totRow = sheet.getRow(sheet.lastRow._number)
        totRow.height = 24

        totRow._cells.forEach(itm => {
            let sumCol = 0

            for (let idx = 3; idx < sheet.lastRow._number; idx++) {
                sumCol += sheet.getCell(itm._address.slice(0, 1) + idx).value
            }

            if(itm.style.numFmt == '#,##0'){
                itm.value = { formula: 'SUM(' + itm._address.slice(0, 1) + '3:' + itm._address.slice(0, 1)  + '' + (sheet.lastRow._number-1) + ')', result: sumCol}
            }
            else
                itm.value = ''

            totRow.getCell(itm._address.slice(0, 1)).border = borderObj
        });
        sheet.mergeCells('A' + sheet.lastRow._number +':' + numericToStr(colMin-1) + sheet.lastRow._number)
        totRow.getCell(1).value = '합계'
        totRow.getCell(1).style.fill = fillObj
        totRow.getCell(1).border = borderObj
        totRow.getCell(2).font = { bold: true }
    }

    // 엑셀 다운로드
    xlsxDwlr(workbook, ttl)

    let end  = performance.now()

    console.log('estimated time: ' + (end-start).toFixed(2) + ' ms')
    console.log(' excel create End <<')
}

/**
 * 
 * @param { Array } header
 * @param { Array } data
 * @param { Object } colLengObj
 * @param { String } dt
 * @param { String } subDt
 * @param { String } wrtr
 * @returns 생성된 엑셀 다운로드
 */
function empWageStmtXlsxPrdc(header, data, colLengObj, dt, subDt, wrtr) {
    let start  = performance.now()

    header.forEach(itm => {
        itm.style = styleObj
    });

    // excel 파일 생성
    let workbook = new excInst.Workbook()
    workbook.title = '직원급여대장'
    workbook.description = dt + '직원급여대장'
    workbook.creator = wrtr
    workbook.lastModifiedBy = wrtr
    workbook.company = '요양SYS'
    workbook.manager = '요양SYS'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()
    workbook.subject = ''
    workbook.category = ''
    workbook.keywords = ''
    workbook.calcProperties.fullCalcOnLoad = true

    // 시트 생성
    let sheet = workbook.addWorksheet('직원급여대장')
    sheet.columns = header

    // 로우 복제
    sheet.duplicateRow(1, 1, true)

    // 로우 위치 이동
    sheet.spliceRows(1, 1, [], [])

    // 셀 병합
    for (let index = 66; index < 74; index++) {
        sheet.mergeCells( numericToStr(index) + '3:' + numericToStr(index) + '5')
    }

    for (let index = 74; index < 84; index++) {
        sheet.mergeCells( numericToStr(index) + '4:' + numericToStr(index) + '5')
    }

    let prvsStrt = 65 + colLengObj.dft
    let prvsEnd = prvsStrt + colLengObj.prvs + colLengObj.prvsEtc

    sheet.mergeCells( numericToStr(prvsStrt) + '3:' + numericToStr(prvsEnd) + '3' )
    sheet.mergeCells( numericToStr(prvsStrt+colLengObj.prvs) + '4:' + numericToStr(prvsEnd-1) + '4' )
    sheet.mergeCells( numericToStr(prvsEnd) + '4:' + numericToStr(prvsEnd) + '5' )

    sheet.getCell( numericToStr(prvsStrt) + '3' ).value = '지급'
    sheet.getCell( numericToStr(prvsStrt+colLengObj.prvs) + '4' ).value = '기타'
    
    let ddctStrt = prvsStrt + colLengObj.prvs + colLengObj.prvsEtc + 1
    let ddctStrt2 = prvsStrt + colLengObj.prvs + colLengObj.prvsEtc + 1 + colLengObj.ddct
    let ddctEnd = ddctStrt + colLengObj.ddct + colLengObj.ddctEtc
    let ddctEnd2 = ddctEnd - 1

    sheet.mergeCells( numericToStr(ddctStrt) + '3:' + numericToStr(ddctEnd) + '3' )
    sheet.getCell( numericToStr(ddctStrt) + '3' ).value = '공제'
    for (let index = 0; index < 6; index++) {
        let tttt = prvsStrt + colLengObj.prvs + colLengObj.prvsEtc + 1 + index
      
        sheet.mergeCells( numericToStr(tttt) + '4:' + numericToStr(tttt) + '5' )
    }
 
    sheet.getCell(numericToStr(ddctStrt2) + '4').value = '기타'
    if(numericToStr(ddctStrt2) != numericToStr(ddctEnd2))
        sheet.mergeCells( numericToStr(ddctStrt2) + '4:' + numericToStr(ddctEnd2) + '4' )

    sheet.mergeCells( numericToStr(ddctEnd) + '4:' + numericToStr(ddctEnd) + '5' )
    
    let wagSttEnd = prvsStrt + colLengObj.prvs + colLengObj.prvsEtc + colLengObj.ddct + colLengObj.ddctEtc + 2

    sheet.mergeCells( numericToStr(wagSttEnd) + '3:' + numericToStr(wagSttEnd) + '5' )
    sheet.mergeCells( numericToStr(wagSttEnd + 1) + '3:' + numericToStr(wagSttEnd + colLengObj.fclty) + '3' )
    sheet.getCell( numericToStr(wagSttEnd + 1) + '3' ).value = '기관부담금'

    for (let index = (wagSttEnd + 1); index < (wagSttEnd + 1) + colLengObj.fclty; index++) {
        sheet.mergeCells( numericToStr(index) + '4:' + numericToStr(index) + '5' )
    }

    // 엑셀 헤더 타이틀 작성
    let topRow = sheet.getRow(1)
    topRow.getCell(2).value = dt + ' 직원급여대장'
    topRow._cells.forEach((itm, i) => {
        if(i > 0){
            itm.font = { bold: true, size: 20 }
        }
    });

    // 엑셀 서브 헤더 타이틀 작성
    let topDwRow = sheet.getRow(2)
    topDwRow.getCell(2).value = '근무기간 ( ' + subDt + ' )'
    sheet.getRow(1).height = 36
    sheet.getRow(2).height = 26
    sheet.mergeCells('B1:' + numericToStr(wagSttEnd + colLengObj.fclty) + '1' )
    sheet.mergeCells('B2:' + numericToStr(wagSttEnd + colLengObj.fclty) + '2' )

    // 데이터 적용
    data.forEach((itm, i) => {
        sheet.addRow(itm)

        sheet._rows[i+5].height = 22
        //sheet._rows[i+5].border = borderObj
      
        sheet._rows[i+5]._cells.forEach((itm, i) => {
            itm.style.border = borderObj
            if(i > 8){
                itm.style.numFmt = '#,##0'
            }
        });
    });
    
    // 마지막 열에 합계 정보 추가
    sheet.addRow({})
    let totRow = sheet.getRow(sheet.lastRow._number)
    totRow.height = 24
    
    for (let index = 74; index <= wagSttEnd + colLengObj.fclty; index++) {
        let sumCol = 0

        for (let idx = 6; idx < sheet.lastRow._number; idx++) {
            sumCol += sheet.getCell(numericToStr(index) + idx).value
        }

        totRow.getCell(numericToStr(index)).value
            = { formula: 'SUM(' + numericToStr(index) + '6:' + numericToStr(index) + '' + (sheet.lastRow._number-1) + ')', result: sumCol}
        
        totRow.getCell(numericToStr(index)).border = borderObj
        totRow.getCell(numericToStr(index)).style.numFmt = '#,##0'
    }
    
    sheet.mergeCells( 'B' + sheet.lastRow._number + ':' + 'I' + sheet.lastRow._number )
    totRow.getCell(2).style.fill = fillObj
    totRow.getCell(2).border = borderObj
    totRow.getCell(2).font = { bold: true }
    totRow.getCell(2).value = '합계'

    // 헤더 스타일 적용
    let thirdRow = sheet.getRow(3)
    thirdRow.height = 28
    thirdRow._cells.forEach((itm, i) => {
        if(i > 0){
            itm.style.fill = fillObj
            itm.border = borderObj
            itm.font = { bold: true }
        }
    });

    let forthRow = sheet.getRow(4)
    forthRow.height = 24
    forthRow._cells.forEach((itm, i) => {
        if(i > 0){
            itm.style.fill = fillObj
            itm.border = borderObj
            itm.font = { bold: true }
        }
    });

    let fifthRow = sheet.getRow(5)
    fifthRow.height = 24
    fifthRow._cells.forEach((itm, i) => {
        if(i > 0){
            itm.style.fill = fillObj
            itm.border = borderObj
            itm.font = { bold: true }
        }
    });

    // 엑셀 다운로드
    xlsxDwlr(workbook, (dt + ' 직원급여대장'))
        
    let end  = performance.now()
    console.log('estimated time: ' + (end-start).toFixed(2) + ' ms')
}

function mdcexXlsxPrdc(ttl, fclty, list) {
    // excel 생성
    let workbook = new excInst.Workbook()
    workbook.title = ttl
    workbook.description = ''
    workbook.creator = ''
    workbook.lastModifiedBy = ''
    workbook.company = '요양SYS'
    workbook.manager = '요양SYS'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    // 기본 시트 생성
    let sheet = workbook.addWorksheet('기본정보')
    let listSheet = workbook.addWorksheet('의료비납입내역1')
    sheet.columns = [
        { header: 'subTtl', key: 'subTtl', width: 26, style: styleObj },
        { header: 'data', key: 'data', width: 26, style: styleObj },
        { header: 'cntnt', key: 'cntnt', width: 60, style: styleObj }
    ]
    listSheet.columns = [
        { header: '주민등록번호', key: 'rsdntRgnmb', width: 22, style: styleObj },
        { header: '성명', key: 'name', width: 26, style: styleObj },
        { header: '수납일자', key: 'dpsDt', width: 20, style: styleObj },
        { header: '수납금액', key: 'dpsAmt', width: 20, style: { alignment: { horizontal: 'right', vertical: 'middle' }, } }
    ]
    sheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 13, showGridLines: false }
    ]
    listSheet.views = [
        { state: 'frozen', xSplit: 0, ySplit: 2, showGridLines: false }
    ]

    // 기본 시트 데이터 작성
    let ttlRow = sheet.getRow(1)
    ttlRow.height = 50
    ttlRow.getCell(1).value = '국세청 연말정산간소화 서비스를 위한 의료비 납입내역'
    sheet.mergeCells( 'A1:' + 'C1')
    ttlRow._cells.forEach(itm => {
        itm.font = { size: 16, bold: true }
    });

    for (let idx = 3; idx < (fclty.length + 3); idx++) {
        let bsnsRow = sheet.getRow(idx)
        if(idx == 5 || idx == 8)
            bsnsRow.height = 40
        else if(idx == 11)
            bsnsRow.height = 56
        else
            bsnsRow.height = 30
        bsnsRow.getCell(1).value = fclty[idx-3].subTtl
        bsnsRow.getCell(2).value = fclty[idx-3].inData
        bsnsRow.getCell(3).value = fclty[idx-3].cntnt

        bsnsRow._cells.forEach((itm, i) => {
            if(i == 2){
                itm.style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'c0c0c0' } }
                itm.style.alignment = { vertical: 'middle', wrapText: true }
            }
            else
                itm.style.alignment = styleObj.alignment
            if(i == 0)
                itm.style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } }

            if(idx == 3)
                itm.border = {
                    top: { style:'thin', color: { argb:'ff000000' } },
                    bottom: { style:'dotted', color: { argb:'ff000000' } },
                    left: { style:'thin', color: { argb:'ff000000' } },
                    right: { style:'thin', color: { argb:'ff000000' } }
                }
            else if(idx == 11)
                itm.border = {
                    bottom: { style:'thin', color: { argb:'ff000000' } },
                    left: { style:'thin', color: { argb:'ff000000' } },
                    right: { style:'thin', color: { argb:'ff000000' } }
                }
            else
                itm.border = {
                    bottom: { style:'dotted', color: { argb:'ff000000' } },
                    left: { style:'thin', color: { argb:'ff000000' } },
                    right: { style:'thin', color: { argb:'ff000000' } }
                }
            itm.font = { size: 10 }
        });
    }

    // 기본 시트 고정 텍스트 작성
    for (let idx = 15; idx < 41; idx++) {
        sheet.mergeCells( 'A'+idx+':' + 'C'+idx+'')
        sheet.getRow(idx)._cells.forEach(itm => {
            itm.style.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true }
        });
    }
    let ttlNotes = sheet.getRow(14)
    ttlNotes.height = 20
    ttlNotes.getCell(1).value = '작성시 주의사항'
    ttlNotes._cells.forEach((itm, i) => {
        itm.style.alignment = styleObj.alignment
        if(i == 0)
            itm.style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } }
        itm.border = borderObj
    });

    for (let idx = 16; idx < 41; idx++) {
        let txt = ''
        if(idx == 16) txt = '1. 절대 서식의 형태를 변경하면 안됩니다.'
        else if(idx == 17) txt = '    . Sheet 및 Row는 복사하여 사용하십시요.'
        else if(idx == 18) txt = '    . Sheet 1장당 최대 60,000 라인까지 입력하시고, 60,000 라인 이후의 데이터는 다른 Sheet에 입력하시기 바랍니다.'
        else if(idx == 20) txt = "2. 이 파일의 주민등록번호, 날짜 데이터가 입력되는 셀의 형식은 'TEXT' 형식으로 지정되어 있습니다."
        else if(idx == 21) txt = "    . 2000년 이후 출생자의 주민등록번호 입력시 첫자리의 '0'이 자동으로 제거될 경우 셀 입력 형식을 'TEXT'로 변경하십시요."
        else if(idx == 23) txt = "3. 사업자등록번호 입력방법"
        else if(idx == 24) txt = "    예1) 1234567890"
        else if(idx == 25) txt = "    예2) 123-45-6789"
        else if(idx == 27) txt = "4. 주민등록번호 입력방법"
        else if(idx == 28) txt = "    예1) 0701011234567"
        else if(idx == 29) txt = "    예2) 070101-1234567"
        else if(idx == 31) txt = "5. 날짜 입력방법"
        else if(idx == 32) txt = "    예1) 20150101"
        else if(idx == 33) txt = "    예2) 2015-01-01"
        else if(idx == 34) txt = "    예3) 2015.01.01"
        else if(idx == 36) txt = "6. 중간에 빈줄이 존재할 경우 빈줄 아래의 데이터는 처리되지 않습니다."
        else if(idx == 38) txt = "7. 기본정보 Sheet는 항상 첫번째 위치에 존재해야 합니다."
        else if(idx == 40) txt = "8. 5MB를 초과하는 경우 엑셀로 제출하실 수 없으며 반드시 텍스트 파일로 제출하여야 합니다. Xlsx 형식으로 저장하면 저장용량을 줄일 수 있습니다."

        let row = sheet.getRow(idx)
        row.height = 14
        row.getCell(1).value = txt
        if(idx == 40)
            row.font = { size: 10, bold: true }
        else
            row.font = { size: 10 }
    }

    // 데이터 시트 데이터 작성
    list.forEach((itm, i) => {
        listSheet.addRow(itm)
        listSheet._rows[i].height = 16

        listSheet._rows[i]._cells.forEach((idx, j) => {
            idx.border = borderObj
            if(j == 3)
                idx.style.numFmt = '#,##0_);[Red](#,##0)'
        });
        listSheet._rows[i].font = { size: 10 }
    });

    listSheet.spliceRows(1, 1, [], [])

    let dataHeader = listSheet.getRow(1)
    dataHeader.height = 20
    dataHeader.getCell(1).value = listSheet.columns[0].header
    dataHeader.getCell(2).value = listSheet.columns[1].header
    dataHeader.getCell(3).value = listSheet.columns[2].header
    dataHeader.getCell(4).value = listSheet.columns[3].header

    dataHeader._cells.forEach(itm => {
        itm.style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } }
        itm.style.alignment = styleObj.alignment
        itm.border = borderObj
        itm.font = { size: 10, bold: true }
    });

    listSheet.lastRow.height = 16
    listSheet.lastRow._cells.forEach((itm, j) => {
        itm.border = borderObj
        itm.font = { size: 10 }
        if(j == 3)
            itm.style.numFmt = '#,##0_);[Red](#,##0)'
    });

    let cntntHeader = listSheet.getRow(2)
    cntntHeader.height = 126
    cntntHeader.getCell(1).value = '수진자의 주민등록번호를 입력합니다.\n예1) 7001011234567\n예2) 010203-3234567'
    cntntHeader.getCell(2).value = '수진자의 성명을 입력합니다.\n최대 40bytes(한글20글자)까지만 처리됩니다.\n예) 홍길동'
    cntntHeader.getCell(3).value = "수납일자를 '년(4)월(2)일(2)' 형식으로 연속하여 입력합니다. \n같은 날에 여러건의 수납내역이 있을 경우 합산하여 입력합니다\n예1) 20150102\n예2) 2015-01-02\n예3) 2015.01.02"
    cntntHeader.getCell(4).value = ''
    cntntHeader._cells.forEach(itm => {
        itm.style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'c0c0c0' } }
        itm.border = borderObj
        itm.style.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true }
        itm.font = { size: 10 }
    });
    
    // 엑셀 다운로드
    xlsxDwlr(workbook, ttl)
}


/**
 * @param { Array } loss
 * @param { Array } profit
 * @param { String } monthly
 * @returns 월별손익관리 엑셀 생성
 */
function profitAndLossMnth(loss, profit, monthly){
    console.log('>> excel create Start : ')

    let start  = performance.now()
    let trgNm = monthly.slice(0, 4) + '년' + monthly.slice(4, 7) + '월'
    let hdr = [
        { header: '', key: '', width: 10 },
        { header: '항목', key: 'profitNm', width: 46 },
        { header: '금액', key: 'profitAmt', width: 20, style: { numFmt: '#,##0' } },
        { header: '항목', key: 'lossNm', width: 46 },
        { header: '금액', key: 'lossAmt', width: 20, style: { numFmt: '#,##0' } },
    ]
    let data = []

    // 데이터 정리
    if(profit.length > loss.length)
        profit.forEach((itm, i) => {
            let obj = {}
            obj.profitNm = itm.itmNm
            obj.profitAmt = itm.amt

            if(loss.length > i){
                obj.lossNm = loss[i].itmNm
                obj.lossAmt = loss[i].amt
            }
            else{
                obj.lossNm = ''
                obj.lossAmt = ''
            }

            data.push(obj)
        });
    else
        loss.forEach((itm, i) => {
            let obj = {}
            
            if(profit.length > i){
                obj.profitNm = profit[i].itmNm
                obj.profitAmt = profit[i].amt
            }
            else{
                obj.profitNm = ''
                obj.profitAmt = ''
            }

            obj.lossNm = itm.itmNm
            obj.lossAmt = itm.amt

            data.push(obj)
        });

    // 합계 작성
    let totRow = {}
    totRow.profitNm = '합계'
    totRow.profitAmt = profit.reduce((acc, cur) => { return acc += cur.amt }, 0)
    totRow.lossNm = '합계'
    totRow.lossAmt = loss.reduce((acc, cur) => { return acc += cur.amt }, 0)
    data.push(totRow)

    // excel 생성
    let workbook = new excInst.Workbook()
    workbook.title = trgNm + '_' + '손익관리'
    workbook.description = ''
    workbook.creator = ''
    workbook.lastModifiedBy = ''
    workbook.company = '요양SYS'
    workbook.manager = '요양SYS'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    // 시트 생성
    let sheet = workbook.addWorksheet(trgNm)
    sheet.columns = hdr
    // sheet.views = [
    //     { state: 'frozen', xSplit: 0, ySplit: 1, }
    // ]

    // 로우 복제
    sheet.duplicateRow(1, 2, false)

    // 로우 변경
    sheet.spliceRows(1, 1, [], [])
    sheet.spliceRows(2, 1, [], [])
    sheet.spliceRows(4, 1, [], [])

    // 엑셀 타이틀
    let ttlRow = sheet.getRow(2)
    ttlRow.getCell(2).value = monthly.slice(0, 4) + '년 ' + monthly.slice(4, 7) + '월 손익관리'
    ttlRow.getCell(2).style.alignment = { horizontal: 'center', vertical: 'middle' }
    ttlRow.getCell(2).font = { bold: true, size: 20 }
    sheet.mergeCells('B2:E2')

    // 단위 설명
    let dtlRow = sheet.getRow(4)
    dtlRow.getCell(5).value = '( 단위: 원 )'
    dtlRow.getCell(5).style.alignment = { horizontal: 'right', vertical: 'middle' }

    // 테이블 헤더 1열
    let tblRow = sheet.getRow(5)
    tblRow.height = 32
    tblRow.getCell(2).value = '수익'
    tblRow.getCell(4).value = '비용'
    tblRow.getCell(2).font = { bold: true, size: 14 }
    tblRow.getCell(4).font = { bold: true, size: 14 }
    tblRow.getCell(2).style.alignment = styleObj.alignment
    tblRow.getCell(4).style.alignment = styleObj.alignment
    tblRow.getCell(2).style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'c0c0c0' } }
    tblRow.getCell(4).style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'c0c0c0' } }
    tblRow.getCell(2).border = {
        top: { style:'thin', color: { argb:'ff000000' } },
        bottom: { style:'thin', color: { argb:'ff000000' } },
        left: { style:'thin', color: { argb:'ff000000' } },
        right: { style:'thin', color: { argb:'ff000000' } }
    }
    tblRow.getCell(4).border = {
        top: { style:'thin', color: { argb:'ff000000' } },
        bottom: { style:'thin', color: { argb:'ff000000' } },
        left: { style:'thin', color: { argb:'ff000000' } },
        right: { style:'thin', color: { argb:'ff000000' } }
    }
    sheet.mergeCells('B5:C5')
    sheet.mergeCells('D5:E5')

    // 테이블 헤더 2열
    let tblDRow = sheet.getRow(6)
    tblDRow.height = 26
    for (let index = 2; index < 6; index++) {
        tblDRow.getCell(index).font = { bold: true, size: 12 }
        tblDRow.getCell(index).style.alignment = styleObj.alignment
        tblDRow.getCell(index).style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'c0c0c0' } }
        tblDRow.getCell(index).border = {
            top: { style:'thin', color: { argb:'ff000000' } },
            bottom: { style:'thin', color: { argb:'ff000000' } },
            left: { style:'thin', color: { argb:'ff000000' } },
            right: { style:'thin', color: { argb:'ff000000' } }
        }
    }

    data.forEach(itm => {
        sheet.addRow(itm)
    });

    for (let index = 6; index < (data.length + 6); index++) {
        sheet._rows[index].height = 22

        sheet._rows[index]._cells.forEach(itm => {
            itm.border = borderObj
            itm.style.alignment = styleObj.alignment
        });
        
    }

    // 합계 행 스타일링
    let tblLastRow = sheet.getRow(6 + data.length)
    for (let index = 2; index < 6; index++) {
        tblLastRow.getCell(index).font = { bold: true, size: 12 }
        tblLastRow.getCell(index).style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'a6c9ec' } }
    }

    // 손익 정보 추가
    let prfAlos = sheet.getRow(8 + data.length)
    prfAlos.height = 22
    prfAlos.getCell(2).value = monthly.slice(0, 4) + '년 ' + monthly.slice(4, 7) + '월 손익'
    prfAlos.getCell(2).style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } }
    prfAlos.getCell(3).value = { formula: 'C' + (6 + data.length) + '-E' + (6 + data.length) }
    for (let index = 2; index < 4; index++) {
        prfAlos.getCell(index).font = { bold: true, size: 12 }
        prfAlos.getCell(index).style.alignment = styleObj.alignment
        prfAlos.getCell(index).border = borderObj
    }

    // 엑셀 다운로드
    xlsxDwlr(workbook, trgNm + '_' + '손익관리')

    let end  = performance.now()

    console.log('estimated time: ' + (end-start).toFixed(2) + ' ms')
    console.log(' excel create End <<')
}

/**
 * @param { Array } list
 * @param { String } yyyy
 * @returns 연별손익관리 엑셀 생성
 */
function profitAndLossYear(list, yyyy){
    console.log('>> excel create Start : ')

    let start  = performance.now()
    let profit = list.filter( v => v.calcClcd == '1' )
    let loss = list.filter( v => v.calcClcd == '2' )
    let data = []
    let halfLine = 0

    // jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec
    let hdr = [
        { header: '', key: '', width: 10 }, { header: '항목', key: '', width: 8 },
        { header: '항목', key: 'itmNm', width: 40 },
        { header: '1월', key: 'amtJan', width: 14, style: { numFmt: '#,##0' } },
        { header: '2월', key: 'amtFeb', width: 14, style: { numFmt: '#,##0' } },
        { header: '3월', key: 'amtMar', width: 14, style: { numFmt: '#,##0' } },
        { header: '4월', key: 'amtApr', width: 14, style: { numFmt: '#,##0' } },
        { header: '5월', key: 'amtMay', width: 14, style: { numFmt: '#,##0' } },
        { header: '6월', key: 'amtJun', width: 14, style: { numFmt: '#,##0' } },
        { header: '7월', key: 'amtJul', width: 14, style: { numFmt: '#,##0' } },
        { header: '8월', key: 'amtAug', width: 14, style: { numFmt: '#,##0' } },
        { header: '9월', key: 'amtSep', width: 14, style: { numFmt: '#,##0' } },
        { header: '10월', key: 'amtOct', width: 14, style: { numFmt: '#,##0' } },
        { header: '11월', key: 'amtNov', width: 14, style: { numFmt: '#,##0' } },
        { header: '12월', key: 'amtDec', width: 14, style: { numFmt: '#,##0' } },
    ]

    // 데이터 정리
    let profitTot = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let lossTot = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    profit.forEach(itm => {
        profitTot[0] += itm.amtJan
        profitTot[1] += itm.amtFeb
        profitTot[2] += itm.amtMar
        profitTot[3] += itm.amtApr
        profitTot[4] += itm.amtMay
        profitTot[5] += itm.amtJun
        profitTot[6] += itm.amtJul
        profitTot[7] += itm.amtAug
        profitTot[8] += itm.amtSep
        profitTot[9] += itm.amtOct
        profitTot[10] += itm.amtNov
        profitTot[11] += itm.amtDec

        data.push(itm)
    });
    data.push({
        itmNm: '합계', amtJan: profitTot[0], amtFeb: profitTot[1], amtMar: profitTot[2], amtApr: profitTot[3],
        amtMay: profitTot[4], amtJun: profitTot[5], amtJul: profitTot[6], amtAug: profitTot[7], amtSep: profitTot[8],
        amtOct: profitTot[9], amtNov: profitTot[10], amtDec: profitTot[11],
    })
    halfLine = data.length
    loss.forEach(itm => {
        lossTot[0] += itm.amtJan
        lossTot[1] += itm.amtFeb
        lossTot[2] += itm.amtMar
        lossTot[3] += itm.amtApr
        lossTot[4] += itm.amtMay
        lossTot[5] += itm.amtJun
        lossTot[6] += itm.amtJul
        lossTot[7] += itm.amtAug
        lossTot[8] += itm.amtSep
        lossTot[9] += itm.amtOct
        lossTot[10] += itm.amtNov
        lossTot[11] += itm.amtDec

        data.push(itm)
    });
    data.push({
        itmNm: '합계', amtJan: lossTot[0], amtFeb: lossTot[1], amtMar: lossTot[2], amtApr: lossTot[3],
        amtMay: lossTot[4], amtJun: lossTot[5], amtJul: lossTot[6], amtAug: lossTot[7], amtSep: lossTot[8],
        amtOct: lossTot[9], amtNov: lossTot[10], amtDec: lossTot[11],
    })
    data.push({
        itmNm: '', amtJan: profitTot[0] - lossTot[0], amtFeb: profitTot[1] - lossTot[1],
        amtMar: profitTot[2] - lossTot[2], amtApr: profitTot[3] - lossTot[3],
        amtMay: profitTot[4] - lossTot[4], amtJun: profitTot[5] - lossTot[5],
        amtJul: profitTot[6] - lossTot[6], amtAug: profitTot[7] - lossTot[7],
        amtSep: profitTot[8] - lossTot[8], amtOct: profitTot[9] - lossTot[9],
        amtNov: profitTot[10] - lossTot[10], amtDec: profitTot[11] - lossTot[11]
    })

    // excel 생성
    let workbook = new excInst.Workbook()
    workbook.title = yyyy + '년_손익관리'
    workbook.description = ''
    workbook.creator = ''
    workbook.lastModifiedBy = ''
    workbook.company = '요양SYS'
    workbook.manager = '요양SYS'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()

    // 시트 생성
    let sheet = workbook.addWorksheet(yyyy + '년')
    sheet.columns = hdr

    // 로우 복제
    sheet.duplicateRow(1, 1, false)

    // 로우 변경
    sheet.spliceRows(1, 1, [], [])
    sheet.spliceRows(2, 1, [], [])

    // 엑셀 타이틀
    let ttlRow = sheet.getRow(2)
    ttlRow.getCell(2).value = yyyy+ '년 손익관리'
    ttlRow.getCell(2).style.alignment = { horizontal: 'center', vertical: 'middle' }
    ttlRow.getCell(2).font = { bold: true, size: 20 }
    sheet.mergeCells('B2:O2')

    // 단위 설명
    let dtlRow = sheet.getRow(3)
    dtlRow.getCell(15).value = '( 단위: 원 )'
    dtlRow.getCell(15).style.alignment = { horizontal: 'right', vertical: 'middle' }

    // 테이블 헤더
    let tblDRow = sheet.getRow(4)
    tblDRow.height = 26
    for (let index = 2; index < 16; index++) {
        tblDRow.getCell(index).font = { bold: true, size: 12 }
        tblDRow.getCell(index).style.alignment = styleObj.alignment
        tblDRow.getCell(index).style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'c0c0c0' } }
        tblDRow.getCell(index).border = {
            top: { style:'thin', color: { argb:'ff000000' } },
            bottom: { style:'thin', color: { argb:'ff000000' } },
            left: { style:'thin', color: { argb:'ff000000' } },
            right: { style:'thin', color: { argb:'ff000000' } }
        }
    }
    sheet.mergeCells('B4:C4')

    //
    data.forEach(itm => {
        sheet.addRow(itm)
    });

    for (let index = 4; index < (data.length+4); index++) {
        sheet._rows[index].height = 20

        sheet._rows[index]._cells.forEach(itm => {
            itm.border = borderObj
            itm.style.alignment = { vertical: 'middle' }
        });
    }

    // 디자인 마무리
    let tttRow = sheet.getRow(5)
    tttRow.getCell(2).value = '수익'
    tttRow.getCell(2).font = { bold: true, size: 12 }
    tttRow.getCell(2).border = borderObj
    tttRow.getCell(2).style.alignment = styleObj.alignment
    let bbbRow = sheet.getRow(halfLine+5)
    bbbRow.getCell(2).value = '비용'
    bbbRow.getCell(2).font = { bold: true, size: 12 }
    bbbRow.getCell(2).border = borderObj
    bbbRow.getCell(2).style.alignment = styleObj.alignment
    let lllRow = sheet.getRow(data.length+4)
    lllRow.getCell(2).value = '손익 ( 수익 - 비용 )'
    lllRow.getCell(2).font = { bold: true }
    lllRow.getCell(2).border = borderObj
    lllRow.getCell(2).style.alignment = styleObj.alignment
    sheet.getRow(data.length+4).getCell(2).style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } }
    for (let index = 3; index < 16; index++) {
        lllRow.getCell(index).font = { bold: true }
        sheet.getRow(halfLine+4).getCell(index).style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'a6c9ec' } }
        sheet.getRow(data.length+3).getCell(index).style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'a6c9ec' } }
        sheet.getRow(data.length+4).getCell(index).style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff00' } }
    }
    sheet.mergeCells('B5:B' + (halfLine+4))
    sheet.mergeCells('B' + (halfLine+5) + ':B' + (data.length+3))
    sheet.mergeCells('B' + (data.length+4) + ':C' + (data.length+4))

    // 엑셀 다운로드
    xlsxDwlr(workbook, yyyy + '년_손익관리')

    let end  = performance.now()

    console.log('estimated time: ' + (end-start).toFixed(2) + ' ms')
    console.log(' excel create End <<')
}

export{
    snglHdrXlsxPrdc, dblHdrXlsxPrdc, empWageStmtXlsxPrdc, mdcexXlsxPrdc, profitAndLossMnth, profitAndLossYear
}