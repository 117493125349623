<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row no-gutters>
            <v-col cols="3">
                <BathBnfcr
                    :cd62="cd62"
                    :cd63="cd63"
                    :cd64="cd64"
                    :cd65="cd65"
                    @onBnfcr="setBnfcr"
                ></BathBnfcr>
            </v-col>
            <v-col class="pl-3" cols="9">
                <BathClndr 
                    @onBathObsrvRcrd="setBathObsrvRcrd"
                    @onBathRecSpclNote="onBathRecSpclNote"></BathClndr>
            </v-col>
        </v-row>

        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" persistent max-width="1200" min-width="1200">                
                <BathObsrvRcrd 
                    v-if="dialog[0]"
                    :inqYMD="sltMmbr.bathDt"
                    :bnMmbrPk="sltMmbr.bnMmbrPk"                    
                    @modalEvent="modalEvent"></BathObsrvRcrd>
            </v-dialog>
        </div> 
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[1]" persistent max-width="800" min-width="800">                
                <BathRecSpclNote 
                    v-if="dialog[1]"
                    :inqYYMM="yymm"
                    :bnMmbrPks="bnMmbrPks"                    
                    @isClose="dialog.splice(1,1,false)"></BathRecSpclNote>
            </v-dialog>
        </div> 
    </v-sheet>
</template>

<script>
import BathBnfcr from './BathBlnftSrvRcrdBnfcr';
import BathClndr from './BathBlnftSrvRcrdClndr';
import BathObsrvRcrd from '../../components/bnftrcrd/BathObsrvRcrd.vue';    //몸씻기
import BathRecSpclNote from '../../components/bnftrcrd/BathRecSpclNote.vue';    //목욕급여제공기록특이사항


export default {
    name: 'BathBlnftSrvRcrdMth',

    props : {                
        cd62:{type: Array, default: () => { return [] }},
        cd63:{type: Array, default: () => { return [] }},
        cd64:{type: Array, default: () => { return [] }},
        cd65:{type: Array, default: () => { return [] }},
    },
        
    components: {
        BathBnfcr,
        BathClndr,
        BathObsrvRcrd,
        BathRecSpclNote
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {

    },

    watch:{ 
        
    },
        
    methods: {
        setBnfcr:function(arr){
            this.bnMmbrPks = arr
            ++this.bnfcrChg
        },

        modalEvent:function(key){
            this.dialog.splice(key, 1, false)
            ++this.bnfcrChg
        },

        //목욕급여 관찰기록 ON
        setBathObsrvRcrd:function(event){

            this.sltMmbr.bathDt     = ''
            this.sltMmbr.bathTm     = ''
            this.sltMmbr.bnMmbrPk   = -1
            
            if(event.bnMmbrPk != null) {
                this.sltMmbr.bathDt     = event.bathDt
                this.sltMmbr.bathTm     = event.bathTm
                this.sltMmbr.bnMmbrPk   = event.bnMmbrPk
                this.dialog.splice(0, 1, true)
            } 
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 3000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '수급자 정보가 정확하지 않습니다.'         // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }
        },

        //목욕기록 특이사항 ON
        onBathRecSpclNote:function(v){
            this.yymm = ''

            if(this.bnMmbrPks.length > 0){
                this.yymm = this.$moment(v, 'YYYY-MM-DD').format('YYYYMM')
                this.dialog.splice(1, 1, true)
            } 
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 3000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '수급자를 선택해주세요'         // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }
        }
    },
    
    data: () => ({
        tab         : 0,
        bnfcrChg    : 0,
        bnMmbrPks   : [],        
        sltMmbr     : {bathDt:'',bathTm:'',bnMmbrPk:-1,},
        yymm        : '',
        dialog      : [false,false],
        tabItems    : [
            { index: 0, tab: '일별', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 1, tab: '월별', class: 'fontOneDotTwoRem px-10', enter: false },
        ],
    }),
};
</script>