<template>
    <v-sheet class="rounded-lg rounded-l-0  px-4" min-width="1280" max-width="1280" height="835" color="">            
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    8-1.&nbsp;직원&nbsp;임금명세서&nbsp;관리
                </span>
            </div>
            <v-spacer></v-spacer>    
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span class="fontOneRem ml-1">{{list.text}}</span>
                </v-btn>
                <v-dialog v-else-if="list.type == 'dialogue'"
                    v-model="dialog[i]" :max-width="list.width" content-class="round">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <MsclnExpnsIns v-if="list.text == '기타비용 입력'"></MsclnExpnsIns>
                        <div v-else>개발중</div>
                </v-dialog>
            </span>      
            <span class="d-flex justify-center">
                <v-btn
                    :class="outlinedBtn[0].class" :color="outlinedBtn[0].color"                                        
                    height="30" samll rounded outlined
                    @click="getExlEmplWgeEtcHdInf">
                    <v-icon style="padding-top: 2px;" small>{{outlinedBtn[0].icon}}</v-icon>
                    <span class="ml-1" style="font-size: 1rem;">{{outlinedBtn[0].text}}</span>
                </v-btn>
                <v-btn
                    :class="outlinedBtn[1].class" :color="outlinedBtn[1].color"                    
                    height="30" samll rounded outlined
                    @click="empWageDtlPrt">
                    <v-icon style="padding-top: 2px;" small>{{outlinedBtn[1].icon}}</v-icon>
                    <span class="ml-1" style="font-size: 1rem;">{{outlinedBtn[1].text}}</span>
                </v-btn>
            </span> 
        </div>
        <v-row class="ma-0 pa-0 mt-2">
            <v-col cols="12">
                <expandMonthPicker @nowYearWithMonth="getPickerDate"></expandMonthPicker>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mt-5" no-gutters> 
            <v-col cols="1">
                <exBtn :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />        
            </v-col>  
            <v-col cols="2">
                <span class="d-inline-block" style="width: 130px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" height="33px" background-color="white" append-icon="mdi-magnify"
                        single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                    </v-text-field>
                </span>
            </v-col>        
            <v-col cols="9" class="d-flex justify-end align-center">
                <v-btn
                    v-for="(itm, j) in actBtn" :key="j"
                    :class="itm.class" :color="itm.color" style="padding: 13px 12px 12px 10px !important"
                    @click="onActBtn(j)" :disabled="itm.disabled"
                    min-width="30" height="25" small outlined>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                <v-icon size="20">{{ itm.icon }}</v-icon>
                                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">{{ itm.text }}</span>
                            </div>
                        </template>
                        <span>{{ itm.tip }}</span>
                    </v-tooltip>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-3 pa-0" style="overflow-x: scroll;" no-gutters>
            <v-col cols="12">
                <v-data-table
                    class="copayClmsTbl tableFixedElm" height="630" locale="ko"                    
                    :headers="tblUse.headers" :items="tblUse.serverItems"
                    :search="schDummy" :custom-filter="customFilter"
                    :loading="tblUse.loading" @current-items="calcTotAmt"
                    hide-default-header fixed-header hide-default-footer disable-pagination dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th class="clmsFixTbl clmsHeaderCommon fixPstn0 zPstn3 tableBR tableBT" style="width: 57px; padding-left: 17px !important;" rowspan='2'>
                                    <span class="d-inline-block" style="width: 28px; height: 28px;">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            v-model="allChecker" @change="checkBoxMessage"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </th>
                                <th class="clmsFixTbl black--text clmsHeaderCommon tableBR tableBT" style="left: 57px; width: 80px !important; z-index: 3;" colspan="1" rowspan='2'>{{props.headers[0].text}}</th>
                                <th class="clmsFixTbl black--text clmsHeaderCommon tableBR tableBT" style="left: 137px; width: 80px !important; z-index: 3;" colspan="1" rowspan='2'>{{props.headers[1].text}}</th>
                                <th class="clmsFixTbl black--text clmsHeaderCommon tableBR tableBT" style="left: 217px; width: 80px !important; z-index: 3;" colspan="1" rowspan='2'>{{props.headers[2].text}}</th>
                                <th class="clmsFixTbl black--text clmsHeaderCommon tableBR tableBT" style="left: 300px; width: 80px !important; z-index: 3;" colspan="1" rowspan='2'>{{props.headers[3].text}}</th>
                                <th class="clmsFixTbl black--text clmsHeaderCommon tableBR tableBT" style="left: 380px; width: 90px !important; z-index: 3;" colspan="1" rowspan='2'>{{props.headers[18].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" colspan="3">{{props.headers[4].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" colspan="4">{{props.headers[5].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" colspan="1" rowspan='2'>{{props.headers[6].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" colspan="2">{{props.headers[7].text}}</th>
                                <th class="clmsFixTbl black--text clmsHeaderCommon tableBL tableBT" style="right: 0px; z-index: 3;" colspan="1" rowspan='2'>{{props.headers[8].text}}</th>
                            </tr>
                            <tr style="border-top: 1px solid #dbdbdb !important;">
                                <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[9].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[10].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[11].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[12].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[13].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[14].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[15].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[16].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[17].text}}</th>
                            </tr>
                        </thead>
                    </template>                
                    <template v-slot:item='{ item }'>
                        <tr class="fixedCol">
                            <td 
                                :class="[
                                    'clmsFixTbl clmsBodyCommon zPstn2 fixedCol0 fixPstn0 px-0 tableBR tableBB',
                                    item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']"    
                                    style="padding-left: 6px !important;"                                
                                >
                                <span class="d-inline-block" style="width: 28px; height: 28px;">
                                    <v-checkbox
                                        class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                        v-model="item.isSelected" @change="checkBoxIndex()"
                                        hide-details>
                                    </v-checkbox>
                                </span>
                            </td>
                            <td :class="[
                                'clmsFixTbl clmsBodyCommon fixedCol0 zPstn2 tableBR tableBB',
                                item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']"
                                style="left: 57px;">{{item.name}}</td>
                            <td :class="[
                                'clmsFixTbl clmsBodyCommon fixedCol0 zPstn2 tableBR tableBB',
                                item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']" 
                                style="left: 137px;">{{item.jobNm}}</td>
                            <td :class="[
                                'clmsFixTbl clmsBodyCommon fixedCol0 zPstn2 tableBR tableBB',
                                item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']" 
                                style="left: 217px;">{{item.entDt?.beforeDateFormatDot()}}</td>
                            <td :class="[
                                'clmsFixTbl clmsBodyCommon fixedCol0 zPstn2 tableBR tableBB',
                                item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']" 
                                style="left: 300px;">{{item.wgeTypNm?.toLocaleString('ko-KR')}}</td>
                            <td :class="[
                                'clmsFixTbl clmsBodyCommon fixedCol0 zPstn2 tableBR tableBB',
                                item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']"
                                style="left: 380px;">
                                <span
                                    v-if="item.cnfrmYn == '1'"
                                    class="d-inline-block rounded blueBtnColor"
                                    style="width: 25px; padding-bottom: 3px; margin: 1px 0 0 3px; cursor: initial;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="white--text" size="16" v-bind="attrs" v-on="on">mdi-check-bold</v-icon>
                                        </template>
                                        <span>확정</span>
                                    </v-tooltip>
                                </span>
                                <span
                                    v-if="item.cnfrmYn == '2'"
                                    class="d-inline-block rounded grey005"
                                    style="width: 25px; padding-bottom: 3px; margin: 1px 0 0 3px; cursor: initial;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="white--text" size="16" v-bind="attrs" v-on="on">mdi-minus-thick</v-icon>
                                        </template>
                                        <span>미확정</span>
                                    </v-tooltip>
                                </span>
                            </td>
                            <td :class="[
                                    'clmsBodyCommon fixedCol0 tableBR tableBB',
                                    item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']"
                                >{{item.amt1Tx?.toLocaleString('ko-KR')}}</td>
                            <td :class="[
                                    'clmsBodyCommon fixedCol0 tableBR tableBB',
                                    item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']"
                                >{{item.amt1Ntx?.toLocaleString('ko-KR')}}</td>
                            <td :class="[
                                    'clmsBodyCommon fixedCol0 tableBR tableBB',
                                    item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']"
                                >{{item.amt1Tot?.toLocaleString('ko-KR')}}</td>
                            <td :class="[
                                    'clmsBodyCommon fixedCol0 tableBR tableBB',
                                    item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']"
                                >{{item.amt2Ins?.toLocaleString('ko-KR')}}</td>
                            <td :class="[
                                    'clmsBodyCommon fixedCol0 tableBR tableBB',
                                    item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']"
                                >{{item.amt2Tx?.toLocaleString('ko-KR')}}</td>
                            <td :class="[
                                    'clmsBodyCommon fixedCol0 tableBR tableBB',
                                    item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']"
                                >{{item.amt2Msc?.toLocaleString('ko-KR')}}</td>
                            <td :class="[
                                    'clmsBodyCommon fixedCol0 tableBR tableBB',
                                    item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']"
                                >{{item.amt2Tot?.toLocaleString('ko-KR')}}</td>
                            <td :class="[
                                    'clmsBodyCommon fixedCol0 tableBR tableBB',
                                    item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']"
                                >{{item.amtRealPym?.toLocaleString('ko-KR')}}</td>
                            <td :class="[
                                    'clmsBodyCommon fixedCol0 tableBR tableBB',
                                    item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']"
                                >{{item.amtSclInsrn?.toLocaleString('ko-KR')}}</td>
                            <td :class="[
                                    'clmsBodyCommon fixedCol0 tableBR tableBB',
                                    item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']"
                                >{{item.amtRtr?.toLocaleString('ko-KR')}}</td>
                            <td :class="[
                                'clmsFixTbl clmsBodyCommon fixedCol0 zPstn2 tableBL tableBB',
                                item.isSelected == true ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white']"
                                style="right: 0px;">  
                                <div class="d-flex">
                                    <span>
                                        <v-btn
                                            color="grey006" width="30" height="26"
                                            @click="onShowEmp(item)"
                                            :disabled="item.wgePymHisPk == null"
                                            icon>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                        <v-icon size="20">mdi-square-edit-outline</v-icon>
                                                    </div>
                                                </template>
                                                <span>임금명세서조회</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </span> 
                                    <span>
                                        <v-btn
                                            color="grey006" width="30" height="26"   
                                            :disabled="item.wgePymHisPk == null" 
                                            @click="empWageStmtPrt(item.empPk)"                          
                                            icon>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                        <v-icon size="20">mdi-printer-outline</v-icon>
                                                    </div>
                                                </template>
                                                <span>임금명세서출력</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </span>   
                                </div>                          
                            </td>
                        </tr>
                    </template>


                    <template v-slot:[`body.append`]="{ item }">
                        <tr sclass="allHdrFix" style="bottom: 0;">
                            <td class="fixedTable fixPstn0 clmsHeaderCommon black--text tableBR tableBT fixedTable py-2" style="" colspan="6">합계</td>                            
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{tblUse.amt1Tx?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{tblUse.amt1Ntx?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{tblUse.amt1Tot?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{tblUse.amt2Ins?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{tblUse.amt2Tx?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{tblUse.amt2Msc?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{tblUse.amt2Tot?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{tblUse.amtRealPym?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{tblUse.amtSclInsrn?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{tblUse.amtRtr?.toLocaleString('ko-KR')}}</td>
                            <td class="fixedTable clmsHeaderCommon black--text tableBL tableBT" style="right: 0px;font-size: 0.875rem !important; width:52px !important;" colspan="1">{{item}}</td>
                        </tr>
                    </template>
                    
                </v-data-table>
            </v-col>
        </v-row>
        <div class="pa-0 ma-0">
            <v-dialog v-model="modal[0]" persistent max-width="1200" min-width="1200">                
                <empWageStmtWrt 
                    ref="empWageStmtWrt"
                    :date="yymm"
                    :empWageStmtWrtPk="empWageStmtWrtPk"
                    :mmbrPk="sltMmbrPk"
                    :cnfrm="wrtCnfrm"
                    @modalEvent="modalEvent"></empWageStmtWrt>
            </v-dialog>
        </div> 
        <div class="pa-0 ma-0">
            <v-dialog eager v-model="modal[1]" persistent max-width="1200" min-width="1200">                
                <empWageStmtMsgSend 
                    ref="empWageStmtMsgSend"
                    :date="yymm"
                    :empWageStmtMsgSendPk="empWageStmtMsgSendPk"
                    @modalEvent="modalEvent"></empWageStmtMsgSend>
            </v-dialog>
        </div>
    </v-sheet>
</template>

<script>
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import empWageStmtWrt from '../../components/impExp/EmpWageStmtWrt.vue';  //임금명세서 저장
import empWageStmtMsgSend from '../../components/impExp/EmpWageStmtMsgSend.vue';  //임금명세서 발송

import { http } from '@/api/baseAxios';
import { empWageStmtXlsxPrdc } from '../../api/global/cmmExcelJS.js';

export default {
    name: 'EmpWageStmtMgmt',
        
    components: {
        expandMonthPicker,
        exBtn,
        empWageStmtWrt,
        empWageStmtMsgSend,
    },

    created: function(){
        
    },

    computed: {
        getSubRunEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {     
            this.yymm = this.$moment().format('YYYYMM')
            this.getAllEmplWgePymList()
        })
    },

    watch:{
        getSubRunEvt: function (bol) {
            
            if(bol && this.rmWgeAct){                
                this.preRmWgePymHis()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmWgeAct = false
            }

            if(bol && this.wgeCnfrmS){                
                this.preWgePymCnfrm(1)
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.wgeCnfrmS = false
            }

            if(bol && this.wgeCnfrmC){     
                this.preWgePymCnfrm(2)           
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.wgeCnfrmC = false
            }
        },
    },

    methods: {
        //직원임금명세전체 조회
        getAllEmplWgePymList:function(){
            this.vCd42.splice(0)
            this.tblUse.serverItems.splice(0)
            this.exBtn[0].expandList.splice(0)
            this.tblUse.loading = true
            http.post('impExp/selAllEmplWgePymList', {inqYYMM : this.yymm})
                .then((response) => ( this.rstAllEmplWgePymList(response.data) ))
                .catch((error) => console.log('connect error /impExp/selPrgInfShtlist : ' + error))
        },

        //직원임금명세전체 조회(코드제외)
        getOnlyList:function(){
            this.tblUse.serverItems.splice(0)
            this.tblUse.loading = true
            http.post('impExp/selOnlyAllEmplWgePymList', {inqYYMM : this.yymm})
                .then((response) => ( this.rstOnlyList(response.data) ))
                .catch((error) => console.log('connect error /impExp/selOnlyAllEmplWgePymList : ' + error))
        },

        //임금명세서 생성
        setInsWgePym(obj){
            http.post('impExp/insWgePym', obj)
                .then((response) => ( this.rstWgePym(response.data) ))
                .catch((error) => console.log('connect error /impExp/insWgePym : ' + error))
        },

        //임금명세서 확정/취소
        setWgePymCnfrm(obj){
            http.post('impExp/updlWgePymCnfrm', obj)
                .then((response) => ( this.rstWgePym(response.data) ))
                .catch((error) => console.log('connect error /impExp/updlWgePymCnfrm : ' + error))
        },

        //임금명세서 삭제
        rmWgePymHis(obj){
            http.post('impExp/delWgePymHis', obj)
                .then((response) => ( this.rstWgePym(response.data) ))
                .catch((error) => console.log('connect error /impExp/delWgePymHis : ' + error))
        },

         // 엑셀 생성 데이터 조회
         getExlEmplWgeEtcHdInf: function () {

            http.post('impExp/selExlEmplWgeEtcHdInfo', {inqYYMM : this.yymm})
                .then((response) => ( this.rstExlEmplWgeEtcHdInf(response.data) ))
                .catch((error) => console.log('connect error /impExp/selOnlyAllEmplWgePymList : ' + error))
        },

        //결과 :: 직원임금명세전체 조회
        rstAllEmplWgePymList:function(res){
            this.tblUse.loading = false
            if(res != null && res != ''){                
                if(res.code != null){
                    res.code.vCd42.forEach(cd => {
                        this.vCd42.push(cd)
                        this.exBtn[0].expandList.push(cd.valcdnm)
                    });
                }

                if(res.list != null){
                    res.list.forEach(itm => {   
                        
                        this.vCd42.forEach(cd => {
                            if(itm.jobCd == cd.valcd)
                                itm.jobCd = cd.valcdnm
                        });
                        
                        this.tblUse.serverItems.push(itm)
                    });
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = '목록 조회 실패하였습니다.'         // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }

            this.calcTotAmt(this.tblUse.serverItems)
        },

        //결과 :: 직원임금명세전체 조회(코드제외)
        rstOnlyList:function(res){
            
            this.tblUse.loading = false
            if(res.statusCode == '200'){

                if(res.result != null){
                    res.result.forEach(itm => {   
                        
                        this.vCd42.forEach(cd => {
                            if(itm.jobCd == cd.valcd)
                                itm.jobCd = cd.valcdnm
                        });
                        
                        this.tblUse.serverItems.push(itm)
                    });

                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = '목록 조회 실패하였습니다.'         // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }

            this.calcTotAmt(this.tblUse.serverItems)
        },

        //결과 :: 임금명세서 이벤트
        rstWgePym:function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀

            if (res.statusCode == 200) {
                obj.tmr = 3000
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.$store.commit('setWrnnInf', obj)         
                this.getOnlyList()
            } else {
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                this.$store.commit('setWrnnInf', obj)
            }     
        },

        //결과::엑셀생성데이터 조회
        rstExlEmplWgeEtcHdInf:function(res){
            if(res?.list.length > 0){
                // 엑셀 헤더 정보 작성
                let exlColPrvsEtc = []
                let exlColDdctEtc = []
                let exlColPrvsTot = [{ header: '합계', key: 'pAmtTot', width: 20 }]
                let exlColDdctTot = [{ header: '합계', key: 'dAmtTot', width: 20 }]
                let actTot = [{ header: '실수령액', key: 'actAmtTot', width: 20 }]
                res.ptAmtNmsList.forEach(itm => {
                    let obj = {}
                    obj.header = itm.val
                    obj.key = itm.col
                    obj.width = 22

                    exlColPrvsEtc.push(obj)
                });
                res.dtAmtNmsList.forEach(itm => {
                    let obj = {}
                    obj.header = itm.val
                    obj.key = itm.col
                    obj.width = 22

                    exlColDdctEtc.push(obj)
                });

                // 동적 컬럼 갯수 정보 작성
                let colLengObj = {}
                colLengObj.dft = this.exlColDft.length
                colLengObj.prvs = this.exlColPrvs.length
                colLengObj.prvsEtc = exlColPrvsEtc.length
                colLengObj.ddct = this.exlColDdct.length
                colLengObj.ddctEtc = exlColDdctEtc.length
                colLengObj.fclty = this.exlColFclty.length

                // 헤더 정보를 1차원 배열로 합성
                let hArr = [
                    ...this.exlColDft,
                    ...this.exlColPrvs, ...exlColPrvsEtc, ...exlColPrvsTot,
                    ...this.exlColDdct, ...exlColDdctEtc, ...exlColDdctTot,
                    ...actTot, ...this.exlColFclty
                ]

                // 엑셀 생성 함수 호출 ( 직원 임금명세 전용 )
                let ttl = this.$moment(this.yymm, 'YYYYMM').format('YYYY년 MM월')
                let subTtl = this.$moment(this.yymm+'01', 'YYYYMMDD').format('YYYY.MM.DD') + ' ~ ' + this.$moment(this.yymm+'01', 'YYYYMMDD').endOf('month').format('YYYY.MM.DD')
                empWageStmtXlsxPrdc( hArr, res.list, colLengObj, ttl, subTtl, this.$store.getters.getUserNm )
            } else {
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '임금 확정 목록이 존재하지 않습니다.'         // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }
        },

        //달력변경이벤트
        getPickerDate: function (date) {
            if(this.yymm != date){
                this.yymm = date
                this.getOnlyList()
            }
        },

        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'jobCd'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },

        filterSearch: function (sch) {            
            if(sch.length > 0){
                sch.forEach((item) => { this.schChkField.jobCd.push(item) });            
                this.schDummy = this.schDummy + '.'
            }else{
                this.schDummy= ''
                this.schChkField.jobCd.splice(0)
            }
        },

        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.jobCd.length != 0){
                this.schChkField.jobCd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.jobCd.includes(stcd)
                    else col1 = col1 || filter.jobCd.includes(stcd)
                });
            }

            return col0 && col1;
        },

        setSearchText: function (value) {            
            this.schField = value
            this.schDummy = this.schDummy + '.'
        },

        checkBoxMessage: function () {
            if(this.allChecker == true){
                this.tblUse.serverItems.forEach(item => {
                    item.isSelected = true
                })
            }
            else if(this.allChecker == false){
                this.tblUse.serverItems.forEach(item => {
                    item.isSelected = false
                })
            }
            let pkList = []
            this.tblUse.serverItems.forEach(items => {
                if(items.isSelected == true) pkList.push(items.empPk)
            });
            this.selectedList = pkList
        },

        checkBoxIndex: function () {
            let chk = []
            let pkList = []
            this.tblUse.serverItems.forEach(items => {
                if(typeof items.isSelected == 'undefined') chk.push(false)
                else chk.push(items.isSelected)

                if(items.isSelected == true) pkList.push(items.empPk)
            });
            this.selectedList = pkList
            let result = chk.every((item) => item==true)

            if(result) this.allChecker = true
            else this.allChecker = false
        },

        // 합계 계산
        calcTotAmt: function (arr) {
            this.tblUse.amt1Tx        = 0
            this.tblUse.amt1Ntx       = 0
            this.tblUse.amt1Tot       = 0
            this.tblUse.amt2Ins       = 0
            this.tblUse.amt2Tx        = 0
            this.tblUse.amt2Msc       = 0
            this.tblUse.amt2Tot       = 0
            this.tblUse.amtRealPym    = 0
            this.tblUse.amtSclInsrn   = 0
            this.tblUse.amtRtr        = 0
            
            arr.forEach(itm => {
                itm.amt1Tot = Number(itm.amt1Tx)+Number(itm.amt1Ntx)
                itm.amt2Tot = Number(itm.amt2Tx)+Number(itm.amt2Ins)+Number(itm.amt2Msc)

                this.tblUse.amt1Tx        += Number(itm.amt1Tx)
                this.tblUse.amt1Ntx       += Number(itm.amt1Ntx)
                this.tblUse.amt1Tot       += Number(itm.amt1Tot)
                this.tblUse.amt2Ins       += Number(itm.amt2Ins)
                this.tblUse.amt2Tx        += Number(itm.amt2Tx)
                this.tblUse.amt2Msc       += Number(itm.amt2Msc)
                this.tblUse.amt2Tot       += Number(itm.amt2Tot)
                this.tblUse.amtRealPym    += Number(itm.amtRealPym)
                this.tblUse.amtSclInsrn   += Number(itm.amtSclInsrn)
                this.tblUse.amtRtr        += Number(itm.amtRtr)
            });
        },

        //상단 버튼 이벤트
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.empWageStmtMsgSendPk = 1
                    this.modal.splice(1, 1, true)
                    break;
                default:
                    break;
            }
        },

        //하단 버튼 이벤트
        onActBtn:function(key){
            switch (key) {
                case 0:
                    this.preInsWgePym()
                    break;
                case 1:
                    this.queWgePymCnfrm(key)
                    break;
                case 2:
                    this.queWgePymCnfrm(key)
                    break;
                case 3:
                    this.queRmWgePymHis()
                    break;
                default:
                    break;
            }
        },  

        //질문::임금명세서 확정/ 취소
        queWgePymCnfrm:function(key){
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '임금명세서'
            obj.cntnt = []
            if(key== 1) {
                obj.cntnt.push('확정처리을(를) 진행하시겠습니까?')
                this.wgeCnfrmS = true
            }
            else {
                obj.cntnt.push('확정취소을(를) 진행하시겠습니까?')
                this.wgeCnfrmC = true
            }

            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 진행하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });   
        },

        queRmWgePymHis:function(){
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '임금명세서'
            obj.cntnt = ['삭제하시겠습니까?']
            
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'
            
            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });   

            this.rmWgeAct = true
        },

        //준비 :: 임금명세서 생성
        preInsWgePym:function(){
            let obj = {}
            obj.actv = true                 // 경고 노출여부

            let param = {
                inqYYMM : this.yymm,
                empPks  : []
            }

            let arr = this.tblUse.serverItems.filter(v=>v.isSelected =! undefined && v.isSelected)
            
            if(arr != null && arr.length > 0){
                let pass    = true
                let names   = []                

                arr.forEach(emp => {
                    if(emp.cnfrmYn == '1') {
                        pass    = false
                        names.push(emp.name)
                    } else {
                        param.empPks.push(emp.empPk)
                    }
                });

                if(pass){
                    this.setInsWgePym(param)
                }else{
                    obj.tmr = 3000                  // 경고 노출시간(-1: 무제한)
                    obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                    obj.title = '확정여부를 확인 해주세요!'         // 경고 타이틀
                    obj.cntnt = []                                           // 경고 내용 (arr만 출력됨!)
                    names.forEach(el => {
                        obj.cntnt.push(el)
                    });
                    this.$store.commit('setWrnnInf', obj)
                }

            } else {
                
                obj.tmr = 3000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '직원을 선택해주세요.'         // 경고 타이틀
                this.$store.commit('setWrnnInf', obj)
            }
        },

        //준비 :: 임금명세서 확정/취소
        preWgePymCnfrm:function(key){
            let obj = {}
            obj.actv = true                 // 경고 노출여부

            let param = {
                stdYymm : this.yymm,
                empPks  : [],
                cnfrmYn : key
            }

            let arr = this.tblUse.serverItems.filter(v=>v.isSelected =! undefined && v.isSelected)

            if(arr != null && arr.length > 0){               

                let passCnfrm   = true
                let cnfrmNames  = []
                let passPk      = true
                let pkNames     = []

                arr.forEach(emp => {
                    param.empPks.push(emp.empPk)

                    //확정처리
                    if(key == 1){
                        if(emp.cnfrmYn == '1'){
                            passCnfrm = false
                            cnfrmNames.push(emp.name)
                        }
                    //확정취소
                    } else if (key==2){
                        if(emp.cnfrmYn != '1'){
                            passCnfrm = false
                            cnfrmNames.push(emp.name)
                        }
                    }

                    if(emp.wgePymHisPk == 0 || emp.wgePymHisPk == null){
                        passPk      = false
                        pkNames.push(emp.name)
                    }
                });

                if(passCnfrm && passPk){
                    this.setWgePymCnfrm(param)
                }else{
                    obj.title = ''                  // 경고 타이틀
                    obj.tmr = 3000                  // 경고 노출시간(-1: 무제한)
                    obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                    obj.cntnt = []                  // 경고 내용 (arr만 출력됨!)

                    if(key == 1){

                        if(!passCnfrm) {
                            obj.title = '이미 확정처리된 직원이 포함되어있습니다!'
                            cnfrmNames.forEach(el => {
                                obj.cntnt.push(el)
                            });
                        }
                        else {
                            obj.title = '명세서가 생성이 되어있지 않습니다!'
                            pkNames.forEach(el => {
                                obj.cntnt.push(el)
                            });
                        }
                    } else {
                        if (!passPk) {
                            obj.title = '명세서가 생성이 되어있지 않습니다!'
                            pkNames.forEach(el => {
                                obj.cntnt.push(el)
                            });
                        } else {
                            if(!passCnfrm) {
                                obj.title = '확정처리 후 이용해주세요.'
                                cnfrmNames.forEach(el => {
                                    obj.cntnt.push(el)
                                });
                            }
                        }
                    }
                    this.$store.commit('setWrnnInf', obj)
                }

            } else {
                
                obj.tmr = 3000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '직원을 선택해주세요.'         // 경고 타이틀
                this.$store.commit('setWrnnInf', obj)
            }
        },

        //준비 :: 임금명세서 삭제
        preRmWgePymHis:function(){
            let obj = {}
            obj.actv = true                 // 경고 노출여부

            let param = {
                inqYYMM : this.yymm,
                empPks  : []
            }

            let arr = this.tblUse.serverItems.filter(v=>v.isSelected =! undefined && v.isSelected)
            
            if(arr != null && arr.length > 0){
                
                let passCnfrm   = true
                let cnfrmNames  = []
                let passPk      = true
                let pkNames     = []

                arr.forEach(emp => {
                    param.empPks.push(emp.empPk)

                    if(emp.cnfrmYn == '1') {
                        passCnfrm    = false
                        cnfrmNames.push(emp.name)
                    } 

                    if(emp.wgePymHisPk == 0 || emp.wgePymHisPk == null){
                        passPk      = false
                        pkNames.push(emp.name)
                    }
                });

                if(passCnfrm && passPk){
                    this.rmWgePymHis(param)
                }else{
                    obj.title = ''                  // 경고 타이틀
                    obj.tmr = 3000                  // 경고 노출시간(-1: 무제한)
                    obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                    obj.cntnt = []                  // 경고 내용 (arr만 출력됨!)

                    if (!passPk) {
                        obj.title = '명세서가 생성이 되어있지 않습니다!'
                        pkNames.forEach(el => {
                            obj.cntnt.push(el)
                        });
                    } else {
                        if(!passCnfrm) {
                            obj.title = '확정취소 후 이용해주세요.'
                            cnfrmNames.forEach(el => {
                                obj.cntnt.push(el)
                            });
                        }
                    }

                    this.$store.commit('setWrnnInf', obj)
                }

            } else {
                
                obj.tmr = 3000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '직원을 선택해주세요.'         // 경고 타이틀
                this.$store.commit('setWrnnInf', obj)
            }
        },

        modalEvent: function (event) { 
            if(event == 99){
                this.getOnlyList(this.yymm)
            }else{
                if(event == 0) this.empWageStmtWrtPk = -1
                if(event == 1) this.empWageStmtMsgSendPk = -1
                this.modal.splice(event, 1, false)
            }      
        },

        //전체 출력
        empWageDtlPrt: function (){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.prtCd = '24'
            this.prtThrowObj.inqYYMM = this.yymm
            this.rprtActCall()
        },

        rprtActCall: function (){
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },

        onShowEmp:function(itm){  
            this.empWageStmtWrtPk = itm.empPk
            this.sltMmbrPk = itm.mmbrPk

            this.wrtCnfrm = (itm.cnfrmYn == '1')?true:false
            this.modal.splice(0, 1, true)
        },

        empWageStmtPrt:function(bzpk){
            this.prtThrowObj.list[0].bzPk.splice(0)
            // prtThrowObj: { fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '24', inqYYMM: '', list: [ { bzClcd: [], bzPk: [] } ], },            
            this.prtThrowObj.prtCd = '31'
            this.prtThrowObj.inqYYMM = this.yymm
            this.prtThrowObj.list[0].bzPk.push(bzpk)
            //console.log(this.prtThrowObj)
            this.rprtActCall()
        },
    },
    data: () => ({
        allChecker  : false,
        modal       : [ false, false ],
        yymm        : '',
        vCd42       : [],
        schField    : '',
        schDummy    : '',
        schChkField : { jobCd: [], target: '' },
        exBtn       : [ 
            { index:  0, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
        ],
        tblUse:{
            loading         : false,
            headers         : [
                                    { text: '직원명', value: 'name', align: 'center'},
                                    { text: '직종', value: 'jobNm', align: 'center'},
                                    { text: '입사일', value: 'entDt', align: 'center'},
                                    { text: '임금형태', value: 'wgeTypNm', align: 'center'},                                    
                                    { text: '지급내역', value: '', align: 'center'},
                                    { text: '공제내역', value: '', align: 'center'},
                                    { text: '실지급액', value: '', align: 'center'},
                                    { text: '기관부담금', value: '', align: 'center'},
                                    { text: '상세', value: '', align: 'center'},
                                    { text: '과세합계', value: '', align: 'center'},
                                    { text: '비과세합계', value: '', align: 'center'},
                                    { text: '합계', value: '', align: 'center'},
                                    { text: '4대보험', value: '', align: 'center'},
                                    { text: '소득세', value: '', align: 'center'},
                                    { text: '기타공제', value: '', align: 'center'},            
                                    { text: '합계', value: '', align: 'center'},            
                                    { text: '사회보험', value: '', align: 'center'},            
                                    { text: '퇴직적립금', value: '', align: 'center'},         
                                    { text: '확정여부', value: '', align: 'center'},   
                                ],
            serverItems     : [],
            amt1Tx          : 0,
            amt1Ntx         : 0,
            amt1Tot         : 0,
            amt2Ins         : 0,
            amt2Tx          : 0,
            amt2Msc         : 0,
            amt2Tot         : 0,
            amtRealPym      : 0,
            amtSclInsrn     : 0,
            amtRtr          : 0,
        },
        filledBtn: [            
            { icon: 'mdi-arrow-expand-right', class: 'ml-1 white--text', color:'blueBtnColor', text: '명세서 발송', type: 'action', width:'800px', disabled: false, },
        ],
        actBtn: [
            { icon: 'mdi-plus-circle-multiple-outline', class: 'white--text', color:'blueBtnColor', text: '임금명세서 (재)생성', tip:'임금명세서 (재)생성', disabled: false, },            
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '확정처리', tip:'임금명세서 확정처리', disabled: false, },
            { icon: 'mdi-undo-variant', class: 'ml-1 white--text', color:'grey006', text: '확정취소', tip:'임금명세서 확정취소', disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', tip: '임금명세서 삭제', disabled: false, },
        ],
        outlinedBtn: [
            { icon: 'mdi-microsoft-excel', class: 'ml-1 font-weight-bold', color:'grey006', text: '엑셀', width:'1000px', disabled: false, },
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: false, },
        ],
        sltMmbrPk               : -1,
        empWageStmtWrtPk        : -1,
        empWageStmtMsgSendPk    : -1,
        //엑셀 출력 필드
        exlColDft: [
            { header: '', key: 'empty', width: 5, },
            { header: '순번', key: 'rn', width: 12, }, { header: '직원명', key: 'name', width: 12, },
            { header: '생년월일', key: 'rsdntBirth', width: 12, }, { header: '입사일', key: 'entDt', width: 12, },
            { header: '직책', key: 'jobNm', width: 12, }, { header: '급여구분', key: 'wgeTypNm', width: 12, },
            { header: '근무시간', key: 'actWrkHr', width: 12, }, { header: '근무일수', key: 'actWrkDd', width: 12, },
        ],
        exlColPrvs:[
            { header: '기본급', key: 'pAmt1', width: 14, }, { header: '장기근속장려금', key: 'pAmt2', width: 14, },
            { header: '주휴수당', key: 'pAmt3', width: 14, }, { header: '시간외수당', key: 'pAmt4', width: 14, },
            { header: '야간근무수당', key: 'pAmt5', width: 14, }, { header: '휴일근무수당', key: 'pAmt6', width: 14, },
            { header: '연장근무수당', key: 'pAmt7', width: 14, }, { header: '연차수당', key: 'pAmt8', width: 14, },
            { header: '연말정산', key: 'pAmt9', width: 14, }, { header: '식대(비과세)', key: 'pAmt10', width: 14, },
        ],
        exlColDdct: [
            { header: '국민연금', key: 'dAmt1', width: 16, }, { header: '고용보험', key: 'dAmt2', width: 16, },
            { header: '건강보험', key: 'dAmt3', width: 16, }, { header: '장기요양보험', key: 'dAmt4', width: 16, },
            { header: '소득세', key: 'dAmt5', width: 16, }, { header: '지방소득세', key: 'dAmt6', width: 16, },
        ],
        exlColFclty:[
            { header: '국민연금', key: 'fAmt1', width: 16, }, { header: '고용보험', key: 'fAmt2', width: 16, },
            { header: '건강보험', key: 'fAmt3', width: 16, }, { header: '장기요양보험', key: 'fAmt4', width: 16, },
            { header: '산재보험', key: 'fAmt5', width: 16, }, { header: '퇴직적립금', key: 'dAmtRtr', width: 16, },
        ],

        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '24', inqYYMM: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
        wgeCnfrmS   : false,
        wgeCnfrmC   : false,
        rmWgeAct    : false,
        wrtCnfrm    : true,
    })
}
</script>