<template>
    <v-sheet class="ml-0">
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <v-row class="mt-2" no-gutters>
            <v-col cols="4">
                <div class="d-flex align-center">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold mr-2">식사&nbsp;제공&nbsp;시간</span>
                    <span class="d-flex align-center">
                        <v-btn
                            class="mr-9" width="21" height="21" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 27)" icon>
                            <v-icon color="grey006" size="21" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                        </v-btn>
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-4" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"  
                        @click="onClickBtn('저장')"             
                        min-width="30" height="25" small outlined>
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                    <v-icon size="20">mdi-content-save-edit-outline</v-icon>
                                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">저장</span>
                                </div>  
                            </template>
                            <span>본인부담금&nbsp;청구&nbsp;월&nbsp;저장</span>
                        </v-tooltip>
                    </v-btn>
                </div>
                <div class="mt-2 d-flex align-center" style="">
                    <div style="width: 200px;">
                        <v-row class="tableBT" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01" cols="6">
                                <span class="black--text font-weight-medium py-2" style="text-align: center;">아침</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center" cols="6">
                                <span class="d-inline-block align-center">
                                    <span class="d-inline-block ml-1 pa-1">
                                        <CmmTimeField v-model="itm1.itm" :required="true"></CmmTimeField>
                                    </span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="tableBT" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01" cols="6">
                                <span class="black--text font-weight-medium py-2" style="text-align: center;">점심</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center" cols="6">
                                <span class="d-inline-block align-center">
                                    <span class="d-inline-block ml-1 pa-1">
                                        <CmmTimeField v-model="itm2.itm" :required="true"></CmmTimeField>
                                    </span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="tableBT" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01" cols="6">
                                <span class="black--text font-weight-medium py-2" style="text-align: center;">저녁</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center" cols="6">
                                <span class="d-inline-block align-center">
                                    <span class="d-inline-block ml-1 pa-1">
                                        <CmmTimeField v-model="itm3.itm" :required="true"></CmmTimeField>
                                    </span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="tableBT" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01" cols="6">
                                <span class="black--text font-weight-medium py-2" style="text-align: center;">오전간식</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center" cols="6">
                                <span class="d-inline-block align-center">
                                    <span class="d-inline-block ml-1 pa-1">
                                        <CmmTimeField v-model="itm4.itm" :required="true"></CmmTimeField>
                                    </span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="tableBT" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01" cols="6">
                                <span class="black--text font-weight-medium py-2" style="text-align: center;">오후간식</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center" cols="6">
                                <span class="d-inline-block align-center">
                                    <span class="d-inline-block ml-1 pa-1">
                                        <CmmTimeField v-model="itm5.itm" :required="true"></CmmTimeField>
                                    </span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="tableBT tableBB" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01" cols="6">
                                <span class="black--text font-weight-medium py-2" style="text-align: center;">취침시간</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center" cols="6">
                                <span class="d-inline-block align-center">
                                    <span class="d-inline-block ml-1 pa-1">
                                        <CmmTimeField v-model="itm6.itm" :required="true"></CmmTimeField>
                                    </span>
                                </span>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <v-row class="pa-0 mt-6 mb-2 align-center posn-rltv" no-gutters>
                    <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                        <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                    </span>
                    <span class="fontOneDotTwoRem font-weight-medium ml-6 mr-2">급여제공기록 익일 작성 여부</span>
                    <span class="d-flex align-center">
                        <v-btn
                            class="mr-9" width="21" height="21" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 37)" icon>
                            <v-icon color="grey006" size="21" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                        </v-btn>
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="white--text" style="padding: 9px 7px 8px 5px !important;"
                        color="blueBtnColor" @click="preOvrnLmt"
                        min-width="20" height="20" small outlined>
                        <v-icon class="mr-1" size="14">mdi-content-save-edit-outline</v-icon>
                        <span class="fontNineThRem" style="padding-bottom: 2px;">저장</span>
                    </v-btn>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 9px;" cols="3">
                        <span class="font-weight-medium">작성여부</span>
                    </v-col>
                    <v-col class="px-2 py-1" cols="9">
                        <v-radio-group
                            v-model="ovrnLmtInf.itmVal1"
                            class="radio-dft-Theme d-flex mt-0 pt-0"
                            hide-details row>
                            <v-radio
                                class="d-inline-block ma-0 pt-1 pl-2"
                                style="padding-bottom: 6px !important;"
                                :ripple="false" label="예" value="1">
                            </v-radio>
                            <v-radio
                                class="d-inline-block ma-0 pt-1 pl-4"
                                style="padding-bottom: 6px !important;"
                                :ripple="false" label="아니요" value="2">
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="5">
                        <span class="font-weight-medium">익일기록 작성 시작시간</span>
                    </v-col>
                    <v-col class="px-2 py-1" cols="3">
                        <CmmTimeField v-model="ovrnLmtInf.itmVal2" :required="true"></CmmTimeField>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0 mt-6 mb-2 align-center posn-rltv" no-gutters>
                    <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                        <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
                    </span>
                    <span class="fontOneDotTwoRem font-weight-medium ml-6 mr-2">출퇴근 기록 방법</span>
                    <span class="d-flex align-center">
                        <v-btn
                            class="mr-9" width="21" height="21" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 38)" icon>
                            <v-icon color="grey006" size="21" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                        </v-btn>
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="white--text" style="padding: 9px 7px 8px 5px !important;"
                        color="blueBtnColor"
                        @click="preWrkngJrnl"
                        min-width="20" height="20" small outlined>
                        <v-icon class="mr-1" size="14">mdi-content-save-edit-outline</v-icon>
                        <span class="fontNineThRem" style="padding-bottom: 2px;">저장</span>
                    </v-btn>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="px-2 py-1" cols="12">
                        <v-radio-group
                            v-model="wrkngJrnlInf.itmVal1"
                            class="radio-dft-Theme d-flex mt-0 pt-0"
                            hide-details row>
                            <v-radio
                                class="d-inline-block ma-0 pt-1 pl-2"
                                style="padding-bottom: 6px !important;"
                                :ripple="false" label="근무일정 복사" value="1">
                            </v-radio>
                            <v-radio
                                class="d-inline-block ma-0 pt-1 pl-4"
                                style="padding-bottom: 6px !important;"
                                :ripple="false" label="출퇴근 실제 시간" value="2">
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="4">
                <v-form ref="form" lazy-validation class="mt-1 ">
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">치료식이</span>
                        <v-btn
                            class="ml-1" width="21" height="21" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 28)" icon>
                            <v-icon color="grey006" size="21" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                        </v-btn>
                    </div>
                    <div class="mt-2 d-flex align-center">
                        <div style="width: 300px;">
                            <v-row class="tableBT" :class="['', items.length > 14 ? 'mr-2__5' : '']" no-gutters>
                                <v-col class="d-flex justify-center align-center nmlBfClrImp-greyE01 tableBR" cols="8">
                                    <span class="black--text font-weight-medium py-2" style="text-align: center;">치료식이명</span>
                                </v-col>
                                <v-col class="d-flex justify-center align-center nmlBfClrImp-greyE01" cols="4">
                                    <span class="d-inline-block align-center">
                                        <v-btn icon color='blue200' @click="addRow()">
                                            <v-icon>mdi-plus-circle-outline</v-icon>
                                        </v-btn>
                                    </span>
                                </v-col>
                            </v-row>
                            <div style=" height:630px; overflow-y:auto; ">
                                <v-row class="tableBB" no-gutters v-for="(item, i) in items" :key="i" >
                                    <v-col class="d-flex justify-center align-center " cols="8" style=" border-right:1px solid #dbdbdb !important;">
                                        <span class="black--text font-weight-medium py-2" style="text-align: center; width: 90%;">
                                            <v-text-field v-model="item.itmVal1" color="blue" height="28" hide-details outlined dense :rules="[rules.required]"></v-text-field>
                                        </span>
                                    </v-col>
                                    <v-col class="d-flex justify-center align-center" cols="4">
                                        <span class="d-flex align-center justify-center" style="text-align:center;">
                                            <v-btn icon color='blue200' @click="onSave('initClcd3', i)">
                                                <v-icon>mdi-content-save-outline</v-icon>
                                            </v-btn>
                                            <v-btn icon color='grey006' v-if="item.fcltyInitPk > 0" @click="onDelete(i)">
                                                <v-icon>mdi-trash-can-outline</v-icon>
                                            </v-btn>
                                            <v-btn class="" color="grey006" icon height="26" v-else
                                                @click="onDelete1(i)"><v-icon>mdi-minus-circle-outline</v-icon>
                                            </v-btn>
                                        </span>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                </v-form>   
            </v-col>
            <v-col cols="3"></v-col>
        </v-row>
        <v-dialog v-model="dialogDelete" max-width="500">
            <btnModalDelete :title="'치료식이'" @madalState="onDeleteBtn"></btnModalDelete>
        </v-dialog>
    </v-sheet>
</template>

<script>
import CmmTimeField from '../commons/CmmTimeField.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import { selFcltyInitList, insFcltyInit, insFcltyInitMulti, delFcltyInit } from '../../api/opr.js';
export default {
    name: 'FacInfoMgmtTab4',

    components: {
        CmmTimeField,
        btnModalDelete,
    },

    created: function () {
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {    
            this.selFcltyInitList()
        })
    },

    computed: {

    },

    watch: {
    },

    methods: {
        onClickBtn: function(text) {
            if(text=="저장"){           
                this.onSave('initClcd2', null);
            } 
        },

        // 요양시스 기본정보 단건 저장(식사제공시간, 급여제공기록 시간)
        setFcltyInit: function (obj) {
            insFcltyInit(obj)
                .then((response) => (this.insFcltyInitAfter(response.data)))
                .catch((error) => console.log('connect error /opr/insFcltyInit : ' + error))
        },

        fcltyInitRst: function (res) {
            console.log(res)
        },

        selFcltyInitList: function () {
            selFcltyInitList(this.$store.getters.getFcltyNum, ['2', '3', '4', '5'])
                .then((response) => (this.selFcltyInitListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selFcltyInitList : ' + error))
        },
        selFcltyInitListAfter: function (res) {
            this.items.splice(0)
            res.forEach(e => {
                if(e.initClcd == "2"){
                    if (e.itmCd1 == '1') {
                        this.itm1.fcltyInitPk = e.fcltyInitPk
                        this.itm1.itmCd1 = e.itmCd1
                        this.itm1.itm = e.itmVal1 +":"+ e.itmVal2
                    }
                    if (e.itmCd1 == '2') {
                        this.itm2.fcltyInitPk = e.fcltyInitPk
                        this.itm2.itmCd1 = e.itmCd1
                        this.itm2.itm = e.itmVal1 +":"+ e.itmVal2
                    }
                    if (e.itmCd1 == '3') {
                        this.itm3.fcltyInitPk = e.fcltyInitPk
                        this.itm3.itmCd1 = e.itmCd1
                        this.itm3.itm = e.itmVal1 +":"+ e.itmVal2
                    }
                    if (e.itmCd1 == '4') {
                        this.itm4.fcltyInitPk = e.fcltyInitPk
                        this.itm4.itmCd1 = e.itmCd1
                        this.itm4.itm = e.itmVal1 +":"+ e.itmVal2
                    }
                    if (e.itmCd1 == '5') {
                        this.itm5.fcltyInitPk = e.fcltyInitPk
                        this.itm5.itmCd1 = e.itmCd1
                        this.itm5.itm = e.itmVal1 +":"+ e.itmVal2
                    }
                    if (e.itmCd1 == '6') {
                        this.itm6.fcltyInitPk = e.fcltyInitPk
                        this.itm6.itmCd1 = e.itmCd1
                        this.itm6.itm = e.itmVal1 +":"+ e.itmVal2
                    }
                }else if(e.initClcd == "3"){
                    this.items.push(e)
                }else if(e.initClcd == "4"){
                    this.ovrnLmtInf.fcltyInitPk = e.fcltyInitPk
                    this.ovrnLmtInf.itmVal1 = e.itmVal1
                    this.ovrnLmtInf.itmVal2 = e.itmVal2
                }else if(e.initClcd == "5"){
                    this.wrkngJrnlInf.fcltyInitPk = e.fcltyInitPk
                    this.wrkngJrnlInf.itmVal1 = e.itmVal1
                }

                
            });
            if(this.items.length==0){
                this.addRow()
            }
        },
        // 급여제공기록 작성여부 저장
        preOvrnLmt: function () {
            let ovrInf = Object.assign({}, this.ovrnLmtInf)
            ovrInf.fcltyNum = this.$store.getters.getFcltyNum
            ovrInf.userNm = this.$store.getters.getUserNm
            
            if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(ovrInf.itmVal2))
                this.setFcltyInit(ovrInf)
            else
                this.snackControll([true, 2500, '입력 항목을 다시 확인해주세요.', 'warning'])
        },
        
        //출퇴근 기록 방법 저장
        preWrkngJrnl: function () {
            let wrkngJrnl = Object.assign({}, this.wrkngJrnlInf)
            wrkngJrnl.fcltyNum = this.$store.getters.getFcltyNum
            wrkngJrnl.userNm = this.$store.getters.getUserNm            
            this.setFcltyInit(wrkngJrnl)
        },

        onSave: function (type, index) {
            if (this.$refs.form.validate()) {
                if (type == 'initClcd3') {
                    let item = this.items[index]
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm: this.$store.getters.getUserNm,
                        initClcd: '3',
                        itmVal1: item.itmVal1,
                        itmCd1: item.itmCd1.toString(),
                        fcltyInitPk: item.fcltyInitPk,
                    }
                    insFcltyInit(obj)
                        .then((response) => (this.insFcltyInitAfter(response.data)))
                        .catch((error) => console.log('connect error /opr/insFcltyInit : ' + error))
                }
                if (type == 'initClcd2') {
                    let itm1 = this.itm1.itm.split(":")
                    let itm2 = this.itm2.itm.split(":")
                    let itm3 = this.itm3.itm.split(":")
                    let itm4 = this.itm4.itm.split(":")
                    let itm5 = this.itm5.itm.split(":")
                    let itm6 = this.itm6.itm.split(":")
                    let item = {
                        itmVal1: itm1[0],
                        itmVal2: itm1[1],
                        itmCd1: "1",
                        initClcd: "2",
                        fcltyInitPk: this.itm1.fcltyInitPk,
                        fcltyNum: this.$store.getters.getFcltyNum
                    }
                    this.items1.push(item)
                    let item1 = {
                        itmVal1: itm2[0],
                        itmVal2: itm2[1],
                        itmCd1: "2",
                        initClcd: "2",
                        fcltyInitPk: this.itm2.fcltyInitPk,
                        fcltyNum: this.$store.getters.getFcltyNum
                    }
                    this.items1.push(item1)
                    let item2 = {
                        itmVal1: itm3[0],
                        itmVal2: itm3[1],
                        itmCd1: "3",
                        initClcd: "2",
                        fcltyInitPk: this.itm3.fcltyInitPk,
                        fcltyNum: this.$store.getters.getFcltyNum
                    }
                    this.items1.push(item2)
                    let item3 = {
                        itmVal1: itm4[0],
                        itmVal2: itm4[1],
                        itmCd1: "4",
                        initClcd: "2",
                        fcltyInitPk: this.itm4.fcltyInitPk,
                        fcltyNum: this.$store.getters.getFcltyNum
                    }
                    this.items1.push(item3)
                    let item4 = {
                        itmVal1: itm5[0],
                        itmVal2: itm5[1],
                        itmCd1: "5",
                        initClcd: "2",
                        fcltyInitPk: this.itm5.fcltyInitPk,
                        fcltyNum: this.$store.getters.getFcltyNum
                    }
                    this.items1.push(item4)
                    let item5 = {
                        itmVal1: itm6[0],
                        itmVal2: itm6[1],
                        itmCd1: "6",
                        initClcd: "2",
                        fcltyInitPk: this.itm6.fcltyInitPk,
                        fcltyNum: this.$store.getters.getFcltyNum
                    }
                    this.items1.push(item5)

                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm: this.$store.getters.getUserNm,
                        list: this.items1
                    }
                    insFcltyInitMulti(obj)
                        .then((response) => (this.insFcltyInitMultiAfter(response.data)))
                        .catch((error) => console.log('connect error /opr/insFcltyInit : ' + error))
                }
            }else{
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            }
        },
        insFcltyInitAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selFcltyInitList()
                this.addRow()
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        insFcltyInitMultiAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selFcltyInitList()
                this.items1.splice(0)
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        addRow: function () {
            if(this.items.length>0){
                let itmCd = this.items[this.items.length - 1]
                let nextCd1 = parseInt( Number(itmCd.itmCd1) )
                this.items.push({initClcd: '', itmCd1: nextCd1+1, itmVal1: '', itmCd2: '', itmVal2: '', fcltyInitPk: 0,})
            }else{
                this.items.push({initClcd: '', itmCd1: '1', itmVal1: '', itmCd2: '', itmVal2: '', fcltyInitPk: 0,})
            }
        },
        onDelete1(key) {
            this.items.splice(key, 1)
        },
        onDelete(key) {
            if (key > -1) {
                this.key = key
                this.dialogDelete = true
            } else {
                if (key != 0) {
                    this.items.splice(key, 1)
                }
            }
        },
        onDeleteBtn: function (res) {
            if (res == 9990) {
                if (this.items[this.key].fcltyInitPk > 0) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        fcltyInitPk: this.items[this.key].fcltyInitPk,
                        userNm: this.$store.getters.getUserNm,
                    }
                    delFcltyInit(obj)
                        .then((response) => (this.delFcltyInitAfter(response.data)))
                        .catch((error) => console.log(error))
                } else {
                    this.items.splice(this.key, 1)
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }
        },
        delFcltyInitAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.dialogDelete = false
                this.selFcltyInitList()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
    },

    data: () => ({
        snackOptions: [false, 3000, '', 'info'],
        items: [
            {
                initClcd: '',
                itmCd1: '',
                itmVal1: '',
                itmCd2: '',
                itmVal2: '',
                fcltyInitPk: 0,
            }
        ],
        items1: [],
        itm1: {fcltyInitPk: 0,itmCd1: '1', itm: '00:00'},
        itm2: {fcltyInitPk: 0,itmCd1: '2', itm: '00:00'},
        itm3: {fcltyInitPk: 0,itmCd1: '3', itm: '00:00'},
        itm4: {fcltyInitPk: 0,itmCd1: '4', itm: '00:00'},
        itm5: {fcltyInitPk: 0,itmCd1: '5', itm: '00:00'},
        itm6: {fcltyInitPk: 0,itmCd1: '6', itm: '00:00'},
        ovrnLmtInf: { fcltyInitPk: -1, initClcd: '4', itmCd1: '1', itmVal1: '1', itmCd2: '2', itmVal2: '00:00' },
        wrkngJrnlInf:{ fcltyInitPk: -1, initClcd: '5', itmCd1: '1', itmVal1: '1' },
        header: [
            { text: '아침'},
            { text: '점심'},
            { text: '저녁'},
            { text: '오전간식'},
            { text: '오후간식'},
            { text: '취침시간'},
        ],
        dialogDelete: false,
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '저장', type: 'action', width: '500px', visible: true, disabled: false, },
        ],
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>