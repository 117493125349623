import { render, staticRenderFns } from "./YysAllNotice.vue?vue&type=template&id=336083c2"
import script from "./YysAllNotice.vue?vue&type=script&lang=js"
export * from "./YysAllNotice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports