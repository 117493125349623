<template>
    <v-sheet class="d-inline-block rounded-lg rounded-l-0" min-width="1260" max-width="1560" height="835">        
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">10-3. 코드관리</span>
            </div>
            <v-spacer></v-spacer>
        </div>
        <v-row class="mt-3" no-gutters>
            <v-col class="ma-0 pa-0" cols="5">
                <div class="d-flex align-center">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">분류코드</span>
                </div>
                <v-row class="mt-3" no-gutters>
                    <v-col class="ma-0 py-0 px-3" cols="12">
                        <v-data-table class="dft-hvr-tbl"
                            :headers="clcdCdHeader"
                            :items="clcdCdList"
                            :loading="clcdCdLoader"
                            loading-text="Loading... Please wait"
                            fixed-header hide-default-header hide-default-footer dense disable-pagination                         
                            height='720'
                        >
                            <template v-slot:header="{ props }" >
                                <thead>
                                    <tr>                                        
                                        <th class="black--text clmsDtlHeaderCommon py-2 tableBT tableBR" style="width:50%;">{{props.headers[0]?.text}}</th>
                                        <th class="black--text clmsDtlHeaderCommon tableBT" style="width:50%;">{{props.headers[1]?.text}}</th>                                        
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:item='{ item, index}' >
                                <tr :class="['', sltClcdIdx == index ? 'onClr' : 'hvrClr']" @click="clcdClickEvent(index, item)">                                    
                                    <td class="clmsBodyCommon tableBR pa-0">{{item.valClcdnm}}</td>
                                    <td class="clmsBodyCommon pa-0">{{item.valCdDesc }}</td>                                    
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="ma-0 pa-0" cols="7">
                <div class="d-flex align-center">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">상세코드</span>
                </div>
                <v-row class="mt-3" no-gutters>
                    <v-col class="ma-0 py-0 px-3" cols="12">
                        <v-data-table
                            :headers="itmCdHeader"
                            :items="itmCdList"
                            :loading="itmCdLoader"
                            loading-text="Loading... Please wait"
                            fixed-header hide-default-header hide-default-footer dense disable-pagination                         
                            height='720'
                        >
                            <template v-slot:header="{ props }" >
                                <thead>
                                    <tr>                                        
                                        <th class="black--text clmsDtlHeaderCommon py-2 tableBT tableBR" style="width:65%;">
                                            <span v-if="sltItem.valClcd == 96">
                                                목욕시간
                                            </span>
                                            <span v-else>
                                                {{props.headers[0]?.text}}
                                            </span>
                                        </th>                                        
                                        <th class="black--text clmsDtlHeaderCommon py-2 tableBT tableBR" style="width:10%;">{{props.headers[1]?.text}}</th>
                                        <th class="black--text clmsDtlHeaderCommon py-2 tableBT tableBR" style="width:13%;">{{props.headers[2]?.text}}</th>
                                        <th class="black--text clmsDtlHeaderCommon tableBT" style="width:12%;">
                                            <v-btn :disabled="sltItem.valClcd == 96" @click="addRow"
                                                icon class="mr-1" color="blue200">                                                
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon v-bind="attrs" v-on="on">mdi-plus-circle-outline</v-icon>
                                                    </template>
                                                    <span>상세코드 추가</span>
                                                </v-tooltip>
                                            </v-btn>
                                        </th>                                        
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:item='{item,index}' >
                                <tr>                                    
                                    <td class="clmsBodyCommon tableBR pa-1">
                                        <div v-if="sltItem.valClcd == 116">
                                            <v-text-field v-model="item.itmCdnm" :error="item.err" :key="forceRender" height="28" color="blue" hide-details outlined dense></v-text-field>
                                        </div>
                                        <div v-else>  
                                            <div class="d-flex justify-end align-center" v-if="sltItem.valClcd == 96" >                                           
                                                <span class="inline-block" style="width:75px;"><v-text-field v-model="item.valCdnm" color="blue" hide-details outlined dense></v-text-field></span>
                                                <span class="inline-block pl-1">분</span>
                                            </div>
                                            <span v-else>                                           
                                                <v-text-field v-model="item.valCdnm" :error="item.err" :key="forceRender" height="28" color="blue" hide-details outlined dense></v-text-field>
                                            </span>
                                        </div>
                                    </td>
                                    <!-- <td class="clmsBodyCommon tableBR pa-1">
                                        <span v-if="sltItem.valClcd == 116">{{ item.itmCd }}</span>
                                        <span v-else>{{ item.valCd }}</span>
                                    </td> -->
                                    <td class="clmsBodyCommon tableBR pa-1">
                                        <v-text-field
                                            v-model="item.ord"
                                            :disabled="sltItem.valClcd == 96 || item.fcltyNum == '10000000000'"
                                            color="blue" height="28"
                                            hide-details outlined dense>
                                        </v-text-field>
                                    </td>
                                    <td class="clmsBodyCommon tableBR pa-1">
                                        <v-select class="hsptlSelect"
                                            v-model="item.useYn" height="28"
                                            :disabled="sltItem.valClcd == 96 || item.fcltyNum == '10000000000'"
                                            item-text="valcdnm" item-value="valcd" :items="selYn"
                                            dense hide-details outlined>
                                        </v-select>
                                    </td>
                                    <td class="clmsBodyCommon pa-0">
                                        <div v-if="item.fcltyNum != '10000000000'">
                                            <v-btn color="blue200" icon @click="onSave(item)">                                                
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon v-bind="attrs" v-on="on">mdi-content-save-outline</v-icon>
                                                    </template>
                                                    <span>저장</span>
                                                </v-tooltip>
                                            </v-btn>
                                            <v-btn v-if="item.cmnItmCdPk == undefined && item.cmnItmValCdPk == undefined" icon class="" @click="onDelete(index)">                                                
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <!-- <v-icon v-if="(sltItem.valClcd == 116 && item.cmnItmCdPk > 0)" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                                        <v-icon v-else-if="(sltItem.valClcd != 116 && item.cmnItmValCdPk > 0)" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon> -->
                                                        <v-icon v-bind="attrs" v-on="on">mdi-minus-circle-outline</v-icon>
                                                    </template>
                                                    <span v-if="sltItem.valClcd == 116">{{item.itmCdnm}} 삭제</span>
                                                    <span v-else>{{item.valCdnm}} 삭제</span>
                                                </v-tooltip>
                                            </v-btn>
                                        </div>
                                        <div v-else>
                                            <span class="red--text">공통코드</span>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-sheet>
</template> 

<script>

import { selCmnItmValClCdList, selCmnItmCd, selCmnItmValCd, insCmnItmCd, insCmnItmValCd, delCmnItmCd, delCmnItmValCd} from '../../api/cmm.js';

export default {
    name: 'CmmCdMgmt',

    components: {
        
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.selCmnItmValClCdList()
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },
    watch: {
        getSubEvt: function (bol) {
            if(bol && this.rmCnfmCdMgmt){
                this.preDelCmnItmCd()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmCnfmCdMgmt = false
            }
        },

    },

    methods: {

        preCmnItmCd:function(itm){

            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                userNm      : this.$store.getters.getUserNm,
                clsfc1Cd    : this.sltItem.clsfc1cd,
                clsfc1Cdnm  : this.sltItem.clsfc1cdnm,
                clsfc2Cd    : this.sltItem.clsfc2cd,
                clsfc2Cdnm  : this.sltItem.clsfc2cdnm,
                clsfc3Cd    : this.sltItem.clsfc3cd,
                clsfc3Cdnm  : this.sltItem.clsfc3cdnm,
                valClcd     : this.sltItem.valClcd,
                itmDesc     : this.sltItem.valCdDesc,
                itmCdnm     : itm.itmCdnm,
                itmCd       : itm.itmCd,
                ord         : itm.ord,
                useYn       : itm.useYn,
                cmnItmCdPk  : (itm.cmnItmCdPk != undefined && itm.cmnItmCdPk != null)?itm.cmnItmCdPk:0
            }

            this.setCmnItmCd(obj)
        },

        preCmnItmValCd:function(itm){
            let cmnItmValCdPk   = 0
            let valCd           = 0
            let maxValCd        = 0
            let maxOrd          = 0

            if(itm.cmnItmValCdPk != undefined && itm.cmnItmValCdPk != null) {
                cmnItmValCdPk   = itm.cmnItmValCdPk
                valCd           = itm.valCd
            } else {
                this.itmCdList.forEach(x => {                    
                    if(maxValCd < Number(x.valCd)) maxValCd = x.valCd  
                    if(maxOrd < Number(x.ord)) maxOrd = x.ord  
                })

                valCd = Number(maxValCd)+1
            }

            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                userNm      : this.$store.getters.getUserNm,                
                valClcd     : this.sltItem.valClcd,
                valClcdnm   : this.sltItem.valClcdnm,
                valCdDesc   : this.sltItem.valCdDesc,
                valCdnm     : itm.valCdnm,
                valCd       : valCd,
                ord         : (itm.ord != '' && itm.ord != null)?itm.ord:Number(maxOrd)+1,
                useYn       : itm.useYn,
                cdPrp       : '2',
                cmnItmValCdPk  : cmnItmValCdPk,
            }

            this.setCmnItmValCd(obj)
        },

        preDelCmnItmCd:function(){
            let pass = true
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                userNm      : this.$store.getters.getUserNm, 
                cmnItmCdPk  : 0,
                cmnItmValCdPk : 0,
            }

            if(this.sltItem.valClcd == 116) {
                if(this.itmCdList[this.sltIdx].cmnItmCdPk > 0){
                    obj.cmnItmCdPk = this.itmCdList[this.sltIdx].cmnItmCdPk
                    this.delCmnItmCd(obj)
                }else{
                    pass = false
                }
                
            } else {
                
                if(this.itmCdList[this.sltIdx].cmnItmValCdPk > 0){
                    obj.cmnItmValCdPk = this.itmCdList[this.sltIdx].cmnItmValCdPk
                    this.delCmnItmValCd(obj)
                }else{
                    pass = false
                }
            }

            if(!pass) {
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '삭제대상을 찾을 수 없습니다.'         // 경고 타이틀
                this.$store.commit('setWrnnInf', obj)
            }
        },

        selCmnItmValClCdList:function(){    
            this.clcdCdList.splice(0)   
            this.clcdCdLoader = true    
            selCmnItmValClCdList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.selCmnItmValClCdListAfter(response.data) ))
                .catch((error) => console.log('connect error cmm/selCmnItmValClCdList : ' + error))
        },

        selCmnItmCd: function () {
            this.itmCdList.splice(0)
            this.itmCdLoader = true   
            selCmnItmCd(this.$store.getters.getFcltyNum, this.sltItem.clsfc1cd, this.sltItem.clsfc2cd, this.sltItem.clsfc3cd, 1, 'ALL')    
                .then((response) => (this.selCmnItmCdAfter(response.data)))
                .catch((error) => console.log('connect error cmm/selCmnItmCd : ' + error))
        },

        selCmnItmValCd: function () {    
            this.itmCdList.splice(0)   
            this.itmCdLoader = true        
            selCmnItmValCd(this.$store.getters.getFcltyNum, this.sltItem.valClcd)
                .then((response) => (this.selCmnItmCdAfter(response.data)))
                .catch((error) => console.log('connect error cmm/selCmnItmValCd : ' + error))
        },

        setCmnItmCd:function(obj){
            insCmnItmCd(obj)
                .then((response) => (this.setSaveAfter(response.data)))
                .catch((error) => console.log('connect error cmm/insCmnItmCd : ' + error))
        },

        setCmnItmValCd:function(obj){
            insCmnItmValCd(obj)
                .then((response) => (this.setSaveAfter(response.data)))
                .catch((error) => console.log('connect error cmm/insCmnItmValCd : ' + error))
        },

        //상세코드 삭제(116)
        delCmnItmCd:function(obj){
            delCmnItmCd(obj)
                .then((response) => (this.setDelAfter(response.data)))
                .catch((error) => console.log('connect error cmm/delCmnItmCd : ' + error))
        },

        //상세코드 삭제(116 외)
        delCmnItmValCd:function(obj){
            delCmnItmValCd(obj)
                .then((response) => (this.setDelAfter(response.data)))
                .catch((error) => console.log('connect error cmm/delCmnItmValCd : ' + error))
        },

        selCmnItmValClCdListAfter: function (res) {
            
            this.clcdCdLoader = false

            if(res != null) { 
                let rn = 1 
                res.forEach(el => {
                    el.rn = rn
                    this.clcdCdList.push(el)
                    rn++
                });
            }            
        },

        selCmnItmCdAfter:function(res){            
            this.itmCdLoader = false
            if(res != null && res.length > 0){
                res.forEach(el => {
                    el.err = false
                    this.itmCdList.push(el)
                });
            } else {
                if(this.sltItem.valClcd == '96'){
                    let obj = {
                        cmnItmValCdPk   : 0,
                        err             : false,
                        fcltyNum        : this.$store.getters.getFcltyNum,
                        ord             : 1,
                        useYn           : '1',
                        valCd           : '1',
                        valCdDesc       : '목욕급여제공기록',
                        valCdnm         : '0',
                        valClcd         : '96',
                        valClcdnm       : '목욕시간',
                    }
                    this.itmCdList.push(obj)
                }
            }
        },

        setSaveAfter:function(res){
            let obj = {}

            if(res.statusCode == 200){                                                
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
            }
            else{                
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
            }

            this.$store.commit('setWrnnInf', obj)

            if(this.sltItem.valClcd == 116)
                this.selCmnItmCd()
            else
                this.selCmnItmValCd()

        },

        setDelAfter:function(res){           
            this.setSaveAfter(res)

            this.rmCnfmCdMgmt = false
            this.itmCdList.splice(this.sltIdx, 1)
            this.sltIdx = -1
        },

        //분류코드 선택시
        clcdClickEvent:function(idx,item){
            
            this.sltClcdIdx = idx
            this.sltItem.valClcd  = item.valClcd
            this.sltItem.clsfc1cd = item.clsfc1cd
            this.sltItem.clsfc2cd = item.clsfc2cd
            this.sltItem.clsfc3cd = item.clsfc3cd

            this.sltItem.valClcd     = (item.valClcd != undefined)?item.valClcd:''
            this.sltItem.clsfc1cd    = (item.clsfc1cd != undefined)?item.clsfc1cd:''
            this.sltItem.clsfc1cdnm  = (item.clsfc1cdnm != undefined)?item.clsfc1cdnm:''
            this.sltItem.clsfc2cd    = (item.clsfc2cd != undefined)?item.clsfc2cd:''
            this.sltItem.clsfc2cdnm  = (item.clsfc2cdnm != undefined)?item.clsfc2cdnm:''
            this.sltItem.clsfc3cd    = (item.clsfc3cd != undefined)?item.clsfc3cd:''
            this.sltItem.clsfc3cdnm  = (item.clsfc3cdnm != undefined)?item.clsfc3cdnm:''
            this.sltItem.valCdDesc   = (item.valCdDesc != undefined)?item.valCdDesc:''
            this.sltItem.valClcdnm   = (item.valClcdnm != undefined)?item.valClcdnm:''

            if(item.valClcd == 116)
                this.selCmnItmCd()
            else
                this.selCmnItmValCd()
        },

        addRow:function(){
            this.itmCdList.push({
                itmCdnm : '',
                valCdnm : '',
                ord     : '',
                useYn   : '1',
            })
        },

        //저장
        onSave:function(itm){
            //validation 초기화
            this.itmCdList.forEach(el => {el.err= false});

            if(this.sltItem.valClcd == 116) {
                if(itm.itmCdnm.replace(/\s/g, "") != ''){
                    this.preCmnItmCd(itm)
                }else{
                    let obj = {}
                    obj.actv = true                 // 경고 노출여부
                    obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                    obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                    obj.title = '코드명을 입력해주세요.'         // 경고 타이틀
                    this.$store.commit('setWrnnInf', obj)

                    itm.err= true
                }
            }
            else {
                if(itm.valCdnm.replace(/\s/g, "") != ''){
                    this.preCmnItmValCd(itm)
                }else{
                    let obj = {}
                    obj.actv = true                 // 경고 노출여부
                    obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                    obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                    obj.title = '코드명을 입력해주세요.'         // 경고 타이틀
                    this.$store.commit('setWrnnInf', obj)

                    itm.err= true
                }
            } 

            this.forceRender++
        },

        //상세코드 삭제
        onDelete:function(idx){

            this.itmCdList.splice(idx, 1)

            // if(this.sltItem.valClcd == 116) {                
            //     if(itm.cmnItmCdPk > 0){
            //         this.sltIdx = idx
            //         this.onCnfm(itm)
            //     }else{
            //         this.itmCdList.splice(idx, 1)
            //     }

            // }else{
            //     if(itm.cmnItmValCdPk > 0){
            //         this.sltIdx = idx
            //         this.onCnfm(itm)
            //     }else{
            //         this.itmCdList.splice(idx, 1)
            //     }
            // }
        },

        onCnfm:function(itm){
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            if(this.sltItem.valClcd == 116) {
                obj.title = this.sltItem.valClcdnm
                obj.cntnt = ['상세코드 \''+itm.itmCdnm+'\' 을(를) 삭제하시겠습니까?']
            }
            else{
                obj.title = this.sltItem.valClcdnm
                obj.cntnt = ['상세코드 \''+itm.valCdnm+'\' 을(를) 삭제하시겠습니까?']
            }
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });

            this.rmCnfmCdMgmt = true
        }


    },

    data: () => ({
        forceRender     : 0,
        sltClcdIdx      : -1,
        rmCnfmCdMgmt    : false,
        sltIdx          : -1,
        sltItem         : {
            valClcd     : '',
            clsfc1cd    : '',
            clsfc1cdnm  : '',
            clsfc2cd    : '',
            clsfc2cdnm  : '',
            clsfc3cd    : '',
            clsfc3cdnm  : '',
            valCdDesc   : '',
            valClcdnm   : '',
        },
        clcdCdLoader  : false,
        clcdCdHeader:[
            { text: '분류', align:'center', value: 'valClcdnm',},            
            { text: '관련업무', align:'center', value: 'valCdDesc',},
        ],
        clcdCdList:[],
        itmCdLoader  : false,
        itmCdHeader:[
            { text: '코드명', align:'center', value: 'valCdnm',},  
            { text: '순서', align:'center', value: 'ord',},            
            { text: '사용여부', align:'center', value: 'useYn',},
            { text: '처리', align:'center', value: '',},
        ],
        itmCdList:[],
        selYn: [{ valcd: '1', valcdnm: '사용' }, { valcd: '2', valcdnm: '미사용' },],
    }),
}
</script>
